export const gcpMemorystoreSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      description:
        "The ID of the instance or a fully qualified identifier for the instance.",
    },
    region: {
      type: "string",
      description: "The GCP region to use.",
    },
    enable_apis: {
      type: "boolean",
      description: "Flag for enabling redis.googleapis.com in your project",
      default: true,
    },
    authorized_network: {
      type: "string",
      description:
        "The full name of the Google Compute Engine network to which the instance is connected. For example: projects/<PROJECT_ID>/global/networks/<NETWORK_NAME>",
    },
    tier: {
      type: "string",
      description:
        "The service tier of the instance. https://cloud.google.com/memorystore/docs/redis/reference/rest/v1/projects.locations.instances#Tier",
      enum: ["TIER_UNSPECIFIED", "BASIC", "STANDARD_HA"],
      default: "BASIC",
    },
    memory_size_gb: {
      type: "number",
      description: "Redis memory size in GiB. Defaulted to 1 GiB",
      default: 1,
    },
    replica_count: {
      type: ["number", "null"],
      description: "The number of replicas.",
      default: null,
    },
    read_replicas_mode: {
      type: "string",
      enum: [
        "READ_REPLICAS_MODE_UNSPECIFIED",
        "READ_REPLICAS_DISABLED",
        "READ_REPLICAS_ENABLED",
      ],
      description:
        "Read replicas mode. https://cloud.google.com/memorystore/docs/redis/reference/rest/v1/projects.locations.instances#readreplicasmode ",
      default: "READ_REPLICAS_DISABLED",
    },
    location_id: {
      type: ["string", "null"],
      description:
        "The zone where the instance will be provisioned. If not provided, the service will choose a zone for the instance. For STANDARD_HA tier, instances will be created across two zones for protection against zonal failures. If [alternativeLocationId] is also provided, it must be different from [locationId].",
    },
    alternative_location_id: {
      type: ["string", "null"],
      description:
        "The alternative zone where the instance will be provisioned.",
      default: null,
    },
    redis_version: {
      type: ["string", "null"],
      description:
        "The version of Redis software. If not provided, latest supported version will be used. Currently, the supported values are: REDIS_3_2 for Redis 3.2 compatibility, REDIS_4_0 for Redis 4.0 compatibility (default), REDIS_5_0 for Redis 5.0 compatibility, REDIS_6_X for Redis 6.x compatibility",
      default: null,
    },
    redis_configs: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      description:
        "The Redis configuration parameters. See https://cloud.google.com/memorystore/docs/redis/reference/rest/v1/projects.locations.instances#Instance.FIELDS.redis_configs",
    },
    display_name: {
      type: ["string", "null"],
      description:
        "An arbitrary and optional user-provided name for the instance.",
      default: null,
    },
    reserved_ip_range: {
      type: ["string", "null"],
      description:
        "The CIDR range of internal addresses that are reserved for this instance.",
      default: null,
    },
    secondary_ip_range: {
      type: ["string", "null"],
      description:
        "Optional. Additional IP range for node placement. Required when enabling read replicas on an existing instance.",
      default: null,
    },
    connect_mode: {
      type: ["string", "null"],
      enum: ["DIRECT_PEERING", "PRIVATE_SERVICE_ACCESS"],
      description:
        "The connection mode of the Redis instance. Can be either DIRECT_PEERING or PRIVATE_SERVICE_ACCESS. The default connect mode if not provided is DIRECT_PEERING.",
      default: "DIRECT_PEERING",
    },
    labels: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      description: "The resource labels to represent user provided metadata.",
      default: {},
    },
    auth_enabled: {
      type: "boolean",
      description:
        "Indicates whether OSS Redis AUTH is enabled for the instance. If set to true AUTH is enabled on the instance.",
      default: false,
    },
    transit_encryption_mode: {
      type: "string",
      description:
        "The TLS mode of the Redis instance, If not provided, TLS is enabled for the instance.",
      enum: [
        "SERVER_AUTHENTICATION",
        "TRANSIT_ENCRYPTION_MODE_UNSPECIFIED",
        "DISABLED",
      ],
      default: "SERVER_AUTHENTICATION",
    },
    maintenance_policy: {
      type: "object",
      description: "The maintenance policy for an instance.",
      properties: {
        day: {
          type: ["string", "null"],
        },
        start_time: {
          type: "object",
          properties: {
            hours: {
              type: ["number", "null"],
            },
            minutes: {
              type: ["number", "null"],
            },
            seconds: {
              type: ["number", "null"],
            },
            nanos: {
              type: ["number", "null"],
            },
          },
        },
      },
      default: {
        day: null,
        start_time: {
          hours: null,
          minutes: null,
          seconds: null,
          nanos: null,
        },
      },
    },
    customer_managed_key: {
      type: ["string", "null"],
      description:
        "Default encryption key to apply to the Redis instance. Defaults to null (Google-managed).",
      default: null,
    },
    persistence_config: {
      type: "object",
      description:
        "The Redis persistence configuration parameters. https://cloud.google.com/memorystore/docs/redis/reference/rest/v1/projects.locations.instances#persistenceconfig",
      properties: {
        persistence_mode: {
          type: ["string", "null"],
          enum: [null, "DISABLED", "RDB"],
          default: null,
        },
        rdb_snapshot_period: {
          enum: [
            null,
            "ONE_HOUR",
            "SIX_HOURS",
            "TWELVE_HOURS",
            "TWENTY_FOUR_HOURS",
          ],
          type: ["string", "null"],
          default: null,
        },
      },
    },
  },
  required: ["name", "region", "authorized_network"],
};

export const gcpMemorystoreUISchema = {
  region: {
    "ui:widget": "text",
  },
  project: {
    "ui:widget": "text",
  },
  enable_apis: {
    "ui:widget": "checkbox",
  },
  name: {
    "ui:widget": "text",
  },
  authorized_network: {
    "ui:widget": "text",
  },
  tier: {
    "ui:widget": "text",
  },
  memory_size_gb: {
    "ui:widget": "text",
  },
  replica_count: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  read_replicas_mode: {
    "ui:widget": "text",
  },
  location_id: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  alternative_location_id: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  redis_version: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  display_name: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  reserved_ip_range: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  secondary_ip_range: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  connect_mode: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  labels: {
    "ui:widget": "textarea",
  },
  auth_enabled: {
    "ui:widget": "checkbox",
  },
  transit_encryption_mode: {
    "ui:widget": "text",
  },
  maintenance_policy: {
    "ui:description": "The maintenance policy for an instance.",
    "ui:widget": "object",
    day: {
      "ui:widget": "text",
      "ui:emptyValue": null,
    },
    start_time: {
      "ui:widget": "object",
      "ui:description": "Start Time Settings",
      hours: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
      minutes: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
      seconds: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
      nanos: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
    },
  },
  customer_managed_key: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  persistence_config: {
    "ui:widget": "object",
    persistence_mode: {
      "ui:widget": "text",
    },
    rdb_snapshot_period: {
      "ui:widget": "text",
    },
  },
};
