import { React, forwardRef } from "react";
import { LogViewer, LogViewerSearch } from "@patternfly/react-log-viewer";
import {
  Toolbar as PFToolbar,
  ToolbarContent,
  ToolbarItem,
} from "@patternfly/react-core";
import { getModuleName } from "../utils/api";
import "@patternfly/react-core/dist/styles/base.css";
import "./css/logviewer.css";
import {
  Box,
  Dialog,
  Slide,
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { Clear as ClearIcon } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function TFplanViewer({
  node,
  open,
  handleClose,
  plan,
  handleConfirm,
  action,
}) {
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <ClearIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Module: {node.type}
          </Typography>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Name: {getModuleName(node)}
          </Typography>
          {action !== null && (
            <Button
              //autoFocus
              onClick={handleConfirm}
              size="medium"
              sx={{
                m: 1,
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "white",
                },
              }}
              variant="outlined"
            >
              Terraform {action}
            </Button>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ background: "#fff" }}>
        <LogViewer
          hasLineNumbers={true}
          height="90vh"
          data={plan}
          theme="light"
          toolbar={
            <PFToolbar>
              <ToolbarContent>
                <ToolbarItem>
                  <LogViewerSearch placeholder="Search value" />
                </ToolbarItem>
              </ToolbarContent>
            </PFToolbar>
          }
        />
      </Box>
    </Dialog>
  );
}
export default TFplanViewer;
