export const awsEKSmanagedNodeGroupSchema = {
  title: "EKS Node group Configuration",
  type: "object",
  properties: {
    name: {
      title: "Node Group Name",
      type: "string",
    },
    tags: {
      title: "Tags",
      type: "object",
      additionalProperties: {
        type: "string",
        title: "Tag Value",
      },
      default: {},
    },
    subnet_ids: {
      title: "Subnet IDs",
      type: "array",
      items: {
        type: "string",
        title: "Subnet ID",
      },
    },
    min_size: {
      title: "Min Size",
      type: "number",
      default: 0,
    },
    max_size: {
      title: "Max Size",
      type: "number",
      default: 3,
    },
    desired_size: {
      title: "Desired Size",
      type: "number",
      default: 1,
    },
    use_name_prefix: {
      title: "Use Name Prefix",
      type: "boolean",
      default: true,
    },
    ami_type: {
      title: "AMI Type",
      type: ["string", "null"],
      default: null,
    },
    platform: {
      title: "Platform",
      type: "string",
      default: "linux",
    },
    ami_release_version: {
      title: "AMI Release Version",
      type: ["string", "null"],
      default: null,
    },
    capacity_type: {
      title: "Capacity Type",
      type: "string",
      enum: ["ON_DEMAND", "SPOT"],
      default: "ON_DEMAND",
    },
    disk_size: {
      title: "Disk Size",
      type: "number",
      default: 20,
    },
    force_update_version: {
      title: "Force Update Version",
      type: "boolean",
      default: false,
    },
    instance_types: {
      title: "Instance Types",
      type: "array",
      items: {
        type: "string",
        title: "Instance Type",
      },
      default: ["t3.medium"],
    },
    labels: {
      title: "Labels",
      type: "object",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },
    launch_template_version: {
      title: "Launch template version",
      type: ["string", "null"],
      default: null,
    },
    remote_access: {
      title: "Remote access settings",
      type: "object",
      properties: {
        ec2_ssh_key: {
          type: ["string", "null"],
          title: "EC2 SSH Key",
          default: null,
        },
        source_security_group_ids: {
          type: "array",
          title: "Source Security Group IDs",
          items: {
            type: "string",
            title: "Source Security Group ID",
          },
        },
      },
      default: {},
    },
    taints: {
      title: "Taints",
      type: "array",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
            title: "Key",
          },
          value: {
            type: ["string", "null"],
            title: "Value",
            default: null,
          },
          effect: {
            type: "string",
            title: "Effect",
            enum: ["NO_SCHEDULE", "PREFER_NO_SCHEDULE", "NO_EXECUTE"],
          },
        },
        required: ["key", "effect", "value"],
      },
      default: [],
    },
    update_config: {
      title: "Update Config",
      type: "object",
      properties: {
        max_unavailable_percentage: {
          type: ["number", "null"],
          default: 33,
          title: "Max Unavailable Percentage",
        },
        max_unavailable: {
          type: ["number", "null"],
          default: null,
          title: "Max Unavailable",
        },
      },
    },
    timeouts: {
      title: "Terraform timeouts",
      type: "object",
      properties: {
        create: {
          type: "string",
        },
        update: {
          type: "string",
        },
        delete: {
          type: "string",
        },
      },
      default: {
        create: "60m",
        update: "60m",
        delete: "60m",
      },
    },
    enable_bootstrap_user_data: {
      title: "Enable Bootstrap User Data",
      type: "boolean",
      default: false,
    },
    cluster_service_ipv4_cidr: {
      title: "Cluster Service Ipv4 Cidr",
      type: ["string", "null"],
      default: null,
    },
    pre_bootstrap_user_data: {
      title: "Pre Bootstrap User Data",
      type: "string",
      default: "",
    },
    post_bootstrap_user_data: {
      title: "Post Bootstrap User Data",
      type: "string",
      default: "",
    },
    bootstrap_extra_args: {
      title: "Bootstrap Extra Args",
      type: "string",
      default: "",
    },
    user_data_template_path: {
      title: "User Data Template Path",
      type: "string",
      default: "",
    },
    //Launch template
    create_launch_template: {
      title: "Create Launch Template",
      type: "boolean",
      default: true,
    },
    use_custom_launch_template: {
      title: "Use Custom Launch Template",
      type: "boolean",
      default: true,
    },
    launch_template_id: {
      title: "Launch Template Id",
      type: "string",
      default: "",
    },
    launch_template_name: {
      title: "Launch Template Name",
      type: ["string", "null"],
      default: null,
    },
    launch_template_use_name_prefix: {
      title: "Launch Template Use Name Prefix",
      type: "boolean",
      default: false,
    },
    launch_template_description: {
      title: "Launch Template Description",
      type: ["string", "null"],
      default: null,
    },
    ebs_optimized: {
      title: "EBS Optimized",
      type: "boolean",
      default: true,
    },
    ami_id: {
      title: "AMI ID",
      type: "string",
      default: "",
    },
    key_name: {
      title: "Key Name",
      type: ["string", "null"],
      default: null,
    },
    vpc_security_group_ids: {
      title: "VPC Security Group IDs",
      type: "array",
      items: {
        type: "string",
        title: "VPC Security Group ID",
      },
      default: [],
    },
    attach_cluster_primary_security_group_id: {
      title: "Attach Cluster Primary Security Group Id",
      type: "boolean",
      default: true,
    },
    launch_template_default_version: {
      title: "Launch Template Default Version",
      type: ["string", "null"],
      default: null,
    },
    update_launch_template_default_version: {
      title: "Update Launch Template Default Version",
      type: "boolean",
      default: true,
    },
    disable_api_termination: {
      title: "Disable Api Termination",
      type: "boolean",
      default: false,
    },
    kernel_id: {
      title: "Kernel Id",
      type: ["string", "null"],
      default: null,
    },
    ram_disk_id: {
      title: "Ram Disk Id",
      type: ["string", "null"],
      default: null,
    },
    block_device_mappings: {
      title: "Block Device Mappings",
      type: "array",
      items: {
        type: "object",
        properties: {
          device_name: {
            type: "string",
            title: "Device Name",
          },
          no_device: {
            type: "boolean",
            title: "No Device",
            default: false,
          },
          virtual_name: {
            type: ["string", "null"],
            title: "Virtual Name",
            default: null,
          },
          ebs: {
            type: "object",
            title: "EBS Block Device",
            properties: {
              delete_on_termination: {
                type: "boolean",
                title: "Delete On Termination",
                default: true,
              },
              encrypted: {
                type: "boolean",
                title: "Encrypted",
                default: true,
              },
              iops: {
                type: ["number", "null"],
                title: "IOPS",
                default: null,
              },
              kms_key_id: {
                type: ["string", "null"],
                title: "KMS Key ID",
                default: null,
              },
              snapshot_id: {
                title: "Snapshot ID",
                type: ["string", "null"],
                default: null,
              },
              volume_size: {
                title: "Volume Size",
                type: "number",
                default: 50,
              },
              volume_type: {
                title: "Volume Type",
                type: "string",
                enum: ["gp2", "gp3", "io1", "io2", "st1", "sc1"],
                default: "gp2",
              },
            },
          },
        },
        required: ["device_name"],
      },
      default: [],
    },
    capacity_reservation_specification: {
      title: "Capacity Reservation Specification",
      type: "object",
      properties: {
        capacity_reservation_preference: {
          title: "Capacity Reservation Preference",
          enum: ["open", "none", null],
          type: ["string", "null"],
          default: null,
        },
        capacity_reservation_target: {
          type: "object",
          title: "Capacity Reservation Target",
          properties: {
            capacity_reservation_id: {
              title: "Capacity Reservation Id",
              type: ["string", "null"],
              default: null,
            },
            capacity_reservation_resource_group_arn: {
              title: "Capacity Reservation Resource Group Arn",
              type: ["string", "null"],
              default: null,
            },
          },
        },
      },
      default: {},
    },
    cpu_options: {
      title: "Cpu Options",
      type: "object",
      properties: {
        core_count: {
          title: "Core Count",
          type: ["number", "null"],
          default: null,
        },
        threads_per_core: {
          title: "Threads Per Core",
          type: ["number", "null"],
          default: null,
        },
        amd_sev_snp: {
          title: "Amd Sev Snp",
          type: ["string", "null"],
          enum: ["enabled", "disabled", null],
          default: null,
        },
      },
      default: {},
    },
    credit_specification: {
      title: "Credit Specification",
      type: "object",
      properties: {
        cpu_credits: {
          title: "Cpu Credits",
          enum: ["standard", "unlimited"],
          type: "string",
        },
      },
      default: { cpu_credits: "unlimited" },
    },
    elastic_gpu_specifications: {
      title: "Elastic GPU Specifications",
      type: "array",
      items: {
        type: "object",
        title: "Elastic GPU Specification",
        properties: {
          type: {
            title: "Type",
            type: "string",
          },
          name: {
            title: "Name",
            type: "string",
          },
        },
        required: ["type", "name"],
      },
      default: [],
    },
    elastic_inference_accelerator: {
      type: "object",
      title: "Elastic Inference Accelerator",
      properties: {
        type: {
          type: ["string", "null"],
          title: "Type",
          default: null,
        },
      },
      default: {},
    },
    enclave_options: {
      type: "object",
      properties: {
        enabled: {
          type: "boolean",
          title: "Enabled",
        },
      },
      title: "Enclave Options",
      default: {
        enabled: false,
      },
    },
    instance_market_options: {
      type: "object",
      properties: {
        market_type: {
          type: ["string", "null"],
          title: "Market Type",
        },
        spot_options: {
          type: "object",
          title: "Spot Options",
          properties: {
            block_duration_minutes: {
              type: ["number", "null"],
              title: "Block Duration Minutes",
              default: null,
            },
            instance_interruption_behavior: {
              type: ["string", "null"],
              title: "Instance Interruption Behavior",
              default: null,
            },
            max_price: {
              type: ["string", "null"],
              title: "Max Price",
              default: null,
            },
            spot_instance_type: {
              type: ["string", "null"],
              title: "Spot Instance Type",
              default: null,
            },
          },
        },
        default: {},
      },
      title: "Instance Market Options",
    },
    maintenance_options: {
      type: "object",
      properties: {
        auto_recovery: {
          type: ["string", "null"],
          title: "Auto Recovery",
          default: null,
        },
      },
      title: "Maintenance Options",
    },
    license_specifications: {
      type: "array",
      title: "License Specifications",
      items: {
        type: "object",
        title: "License Specification",
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          license_configuration_arn: {
            type: "string",
            title: "License Configuration Arn",
          },
        },
      },
      default: [],
    },
    metadata_options: {
      type: "object",
      properties: {
        http_endpoint: {
          type: "string",
          title: "Http Endpoint",
        },
        http_tokens: {
          type: "string",
          title: "Http Tokens",
        },
        http_protocol_ipv6: {
          type: "string",
          title: "Http Protocol Ipv6",
        },
        http_put_response_hop_limit: {
          type: "number",
          title: "Http Put Response Hop Limit",
        },
        instance_metadata_tags: {
          type: "string",
          title: "Instance Metadata Tags",
        },
      },
      title: "Metadata Options",
      default: {
        http_endpoint: "enabled",
        http_tokens: "optional",
        http_put_response_hop_limit: 1,
        instance_metadata_tags: "enabled",
        http_protocol_ipv6: "disabled",
      },
    },
    enable_monitoring: {
      title: "Enable Monitoring",
      type: "boolean",
      default: true,
    },
    network_interfaces: {
      type: "array",
      title: "Network Interfaces",
      items: {
        type: "object",
        properties: {
          description: {
            title: "Description",
            type: "string",
          },
          associate_carrier_ip_address: {
            title: "Associate Carrier IP Address",
            type: "boolean",
            default: false,
          },
          associate_public_ip_address: {
            title: "Associate Public IP Address",
            type: "boolean",
            default: false,
          },
          delete_on_termination: {
            title: "Delete On Termination",
            type: "boolean",
            default: true,
          },
          device_index: {
            title: "Device Index",
            type: ["integer", "null"],
            default: null,
          },
          interface_type: {
            title: "Interface Type",
            type: ["string", "null"],
            default: null,
          },
          ipv4_address_count: {
            title: "Ipv4 Address Count",
            type: ["integer", "null"],
            default: null,
          },
          ipv4_addresses: {
            title: "IPv4 Addresses",
            type: "array",
            items: {
              type: "string",
            },
            default: [],
          },
          ipv4_prefixes: {
            title: "IPv4 Prefixes",
            type: "array",
            items: {
              type: "string",
              title: "IPv4 Prefix",
            },
            default: [],
          },
          ipv6_address_count: {
            title: "Ipv6 Address Count",
            type: ["integer", "null"],
            default: null,
          },
          ipv6_addresses: {
            type: "array",
            title: "IPv6 Addresses",
            items: {
              type: "string",
              title: "IPv6 Address",
            },
            default: [],
          },
          ipv6_prefixes: {
            title: "IPv6 Prefixes",
            type: "array",
            items: {
              type: "string",
              title: "IPv6 Prefix",
            },
            default: [],
          },
          network_interface_id: {
            title: "Network Interface ID",
            type: ["string", "null"],
            default: null,
          },
          network_card_index: {
            type: ["integer", "null"],
            title: "Network Card Index",
            default: null,
          },
          private_ip_address: {
            type: ["string", "null"],
            title: "Private IP Address",
            default: null,
          },
          security_groups: {
            type: "array",
            title: "Security Groups",
            items: {
              type: "string",
              title: "Security Group",
            },
            default: [],
          },
        },
        required: ["description"],
      },
      default: [],
    },
    placement: {
      type: "object",
      title: "Placement",
      properties: {
        affinity: {
          title: "Affinity",
          type: ["string", "null"],
          default: null,
        },
        availability_zone: {
          title: "Availability Zone",
          type: ["string", "null"],
          default: null,
        },
        group_name: {
          title: "Group Name",
          type: ["string", "null"],
          default: null,
        },
        host_id: {
          title: "Host ID",
          type: ["string", "null"],
          default: null,
        },
        host_resource_group_arn: {
          title: "Host Resource Group Arn",
          type: ["string", "null"],
          default: null,
        },
        spread_domain: {
          title: "Spread Domain",
          type: ["string", "null"],
          default: null,
        },
        tenancy: {
          type: ["string", "null"],
          title: "Tenancy",
          default: null,
        },
      },
      default: {},
    },
    private_dns_name_options: {
      title: "Private Dns Name Options",
      type: "object",
      default: {},
      properties: {
        enable_resource_name_dns_aaaa_record: {
          type: "boolean",
          title: "Enable Resource Name DNS Aaaa Record",
          default: false,
        },
        enable_resource_name_dns_a_record: {
          type: "boolean",
          title: "Enable Resource Name DNS  ARecord",
          default: false,
        },
        hostname_type: {
          type: ["string", "null"],
          enum: ["ip-name", "resource-name", null],
          title: "Hostname Type",
          default: null,
        },
      },
    },
    launch_template_tags: {
      title: "Launch Template Tags",
      type: "object",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },
    tag_specifications: {
      title: "Tag Specifications",
      type: "array",
      items: {
        type: "string",
      },
      default: ["instance", "volume", "network-interface"],
    },
    // IAM
    create_iam_role: {
      type: "boolean",
      title: "Create IAM Role",
      default: true,
    },
    cluster_ip_family: {
      type: ["string", "null"],
      title: "Cluster IP Family",
      default: null,
    },
    iam_role_arn: {
      type: ["string", "null"],
      title: "IAM Role Arn",
      default: null,
    },
    iam_role_name: {
      type: ["string", "null"],
      title: "IAM Role Name",
      default: "eks-node-group-iam-role",
    },
    iam_role_use_name_prefix: {
      type: "boolean",
      title: "IAM Role Use Name Prefix",
      default: true,
    },
    iam_role_path: {
      type: ["string", "null"],
      title: "IAM Role Path",
      default: null,
    },
    iam_role_description: {
      type: ["string", "null"],
      title: "IAM Role Description",
      default: null,
    },
    iam_role_permissions_boundary: {
      type: ["string", "null"],
      title: "IAM Role Permissions Boundary",
      default: null,
    },
    iam_role_attach_cni_policy: {
      type: "boolean",
      title: "IAM Role Attach Cni Policy",
      default: true,
    },
    iam_role_additional_policies: {
      title: "IAM Role Additional Policies",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          arn: {
            type: "string",
            title: "Arn",
          },
        },
        required: ["name", "arn"],
      },
      default: [],
    },
    iam_role_tags: {
      title: "IAM Role Tags",
      type: "object",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },

    //Schedule
    create_schedule: {
      type: "boolean",
      title: "Create Schedule",
      default: false,
    },
    schedules: {
      type: "array",
      title: "Schedules",
      items: {
        type: "object",
        title: "Schedule",
        properties: {
          scheduled_action_name: {
            type: "string",
            title: "Scheduled Action Name",
          },
          min_size: {
            type: "number",
            title: "Min Size",
            default: 0,
          },
          max_size: {
            type: "number",
            title: "Max Size",
            default: 0,
          },
          desired_capacity: {
            type: "number",
            title: "Desired Capacity",
            default: 0,
          },
          recurrence: {
            type: ["string", "null"],
            title: "Recurrence",
            default: null,
          },
          start_time: {
            type: ["string", "null"],
            title: "Start Time",
            default: null,
          },
          end_time: {
            type: ["string", "null"],
            title: "End Time",
            default: null,
          },
          time_zone: {
            type: ["string", "null"],
            title: "Time Zone",
            default: null,
          },
        },
        required: ["scheduled_action_name"],
      },
      default: [],
    },
  },

  required: ["name", "subnet_ids"],
};

export const awsEKSmanagedNodeGroupUIschema = {
  "ui:description": "EKS Node group configuration",
  name: {
    "ui:description": "Name of the EKS managed node group",
  },
  tags: {
    "ui:description": "A map of tags to add to all resources",
  },
  subnet_ids: {
    "ui:description":
      "Identifiers of EC2 Subnets to associate with the EKS Node Group",
    items: {
      "ui:description": "Subnet ID",
    },
  },
  min_size: {
    "ui:description": "Minimum number of instances/nodes",
  },
  max_size: {
    "ui:description": "Maximum number of instances/nodes",
  },
  desired_size: {
    "ui:description": "Desired number of instances/nodes",
  },
  use_name_prefix: {
    "ui:description":
      "Determines whether to use `name` as is or create a unique name beginning with the `name` as the prefix",
  },
  ami_type: {
    "ui:emptyValue": null,
    "ui:description":
      "Type of Amazon Machine Image (AMI) associated with the EKS Node Group",
  },
  platform: {
    "ui:description":
      "Identifies if the OS platform is `bottlerocket` or `linux` based; `windows` is not supported",
  },
  ami_release_version: {
    "ui:emptyValue": null,
    "ui:description": "AMI version of the EKS Node Group",
  },
  capacity_type: {
    "ui:description": "Type of capacity associated with the EKS Node Group",
  },
  disk_size: {
    "ui:description": "Disk size in GiB for nodes",
  },
  force_update_version: {
    "ui:description":
      "Force version update if existing pods are unable to be drained due to a pod disruption budget issue",
  },
  instance_types: {
    "ui:description":
      "Set of instance types associated with the EKS Node Group",
    items: {
      "ui:description": "Instance Type",
    },
  },
  labels: {
    "ui:description": "Key-value map of Kubernetes labels",
  },
  launch_template_version: {
    "ui:emptyValue": null,
    "ui:description": "Launch template version number",
  },
  remote_access: {
    "ui:description": "Configuration block with remote access settings",
    ec2_ssh_key: {
      "ui:description": "SSH key to use for SSH access to the nodes",
      "ui:emptyValue": null,
    },
    source_security_group_ids: {
      "ui:description":
        "List of EC2 Security Group IDs to allow SSH access (port 22) from on the worker nodes",
      items: {
        "ui:description": "Security Group ID",
      },
    },
  },
  taints: {
    "ui:description":
      "The Kubernetes taints to be applied to the nodes in the node group",
    items: {
      "ui:description": "Taint",
      "ui:displayfield": "key",
      key: {
        "ui:description": "Key",
      },
      value: {
        "ui:description": "Value",
        "ui:emptyValue": null,
      },
      effect: {
        "ui:description": "Effect",
      },
    },
  },
  update_config: {
    "ui:description":
      "Configuration block of settings for max unavailable resources during node group updates",
    max_unavailable_percentage: {
      "ui:description":
        "Desired max percentage of unavailable worker nodes during node group update",
      "ui:emptyValue": null,
    },
    max_unavailable: {
      "ui:description":
        "Desired max number of unavailable worker nodes during node group update",
      "ui:emptyValue": null,
    },
  },
  timeouts: {
    "ui:description":
      "Create, update, and delete timeout configurations for the node group",
    create: {
      "ui:description": "Create timeout",
    },
    update: {
      "ui:description": "Update timeout",
    },
    delete: {
      "ui:description": "Delete timeout",
    },
  },
  enable_bootstrap_user_data: {
    "ui:description":
      "Determines whether the bootstrap configurations are populated within the user data template. Only valid when using a custom AMI via `ami_id`",
  },
  cluster_service_ipv4_cidr: {
    "ui:description":
      "The CIDR block to assign Kubernetes service IP addresses from. If you don't specify a block, Kubernetes assigns addresses from either the 10.100.0.0/16 or 172.20.0.0/16 CIDR blocks",
    "ui:emptyValue": null,
  },
  pre_bootstrap_user_data: {
    "ui:description":
      "User data that is injected into the user data script ahead of the EKS bootstrap script. Not used when `platform` = `bottlerocket`",
  },
  post_bootstrap_user_data: {
    "ui:description":
      "User data that is appended to the user data script after of the EKS bootstrap script. Not used when `platform` = `bottlerocket`",
  },
  bootstrap_extra_args: {
    "ui:description":
      "Additional arguments passed to the bootstrap script. When `platform` = `bottlerocket`; these are additional [settings](https://github.com/bottlerocket-os/bottlerocket#settings) that are provided to the Bottlerocket user data",
  },
  user_data_template_path: {
    "ui:description":
      "Path to a local, custom user data template file to use when rendering user data",
  },

  //Launch template
  create_launch_template: {
    "ui:description":
      "Determines whether to create a launch template or not. If set to `false`, EKS will use its own default launch template",
  },
  use_custom_launch_template: {
    "ui:description":
      "Determines whether to use a custom launch template or not. If set to `false`, EKS will use its own default launch template",
  },
  launch_template_id: {
    "ui:description":
      "The ID of an existing launch template to use. Required when `create_launch_template` = `false` and `use_custom_launch_template` = `true`",
  },
  launch_template_use_name_prefix: {
    "ui:description":
      "Determines whether to use `launch_template_name` as is or create a unique name beginning with the `launch_template_name` as the prefix",
  },
  launch_template_name: {
    "ui:description": "Name of launch template to be created",
    "ui:emptyValue": null,
  },
  launch_template_description: {
    "ui:description": "Description of the launch template",
    "ui:emptyValue": null,
  },
  ebs_optimized: {
    "ui:description":
      "If true, the launched EC2 instance(s) will be EBS-optimized",
    "ui:emptyValue": null,
  },
  ami_id: {
    "ui:description":
      "The AMI from which to launch the instance. If not supplied, EKS will use its own default image",
  },
  key_name: {
    "ui:description": "The key name that should be used for the instance(s)",
    "ui:emptyValue": null,
  },
  vpc_security_group_ids: {
    "ui:description":
      "A list of security group IDs to associate. This list will be appended with node_security_group_id from the parent output.",
    items: {
      "ui:description": "Security Group ID",
    },
  },
  attach_cluster_primary_security_group_id: {
    "ui:description":
      "Whether to attach the cluster primary security group. This is the security group cluster_security_group_id in the parent outputs.",
  },
  launch_template_default_version: {
    "ui:description": "Default version of the launch template",
    "ui:emptyValue": null,
  },
  update_launch_template_default_version: {
    "ui:description":
      "Whether to update the launch templates default version on each update. Conflicts with `launch_template_default_version`",
    "ui:emptyValue": null,
  },
  disable_api_termination: {
    "ui:description": "If true, enables EC2 instance termination protection",
  },
  kernel_id: {
    "ui:description": "The kernel ID",
    "ui:emptyValue": null,
  },
  ram_disk_id: {
    "ui:description": "The ID of the ram disk",
    "ui:emptyValue": null,
  },
  block_device_mappings: {
    "ui:description":
      "Specify volumes to attach to the instance besides the volumes specified by the AMI",
    items: {
      "ui:description": "Block Device Mapping",
      "ui:displayfield": "device_name",
      device_name: {
        "ui:description": "The name of the device to mount",
      },
      no_device: {
        "ui:description":
          "Whether to suppress the specified device included in the AMI's block device mapping",
      },
      virtual_name: {
        "ui:description": "The Instance Store Device Name (e.g., 'ephemeral0')",
        "ui:emptyValue": null,
      },
      ebs: {
        "ui:description": "EBS Block Device",
        delete_on_termination: {
          "ui:description":
            "Whether the volume should be destroyed on instance termination",
        },
        encrypted: {
          "ui:description":
            "Enables EBS encryption on the volume. Cannot be used with snapshot_id",
        },
        iops: {
          "ui:description":
            "The amount of provisioned IOPS. This must be set with a volume_type of io1/io2/gp3",
        },
        kms_key_id: {
          "ui:description":
            "The ARN of the AWS Key Management Service (AWS KMS) customer master key (CMK) to use when creating the encrypted volume. encrypted must be set to true when this is set.",
        },
        snapshot_id: {
          "ui:description": "The Snapshot ID to mount.",
        },
        volume_size: {
          "ui:description": "The size of the volume in gigabytes",
        },
        volume_type: {
          "ui:description":
            "The type of volume. Can be 'standard', 'gp2', 'io1', 'io2', 'sc1', or 'st1'",
        },
      },
    },
  },
  capacity_reservation_specification: {
    "ui:description": "Targeting for EC2 capacity reservations",
    capacity_reservation_preference: {
      "ui:description":
        "Indicates the instance's Capacity Reservation preferences. Can be open or none. (Default none).",
      "ui:emptyValue": null,
    },
    capacity_reservation_target: {
      "ui:description": "Configuration block for the target capacity",
      capacity_reservation_id: {
        "ui:description":
          "The ID of the Capacity Reservation in which to run the instance.",
        "ui:emptyValue": null,
      },
      capacity_reservation_resource_group_arn: {
        "ui:description":
          "The ARN of the Capacity Reservation resource group in which to run the instance.",
        "ui:emptyValue": null,
      },
    },
  },
  cpu_options: {
    "ui:description": "The CPU options for the instance",
    core_count: {
      "ui:description": "The number of cores for the instance",
      "ui:emptyValue": null,
    },
    threads_per_core: {
      "ui:description":
        "The number of threads per CPU core. To disable Intel Hyper-Threading Technology for the instance, specify a value of 1. Otherwise, specify the default value of 2.",
      "ui:emptyValue": null,
    },
    amd_sev_snp: {
      "ui:description":
        "Indicates whether to enable the instance for AMD SEV-SNP. AMD SEV-SNP is supported with M6a, R6a, and C6a instance types only. Valid values are enabled and disabled",
      "ui:emptyValue": null,
    },
  },
  credit_specification: {
    "ui:description": "Customize the credit specification of the instance",
    cpu_credits: {
      "ui:description":
        "The credit option for CPU usage. Can be standard or unlimited. T3 instances are launched as unlimited by default. T2 instances are launched as standard by default. ",
    },
  },
  elastic_gpu_specifications: {
    "ui:description": "Elastic GPU to attach to the instance",
    items: {
      "ui:description": "Elastic GPU Specification",
      "ui:displayfield": "name",
      name: {
        "ui:description":
          "Generic name. It can be anything. Must be unique per item in this list",
      },
      type: {
        "ui:description":
          "The Elastic GPU Type. See: https://docs.aws.amazon.com/AWSEC2/latest/WindowsGuide/elastic-gpus.html#elastic-gpus-basics",
      },
    },
  },
  elastic_inference_accelerator: {
    "ui:description":
      "Configuration block containing an Elastic Inference Accelerator to attach to the instance",
    type: {
      "ui:description": " Accelerator type",
      "ui:emptyValue": null,
    },
  },
  enclave_options: {
    "ui:description": "Enable Nitro Enclaves on launched instances",
    enabled: {
      "ui:description": "If true, Nitro Enclaves are enabled",
      "ui:emptyValue": false,
    },
  },
  instance_market_options: {
    "ui:description": "The market (purchasing) option for the instance",
    market_type: {
      "ui:description": "The market type. Can be 'spot' or 'on-demand'.",
      "ui:emptyValue": null,
    },
    spot_options: {
      "ui:description": "Spot instance options for the instance",
      block_duration_minutes: {
        "ui:description":
          "The required duration in minutes. This value must be a multiple of 60.",
        "ui:emptyValue": null,
      },
      instance_interruption_behavior: {
        "ui:description":
          "The behavior when a Spot Instance is interrupted. Can be hibernate, stop, or terminate. (Default: terminate).",
        "ui:emptyValue": null,
      },
      max_price: {
        "ui:description":
          "The maximum hourly price you're willing to pay for the Spot Instances.",
        "ui:emptyValue": null,
      },
      spot_instance_type: {
        "ui:description":
          "The Spot Instance request type. Can be one-time, or persistent.",
        "ui:emptyValue": null,
      },
    },
  },
  maintenance_options: {
    "ui:description": "The maintenance options for the instance",
    auto_recovery: {
      "ui:description":
        "Disables the automatic recovery behavior of your instance or sets it to default. Can be 'default' or 'disabled'. See Recover your instance for more details.",
      "ui:emptyValue": null,
    },
  },
  license_specifications: {
    "ui:description": "A map of license specifications to associate with",
    items: {
      "ui:description": "License Specification",
      "ui:displayfield": "license_configuration_arn",
      name: {
        "ui:description": "This can be any value that is unique",
      },
      license_configuration_arn: {
        "ui:description": "ARN of the license configuration",
      },
    },
  },
  metadata_options: {
    "ui:description": "Customize the metadata options for the instance",
    http_endpoint: {
      "ui:description":
        "Whether the metadata service is available. Can be 'enabled' or 'disabled'. (Default: 'enabled').",
    },
    http_tokens: {
      "ui:description":
        "Whether or not the metadata service requires session tokens, also referred to as Instance Metadata Service Version 2 (IMDSv2). Can be 'optional' or 'required'. (Default: 'optional')",
    },
    http_protocol_ipv6: {
      "ui:description":
        "Enables or disables the IPv6 endpoint for the instance metadata service. Can be 'enabled' or 'disabled'",
    },
    http_put_response_hop_limit: {
      "ui:description":
        "The desired HTTP PUT response hop limit for instance metadata requests. The larger the number, the further instance metadata requests can travel. Can be an integer from 1 to 64. (Default: 1).",
    },
    instance_metadata_tags: {
      "ui:description":
        "Enables or disables access to instance tags from the instance metadata service. Can be 'enabled' or 'disabled'",
    },
  },
  enable_monitoring: {
    "ui:description": "Enables/disables detailed monitoring",
  },
  network_interfaces: {
    "ui:description":
      "Customize network interfaces to be attached at instance boot time",
    items: {
      "ui:description": "Network Interface Settings",
      "ui:displayfield": "description",
      associate_carrier_ip_address: {
        "ui:description":
          "Associate a Carrier IP address with eth0 for a new network interface. Use this option when you launch an instance in a Wavelength Zone and want to associate a Carrier IP address with the network interface. Boolean value, can be left unset.",
      },
      associate_public_ip_address: {
        "ui:description":
          "Associate a public ip address with the network interface. Boolean value, can be left unset.",
      },
      delete_on_termination: {
        "ui:description":
          "Whether the network interface should be destroyed on instance termination.",
      },
      description: {
        "ui:description": "Description of the network interface.",
      },
      device_index: {
        "ui:emptyValue": null,
        "ui:description":
          "The integer index of the network interface attachment.",
      },
      interface_type: {
        "ui:emptyValue": null,
        "ui:description":
          "The type of network interface. To create an Elastic Fabric Adapter (EFA), specify efa.",
      },
      ipv4_address_count: {
        "ui:emptyValue": null,
        "ui:description":
          "The number of IPv4 prefixes to be automatically assigned to the network interface. Conflicts with ipv4_prefixes.",
      },
      ipv4_addresses: {
        "ui:description":
          "One or more private IPv4 addresses to associate. Conflicts with ipv4_address_count",
      },
      ipv4_prefixes: {
        "ui:description":
          "One or more IPv4 prefixes to be assigned to the network interface. Conflicts with ipv4_prefix_count",
      },
      ipv6_address_count: {
        "ui:emptyValue": null,
        "ui:description":
          "The number of IPv6 prefixes to be automatically assigned to the network interface. Conflicts with ipv6_prefixes",
      },
      ipv6_addresses: {
        "ui:description":
          "One or more specific IPv6 addresses from the IPv6 CIDR block range of your subnet. Conflicts with ipv6_address_count",
      },
      ipv6_prefixes: {
        "ui:description":
          "One or more IPv6 prefixes to be assigned to the network interface. Conflicts with ipv6_prefix_count",
      },
      network_interface_id: {
        "ui:emptyValue": null,
        "ui:description": "The ID of the network interface to attach",
      },
      network_card_index: {
        "ui:emptyValue": null,
        "ui:description":
          "The index of the network card. Some instance types support multiple network cards. The primary network interface must be assigned to network card index 0. The default is network card index 0.",
      },
      private_ip_address: {
        "ui:emptyValue": null,
        "ui:description": "The primary private IPv4 address.",
      },
      security_groups: {
        "ui:description": "A list of security group IDs to associate.",
      },
    },
  },
  placement: {
    "ui:description": "The placement of the instance",
    affinity: {
      "ui:emptyValue": null,
      "ui:description":
        "The affinity setting for an instance on a Dedicated Host.",
    },
    availability_zone: {
      "ui:emptyValue": null,
      "ui:description": "The Availability Zone for the instance.",
    },
    group_name: {
      "ui:emptyValue": null,
      "ui:description": "The name of the placement group for the instance.",
    },
    host_id: {
      "ui:emptyValue": null,
      "ui:description": "The ID of the Dedicated Host for the instance.",
    },
    host_resource_group_arn: {
      "ui:emptyValue": null,
      "ui:description":
        "The ARN of the host resource group in which to launch the instances. Not all instance types can be launched in a host resource group. A Nitro instance type can only be launched in a Nitro host resource group. A non-Nitro instance type can only be launched in a non-Nitro host resource group.",
    },
    spread_domain: {
      "ui:emptyValue": null,
      "ui:description": "Reserved for future use.",
    },
    tenancy: {
      "ui:emptyValue": null,
      "ui:description":
        "The tenancy of the instance (if the instance is running in a VPC). Can be 'default', 'dedicated', or 'host'.",
    },
  },
  private_dns_name_options: {
    "ui:description":
      "The options for the instance hostname. The default values are inherited from the subnet",
    enable_resource_name_dns_aaaa_record: {
      "ui:description":
        "Indicates whether to respond to DNS queries for instance hostnames with DNS AAAA records.",
    },
    enable_resource_name_dns_a_record: {
      "ui:description":
        "Indicates whether to respond to DNS queries for instance hostnames with DNS A records.",
    },
    hostname_type: {
      "ui:description":
        "The type of hostname for Amazon EC2 instances. For IPv4 only subnets, an instance DNS name must be based on the instance IPv4 address. For IPv6 native subnets, an instance DNS name must be based on the instance ID. For dual-stack subnets, you can specify whether DNS names use the instance IPv4 address or the instance ID. Valid values: ip-name and resource-name",
      "ui:emptyValue": null,
    },
  },
  launch_template_tags: {
    "ui:description":
      "A map of additional tags to add to the tag_specifications of launch template created",
    additionalProperties: {
      "ui:description": "Tag Value",
    },
  },
  tag_specifications: {
    "ui:description": "The tags to apply to the resources during launch",
    items: {
      "ui:description": "Tag",
    },
  },
  // IAM Roles
  create_iam_role: {
    "ui:description":
      "Determines whether an IAM role is created or to use an existing IAM role",
  },
  cluster_ip_family: {
    "ui:description":
      "The IP family used to assign Kubernetes pod and service addresses. Valid values are ipv4 (default) and ipv6",
    "ui:emptyValue": null,
  },
  iam_role_arn: {
    "ui:description":
      "Existing IAM role ARN for the node group. Required if create_iam_role is set to false",
    "ui:emptyValue": null,
  },
  iam_role_name: {
    "ui:description": "Name to use on IAM role created",
    "ui:emptyValue": null,
  },
  iam_role_use_name_prefix: {
    "ui:description":
      "Determines whether the IAM role name (iam_role_name) is used as a prefix",
  },
  iam_role_path: {
    "ui:description": "IAM role path",
    "ui:emptyValue": null,
  },
  iam_role_description: {
    "ui:description": "Description of the role",
    "ui:emptyValue": null,
  },
  iam_role_permissions_boundary: {
    "ui:description":
      "ARN of the policy that is used to set the permissions boundary for the IAM role",
    "ui:emptyValue": null,
  },
  iam_role_attach_cni_policy: {
    "ui:description":
      "Whether to attach the AmazonEKS_CNI_Policy/AmazonEKS_CNI_IPv6_Policy IAM policy to the IAM IAM role. WARNING: If set false the permissions must be assigned to the aws-node DaemonSet pods via another method or nodes will not be able to join the cluster",
  },
  iam_role_additional_policies: {
    "ui:description": "Additional policies to be added to the IAM role",
    items: {
      "ui:description": "IAM Policy",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Name of the additional policy",
      },
      arn: {
        "ui:description": "ARN of the additional policy",
      },
    },
  },
  iam_role_tags: {
    "ui:description": "A map of additional tags to add to the IAM role created",
  },

  //Schedule
  create_schedule: {
    "ui:description":
      "Determines whether to create autoscaling group schedule or not",
  },
  schedules: {
    "ui:description": "Map of autoscaling group schedule to create",
    items: {
      "ui:description": "Schedule",
      "ui:displayfield": "scheduled_action_name",
      scheduled_action_name: {
        "ui:description": "The name of this scaling action. Required",
      },
      min_size: {
        "ui:description":
          "The minimum size of the Auto Scaling group. Set to -1 if you don't want to change the minimum size at the scheduled time. Defaults to 0",
      },
      max_size: {
        "ui:description":
          "The maximum size of the Auto Scaling group. Set to -1 if you don't want to change the maximum size at the scheduled time. Defaults to 0.",
      },
      desired_capacity: {
        "ui:description":
          "The initial capacity of the Auto Scaling group after the scheduled action runs and the capacity it attempts to maintain. Set to -1 if you don't want to change the desired capacity at the scheduled time. Defaults to 0.",
      },
      recurrence: {
        "ui:description":
          "The recurring schedule for this action specified using the Unix cron syntax format.",
        "ui:emptyValue": null,
      },
      start_time: {
        "ui:description":
          'The date and time for the recurring schedule to start, in UTC with the format "YYYY-MM-DDThh:mm:ssZ" (e.g. "2021-06-01T00:00:00Z")',
        "ui:emptyValue": null,
      },
      end_time: {
        "ui:description":
          'The date and time for the recurring schedule to end, in UTC with the format "YYYY-MM-DDThh:mm:ssZ" (e.g. "2021-06-01T00:00:00Z")',
        "ui:emptyValue": null,
      },
      time_zone: {
        "ui:description":
          "Specifies the time zone for a cron expression. Valid values are the canonical names of the IANA time zones (such as Etc/GMT+9 or Pacific/Tahiti)",
        "ui:emptyValue": null,
      },
    },
  },
};
