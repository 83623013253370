import { React, useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Tooltip,
  Backdrop,
  CircularProgress,
  MenuItem,
} from "@mui/material";
import GithubSettings from "./organization/GithubSettings";
import { useAuth } from "../contexts/AuthContext";
import {
  fetchOrganization,
  fetchEnvironments,
  updateOrganizationName,
  updateEnvironment,
} from "../utils/db";
import { apiCall } from "../utils/api";
import { useSnackbar } from "notistack";

export function Env({ organization_id, env, removeEnvironment, isAdmin }) {
  const [open, setOpen] = useState(false);
  const [editArchitectureName, setEditArchitectureName] = useState(false);
  const [newEnvName, setNewEnvName] = useState(env.name);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancelChangeName = () => {
    setEditArchitectureName(false);
    setNewEnvName(env.name);
  };

  const applyChangeName = async () => {
    await updateEnvironment(organization_id, env.id, newEnvName);
    setEditArchitectureName(false);
  };

  return (
    <>
      <Grid item xs={4} borderColor={"black"}>
        <Box display="flex" alignItems="center">
          <Box flex={6}>
            {!editArchitectureName && (
              <Typography m={1} variant="subtitle1">
                {newEnvName}
              </Typography>
            )}
            {editArchitectureName && (
              <TextField
                defaultValue={newEnvName}
                onChange={(e) => setNewEnvName(e.target.value)}
              />
            )}
          </Box>
          {isAdmin() && (
            <Box flex={4}>
              {!editArchitectureName && (
                <IconButton onClick={() => setEditArchitectureName(true)}>
                  <EditIcon />
                </IconButton>
              )}
              {editArchitectureName && (
                <IconButton onClick={() => applyChangeName()}>
                  <CheckIcon />
                </IconButton>
              )}
              {editArchitectureName && (
                <IconButton onClick={() => handleCancelChangeName(false)}>
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={4}>
        <Typography m={1} variant="subtitle1">
          {env.id}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        {isAdmin() && (
          <Box display="flex" justifyContent="flex-end" width="40%">
            <IconButton
              onClick={handleClickOpen}
              disabled={env.active}
              size="large"
            >
              <DeleteIcon />
            </IconButton>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Delete the architecture "{env.name}"?
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Deleting the architecture will delete all the terraform state
                  files for this architecture. Do this only if all the resources
                  in the "{env.name}" architecture have been deleted.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={() => removeEnvironment(env.id)} autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
      </Grid>
    </>
  );
}

export function Editor({ editor, org, isAdmin, getOrganizations }) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();

  const handleRemoveEditor = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/management/${org.id}/${editor.id}/${editor.email}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      enqueueSnackbar(`${inv_response.message}`, { variant: "success" });
      getOrganizations();
    }
    setOpenBackdrop(false);
  };
  return (
    <>
      <Grid item xs={4}>
        <Typography m={1} variant="subtitle1">
          {editor.email}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Box display="flex" flexDirection="column">
          <Box flexDirection="row" display="flex" alignItems="center">
            <Box>
              <Typography m={1} variant="subtitle1">
                Editor
              </Typography>
            </Box>
            {isAdmin() && (
              <Box>
                <Tooltip title="Remove Editor">
                  <span>
                    <IconButton onClick={handleRemoveEditor} m={1} size="large">
                      <PersonRemoveIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}
export function Admin({ email }) {
  return (
    <>
      <Grid item xs={4}>
        <Typography m={1} variant="subtitle1">
          {email}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography m={1} variant="subtitle1">
          Admin
        </Typography>
      </Grid>
    </>
  );
}
export function Invitation({ invite, org, removeInvitation, getInvitations }) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { currentUser } = useAuth();
  const handleResend = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite`,
      "POST",
      {
        organization_id: org.id,
        organization_name: org.name,
        invitee: invite.invitee,
      },
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      enqueueSnackbar(`${inv_response.message}`, { variant: "success" });
    }
    await getInvitations();
    setOpenBackdrop(false);
  };

  const handleCancel = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite/${org.id}/${invite.invitee}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      enqueueSnackbar(`${inv_response.message}`, { variant: "success" });
      removeInvitation(invite.invitee);
    }
    setOpenBackdrop(false);
  };

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="subtitle1" m={1}>
          {invite.invitee}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography variant="subtitle1" m={1}>
          {invite.status}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Box m={1} display="flex" flexDirection="row">
          {invite.status === "DECLINED" && (
            <Box m={1} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                onClick={handleResend}
                m={1}
                size="meidum"
              >
                Resend
              </Button>
            </Box>
          )}

          <Box m={1} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              onClick={handleCancel}
              m={1}
              size="medium"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export function Organization({ organization, updateOrgs, getOrganizations }) {
  const [orgName, setOrgName] = useState("");
  const [orgNameInput, setOrgNameInput] = useState("");
  const [openOrgName, setOpenOrgName] = useState(false);
  const [environments, setEnvironments] = useState([]);
  const [openInvite, setOpenInvite] = useState(false);
  const [invitee, setInvitee] = useState("");
  const [invitations, setInvitations] = useState([]);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line
    const getEnvironments = async () => {
      const envs = await fetchEnvironments(organization.id);
      setEnvironments(envs);
    };
    setOrgName(organization.name);
    getEnvironments();
  }, [organization]);

  const getInvitations = async () => {
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite/${organization.id}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      // eslint-disable-next-line
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let invs = await res.json();
      setInvitations(invs);
    }
  };

  useEffect(() => {
    if (organization.admin.id === currentUser.uid) {
      getInvitations();
    }
    // eslint-disable-next-line
  }, []);

  const isAdmin = () => {
    return organization.admin.id === currentUser.uid;
  };

  const handleCloseOrgName = () => {
    setOpenOrgName(false);
  };
  const handleOpenOrgName = () => {
    setOpenOrgName(true);
  };

  const changeName = async () => {
    if (!orgNameInput) {
      alert("Name can't be an empty string");
      return;
    }
    try {
      await updateOrganizationName(organization.id, orgNameInput);
      const org = await fetchOrganization(organization.id);
      setOrgName(orgNameInput);
      setOrgNameInput(org.name);
      setOpenOrgName(false);
      updateOrgs(organization.id, orgNameInput);
    } catch {
      alert("Failed to update the org name");
    }
  };

  const removeEnvironment = async (organization_id, envId) => {
    //alert(`remove from ${organization.id} environment ${envId}`);
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/${organization_id}/${envId}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let response = await res.json();
      enqueueSnackbar(`${response.message}`, { variant: "success" });
    }
    setOpenBackdrop(false);
  };

  const removeEnv = async (envId) => {
    try {
      await removeEnvironment(organization.id, envId);
      let envs = await fetchEnvironments(organization.id);
      setEnvironments(envs);
    } catch (err) {
      alert(JSON.stringify(err));
    }
  };

  const removeInvitation = (invitee) => {
    setInvitations((inv) =>
      inv.filter((invitation) => invitation.invitee !== invitee)
    );
  };

  const handleInvite = async () => {
    if (!validateEmail()) {
      alert("Invalid email address");
      return;
    }
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite`,
      "POST",
      {
        organization_id: organization.id,
        organization_name: organization.name,
        invitee: invitee,
      },
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      enqueueSnackbar(`${inv_response.message}`, { variant: "success" });
    }
    setOpenInvite(false);
    await getInvitations();
  };

  function validateEmail() {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(invitee)) {
      return true;
    }
    return false;
  }

  return (
    <>
      <Paper
        sx={{
          width: 800,
          backgroundColor: (theme) =>
            isAdmin()
              ? theme.palette.background.paper
              : theme.palette.grey[100],
        }}
      >
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={3}>
            <Typography m={1} variant="subtitle1">
              Organization Name:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontFamily="monospace" m={1}>
              {orgName}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            {isAdmin() && (
              <Box m={1} display="flex" flexGrow={1} justifyContent="flex-end">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenOrgName}
                >
                  Change Name
                </Button>
                <Dialog
                  open={openOrgName}
                  onClose={handleCloseOrgName}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    Change Organization Name
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      variant="standard"
                      error={!orgName ? true : false}
                      autoFocus
                      margin="dense"
                      id="orgName"
                      label="Organization Name"
                      defaultValue={orgNameInput}
                      fullWidth
                      onChange={(e) => setOrgNameInput(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseOrgName} color="primary">
                      Cancel
                    </Button>
                    <Button onClick={changeName} color="primary">
                      Set Name
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          <Grid item xs={3}>
            <Typography m={1} variant="subtitle1">
              Billing Plan:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" fontFamily="monospace" m={1}>
              {organization.billing_tier}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" m={1}>
              Organization Id:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" fontFamily="monospace" m={1}>
              {organization.id}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle1" m={1}>
              State Bucket:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="subtitle1" fontFamily="monospace" m={1}>
              gs://{organization.bucket}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Box m={1} display="flex" flexGrow={1} justifyContent="flex-end">
              <Button variant="contained" color="primary" disabled>
                Change Bucket
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" m={1}>
              Environments:
            </Typography>
          </Grid>
          {environments.map((env) => (
            <Env
              organization_id={organization.id}
              key={env.id}
              env={env}
              removeEnvironment={removeEnv}
              isAdmin={isAdmin}
            />
          ))}
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          <GithubSettings organization_id={organization.id} isAdmin={isAdmin} />
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          <CloudKeys
            organization={organization}
            environments={environments}
            isAdmin={isAdmin}
          />
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          <Grid item xs={12}>
            <Box
              m={1}
              display="flex"
              flexGrow={1}
              justifyContent="flex-begin"
              flexDirection="column"
            >
              <Typography variant="h6" m={0}>
                Team
              </Typography>
              <Typography variant="body2" m={0}>
                Team members for this organization
              </Typography>
            </Box>
          </Grid>
          <Admin email={organization.admin.email} />

          {organization.team.editors.map((member, index) => (
            <Editor
              editor={member}
              key={index}
              org={organization}
              isAdmin={isAdmin}
              getOrganizations={getOrganizations}
            />
          ))}
          {isAdmin() && (
            <Grid item xs={12}>
              <Box m={1}>
                <Button
                  variant="contained"
                  color="primary"
                  size="medium"
                  onClick={() => setOpenInvite(true)}
                >
                  Invite
                </Button>
                <Dialog
                  open={openInvite}
                  onClose={() => setOpenInvite(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Invite a new member to your organization
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      New member will be added as an editor to your organization
                      after they accept the invitation.
                    </DialogContentText>
                    <TextField
                      error={!validateEmail()}
                      variant="standard"
                      autoFocus
                      margin="dense"
                      id="orgName"
                      label="Email Address"
                      defaultValue={""}
                      fullWidth
                      onChange={(e) => setInvitee(e.target.value)}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenInvite(false)}>Cancel</Button>
                    <Button onClick={handleInvite} autoFocus>
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider component="div" />
          </Grid>
          {isAdmin() && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" m={1}>
                Invitation Statuses:
              </Typography>
            </Grid>
          )}
          {isAdmin() &&
            invitations.map((invite, index) => (
              <Invitation
                key={index}
                invite={invite}
                org={organization}
                removeInvitation={removeInvitation}
                getInvitations={getInvitations}
              />
            ))}
          {isAdmin() && invitations.length === 0 && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" m={1}>
                No pending invitations
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.modal + 10 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}

export default function Organizations() {
  const { organizations, setOrganizations, getOrganizations } = useAuth();

  const updateOrgs = (id, name) => {
    let objIndex = organizations.findIndex((obj) => obj.id === id);
    organizations[objIndex].name = name;
    setOrganizations(organizations);
  };

  return (
    <Box
      sx={{
        "& > :not(style)": {
          m: 1,
        },
      }}
    >
      {organizations.map((org) => (
        <Organization
          organization={org}
          key={org.id}
          updateOrgs={updateOrgs}
          getOrganizations={getOrganizations}
        />
      ))}
    </Box>
  );
}

const cloud_keys = [
  "GOOGLE_CLOUD_KEYFILE_JSON",
  "AWS_ACCESS_KEY_ID",
  "AWS_SECRET_ACCESS_KEY",
  "DATABRICKS_ADMIN_TOKEN",
  "DATABRICKS_CLIENT_SECRET",
];

function CloudKeys({ organization, environments, isAdmin }) {
  const [open, setOpen] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [selectedKeyDisabled, setSelectedKeyDisabled] = useState(false);
  const [selectedArchitectureDisabled, setSelectedArchitectureDisabled] =
    useState(false);
  const [selectedArchitecture, setSelectedArchitecture] = useState({
    name: "global",
    id: null,
  });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [keyValue, setKeyValue] = useState("");
  const [isEditOperation, setIsEditOperation] = useState(false);
  const { currentUser, getOrganizations } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const handleSelectedKey = (event) => {
    setSelectedKey(event.target.value);
  };
  const handleSelectedEnvironment = (event) => {
    const arch_name = event.target.value;
    if (arch_name === "global") {
      setSelectedArchitecture({ name: "global", id: null });
      return;
    }
    const arch = environments.find((env) => env.name === arch_name);
    setSelectedArchitecture(arch);
  };
  const cloudKeyAlreadyExistAndNotEditOpertion = () => {
    let { cloud_keys } = organization;
    if (!cloud_keys) {
      return false;
    }
    return cloud_keys.some(
      (key) =>
        key.key_name === selectedKey &&
        key.architecture_name === selectedArchitecture.name &&
        !isEditOperation
    );
  };
  const handleSaveKey = async () => {
    if (cloudKeyAlreadyExistAndNotEditOpertion()) {
      alert(
        `Key ${selectedKey} for "${selectedArchitecture.name}" already exists. You can edit it instead of adding a new one.`
      );
      return;
    }
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/cloudkeys`,
      "POST",
      {
        organization_id: organization.id,
        key_name: selectedKey,
        architecture_name: selectedArchitecture.name,
        architecture_id: selectedArchitecture.id,
        key_value: keyValue,
      },
      currentUser
    );
    if (res.status !== 200) {
      const error = await res.text();
      enqueueSnackbar(error, { variant: "error" });
    }
    if (res.status === 200) {
      const success = await res.json();
      enqueueSnackbar(success.message, { variant: "success" });
      setOpen(false);
    }
    await getOrganizations();
    setOpenBackdrop(false);
    setIsEditOperation(false);
  };

  const deleteKey = async (key) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/cloudkeys`,
      "DELETE",
      {
        organization_id: organization.id,
        key_name: key.key_name,
        architecture_name: key.architecture_name,
        architecture_id: key.architecture_id,
      },
      currentUser
    );
    if (res.status !== 200) {
      const error = await res.text();
      enqueueSnackbar(error, { variant: "error" });
    }
    if (res.status === 200) {
      const success = await res.json();
      enqueueSnackbar(success.message, { variant: "success" });
    }
    await getOrganizations();
    setOpenBackdrop(false);
  };

  const editKey = (key) => {
    setSelectedKey(key.key_name);
    setSelectedKeyDisabled(true);
    setSelectedArchitectureDisabled(true);
    setSelectedArchitecture({
      name: key.architecture_name,
      id: key.architecture_id,
    });
    setKeyValue("");
    setIsEditOperation(true);
    setOpen(true);
  };

  const displayCurrentCloudKeys = () => {
    const { cloud_keys } = organization;
    if (!cloud_keys) {
      return <Typography>No cloud keys</Typography>;
    }
    return cloud_keys.map((key, index) => (
      <Box display="flex" flexDirection="row" alignItems="center" key={index}>
        <Typography component="div" m={1} width="50%">
          {key.key_name}
        </Typography>
        <Typography component="div" width="20%">
          {key.architecture_name}
        </Typography>
        {isAdmin() && (
          <Box display="flex" alignItems="center">
            <IconButton onClick={() => editKey(key)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => deleteKey(key)}>
              <DeleteIcon />
            </IconButton>
          </Box>
        )}
      </Box>
    ));
  };

  const handleCancel = () => {
    setSelectedArchitectureDisabled(false);
    setSelectedKeyDisabled(false);
    setOpen(false);
  };

  return (
    <>
      <Grid item xs={12}>
        <Box m={1}>
          <Box display="flex" flexDirection="column">
            <Typography ml={1} mt={1} variant="h6">
              Cloud Keys
            </Typography>
            <Typography ml={1} mb={1} variant="body2">
              Cloud keys are used to access cloud resources. They are necessary
              to deploy modules in your architectures.
            </Typography>
          </Box>
          <Box m={1}>
            {displayCurrentCloudKeys()}
            <Button
              m={1}
              startIcon={<AddCircleOutlineIcon />}
              onClick={() => setOpen(true)}
            >
              Add
            </Button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Set Cloud Key for {organization.name}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Add Cloud Keys to this organization. For example add AWS keys
                  or GCP service account key. Keys are used to authenticate
                  against your cloud provider when deploying modules. You can
                  set one key per architecture or one global key for all
                  architectures.
                </DialogContentText>
                <Box
                  //variant="standard"
                  // sx={{ margin: (theme) => theme.spacing(1), minWidth: 120 }}
                  margin="normal"
                  display="flex"
                  flexDirection="column"
                  m={1}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    select
                    value={selectedKey}
                    label="Cloud Key"
                    onChange={handleSelectedKey}
                    margin="normal"
                    disabled={selectedKeyDisabled}
                  >
                    {cloud_keys.map((key, index) => (
                      <MenuItem key={index} value={key}>
                        {key}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    id="outlined-multiline-static"
                    label="Value"
                    multiline
                    rows={10}
                    onChange={(e) => setKeyValue(e.target.value)}
                    margin="normal"
                  />
                  <TextField
                    select
                    value={selectedArchitecture.name}
                    label="Architecture"
                    onChange={handleSelectedEnvironment}
                    margin="normal"
                    disabled={selectedArchitectureDisabled}
                  >
                    <MenuItem value="global" key="emptyValue">
                      global
                    </MenuItem>
                    {environments.map((env, index) => (
                      <MenuItem key={env.id} value={env.name}>
                        {env.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => handleCancel()}>Cancel</Button>
                <Button onClick={() => handleSaveKey()} autoFocus>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </Grid>
      <Backdrop
        //sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
        sx={{
          color: "#fff",
          zIndex: (theme) =>
            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}
