export const metastoreSchema = {
  type: "object",
  properties: {
    metastore_name: {
      type: "string",
      title: "Metastore Name",
      description: "Metastore Name",
    },
    create_bucket: {
      type: "boolean",
      description:
        '"Whether to create the S3 bucket to store the metastore data. Set this to false if you have already created the bucket."',
      title: "Create Bucket",
      default: true,
    },
    bucket: {
      type: "string",
      title: "Bucket",
      description: "Name of the S3 bucket to store the metastore data",
    },
    prefix: {
      type: "string",
      title: "Prefix",
      description:
        "Prefix to use for the S3 bucket to store the metastore data",
      default: "metastore",
    },
    owner: {
      type: ["string", "null"],
      title: "Owner",
      description:
        "Username/groupname/service_principle_application_id of the metastore owner.",
      default: null,
    },
    tags: {
      type: "object",
      title: "Tags",
      description: "Tags to apply to the resources created",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },
  },
  required: ["metastore_name", "bucket"],
};

export const metastoreUISchema = {
  "ui:description": "Create Databricks Metastore",
  metastore_name: {
    "ui:placeholder": "Metastore Name",
  },
  bucket: {
    "ui:placeholder": "Bucket",
  },
  prefix: {
    "ui:placeholder": "Prefix",
  },
  owner: {
    "ui:emptyValue": null,
  },
  tags: {
    "ui:placeholder": "Tags",
  },
};
