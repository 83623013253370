export const gcsSchema = {
  title: "GCS Bucket",
  type: "object",
  properties: {
    name: {
      title: "Module name",
      type: "string",
    },
    names: {
      title: "Bucket name",
      type: "array",
      items: {
        description: "Name of the bucket to create.",
        type: "string",
      },
    },
    location: {
      title: "Bucket Location",
      type: "string",
    },
    prefix: {
      title: "Prefix used to generate the bucket name.",
      type: "string",
    },
    versioning: {
      type: "array",
      title: "Enable versioning per bucket",
      description:
        "Enable versioning per bucket. Bucket names must match 'names' field.",
      items: {
        type: "object",
        properties: {
          bucket_name: {
            type: "string",
          },
          enable: {
            type: "boolean",
          },
        },
      },
      default: [],
    },
    force_destroy: {
      type: "array",
      title: "Force destroy",
      description:
        "Force bucket destruction on terraform destroy. Key must match 'names' field.",
      items: {
        type: "object",
        properties: {
          bucket_name: {
            type: "string",
          },
          force_destroy: {
            type: "boolean",
          },
        },
      },
    },
    storage_class: {
      title: "Storage class",
      type: "string",
      enum: [
        "STANDARD",
        "MULTI_REGIONAL",
        "REGIONAL",
        "NEARLINE",
        "COLDLINE",
        "ARCHIVE",
      ],
      default: "STANDARD",
    },
    admins: {
      title: "Bucket admins",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    viewers: {
      title: "List of bucket viewers",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    creators: {
      title: "List of bucket object creators",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    hmac_key_admins: {
      title: "List of HMAC Key Admins",
      description:
        "IAM-style member who will be granted roles/storage.hmacKeyAdmin on all buckets.",
      type: "array",
      items: {
        description:
          "user:foo@example.com or grop:bar@example.com or serviceAccount:sa-id@projectid.gserviceaccounts.com",
        type: "string",
      },
    },
    labels: {
      type: "array",
      title: "Map of labels for project",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
  },
  required: ["name", "location", "names"],
};

export const gcsSchemaUI = {
  "ui:description": "GCS Bucket settings",
  name: {
    "ui:description": "Name of the module",
  },
  names: {
    "ui:description": "Names of the buckets to create.",
    items: {
      "ui:description": "Unique backet name.",
    },
  },
  location: {
    "ui:description": "Bucket Location. For example: US",
  },
  prefix: {
    "ui:description": "Prefix used to generate the bucket name.",
  },
  versioning: {
    "ui:description": "Enable versioning per bucket.",
    items: {
      "ui:displayfield": "bucket_name",
      bucket_name: {
        "ui:description":
          "Name of the bucket on which to enable the versioning.",
      },
      enable: {
        "ui:description": "Enable versioning.",
      },
    },
  },
  force_destroy: {
    "ui:description": "List of buckets on with to force terraform destroy.",
    items: {
      "ui:description": "Bucket on which to apply terraform destroy.",
      "ui:displayfield": "bucket_name",
      bucket_name: {
        "ui:description":
          "Name of the bucket on which to enable terraform destroy.",
      },
      force_destroy: {
        "ui:description": "Force bucket destruction on terraform destroy.",
      },
    },
  },
  storage_class: {
    "ui:description": "Storage class of the bucket.",
  },
  admins: {
    "ui:description": "List of bucket admins.",
    items: {
      "ui:description":
        "IAM-style member who will be granted roles/storage.admin.user:operator@mycompany.com",
    },
  },
  viewers: {
    "ui:description": "List of bucket viewers.",
    items: {
      "ui:description":
        "IAM-style member who will be granted roles/storage.objectViewer. For example: user:operator@mycompany.com",
    },
  },
  creators: {
    "ui:description": "List of bucket object creators.",
    items: {
      "ui:description":
        "IAM-style members who will be granted roles/storage.objectCreator. user:operator@mycompany.com",
    },
  },
  hmac_key_admins: {
    "ui:description": "List of HMAC Key Admins.",
    items: {
      "ui:description":
        "IAM-style member who will be granted roles/storage.hmacKeyAdmin. user:operator@mycompany.com",
    },
  },
  labels: {
    "ui:description": "Map of labels to be attached to the buckets.",
    items: {
      "ui:displayfield": "key",
      "ui:description": "Label key/value pair.",
      key: {
        "ui:description": "Label key.",
      },
      value: {
        "ui:description": "Label value.",
      },
    },
  },
};
