export const awsEKSschema = {
  title: "EKS Cluster Configuration",
  type: "object",
  properties: {
    cluster_name: {
      title: "Name of the EKS cluster",
      type: "string",
    },
    cluster_version: {
      title: "Kubernetes version to use for the EKS cluster",
      type: ["string", "null"],
      default: null,
    },
    tags: {
      title: "Tags",
      type: "object",
      additionalProperties: {
        type: "string",
        title: "Tag Value",
      },
      default: {},
    },
    cluster_timeouts: {
      type: "object",
      title: "Terraform Timeouts",
      properties: {
        create: {
          type: "string",
        },
        update: {
          type: "string",
        },
        delete: {
          type: "string",
        },
      },
      default: {
        create: "60m",
        update: "60m",
        delete: "60m",
      },
    },
    cluster_enabled_log_types: {
      title: "A list of the desired control plane logs to enable",
      type: "array",
      items: {
        type: "string",
        title: "Log Type",
      },
      default: ["audit", "api", "authenticator"],
    },
    control_plane_subnet_ids: {
      title:
        "A list of subnet IDs where the EKS cluster control plane (ENIs) will be provisioned",
      type: "array",
      items: {
        title: "Subnet ID",
        type: "string",
      },
    },
    subnet_ids: {
      title:
        "A list of subnet IDs where the nodes/node groups will be provisioned",
      type: "array",
      items: {
        title: "Subnet ID",
        type: "string",
      },
    },
    cluster_additional_security_group_ids: {
      title:
        "List of additional, externally created security group IDs to attach to the cluster control plane",
      type: "array",
      items: {
        type: "string",
        title: "Security Group ID",
      },
      default: [],
    },
    cluster_endpoint_private_access: {
      title:
        "Indicates whether or not the Amazon EKS private API server endpoint is enabled",
      type: "boolean",
      default: true,
    },
    cluster_endpoint_public_access: {
      title:
        "Indicates whether or not the Amazon EKS public API server endpoint is enabled",
      type: "boolean",
      default: false,
    },
    cluster_endpoint_public_access_cidrs: {
      title:
        "List of CIDR blocks which can access the Amazon EKS public API server endpoint",
      type: "array",
      items: {
        title: "CIDR block",
        type: "string",
      },
      default: ["0.0.0.0/0"],
    },
    cluster_ip_family: {
      title:
        "The IP family used to assign Kubernetes pod and service addresses",
      type: ["string", "null"],
      default: null,
    },
    cluster_service_ipv4_cidr: {
      title: "The CIDR block to assign Kubernetes service IP addresses from",
      type: ["string", "null"],
      default: null,
    },
    cluster_service_ipv6_cidr: {
      title:
        "The CIDR block to assign Kubernetes pod and service IP addresses from if ipv6 was specified when the cluster was created",
      type: ["string", "null"],
      default: null,
    },
    prefix_separator: {
      type: "string",
      title: "Prefix Separator",
      default: "-",
    },
    cluster_encryption_config: {
      type: "object",
      title: "Cluster Encryption Config",
      properties: {
        resources: {
          type: "array",
          title: "Resources",
          items: {
            title: "Resource",
            type: "string",
          },
          default: ["secrets"],
        },
        provider_key_arn: {
          type: ["string", "null"],
          title: "Provider Key Arn",
          default: null,
        },
      },
      default: {
        resources: ["secrets"],
      },
    },
    attach_cluster_encryption_policy: {
      type: "boolean",
      title: "Attach Cluster Encryption Policy",
      default: true,
    },
    create_cluster_primary_security_group_tags: {
      type: "boolean",
      title: "Create Cluster Primary Security Group Tags",
      default: true,
    },

    cluster_addons: {
      type: "array",
      items: {
        type: "object",
        title: "Cluster Addon",
        properties: {
          name: {
            type: "string",
            default: "",
          },
          before_compute: {
            type: "boolean",
            default: true,
          },
          addon_version: {
            type: ["string", "null"],
            default: null,
          },
          most_recent: {
            type: "boolean",
            default: true,
          },
          configuration_values: {
            type: ["string", "null"],
            default: null,
          },
          preserve: {
            type: "boolean",
            default: false,
          },
          resolve_conflicts: {
            type: "string",
            enum: ["NONE", "OVERWRITE", "PRESERVE"],
            default: "OVERWRITE",
          },
          service_account_role_arn: {
            type: ["string", "null"],
            default: null,
          },
          timeouts: {
            title: "Terraform Timeouts",
            properties: {
              create: {
                type: "string",
              },
              update: {
                type: "string",
              },
              delete: {
                type: "string",
              },
            },
            default: {
              create: "60m",
              update: "60m",
              delete: "60m",
            },
          },
        },
        required: ["name"],
      },
      title: "Cluster Addons",
      default: [],
    },
    create_kms_key: {
      type: "boolean",
      title: "Create KMS Key",
      default: true,
    },
    kms_key_description: {
      type: ["string", "null"],
      title: "KMS Key Description",
      default: null,
    },
    kms_key_deletion_window_in_days: {
      type: ["number", "null"],
      title: "KMS Key Deletion Window In Days",
      default: null,
    },
    enable_kms_key_rotation: {
      type: "boolean",
      title: "Enable KMS Key Rotation",
      default: true,
    },
    kms_key_enable_default_policy: {
      type: "boolean",
      title: "KMS Key Enable Default Policy",
      default: false,
    },
    kms_key_owners: {
      type: "array",
      items: {
        type: "string",
        title: "KMS Key Owner",
      },
      title: "KMS Key Owners",
      default: [],
    },
    kms_key_administrators: {
      type: "array",
      items: {
        type: "string",
        title: "KMS Key Administrator",
      },
      title: "KMS Key Administrators",
      default: [],
    },
    kms_key_users: {
      type: "array",
      items: {
        type: "string",
        title: "KMS Key User",
      },
      title: "KMS Key Users",
      default: [],
    },
    kms_key_service_users: {
      type: "array",
      items: {
        type: "string",
        title: "KMS Key Service User",
      },
      title: "KMS Key Service Users",
      default: [],
    },
    kms_key_aliases: {
      type: "array",
      items: {
        type: "string",
        title: "KMS Key Alias",
      },
      title: "KMS Key Aliases",
      default: [],
    },
    create_cloudwatch_log_group: {
      type: "boolean",
      title: "Create Cloudwatch Log Group",
      default: true,
    },
    cloudwatch_log_group_retention_in_days: {
      type: "number",
      title: "Cloudwatch Log Group Retention In Days",
      default: 90,
    },
    cloudwatch_log_group_kms_key_id: {
      type: ["string", "null"],
      title: "Cloudwatch Log Group KMS Key Id",
      default: null,
    },
    enable_irsa: {
      type: "boolean",
      title: "Enable Irsa",
      default: true,
    },
    openid_connect_audiences: {
      type: "array",
      items: {
        type: "string",
        title: "OpenId Connect Audience",
      },
      title: "OpenId Connect Audiences",
      default: [],
    },
    custom_oidc_thumbprints: {
      type: "array",
      items: {
        type: "string",
        title: "Custom OIDC Thumbprint",
      },
      title: "Custom OIDC Thumbprints",
      default: [],
    },
  },
  required: ["cluster_name", "subnet_ids", "control_plane_subnet_ids"],
};

export const awsEKSUIschema = {
  "ui:description": "EKS Cluster Configuration",
  cluster_name: {
    "ui:description": "Name of the EKS cluster",
    "ui:emptyValue": "",
  },
  cluster_version: {
    "ui:description": "Kubernetes version to use for the EKS cluster",
    "ui:emptyValue": null,
  },
  tags: {
    "ui:description": "Map of key/value tags to add to all resources",
  },
  cluster_enabled_log_types: {
    "ui:description": "A list of the desired control plane logs to enable",
    items: {
      "ui:description": "Log Type",
    },
  },
  cluster_additional_security_group_ids: {
    "ui:description":
      "List of additional, externally created security group IDs to attach to the cluster control plane",
    items: {
      "ui:description": "Security Group ID",
    },
  },
  control_plane_subnet_ids: {
    "ui:description":
      "A list of subnet IDs where the EKS cluster control plane (ENIs) will be provisioned",
    items: {
      "ui:description": "Subnet ID",
    },
  },
  subnet_ids: {
    "ui:description":
      "A list of subnet IDs where the nodes/node groups will be provisioned",
    items: {
      "ui:description": "Subnet ID",
    },
  },
  cluster_endpoint_private_access: {
    "ui:description":
      "Indicates whether or not the Amazon EKS private API server endpoint is enabled",
  },
  cluster_endpoint_public_access: {
    "ui:description":
      "Indicates whether or not the Amazon EKS public API server endpoint is enabled",
  },
  cluster_endpoint_public_access_cidrs: {
    "ui:description":
      "List of CIDR blocks which can access the Amazon EKS public API server endpoint",
    items: {
      "ui:description": "CIDR block",
    },
  },
  cluster_ip_family: {
    "ui:description":
      "The IP family used to assign Kubernetes pod and service addresses",
    "ui:emptyValue": null,
  },
  cluster_service_ipv4_cidr: {
    "ui:description":
      "The CIDR block to assign Kubernetes service IP addresses from",
    "ui:emptyValue": null,
  },
  cluster_service_ipv6_cidr: {
    "ui:description":
      "The CIDR block to assign Kubernetes pod and service IP addresses from if ipv6 was specified when the cluster was created",
    "ui:emptyValue": null,
  },
  prefix_separator: {
    "ui:description":
      "The separator to use between the prefix and the generated timestamp for resource names",
  },
  cluster_encryption_config: {
    "ui:description":
      "Configuration block with encryption configuration for the cluster. To disable secret encryption, set this value to `{}`",
    resources: {
      "ui:description": "Resources to be encrypted. Valid values: `secrets`",
      items: {
        "ui:description": "Resource to be encrypted",
      },
    },
    provider_key_arn: {
      "ui:description":
        "ARN of the Key Management Service (KMS) customer master key (CMK). The CMK must be symmetric, created in the same region as the cluster, and if the CMK was created in a different account, the user must have access to the CMK. For more information, see Allowing Users in Other Accounts to Use a CMK in the AWS Key Management Service Developer Guide",
    },
  },
  attach_cluster_encryption_policy: {
    "ui:description":
      "Indicates whether or not to attach an additional policy for the cluster IAM role to utilize the encryption key provided",
  },
  create_cluster_primary_security_group_tags: {
    "ui:description":
      "Indicates whether or not to tag the cluster's primary security group. This security group is created by the EKS service, not the module, and therefore tagging is handled after cluster creation",
  },
  cluster_timeouts: {
    "ui:description":
      "Terraform create, update, and delete timeout configurations for the cluster",
    create: {
      "ui:description": "Create timeout",
    },
    update: {
      "ui:description": "Update timeout",
    },
    delete: {
      "ui:description": "Delete timeout",
    },
  },
  cluster_addons: {
    "ui:description":
      "Map of cluster addon configurations to enable for the cluster. Addon name can be the map keys or set with `name`",
    items: {
      "ui:description": "Cluster Addon Configuration",
      "ui:displayfield": "name",
      name: {
        "ui:description":
          "Name of the EKS add-on. The name must match one of the names returned by describe-addon-versions",
      },
      before_compute: {
        "ui:description":
          "Specifies whether the add-on can be applied to a cluster that has existing workloads or not",
      },
      most_recent: {
        "ui:description":
          "Determines if the most recent or default version of the addon should be returned.",
      },
      addon_version: {
        "ui:description":
          "The version of the EKS add-on. The version must match one of the versions returned by describe-addon-versions",
        "ui:emptyValue": null,
      },
      configuration_values: {
        "ui:description":
          "Custom configuration values for addons with single JSON string. This JSON string value must match the JSON schema derived from describe-addon-configuration.",
        "ui:widget": "textarea",
        "ui:options": {
          rows: 10,
        },
        "ui:emptyValue": null,
      },
      preserve: {
        "ui:description":
          "Indicates if you want to preserve the created resources when deleting the EKS add-on.",
      },
      resolve_conflicts: {
        "ui:description":
          "Deprecated. Define how to resolve parameter value conflicts when migrating an existing add-on to an Amazon EKS add-on or when applying version updates to the add-on.",
      },
      service_account_role_arn: {
        "ui:description":
          "The Amazon Resource Name (ARN) of an existing IAM role to bind to the add-on's service account. The role must be assigned the IAM permissions required by the add-on.",
        "ui:emptyValue": null,
      },
      timeouts: {
        "ui:description": "Terraform timeouts for applying cluster addons",
        create: {
          "ui:description": "Create timeout",
        },
        update: {
          "ui:description": "Update timeout",
        },
        delete: {
          "ui:description": "Delete timeout",
        },
      },
    },
  },
  create_kms_key: {
    "ui:description":
      "Controls if a KMS key for cluster encryption should be created",
  },
  kms_key_description: {
    "ui:description": "The description of the key as viewed in AWS console",
    "ui:emptyValue": null,
  },
  kms_key_deletion_window_in_days: {
    "ui:description":
      "The waiting period, specified in number of days. After the waiting period ends, AWS KMS deletes the KMS key.",
    "ui:emptyValue": null,
  },
  enable_kms_key_rotation: {
    "ui:description": "Specifies whether key rotation is enabled.",
  },
  kms_key_enable_default_policy: {
    "ui:description": "Specifies whether to enable the default key policy.",
  },
  kms_key_owners: {
    "ui:description":
      "A list of IAM ARNs for those who will have full key permissions (`kms:*`)",
    items: {
      "ui:description": "Owner ARN",
    },
  },
  kms_key_administrators: {
    "ui:description":
      "A list of IAM ARNs for [key administrators](https://docs.aws.amazon.com/kms/latest/developerguide/key-policy-default.html#key-policy-default-allow-administrators). If no value is provided, the current caller identity is used to ensure at least one key admin is available",
    items: {
      "ui:description": "Administrator ARN",
    },
  },
  kms_key_users: {
    "ui:description":
      "A list of IAM ARNs for [key users](https://docs.aws.amazon.com/kms/latest/developerguide/key-policy-default.html#key-policy-default-allow-users)",
    items: {
      "ui:description": "User ARN",
    },
  },
  kms_key_service_users: {
    "ui:description":
      "A list of IAM ARNs for [key service users](https://docs.aws.amazon.com/kms/latest/developerguide/key-policy-default.html#key-policy-service-integration)",
    items: {
      "ui:description": "Service User ARN",
    },
  },
  kms_key_aliases: {
    "ui:description":
      "A list of aliases to create. Note - due to the use of `toset()`, values must be static strings and not computed values",
    items: {
      "ui:description": "Alias",
    },
  },
  create_cloudwatch_log_group: {
    "ui:description":
      "Determines whether a log group is created by this module for the cluster logs. If not, AWS will automatically create one if logging is enabled",
  },
  cloudwatch_log_group_retention_in_days: {
    "ui:description":
      "Number of days to retain log events. Default retention - 90 days",
  },
  cloudwatch_log_group_kms_key_id: {
    "ui:description":
      "If a KMS Key ARN is set, this key will be used to encrypt the corresponding log group. Please be sure that the KMS Key has an appropriate key policy (https://docs.aws.amazon.com/AmazonCloudWatch/latest/logs/encrypt-log-data-kms.html)",
    "ui:emptyValue": null,
  },
  enable_irsa: {
    "ui:description":
      "Determines whether to create an OpenID Connect Provider for EKS to enable IRSA",
  },
  openid_connect_audiences: {
    "ui:description":
      "List of OpenID Connect audience client IDs to add to the IRSA provider",
    items: {
      "ui:description": "OpenID Connect Audience",
    },
  },
  custom_oidc_thumbprints: {
    "ui:description":
      "Additional list of server certificate thumbprints for the OpenID Connect (OIDC) identity provider's server certificate(s)",
    items: {
      "ui:description": "Custom OIDC Thumbprint",
    },
  },
};
