export const awsIAMgroupsWithPoliciesSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Module name",
    },
    groups: {
      type: "array",
      items: {
        type: "object",
        title: "IAM group",
        properties: {
          create_group: {
            type: "boolean",
            title: "create group",
            default: true,
          },
          name: {
            type: "string",
            title: "name",
          },
          path: {
            type: "string",
            title: "path",
            default: "/",
          },
          group_users: {
            type: "array",
            items: {
              type: "string",
            },
            title: "group users",
            default: [],
          },
          custom_group_policy_arns: {
            type: "array",
            items: {
              type: "string",
            },
            title: "custom group policy arns",
            default: [],
          },
          custom_group_policies: {
            type: "array",
            items: {
              type: "object",
              additionalProperties: {
                type: "string",
              },
            },
            title: "custom group policies",
            default: [],
          },
          enable_mfa_enforcement: {
            type: "boolean",
            title: "enable mfa enforcement",
            default: true,
          },
          attach_iam_self_management_policy: {
            type: "boolean",
            title: "attach iam self management policy",
            default: true,
          },
          iam_self_management_policy_name_prefix: {
            type: "string",
            title: "iam self management policy name prefix",
            default: "IAMSelfManagement-",
          },
          aws_account_id: {
            type: "string",
            title: "aws account id",
            default: "",
          },
          tags: {
            type: "object",
            title: "tags",
            additionalProperties: {
              type: "string",
              title: "tag value",
            },
            default: {},
          },
        },
        required: ["name"],
      },
      default: [],
    },
  },
  required: ["name"],
};

export const awsIAMgroupsWithPoliciesUISchema = {
  "ui:description": "Creates IAM groups with policies",
  name: {
    "ui:description": "Name of the module to create",
  },
  groups: {
    "ui:description": "List of IAM groups to create",
    items: {
      "ui:displayfield": "name",
      "ui:description": "IAM group settings",
      create_group: {
        "ui:description": "Whether to create IAM group",
      },
      name: {
        "ui:description": "Name of IAM group",
      },
      path: {
        "ui:description": "Desired path for the IAM group",
      },
      group_users: {
        "ui:description":
          "List of IAM users to have in an IAM group which can assume the role",
        items: {
          "ui:description": "IAM user",
        },
      },
      custom_group_policy_arns: {
        "ui:description": "List of IAM policies ARNs to attach to IAM group",
      },
      custom_group_policies: {
        "ui:description":
          "List of maps of inline IAM policies to attach to IAM group. Should have name and policy keys in each element.",
      },
      enable_mfa_enforcement: {
        "ui:description":
          "Determines whether permissions are added to the policy which requires the groups IAM users to use MFA",
      },
      attach_iam_self_management_policy: {
        "ui:description":
          "Whether to attach IAM policy which allows IAM users to manage their credentials and MFA",
      },
      iam_self_management_policy_name_prefix: {
        "ui:description":
          "Name prefix for IAM policy to create with IAM self-management permissions",
      },
      aws_account_id: {
        "ui:description":
          "AWS account id to use inside IAM policies. If empty, current AWS account ID will be used.",
        "ui:emptyValue": "",
      },
      tags: {
        "ui:description": "A map of tags to add to all resources.",
        additionalProperties: {
          "ui:description": "Tag value",
        },
      },
    },
  },
};
