export const bqSchema = {
  type: "object",
  title: "BQ Dataset Settings",
  properties: {
    dataset_id: {
      type: "string",
      title: "Dataset ID",
    },
    dataset_name: {
      type: "string",
      title: "Dataset Name",
    },
    description: {
      type: "string",
      title: "Description",
    },
    location: {
      type: "string",
      enum: ["EU", "US"],
      default: "US",
      title: "Location",
    },
    delete_contents_on_destroy: {
      description:
        "(Optional) If set to true, delete all the tables in the dataset when destroying the resource; otherwise, destroying the resource will fail if tables are present.",
      type: "boolean",
      default: false,
      title: "Delete Contents On Destroy",
    },
    deletion_protection: {
      description:
        "Whether or not to allow Terraform to destroy the instance. Unless this field is set to false in Terraform state, a terraform destroy or terraform apply that would delete the instance will fail",
      default: true,
      title: "Deletion Protection",
      type: "boolean",
    },
    default_table_expiration_ms: {
      type: ["number", "null"],
      default: null,
      description: "TTL of tables using the dataset in MS",
      title: "Default Table Expiration miliseconds",
    },
    encryption_key: {
      title: "Encryption Key",
      description:
        "Default encryption key to apply to the dataset. Defaults to null (Google-managed).",
      type: ["string", "null"],
      default: null,
    },
    access: {
      description:
        "An array of objects that define dataset access for one or more entities.",
      title: "Dataset Access",
      type: "array",
      default: [
        {
          role: "roles/bigquery.dataOwner",
          type: "special_group",
          persona: "projectOwners",
        },
      ],
      items: {
        type: "object",
        properties: {
          role: {
            type: "string",
          },
          type: {
            type: "string",
            enum: ["group_by_email", "user_by_email", "special_group"],
          },
          persona: {
            type: "string",
          },
        },
      },
    },
    tables: {
      description:
        "A list of objects which include table_id, schema, clustering, time_partitioning, range_partitioning, expiration_time and labels.",
      title: "Tables",
      type: "array",
      items: {
        type: "object",
        properties: {
          table_id: {
            type: "string",
          },
          schema: {
            type: "string",
          },
          expiration_time: {
            type: ["string", "null"],
            title: "Expiration Time",
            default: null,
          },
          time_partitioning: {
            title: "Time Partitioning",
            description: "Define only one Time Partitioning",
            type: "array",
            default: [],
            items: {
              type: "object",
              properties: {
                expiration_ms: {
                  type: ["string", "null"],
                  title: "Expiration in miliseconds",
                  default: null,
                },
                field: {
                  type: ["string", "null"],
                  title: "Field",
                  default: null,
                },
                type: {
                  type: ["string", "null"],
                  title: "Type",
                  default: "DAY",
                  enum: ["DAY", "MONTH", "HOUR", "YEAR"],
                },
                require_partition_filter: {
                  type: "boolean",
                  title: "Require Partition Filter",
                },
              },
            },
          },
          range_partitioning: {
            title: "Range Partitoning",
            description: "Define only one range partitioning",
            type: "array",
            default: [],
            items: {
              type: "object",
              properties: {
                field: {
                  type: "string",
                  title: "Field",
                },
                range: {
                  title: "Range",
                  type: "object",
                  properties: {
                    start: {
                      type: "string",
                    },
                    end: {
                      type: "string",
                    },
                    interval: {
                      type: "string",
                    },
                  },
                  required: ["start", "end", "interval"],
                },
              },
            },
          },
          labels: {
            title: "Table Labels",
            type: "array",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
            },
          },
        },
        required: ["table_id", "schema"],
      },
    },
    views: {
      title: "Views",
      description:
        "A list of objects which include table_id, which is view id, and view query",
      type: "array",
      items: {
        type: "object",
        properties: {
          view_id: {
            type: "string",
            title: "View ID",
          },
          query: {
            type: "string",
          },
          use_legacy_sql: {
            type: "boolean",
            default: false,
            title: "Use Legacy SQL",
          },
          labels: {
            title: "View Labels",
            type: "array",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
            },
          },
        },
        required: ["view_id"],
      },
    },
    dataset_labels: {
      title: "Dataset Labels",
      description: "Key value pairs in a map for dataset labels",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
  },
  required: ["dataset_id", "dataset_name"],
};
