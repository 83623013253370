export const gkeSchema = {
  title: "GKE Config",
  type: "object",
  properties: {
    name: {
      title: "GKE Name",
      type: "string",
    },
    description: {
      title: "Description",
      type: "string",
    },
    region: {
      type: "string",
      title: "Region",
    },
    network: {
      type: "string",
      title: "vpc name",
    },
    subnetwork: {
      title: "Subnetwork name",
      type: "string",
    },
    ip_range_pods: {
      title: "Pods IP Range",
      type: "string",
    },
    ip_range_services: {
      title: "Services IP Range",
      type: "string",
    },
    regional: {
      title: "Regional",
      type: "boolean",
      default: true,
    },
    zones: {
      title: "Zones",
      type: "array",
      items: {
        type: "string",
      },
      default: [],
    },
    master_ipv4_cidr_block: {
      title: "Mater IPv4 CIDR Block",
      default: "10.0.0.0/28",
      type: "string",
    },
    network_project_id: {
      title: "Network Project ID",
      type: "string",
      default: "",
    },
    kubernetes_version: {
      type: ["string", "null"],
      title: "Kubernetes version",
      default: null,
    },
    release_channel: {
      type: "string",
      title: "Release Channel",
      enum: ["UNSPECIFIED", "RAPID", "REGULAR", "STABLE"],
      default: "REGULAR",
    },
    gateway_api_channel: {
      title: "Gateway API Channel",
      type: ["string", "null"],
      enum: ["CHANNEL_STANDARD", "CHANNEL_DISABLED", null],
      default: null,
    },
    master_authorized_networks: {
      title: "Master Authorized Networks",
      type: "array",
      items: {
        type: "object",
        properties: {
          cidr_block: {
            type: "string",
            title: "CIDR Block",
          },
          display_name: {
            type: "string",
            title: "Display Name",
          },
        },
        required: ["cidr_block", "display_name"],
      },
      default: [],
    },
    initial_node_count: {
      title: "Initial Node Count",
      type: "number",
      default: 0,
    },
    default_max_pods_per_node: {
      default: 110,
      type: "number",
      title: "Default Max Pods Per Node",
    },
    node_pools: {
      title: "Node Pools",
      type: "array",
      description: "List of node pools",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          service_account: {
            type: "string",
            title: "Service Account",
            default: "",
          },
          disk_size_gb: {
            type: "number",
            title: "Disk Size in GB",
            default: 100,
          },
          disk_type: {
            type: "string",
            title: "Disk Type",
            default: "pd-standard",
            enum: ["pd-standard", "pd-balanced", "pd-ssd"],
          },
          accelerator_type: {
            type: "string",
            title: "Accelerator Type",
            default: "",
          },
          accelerator_count: {
            type: "number",
            title: "Accelerator Count",
            default: 0,
          },
          image_type: {
            type: "string",
            title: "Image Type",
            default: "COS_CONTAINERD",
          },
          machine_type: {
            type: "string",
            title: "Machine Type",
            default: "e2-medium",
          },
          local_ssd_count: {
            type: "number",
            title: "Local SSD Count",
            default: 0,
          },
          preemptible: {
            type: "boolean",
            default: false,
            description: "Use preamtable machines",
          },
          enable_secure_boot: {
            type: "boolean",
            title: "Enable Secure Boot",
            default: false,
          },
          enable_integrity_monitoring: {
            type: "boolean",
            title: "Enable Integrity Monitoring",
            default: true,
          },
          auto_repair: {
            type: "boolean",
            title: "Auto Repair",
            default: true,
          },
          auto_upgrade: {
            type: "boolean",
            title: "Auto Upgrade",
            default: true,
          },
          autoscaling: {
            type: "boolean",
            title: "Autoscaling",
            default: true,
          },
          max_pods_per_node: {
            type: ["number", "null"],
            title: "Max Pods Per Node",
            default: null,
          },
          min_count: {
            type: "number",
            title: "Min Node Count",
            default: 1,
          },
          max_count: {
            type: "number",
            title: "Max Node Count",
            default: 100,
          },
          node_count: {
            type: ["number", "null"],
            title: "Node Count",
            default: null,
          },
          total_min_count: {
            type: ["number", "null"],
            title: "Min Node Count",
            default: null,
          },
          total_max_count: {
            type: ["number", "null"],
            title: "Max Node Count",
            default: null,
          },
          spot: {
            type: "boolean",
            title: "Spot",
            default: false,
          },
          version: {
            type: "string",
            title: "Version",
            default: "",
          },
          stategy: {
            type: "string",
            title: "Strategy",
            enum: ["BLUE_GREEN", "SURGE"],
            default: "SURGE",
          },
          node_locations: {
            type: "string",
            title: "Locations",
            default: "",
          },
          oauth_scopes: {
            type: "array",
            title: "OAuth scopes",
            items: {
              type: "string",
            },
            default: ["https://www.googleapis.com/auth/cloud-platform"],
          },
          labels: {
            default: [],
            type: "array",
            title: "Labels",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
              required: ["key", "value"],
            },
          },
          metadata: {
            default: [],
            type: "array",
            title: "Metadata",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
              required: ["key", "value"],
            },
          },
          tags: {
            type: "array",
            default: [],
            title: "Network tags",
            items: {
              type: "string",
            },
          },
          taints: {
            title: "Kubernetes Taints",
            type: "array",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
                effect: {
                  type: "string",
                  enum: ["NO_SCHEDULE", "PREFER_NO_SCHEDULE", "NO_EXECUTE"],
                  default: "NO_SCHEDULE",
                },
              },
              required: ["key", "value", "effect"],
              default: [],
            },
          },
          resource_labels: {
            type: "array",
            default: [],
            title: "Resource Labels",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                },
                value: {
                  type: "string",
                },
              },
              required: ["key", "value"],
            },
          },
        },
        required: ["name"],
      },
    },
    cluster_autoscaling: {
      title: "Cluster Autoscaling",
      type: "object",
      default: {
        enabled: false,
        autoscaling_profile: "BALANCED",
        max_cpu_cores: 0,
        min_cpu_cores: 0,
        max_memory_gb: 0,
        min_memory_gb: 0,
        gpu_resources: [],
        auto_repair: true,
        auto_upgrade: true,
      },
      properties: {
        enabled: {
          type: "boolean",
          default: false,
        },
        autoscaling_profile: {
          type: "string",
        },
        min_cpu_cores: {
          type: "number",
          default: 0,
        },
        max_cpu_cores: {
          type: "number",
          default: 0,
        },
        min_memory_gb: {
          type: "number",
          default: 0,
        },
        max_memory_gb: {
          type: "number",
          default: 0,
        },
        gpu_resources: {
          type: "array",
          items: {
            type: "object",
            properties: {
              resource_type: {
                type: "string",
              },
              minimum: {
                type: "number",
              },
              maximum: {
                type: "number",
              },
            },
            required: ["resource_type", "minimum", "maximum"],
          },
          default: [],
        },
        auto_repair: {
          type: "boolean",
          default: true,
        },
        auto_upgrade: {
          type: "boolean",
          default: true,
        },
      },
    },
    horizontal_pod_autoscaling: {
      type: "boolean",
      title: "Horizontal Pod Autoscaling",
      default: true,
    },
    http_load_balancing: {
      type: "boolean",
      title: "HTTP Load Balancing",
      default: true,
    },
    datapath_provider: {
      type: "string",
      title: "Datapath Provider",
      default: "ADVANCED_DATAPATH",
    },
    maintenance_start_time: {
      title: "Maintenance Start Time",
      type: "string",
      default: "05:00",
    },
    maintenance_exclusions: {
      title: "Maintenance Exclusions",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          start_time: {
            type: "string",
            title: "Start Time",
          },
          end_time: {
            type: "string",
            title: "End Time",
          },
          exclusion_scope: {
            type: "string",
            title: "Exclusion Scope",
          },
        },
        required: ["name", "start_time", "end_time"],
      },
      default: [],
    },
    maintenance_end_time: {
      title: "Maintenance End Time",
      type: "string",
      default: "",
    },
    maintenance_recurrence: {
      title: "Maintenance Recurrence",
      type: "string",
      default: "",
    },
    stub_domains: {
      title: "Stub Domains",
      type: "array",
      items: {
        type: "object",
        properties: {
          domain: {
            type: "string",
            title: "Name",
          },
          resolver_ips: {
            type: "array",
            items: {
              type: "string",
            },
          },
        },
        required: ["name", "resolver_ips"],
      },
      default: [],
    },
    upstream_nameservers: {
      title: "Upstream Nameservers",
      type: "array",
      default: [],
      items: {
        type: "string",
      },
    },
    logging_service: {
      type: "string",
      title: "Logging Service",
      enum: [
        "logging.googleapis.com",
        "logging.googleapis.com/kubernetes",
        "none",
      ],
      default: "logging.googleapis.com/kubernetes",
    },
    monitoring_service: {
      type: "string",
      title: "Monitoring Service",
      enum: [
        "monitoring.googleapis.com",
        "monitoring.googleapis.com/kubernetes",
        "none",
      ],
      default: "monitoring.googleapis.com/kubernetes",
    },
    monitoring_enable_managed_prometheus: {
      type: "boolean",
      default: false,
      title: "Enable Managed Prometheus",
    },
    grant_registry_access: {
      type: "boolean",
      title: "Grant Registry Access",
      default: true,
    },
    registry_project_ids: {
      type: "array",
      title: "Registry Project IDs",
      items: {
        type: "string",
      },
      default: [],
    },
    cluster_resource_labels: {
      title: "Cluster Resource Labels",
      default: [],
      type: "array",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
        required: ["key", "value"],
      },
    },
    istio: {
      title: "Istio",
      type: "boolean",
      default: false,
    },
    istio_auth: {
      type: "string",
      title: "Istio Auth",
      default: "AUTH_MUTUAL_TLS",
    },
    dns_cache: {
      type: "boolean",
      title: "DNS Cache",
      default: true,
    },
    cluster_dns_provider: {
      type: "string",
      default: "PROVIDER_UNSPECIFIED",
      enum: ["PROVIDER_UNSPECIFIED", "CLOUD_DNS", "PLATFORM_DEFAULT"],
      title: "Cluster DNS Provider",
    },
    cluster_dns_scope: {
      type: "string",
      default: "DNS_SCOPE_UNSPECIFIED",
      enum: ["DNS_SCOPE_UNSPECIFIED", "CLUSTER_SCOPE", "VPC_SCOPE"],
      title: "Cluster DNS Scope",
    },
    cluster_dns_domain: {
      type: "string",
      title: "Cluster DNS Domain",
      default: "",
    },
    database_encryption: {
      title: "Database Encryption",
      type: "array",
      items: {
        type: "object",
        properties: {
          state: {
            type: "string",
            enum: ["ENCRYPTED", "DECRYPTED"],
            default: "DECRYPTED",
          },
          key_name: {
            type: "string",
            default: "",
          },
        },
      },
      default: [{ state: "DECRYPTED", key_name: "" }],
    },
    cloudrun: {
      title: "Cloud Run",
      type: "boolean",
      default: false,
    },
    resource_usage_export_dataset_id: {
      title: "Resouce Usage Export Dataset ID",
      type: "string",
      default: "",
    },
    enable_cost_allocation: {
      title: "Enable Cost Allocation",
      type: "boolean",
      default: false,
    },
    sandbox_enabled: {
      title: "Sandbox Enabled",
      type: "boolean",
      default: false,
    },
    enable_intranode_visibility: {
      type: "boolean",
      default: false,
      title: "Enable Intranode Visibility",
    },
    enable_vertical_pod_autoscaling: {
      type: "boolean",
      title: "Enable Vertical Pod Autoscaling",
      default: false,
    },
    authenticator_security_group: {
      type: ["string", "null"],
      title: "Authenticator Security Group",
      default: null,
    },
    compute_engine_service_account: {
      type: "string",
      title: "Compute Engine Service Account",
      default: "",
    },
    enable_shielded_nodes: {
      title: "Enable Shielded Nodes",
      type: "boolean",
      default: true,
    },
    enable_private_endpoint: {
      title: "Enable Private Endpoint",
      type: "boolean",
      default: true,
    },
    enable_pod_security_policy: {
      title: "Enable Pod Security Policy",
      type: "boolean",
      default: false,
    },
    gce_pd_csi_driver: {
      title: "GCE PD CSI Driver",
      type: "boolean",
      default: true,
    },
    filestore_csi_driver: {
      title: "Filestore CSI Driver",
      type: "boolean",
      default: false,
    },
    add_cluster_firewall_rules: {
      title: "Add Cluster Firewall Rules",
      type: "boolean",
      default: false,
    },
    firewall_priority: {
      title: "Firewall Priority",
      type: "number",
      default: 1000,
    },
    firewall_inbound_ports: {
      title: "Firewall Inbound Ports",
      type: "array",
      items: {
        type: "string",
      },
      default: ["8443", "9443", "15017"],
    },
    config_connector: {
      title: "Config Connector",
      type: "boolean",
      default: false,
    },
    gke_backup_agent_config: {
      title: "GKE Backup Agent Config",
      type: "boolean",
      default: false,
    },
    disable_default_snat: {
      title: "Disable Default SNAT",
      type: "boolean",
      default: false,
    },
    notification_config_topic: {
      title: "Notification Config Topic",
      type: "string",
      default: "",
    },

    timeouts: {
      type: "object",
      properties: {
        create: {
          type: "string",
          default: "30m",
        },
        update: {
          type: "string",
          default: "30m",
        },
        delete: {
          type: "string",
          default: "30m",
        },
      },
    },
  },

  required: [
    "name",
    "network",
    "subnetwork",
    "ip_range_pods",
    "ip_range_services",
  ],
};
