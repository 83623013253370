export const databricksAccountUsersSchema = {
  type: "object",
  properties: {
    name: {
      description: "The name of the module.",
      type: "string",
    },
    users: {
      type: "array",
      items: {
        type: "object",
        properties: {
          user_name: {
            description:
              "This is the username of the given user and will be their form of access and identity.",
            type: "string",
          },
          display_name: {
            description:
              "This is an alias for the username that can be the full name.",
            type: "string",
            default: "",
          },
          external_id: {
            description: "ID of the user in an external identity provider.",
            type: ["string", "null"],
            default: null,
          },
          active: {
            description:
              " Either user is active or not. True by default, but can be set to false in case of user deactivation with preserving user assets.",
            type: "boolean",
            default: true,
          },
          force: {
            description:
              "Ignore cannot create user: User with username X already exists errors and implicitly import the specific user into Terraform state, enforcing entitlements defined in the instance of resource. This functionality is experimental and is designed to simplify corner cases, like Azure Active Directory synchronisation.",
            type: "boolean",
            default: false,
          },
          allow_cluster_create: {
            description:
              "Allow the user to have cluster create privileges. Defaults to false. More fine grained permissions could be assigned with databricks_permissions and cluster_id argument. Everyone without allow_cluster_create argument set, but with permission to use Cluster Policy would be able to create clusters, but within boundaries of that specific policy.",
            type: "boolean",
            default: false,
          },
          allow_instance_pool_create: {
            description:
              "Allow the user to have instance pool create privileges. Defaults to false. More fine grained permissions could be assigned with databricks_permissions and instance_pool_id argument.",
            type: "boolean",
            default: false,
          },
          databricks_sql_access: {
            description:
              "This is a field to allow the group to have access to Databricks SQL feature in User Interface and through databricks_sql_endpoint.",
            type: "boolean",
            default: false,
          },
          force_delete_repos: {
            description:
              "This flag determines whether the user's repo directory is deleted when the user is deleted. It will have no impact when in the accounts SCIM API. False by default.",
            type: "boolean",
            default: false,
          },
          force_delete_home_dir: {
            description:
              "This flag determines whether the user's home directory is deleted when the user is deleted. It will have not impact when in the accounts SCIM API. False by default.",
            type: "boolean",
            default: false,
          },
          disable_as_user_deletion: {
            description:
              "When deleting a user, set the user's active flag to false instead of actually deleting the user. This flag is exclusive to force_delete_repos and force_delete_home_dir flags. True by default for accounts SCIM API, false otherwise.",
            type: "boolean",
            default: false,
          },
        },
        required: ["user_name"],
      },
    },
    required: ["name"],
  },
};

export const databricksAccountUsersUISchema = {
  "ui:description": "The list of users in the databricks account.",
  users: {
    items: {
      "ui:description": "User Details",
      "ui:displayfield": "user_name",
      user_name: {
        "ui:emptyValue": "",
      },
      external_id: {
        "ui:emptyValue": null,
      },
    },
  },
};
