import { Typography, Box } from "@mui/material";
export default function DisplayDetails({
  componentName = "None",
  componentValue = "None",
  bottomMargin = true,
}) {
  return (
    <Box
      sx={{
        flexFlow: "column",
        ml: 1,
        mr: 1,
        mt: 1,
        mb: (theme) => (bottomMargin ? 1 : 0.25),
      }}
    >
      <Typography fontSize="0.5em">{componentName}</Typography>
      <Typography fontSize="0.8em">{componentValue}</Typography>
    </Box>
  );
}
