export const gkeSchemaUI = {
  "ui:description": "GKE Cluster settings",
  name: {
    "ui:description": "The name of the cluster. Required",
  },
  description: {
    "ui:description": "Short description of the cluster",
  },
  region: {
    "ui:description": "The region to host the cluster in. Required",
  },
  network: {
    "ui:description": "The VPC network to host the cluster in. Required",
  },
  subnetwork: {
    "ui:description": "The subnetwork to host the cluster in. Required",
  },
  ip_range_pods: {
    "ui:description":
      "The name of the secondary subnet ip range to use for pods. Required",
  },
  ip_range_services: {
    "ui:description":
      "The name of the secondary subnet ip range to use for services. Required",
  },
  regional: {
    "ui:description":
      "Whether is a regional cluster (zonal cluster if set false. WARNING: changing this after cluster creation is destructive!)",
  },
  zones: {
    "ui:description":
      "The zones to host the cluster in. Press + to add zones one by one",
    items: {
      "ui:description": "For example us-central1-a",
    },
  },
  master_ipv4_cidr_block: {
    "ui:description":
      "The IP range in CIDR notation to use for the hosted master network",
  },
  network_project_id: {
    "ui:description":
      "The project ID of the shared VPC's host (for shared vpc support)",
  },
  kubernetes_version: {
    "ui:description":
      "The Kubernetes version of the masters. If set to 'latest' it will pull latest available version in the selected region.",
  },
  release_channel: {
    "ui:description":
      "The release channel of this cluster. Accepted values are `UNSPECIFIED`, `RAPID`, `REGULAR` and `STABLE`. Defaults to `UNSPECIFIED`.",
  },
  gateway_api_channel: {
    "ui:description":
      "The gateway api channel of this cluster. Accepted values are `CHANNEL_STANDARD` and `CHANNEL_DISABLED`.",
  },
  master_authorized_networks: {
    "ui:description":
      "List of master authorized networks. If none are provided, disallow external access (except the cluster node IPs, which GKE automatically whitelists).",
    items: {
      "ui:description":
        "Add Cidr blocks one by one with respective display names",
      "ui:displayfield": "display_name",
      cidr_block: {
        "ui:description": "CIDR Block. For example 89.0.43.67/32",
      },
      display_name: {
        "ui:description":
          "Display name for the CIDR block. For example: corp-office-ip",
      },
    },
  },
  node_pools: {
    "ui:description": "List of maps containing node pools",
    items: {
      "ui:description": "Add node pools one by one",
      "ui:displayfield": "name",
      name: {
        "ui:description": "The name of the node pool",
      },
      service_account: {
        "ui:description": "The service account to be used by the Node VMs ",
      },
      disk_size_gb: {
        "ui:description":
          "Size of the disk attached to each node, specified in GB. The smallest allowed disk size is 10GB",
      },
      disk_type: {
        "ui:description":
          "Type of the disk attached to each node (e.g. 'pd-standard' or 'pd-ssd')",
      },
      accelerator_type: {
        "ui:description":
          "The accelerator type resource to expose to the instance",
      },
      accelerator_count: {
        "ui:description":
          "The number of the guest accelerator cards exposed to this instance ",
      },
      image_type: {
        "ui:description":
          "The image type to use for this node. Note that changing the image type will delete and recreate all nodes in the node pool.",
      },
      machine_type: {
        "ui:description": "The name of a Google Compute Engine machine type",
      },
      local_ssd_count: {
        "ui:description":
          "The amount of local SSD disks that will be attached to each cluster node and may be used as a `hostpath` volume or a `local` PersistentVolume.",
      },
      preemptible: {
        "ui:description":
          "A boolean that represents whether or not the underlying node VMs are preemptible",
      },
      enable_secure_boot: {
        "ui:description":
          "Secure Boot helps ensure that the system only runs authentic software by verifying the digital signature of all boot components, and halting the boot process if signature verification fails.",
      },
      enable_integrity_monitoring: {
        "ui:description":
          "Enables monitoring and attestation of the boot integrity of the instance. The attestation is performed against the integrity policy baseline. This baseline is initially derived from the implicitly trusted boot image when the instance is created.",
      },
      autorepair: {
        "ui:description": "Whether the nodes will be automatically repaired",
      },
      autoscaling: {
        "ui:description":
          "Configuration required by cluster autoscaler to adjust the size of the node pool to the current cluster usage",
      },
      max_pods_per_node: {
        "ui:description":
          "The maximum number of pods per node in this node pool",
      },
      total_min_count: {
        "ui:description":
          "Total minimum number of nodes in the NodePool. Must be >=0 and <= max_count. Should be used when autoscaling is true. Cannot be used with per zone limits. ",
      },
      total_max_count: {
        "ui:description":
          "Total maximum number of nodes in the NodePool. Must be >= min_count. Cannot be used with per zone limits.",
      },
      min_count: {
        "ui:description":
          "Minimum number of nodes in the NodePool. Must be >=0 and <= max_count. Should be used when autoscaling is true. Cannot be used with total limits.",
      },
      max_count: {
        "ui:description":
          "Maximum number of nodes in the NodePool. Must be >= min_count. Cannot be used with total limits.",
      },
      node_count: {
        "ui:description":
          "The number of nodes in the nodepool when autoscaling is false. Otherwise defaults to 1. Only valid for non-autoscaling clusters",
      },
      initial_node_count: {
        "ui:description":
          "The initial number of nodes for the pool. In regional or multi-zonal clusters, this is the number of nodes per zone. Changing this will force recreation of the resource. Defaults to the value of min_count",
      },
      auto_upgrade: {
        "ui:description":
          "Whether the nodes will be automatically upgraded true (if cluster is regional)",
      },
      spot: {
        "ui:description":
          "A boolean that represents whether the underlying node VMs are spot",
      },
      version: {
        "ui:description":
          "The Kubernetes version for the nodes in this pool. Should only be set if auto_upgrade is false",
      },
      strategy: {
        "ui:description":
          "The upgrade stragey to be used for upgrading the nodes. Valid values of state are: `SURGE` or `BLUE_GREEN`",
      },
      node_locations: {
        "ui:description":
          "The comma separated list of zones in which the cluster's nodes are located. Nodes must be in the region of their regional cluster or in the same region. Defaults to cluster level node locations if nothing is specified",
      },
      oauth_scopes: {
        "ui:description":
          "List of oauth scopes to be enabled on the node VMs. Add oauth scopes one by one",
        items: {
          "ui:description":
            "Add oauth scopes one by one. Example: https://www.googleapis.com/auth/cloud-platform",
        },
      },
      labels: {
        "ui:description":
          "The labels to be applied to this node pool. Press + to add key and value fo the label",
        items: {
          "ui:displayfield": "key",
          "ui:description": "Labels key and value",
          key: {
            "ui:description":
              "The key of the label to be applied to this node pool",
          },
          value: {
            "ui:description":
              "The value of the label to be applied to this node pool",
          },
        },
      },
      metadata: {
        "ui:description":
          "Map of maps containing node metadata by node-pool name",
        items: {
          "ui:description": "Metadata key and value",
          "ui:displayfield": "key",
          key: {
            "ui:description":
              "The key of the metadata to be applied to this node pool",
          },
          value: {
            "ui:description":
              "The value of the metadata to be applied to this node pool",
          },
        },
      },
      tags: {
        "ui:description":
          "The tags to be applied to this node pool. Add tags one by one",
        items: {
          "ui:description": "Tag to be applied to this node pool",
        },
      },
      taints: {
        "ui:description":
          "list containing node taints to be applied to the node pool",
        items: {
          "ui:description": "Taint settings",
          "ui:displayfield": "key",
          key: {
            "ui:description":
              "The key of the taint to be applied to this node pool",
          },
          value: {
            "ui:description":
              "The value of the taint to be applied to this node pool",
          },
          effect: {
            "ui:description":
              "The effect of the taint to be applied to this node pool",
          },
        },
      },
      resource_labels: {
        "ui:description":
          "The resource labels to be applied to this node pool. Add resource labels one by one",
        items: {
          "ui:description": "Resource label key and value",
          "ui:displayfield": "key",
          key: {
            "ui:description": "The key of the resource label",
          },
          value: {
            "ui:description": "The value of the resource label",
          },
        },
      },
    },
  },
  cluster_autoscaling: {
    "ui:description":
      "Cluster autoscaling configuration. See [more details](https://cloud.google.com/kubernetes-engine/docs/reference/rest/v1beta1/projects.locations.clusters#clusterautoscaling)",
  },
  stub_domains: {
    "ui:description":
      "List of stub domains and their resolvers to forward DNS queries for a certain domain to an external DNS server",
    items: {
      "ui:description": "Stub domain settings",
      "ui:displayfield": "domain",
      domain: {
        "ui:description": "The name of the stub domain, such as `example.com`",
      },
      resolver_ips: {
        "ui:description":
          "The list of IP addresses for this domain. Add IP addresses one by one",
        items: {
          "ui:description": "for example 10.254.154.11",
        },
      },
    },
  },
  horizontal_pod_autoscaling: {
    "ui:description": "Enable horizontal pod autoscaling addon",
  },
  http_load_balancing: {
    "ui:description":
      "Enable httpload balancer addon. The addon allows whoever can create Ingress objects to expose an application to a public IP. Network policies or Gatekeeper policies should be used to verify that only authorized applications are exposed.",
  },
  datapath_provider: {
    "ui:description":
      "The desired datapath provider for this cluster. By default, `ADVANCED_DATAPATH` enables Dataplane-V2 feature. `DATAPATH_PROVIDER_UNSPECIFIED` enables the IPTables-based kube-proxy implementation as a fallback since upgrading to V2 requires a cluster re-creation.",
  },
  maintenance_start_time: {
    "ui:description":
      "Time window specified for daily or recurring maintenance operations in RFC3339 format",
  },
  maintenance_exclusions: {
    "ui:description":
      "List of maintenance exclusions. A cluster can have up to three. Press + to add one by one",
    items: {
      "ui:description": "Maintenance exclusions settings",
      "ui:displayfield": "name",
    },
  },
  maintenance_end_time: {
    "ui:description":
      "Time window specified for recurring maintenance operations in RFC3339 format",
  },
  maintenance_recurrence: {
    "ui:description":
      "Frequency of the recurring maintenance window in RFC5545 format.",
  },
  upstream_nameservers: {
    "ui:description":
      "If specified, the values replace the nameservers taken by default from the node’s /etc/resolv.conf",
    items: {
      "ui:description": "Add upstream nameservers one by one",
    },
  },
  logging_service: {
    "ui:description":
      "The logging service that the cluster should write logs to. Available options include logging.googleapis.com, logging.googleapis.com/kubernetes (beta), and none",
  },
  monitoring_service: {
    "ui:description":
      "The monitoring service that the cluster should write metrics to. Automatically send metrics from pods in the cluster to the Google Cloud Monitoring API. VM metrics will be collected by Google Compute Engine regardless of this setting Available options include monitoring.googleapis.com, monitoring.googleapis.com/kubernetes (beta) and none",
  },
  monitoring_enable_managed_prometheus: {
    "ui:description":
      "(Beta) Configuration for Managed Service for Prometheus. Whether or not the managed collection is enabled.",
  },
  grant_registry_access: {
    "ui:description":
      "Grants created cluster-specific service account storage.objectViewer role.",
  },
  registry_project_ids: {
    "ui:description":
      "Projects holding Google Container Registries. If empty, we use the cluster project. If a service account is created and the `grant_registry_access` variable is set to `true`, the `storage.objectViewer` role is assigned on these projects.",
    items: {
      "ui:description": "project id",
    },
  },
  cluster_resource_labels: {
    "ui:description":
      "The GCE resource labels (a map of key/value pairs) to be applied to the cluster",
    items: {
      "ui:description": "Resource label settings",
      "ui:displayfield": "key",
      key: {
        "ui:description": "The key of the resource label",
      },
      value: {
        "ui:description": "The value of the resource label",
      },
    },
  },
  istio: {
    "ui:description": "(Beta) Enable Istio addon",
  },
  istio_auth: {
    "ui:description":
      "(Beta) The authentication type between services in Istio.",
  },
  dns_cache: {
    "ui:description": "(Beta) The status of the NodeLocal DNSCache addon.",
  },
  cluster_dns_provider: {
    "ui:description":
      "Which in-cluster DNS provider should be used. PROVIDER_UNSPECIFIED (default) or PLATFORM_DEFAULT or CLOUD_DNS.",
  },
  cluster_dns_scope: {
    "ui:description":
      "The scope of access to cluster DNS records. DNS_SCOPE_UNSPECIFIED (default) or CLUSTER_SCOPE or VPC_SCOPE. ",
  },
  cluster_dns_domain: {
    "ui:description": "The suffix used for all cluster service records.",
  },
  default_max_pods_per_node: {
    "ui:description": "The maximum number of pods to schedule per node",
  },
  database_encryption: {
    "ui:description":
      "Application-layer Secrets Encryption settings. The object format is {state = string, key_name = string}. Valid values of state are: 'ENCRYPTED'; 'DECRYPTED'. key_name is the name of a CloudKMS key.",
    items: {
      "ui:description": "Database encryption settings",
      "ui:displayfield": "key_name",
      key_name: {
        "ui:description": "The name of a CloudKMS key",
      },
    },
  },
  cloudrun: {
    "ui:description": "(Beta) Enable CloudRun addon",
  },
  resource_usage_export_dataset_id: {
    "ui:description":
      "The dataset id for which network egress metering for this cluster will be enabled. If enabled, a daemonset will be created in the cluster to meter network egress traffic.",
  },
  enable_cost_allocation: {
    "ui:description":
      "Enables Cost Allocation Feature and the cluster name and namespace of your GKE workloads appear in the labels field of the billing export to BigQuery",
  },
  sandbox_enabled: {
    "ui:description":
      "(Beta) Enable GKE Sandbox (Do not forget to set `image_type` = `COS_CONTAINERD` to use it).",
  },
  enable_intranode_visibility: {
    "ui:description":
      "Whether Intra-node visibility is enabled for this cluster. This makes same node pod to pod traffic visible for VPC network",
  },
  enable_vertical_pod_autoscaling: {
    "ui:description":
      "Vertical Pod Autoscaling automatically adjusts the resources of pods controlled by it",
  },
  authenticator_security_group: {
    "ui:description":
      "The name of the RBAC security group for use with Google security groups in Kubernetes RBAC. Group name must be in format gke-security-groups@yourdomain.com",
  },
  compute_engine_service_account: {
    "ui:description":
      "Use the given service account for nodes rather than creating a new dedicated service account.",
  },
  enable_shielded_nodes: {
    "ui:description":
      "Enable Shielded Nodes features on all nodes in this cluster.",
  },
  enable_private_endpoint: {
    "ui:description":
      "When true, the cluster's private endpoint is used as the cluster endpoint and access through the public endpoint is disabled. When false, either endpoint can be used. This field only applies to private clusters, when enable_private_nodes is true",
  },
  enable_pod_security_policy: {
    "ui:description":
      "enabled - Enable the PodSecurityPolicy controller for this cluster. If enabled, pods must be valid under a PodSecurityPolicy to be created.",
  },
  gce_pd_csi_driver: {
    "ui:description":
      "(Beta) Whether this cluster should enable the Google Compute Engine Persistent Disk Container Storage Interface (CSI) Driver.",
  },
  filestore_csi_driver: {
    "ui:description":
      "The status of the Filestore CSI driver addon, which allows the usage of filestore instance as volumes",
  },
  add_cluster_firewall_rules: {
    "ui:description": "Create additional firewall rules",
  },
  firewall_priority: {
    "ui:description": "Priority rule for firewall rules",
  },
  firewall_inbound_ports: {
    "ui:description": "Inbound ports for firewall rules",
    items: {
      "ui:description": "Add inbound ports one by one",
    },
  },
  config_connector: {
    "ui:description":
      "(Beta) Whether ConfigConnector is enabled for this cluster.",
  },
  gke_backup_agent_config: {
    "ui:description":
      "(Beta) Whether Backup for GKE agent is enabled for this cluster.",
  },
  disable_default_snat: {
    "ui:description":
      "Whether to disable the default SNAT to support the private use of public IP addresses",
  },
  notification_config_topic: {
    "ui:description":
      "The desired Pub/Sub topic to which notifications will be sent by GKE. Format is projects/{project}/topics/{topic}.",
  },
};
