import { React, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Box,
  Typography,
  Paper,
  TextField,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import { updateTemplateName } from "../utils/db";
import { useAuth } from "../contexts/AuthContext";

export default function ArchitectureTemplatesList({
  organization,
  rows,
  setSelectedTemplate,
  handleDeleteTemplate,
}) {
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = useState(0);
  const [selectedRowId, setSelectedRowId] = useState(null);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const formatRow = (row) => {
    const { id, name, created_at, modified_at, description } = row;
    return {
      id,
      name,
      created_at: created_at
        ? new Date(created_at).toLocaleString()
        : new Date().toLocaleString(),
      modified_at: modified_at
        ? new Date(modified_at).toLocaleString()
        : new Date().toLocaleString(),
      description: description
        ? description
        : "Short Description of the template.",
    };
  };

  const onSelectedRow = (id) => {
    setSelectedRowId(id);
    setSelectedTemplate(id);
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">ID</TableCell>
              <TableCell align="right">Created At</TableCell>
              <TableCell align="right">Last Modified</TableCell>
              <TableCell align="right">Description</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                hover
                role="checkbox"
                key={formatRow(row).id}
                selected={formatRow(row).id === selectedRowId}
              >
                <TableCell
                  component="th"
                  scope="row"
                  onClick={() => onSelectedRow(formatRow(row).id)}
                >
                  <TemplateNameTableCell
                    name={formatRow(row).name}
                    organization={organization}
                    template_id={formatRow(row).id}
                  />
                </TableCell>
                <TableCell
                  align="right"
                  onClick={() => onSelectedRow(formatRow(row).id)}
                >
                  {formatRow(row).id}
                </TableCell>
                <TableCell align="right">{formatRow(row).created_at}</TableCell>
                <TableCell align="right">
                  {formatRow(row).modified_at}
                </TableCell>
                <TableCell align="right">
                  {formatRow(row).description}
                </TableCell>
                <TableCell align="right" width={20}>
                  <Actions
                    template_id={formatRow(row).id}
                    organization={organization}
                    handleDeleteTemplate={handleDeleteTemplate}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}

function TemplateNameTableCell({ name, organization, template_id }) {
  const [editTemplateName, setEditTemplateName] = useState(false);
  const [newTemplateName, setNewTemplateName] = useState(name);
  const { currentUser } = useAuth();

  const isAdmin = () => {
    if (!organization || organization === undefined) {
      return false;
    }
    return organization.admin.id === currentUser.uid;
  };

  const applyChangeName = async () => {
    await updateTemplateName(organization.id, template_id, newTemplateName);
    setEditTemplateName(false);
  };

  const handleCancelChangeName = () => {
    setEditTemplateName(false);
    setNewTemplateName(name);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box flex={6}>
        {!editTemplateName && (
          <Typography m={1} variant="subtitle1">
            {newTemplateName}
          </Typography>
        )}
        {editTemplateName && (
          <TextField
            defaultValue={newTemplateName}
            onChange={(e) => setNewTemplateName(e.target.value)}
          />
        )}
      </Box>

      {isAdmin() && (
        <Box flex={4}>
          {!editTemplateName && (
            <IconButton onClick={() => setEditTemplateName(true)}>
              <EditIcon />
            </IconButton>
          )}
          {editTemplateName && (
            <IconButton onClick={() => applyChangeName()}>
              <CheckIcon />
            </IconButton>
          )}
          {editTemplateName && (
            <IconButton onClick={() => handleCancelChangeName(false)}>
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      )}
    </Box>
  );
}

function Actions({ organization, template_id, handleDeleteTemplate }) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { currentUser } = useAuth();

  const isAdmin = () => {
    if (!organization || organization === undefined) {
      return false;
    }
    return organization.admin.id === currentUser.uid;
  };

  const handleDelete = async () => {
    await handleDeleteTemplate(organization.id, template_id);
    setOpenDeleteDialog(false);
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton
        onClick={() => setOpenDeleteDialog(true)}
        disabled={!isAdmin()}
      >
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
      >
        <DialogTitle>Delete Template</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this template from your
            organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
