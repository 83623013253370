export const databricksSchemaSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    storage_root: {
      type: ["string", "null"],
      default: null,
      title: "Storage Root",
    },
    owner: {
      type: ["string", "null"],
      default: null,
      title: "Owner",
    },
    properties: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      default: {},
      title: "Properties",
    },
    force_destroy: {
      type: "boolean",
      default: false,
      title: "Force Destroy",
    },
    comment: {
      type: "string",
      default: "This schema is managed by terraform",
      title: "Comment",
    },
    grants: {
      type: "array",
      description: "List of grants",
      items: {
        type: "object",
        properties: {
          principal: {
            type: "string",
            description:
              "User name, group name or service principal application ID",
          },
          privileges: {
            type: "array",
            description:
              "One or more privileges that are specific to a securable type.",
            items: {
              type: "string",
              description: "Privilege to grant to the principal",
              enum: [
                "ALL_PRIVILEGES",
                "CREATE_FUNCTION",
                "CREATE_TABLE",
                "CREATE_VOLUME",
                "EXECUTE",
                "MODIFY",
                "REFRESH",
                "SELECT",
                "READ_VOLUME",
                "WRITE_VOLUME",
                "USE_SCHEMA",
              ],
            },
          },
        },
        required: ["principal"],
      },
    },
  },
  required: ["name"],
};

export const databricksSchemaUISchema = {
  name: {
    "ui:description": "Name of the Schema",
  },
  storage_root: {
    "ui:emptyValue": null,
    "ui:description": "Managed location of the schema.",
  },
  provider_name: {
    "ui:emptyValue": null,
    "ui:description":
      "For Delta Sharing Catalogs: the name of the delta sharing provider. Change forces creation of a new resource.",
  },
  owner: {
    "ui:emptyValue": null,
    "ui:description":
      "Username/groupname/sp application_id of the schema owner.",
  },
  properties: {
    "ui:description": "Extensible Schema properties",
  },
  force_destroy: {
    "ui:description": "Delete schema regardless of its contents",
  },
  comment: {
    "ui:description": "Comment",
  },
  grants: {
    "ui:description": "List of grants",
    items: {
      "ui:displayfield": "principal",
      "ui:description": "Principal to grant privileges to.",
      privileges: {
        "ui:description":
          "One or more privileges that are specific to a securable type.",
        items: {
          "ui:description": "Privilege to grant to the principal",
        },
      },
    },
  },
};
