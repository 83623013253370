// JSON Schema
export const awsIAMassumableRolesWithSamlSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Module Name",
    },
    roles: {
      type: "array",
      title: "Roles",
      items: {
        type: "object",
        title: "Role",
        properties: {
          role_name: {
            type: "string",
            title: "role name",
          },
          create_role: {
            type: "boolean",
            title: "create role",
            default: false,
          },
          provider_id: {
            type: "string",
            title: "provider id",
            default: "",
          },
          provider_ids: {
            type: "array",
            items: {
              type: "string",
              title: "provider id",
            },
            title: "provider ids",
            default: [],
          },
          aws_saml_endpoint: {
            type: "string",
            title: "aws saml endpoint",
            default: "https://signin.aws.amazon.com/saml",
          },
          tags: {
            type: "object",
            additionalProperties: {
              type: "string",
              title: "tag value",
            },
            title: "tags",
            default: {},
          },
          role_name_prefix: {
            type: ["string", "null"],
            title: "role name prefix",
            default: null,
          },
          role_description: {
            type: "string",
            title: "role description",
            default: "",
          },
          role_path: {
            type: "string",
            title: "role path",
            default: "/",
          },
          role_permissions_boundary_arn: {
            type: "string",
            title: "role permissions boundary arn",
            default: "",
          },
          max_session_duration: {
            type: "number",
            title: "max session duration",
            default: 3600,
          },
          role_policy_arns: {
            type: "array",
            items: {
              type: "string",
              title: "role policy arn",
            },
            title: "role policy arns",
            default: [],
          },
          force_detach_policies: {
            type: "boolean",
            title: "force detach policies",
            default: false,
          },
          allow_self_assume_role: {
            type: "boolean",
            title: "allow self assume role",
            default: false,
          },
          trusted_role_actions: {
            type: "array",
            items: {
              type: "string",
              title: "trusted role action",
            },
            title: "trusted role actions",
            default: ["sts:AssumeRoleWithSAML", "sts:TagSession"],
          },
        },
        required: ["role_name"],
      },
    },
  },
  required: ["name"],
};

// UI Schema
export const awsIAMassumableRolesWithSamlUISchema = {
  "ui:description": "Creates IAM roles that can be assumed by SAML providers",
  name: {
    "ui:description": "Name of the module",
  },
  roles: {
    "ui:description": "List of roles to create",
    items: {
      "ui:description": "Role to create",
      "ui:displayfield": "role_name",
      create_role: {
        "ui:description": "Whether to create a role",
      },
      provider_id: {
        "ui:description":
          "ID of the SAML Provider. Use provider_ids to specify several IDs.",
        items: {
          "ui:description": "ID of the SAML Provider",
        },
      },
      provider_ids: {
        "ui:description": "List of SAML Provider IDs",
      },
      aws_saml_endpoint: {
        "ui:description": "AWS SAML Endpoint",
      },
      tags: {
        "ui:description": "A map of tags to add to IAM role resources",
        additionalProperties: {
          "ui:description": "Tag value",
        },
      },
      role_name: {
        "ui:description": "IAM role name",
      },
      role_name_prefix: {
        "ui:description": "IAM role name prefix",
        "ui:emptyValue": null,
      },
      role_description: {
        "ui:description": "IAM Role description",
      },
      role_path: {
        "ui:description": "Path of IAM role",
      },
      role_permissions_boundary_arn: {
        "ui:description": "Permissions boundary ARN to use for IAM role",
      },
      max_session_duration: {
        "ui:description":
          "Maximum CLI/API session duration in seconds between 3600 and 43200",
      },
      role_policy_arns: {
        "ui:description": "List of ARNs of IAM policies to attach to IAM role",
        items: {
          "ui:description": "ARN of IAM policy to attach to IAM role",
        },
      },
      force_detach_policies: {
        "ui:description":
          "Whether policies should be detached from this role when destroying",
      },
      allow_self_assume_role: {
        "ui:description":
          "Determines whether to allow the role to be [assume itself](https://aws.amazon.com/blogs/security/announcing-an-update-to-iam-role-trust-policy-behavior/)",
      },
      trusted_role_actions: {
        "ui:description": "Additional role actions",
        items: {
          "ui:description": "Role action",
        },
      },
    },
  },
};
