export const projectSourceSchema = {
  type: "object",
  properties: {
    project_id: {
      title: "Project ID",
      type: "string",
    },
  },
  required: ["project_id"],
};
