export const databricksNotebookSchema = {
  type: "object",
  properties: {
    path: {
      description:
        "The absolute path of the notebook or directory, beginning with '/, e.g. '/Shared'.",
      type: "string",
      default: "/Shared/Demo",
    },
    language: {
      description: "The language of the notebook.",
      type: "string",
      enum: ["PYTHON", "SCALA", "SQL", "R"],
      default: "PYTHON",
    },
    content: {
      description: "The notebook content.",
      type: "string",
      default: "display(spark.range(10))",
    },
  },
  required: ["path", "language"],
};
export const databricksNotebookUISchema = {
  "ui:description": "Example notebook settings",
  content: {
    "ui:widget": "textarea",
    "ui:options": {
      rows: 15,
    },
  },
};
