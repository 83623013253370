export const awsAFTaccountRequestSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    aft_home_region: {
      type: "string",
      title: "Home Region of AFT Account",
    },
    aft_management_account_id: {
      type: ["string", "null"],
      title: "AFT Management Account ID",
    },
    aft_partion: {
      type: "string",
      title: "AFT Partition",
      default: "aws",
    },
    accounts: {
      type: "array",
      title: "Accounts",
      items: {
        type: "object",
        properties: {
          AccountEmail: {
            type: "string",
            title: "Account Email",
          },
          AccountName: {
            type: "string",
            title: "Account Name",
          },
          ManagedOrganizationalUnit: {
            type: "string",
            title: "Managed Organizational Unit",
          },
          SSOUserEmail: {
            type: "string",
            title: "SSO User Email",
          },
          SSOUserFirstName: {
            type: "string",
            title: "SSO User First Name",
          },
          SSOUserLastName: {
            type: "string",
            title: "SSO User Last Name",
          },
          change_requested_by: {
            type: "string",
            title: "Change Requested By",
          },
          change_reason: {
            type: "string",
            title: "Change Reason",
          },
          custom_fields: {
            type: "array",
            title: "Custom Fields",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                  title: "Name",
                },
                value: {
                  type: "string",
                  title: "Value",
                },
              },
            },
            default: [],
          },
          account_tags: {
            type: "array",
            title: "Account Tags",
            items: {
              type: "object",
              properties: {
                key: {
                  type: "string",
                  title: "Name",
                },
                value: {
                  type: "string",
                  title: "Value",
                },
              },
            },
            default: [],
          },
        },
        required: [
          "AccountEmail",
          "AccountName",
          "ManagedOrganizationalUnit",
          "SSOUserEmail",
          "SSOUserFirstName",
          "SSOUserLastName",
          "change_requested_by",
          "change_reason",
        ],
      },
      default: [],
    },
  },
  required: ["name", "aft_home_region"],
};

export const awsAFTaccountRequestSchemaUI = {
  "ui:description": "AWS AFT Account Request Settings",
  name: {
    "ui:description": "Name of the AWS AFT Account Request",
  },
  aft_home_region: {
    "ui:description":
      "Home Region of the AWS AFT Account Request. Must be the same as the home region of the AWS Control Tower Account.",
  },
  aft_management_account_id: {
    "ui:description":
      "AWS Account ID of the AFT Account. This is the account where AFT module is deployed. If not specified we will use the AWS account ID from the parent source.",
    "ui:emptyValue": null,
  },
  aft_partion: {
    "ui:description": "AWS Partition",
  },
  accounts: {
    "ui:description": "List of AWS Accounts to be created.",
    items: {
      "ui:description": "AWS Account Properties",
      "ui:displayfield": "AccountName",
      AccountEmail: {
        "ui:description":
          "Email address of the AWS Account. This is the root email address for the AWS Account.",
      },
      AccountName: {
        "ui:description": "Name of the AWS Account",
      },
      ManagedOrganizationalUnit: {
        "ui:description":
          "Organizational Unit under which the AWS Account will be created",
      },
      SSOUserEmail: {
        "ui:description": "Email address of the AWS SSO User",
      },
      SSOUserFirstName: {
        "ui:description": "First Name of the AWS SSO User",
      },
      SSOUserLastName: {
        "ui:description": "Last Name of the AWS SSO User",
      },
      change_requested_by: {
        "ui:description": "Name of the person requesting the change",
      },
      change_reason: {
        "ui:description": "Reason for the change",
      },
      custom_fields: {
        "ui:description":
          "List of custom fields to be added to the AWS Account",
        items: {
          "ui:description": "Custom Field Properties",
          "ui:displayfield": "key",
          key: {
            "ui:description": "Name of the custom field",
          },
          value: {
            "ui:description": "Value of the custom field",
          },
        },
      },
      account_tags: {
        "ui:description": "List of tags to be added to the AWS Account",
        items: {
          "ui:description": "Tag properties",
          "ui:displayfield": "key",
          key: {
            "ui:description": "Name of the tag",
          },
          value: {
            "ui:description": "Value of the tag",
          },
        },
      },
    },
  },
};
