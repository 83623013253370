export const awsIAMpoliciesSchema = {
  type: "object",
  title: "AWS IAM Policies",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    policies: {
      title: "Policies",
      type: "array",
      items: {
        type: "object",
        title: "Policy",
        properties: {
          create_policy: {
            type: "boolean",
            title: "create policy",
            default: true,
          },
          name: {
            type: "string",
            title: "name",
          },
          name_prefix: {
            type: ["string", "null"],
            title: "name prefix",
            default: null,
          },
          path: {
            type: "string",
            title: "path",
            default: "/",
          },
          description: {
            type: "string",
            title: "description",
            default: "IAM Policy",
          },
          policy: {
            type: "string",
            title: "policy",
            default: "",
          },
          tags: {
            type: "object",
            additionalProperties: {
              type: "string",
              title: "tag value",
            },
            title: "tags",
            default: {},
          },
        },
        required: ["name", "policy"],
      },
    },
  },
  required: ["name"],
};

export const awsIAMpoliciesSchemaUISchema = {
  "ui:description": "AWS IAM Policies Settings",
  name: {
    "ui:description": "Name of the module",
    "ui:widget": "textarea",
    "ui:options": {
      rows: 1,
    },
  },
  policies: {
    "ui:description": "Policies to create",
    items: {
      "ui:description": "Policy",
      "ui:displayfield": "name",
      create_policy: {
        "ui:description": "Whether to create the IAM policy",
      },
      name: {
        "ui:description": "The name of the policy",
      },
      name_prefix: {
        "ui:description": "IAM policy name prefix",
        "ui:emptyValue": null,
      },
      path: {
        "ui:description": "The path of the policy in IAM",
      },
      description: {
        "ui:description": "The description of the policy",
      },
      policy: {
        "ui:description": "The description of the policy",
        "ui:widget": "textarea",
        "ui:options": {
          rows: 15,
        },
      },
      tags: {
        "ui:description": "A map of tags to add to all resources.",
        additionalProperties: {
          "ui:description": "tag value",
        },
      },
    },
  },
};
