export const orgIAMSchema = {
  title: "Define permissions for the Organization",
  type: "object",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    org_id: {
      title: "Organization ID",
      type: "string",
    },
    permissions: {
      type: "array",
      title: "List of Permissions",
      items: {
        properties: {
          role: {
            title: "Role",
            type: "string",
          },
          personas: {
            type: "array",
            title: "List of personas to which the role will be granted",
            items: {
              type: "string",
              title: "Persona",
            },
          },
        },
      },
    },
  },
  required: ["org_id", "name"],
};

export const orgIAMSchemaUI = {
  permissions: {
    "ui:description":
      "Grant permissions to a role with the list of personas. Press the + button to add a new binding",
    items: {
      "ui:description": "Map of role to list of personas",
      "ui:displayfield": "role",
      role: {
        "ui:description": "For example roles/editor or roles/viewer",
      },
      personas: {
        "ui:description": "Persona can be a user, service account or a group",
        items: {
          "ui:description":
            "For example user:jack@example.com or serviceAccount@project-id.gserviceaccount.com or group:admins@example.com",
        },
      },
    },
  },
};
