export const databricksClusterConfigSchema = {
  type: "object",
  properties: {
    cluster_name: {
      type: "string",
      description: "The name of the cluster",
    },
    data_security_mode: {
      type: "string",
      description:
        "The data security mode. Can be SINGLE_USER or USER_ISOLATION. Default to NONE.",
      enum: ["NONE", "SINGLE_USER", "USER_ISOLATION"],
      default: "NONE",
    },
    single_user_name: {
      type: ["string", "null"],
      description:
        "Username (email) of the user that can use the cluster if data_security_mode is SINGLE_USER",
      default: null,
    },
    local_disk: {
      type: "boolean",
      description: "Whether to use local disk",
      default: true,
    },
    min_cores: {
      type: "number",
      description: "The minimum number of cores to request",
      default: 4,
    },
    gb_per_core: {
      type: "number",
      description: "The number of GB per core to request",
      default: 1,
    },
    min_gpus: {
      type: "number",
      description: "The minimum number of GPUs to request",
      default: 0,
    },
    min_memory_gb: {
      type: "number",
      description: "The minimum amount of memory to request",
      default: 0,
    },
    local_disk_min_size: {
      type: "number",
      description: "The minimum size of the local disk to request",
      default: 0,
    },
    autoscale_min_workers: {
      type: "number",
      description: "The minimum number of workers to autoscale to",
      default: 1,
    },
    autoscale_max_workers: {
      type: "number",
      description: "The minimum number of workers to autoscale to",
      default: 8,
    },
    spark_long_term_support: {
      type: "boolean",
      description: "Whether to use Spark long term support",
      default: true,
    },
    spark_latest: {
      type: "boolean",
      description: "Whether to use the latest version of Spark",
      default: false,
    },
    spark_conf: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      description: "Spark configuration",
      default: {},
    },
    spark_env_vars: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      description: "Spark environment variables",
      default: {},
    },
    max_clusters_per_user: {
      type: "number",
      description: "The maximum number of clusters per user",
      default: 1,
    },
    cluster_policy: {
      type: "string",
      description: "The cluster policy json",
      default:
        '{\n    "autotermination_minutes" : {\n      "type" : "fixed",\n      "value" : 15,\n      "hidden" : true\n    }\n}\n',
    },
  },
  required: ["cluster_name"],
};

export const databricksClusterConfigUISchema = {
  "ui:description": "Databricks Cluster Config",
  cluster_name: {},
  data_security_mode: {},
  single_user_name: {
    "ui:emptyValue": null,
  },
  local_disk: {},
  min_cores: {},
  gb_per_core: {},
  min_gpus: {},
  min_memory_gb: {},
  local_disk_min_size: {},
  autoscale_min_workers: {},
  autoscale_max_workers: {},
  spark_long_term_support: {},
  spark_latest: {},
  spark_conf: {},
  spark_env_vars: {},
  max_clusters_per_user: {},
  cluster_policy: {
    "ui:widget": "textarea",
    "ui:options": {
      rows: 15,
    },
  },
};
