export const awsEKSfargateProfileSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    create: {
      type: "boolean",
      title: "Create",
      default: true,
    },
    create_iam_role: {
      type: "boolean",
      title: "Create Iam Role",
      default: true,
    },
    tags: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      title: "Tags",
      default: {},
    },
    subnet_ids: {
      type: "array",
      items: {
        title: "Subnet ID",
        type: "string",
      },
      title: "Subnet IDs",
      default: [],
    },
    selectors: {
      type: "array",
      items: {
        title: "Selector",
        type: "object",
        properties: {
          namespace: {
            type: "string",
            title: "K8S Namespace",
          },
          labels: {
            type: "object",
            additionalProperties: {
              type: "string",
            },
            title: "Labels",
            default: {},
          },
        },
        required: ["namespace"],
      },
      title: "Selectors",
      default: [],
    },
    timeouts: {
      title: "Terraform timeouts",
      type: "object",
      properties: {
        create: {
          type: "string",
        },
        update: {
          type: "string",
        },
        delete: {
          type: "string",
        },
      },
      default: {
        create: "60m",
        update: "60m",
        delete: "60m",
      },
    },
    cluster_ip_family: {
      type: ["string", "null"],
      title: "Cluster IP Family",
      default: null,
    },
    iam_role_arn: {
      type: ["string", "null"],
      title: "IAM Role Arn",
      default: null,
    },
    iam_role_name: {
      type: "string",
      title: "IAM Role Name",
      default: "",
    },
    iam_role_use_name_prefix: {
      type: "boolean",
      title: "Iam Role Use Name Prefix",
      default: true,
    },
    iam_role_path: {
      type: ["string", "null"],
      title: "IAM Role Path",
      default: null,
    },
    iam_role_description: {
      type: ["string", "null"],
      title: "IAM Role Description",
      default: null,
    },
    iam_role_permissions_boundary: {
      type: ["string", "null"],
      title: "IAM Role Permissions Boundary",
      default: null,
    },
    iam_role_attach_cni_policy: {
      type: "boolean",
      title: "IAM Role Attach CNI Policy",
      default: true,
    },
    iam_role_additional_policies: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      title: "IAM Role Additional Policies",
      default: {},
    },
    iam_role_tags: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      title: "IAM Role Tags",
      default: {},
    },
  },
  required: ["name"],
};

export const awsEKSfargateProfileUISchema = {
  name: {
    "ui:description": "Name of the EKS Fargate Profile",
  },
  subnet_ids: {
    "ui:description": "A list of subnet IDs for the EKS Fargate Profile",
    items: {
      "ui:description": "Subnet ID",
    },
  },
  selectors: {
    "ui:description":
      "Configuration block(s) for selecting Kubernetes Pods to execute with this Fargate Profile",
    items: {
      "ui:description": "Selector Settings",
      "ui:displayfield": "namespace",
      namespace: {
        "ui:description": "Kubernetes namespace for selection",
      },
      labels: {
        "ui:description": "Kubernetes labels for selection as Key/Value pairs",
      },
    },
  },
  timeouts: {
    "ui:description":
      "Create, update, and delete timeout configurations for the node group",
    create: {
      "ui:description": "Create timeout",
    },
    update: {
      "ui:description": "Update timeout",
    },
    delete: {
      "ui:description": "Delete timeout",
    },
  },
  create: {
    "ui:description": "Determines whether to create Fargate profile or not",
  },
  tags: {
    "ui:description": "A map of tags to add to all resources",
  },
  create_iam_role: {
    "ui:description":
      "Determines whether an IAM role is created or to use an existing IAM role",
  },
  cluster_ip_family: {
    "ui:description":
      "The IP family used to assign Kubernetes pod and service addresses. Valid values are `ipv4` (default) and `ipv6`",
    "ui:emptyValue": null,
  },
  iam_role_arn: {
    "ui:description":
      "Existing IAM role ARN for the Fargate profile. Required if `create_iam_role` is set to `false`",
    "ui:emptyValue": null,
  },
  iam_role_name: {
    "ui:description": "Name to use on IAM role created",
  },
  iam_role_use_name_prefix: {
    "ui:description":
      "Determines whether the IAM role name (`iam_role_name`) is used as a prefix",
  },
  iam_role_path: {
    "ui:description": "IAM role path",
    "ui:emptyValue": null,
  },
  iam_role_description: {
    "ui:description": "Description of the role",
    "ui:emptyValue": null,
  },
  iam_role_permissions_boundary: {
    "ui:description":
      "ARN of the policy that is used to set the permissions boundary for the IAM role",
    "ui:emptyValue": null,
  },
  iam_role_attach_cni_policy: {
    "ui:description":
      "Whether to attach the `AmazonEKS_CNI_Policy`/`AmazonEKS_CNI_IPv6_Policy` IAM policy to the IAM IAM role. WARNING: If set `false` the permissions must be assigned to the `aws-node` DaemonSet pods via another method or nodes will not be able to join the cluster",
  },
  iam_role_additional_policies: {
    "ui:description":
      "Additional policies to be added to the IAM role. Key can be anything, Value is the policy ARN",
  },
  iam_role_tags: {
    "ui:description":
      "A key/value map of additional tags to add to the IAM role created",
  },
};
