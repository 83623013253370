export const instanceSchema = {
  title: "Instance From Template",
  type: "object",
  properties: {
    hostname: {
      title: "Instance Host Name",
      type: "string",
    },
    hostname_suffix_separator: {
      type: "string",
      title: "Hostname Suffix Separator",
      default: "-",
    },
    add_hostname_suffix: {
      type: "boolean",
      default: true,
      title: "Add Hostname Suffix",
    },
    zone: {
      title: "Zone where instances should be deployed",
      type: "string",
      default: "",
    },
    region: {
      title: "Region",
      type: "string",
      default: "",
    },
    num_instances: {
      title: "Number of Instances",
      type: "string",
      default: "1",
    },
    subnetwork: {
      type: "string",
      title: "Subnetwork self_link",
      default: "",
    },
    subnetwork_project: {
      type: "string",
      title: "Subnetwork Project",
      default: "",
    },
    network: {
      type: "string",
      title: "Network self_link",
      default: "",
    },
    static_ips: {
      type: "array",
      title: "Static IPs",
      items: {
        type: "string",
      },
      default: [],
    },
    access_config: {
      type: "array",
      title: "Access Config",
      items: {
        type: "object",
        properties: {
          nat_ip: {
            title: "NAT IP",
            type: "string",
          },
          network_tier: {
            title: "Network Tier",
            type: "string",
            enum: ["STANDARD", "PREMIUM"],
            default: "PREMIUM",
          },
        },
      },
      default: [],
    },
    ipv6_access_config: {
      type: "array",
      title: "IPv6 Access Config",
      items: {
        type: "object",
        properties: {
          network_tier: {
            title: "Network Tier",
            type: "string",
            default: "STANDARD",
          },
        },
      },
      default: [],
    },
    deletion_protection: {
      type: "boolean",
      title: "Deletion Protection",
      default: false,
    },
    alias_ip_ranges: {
      type: "array",
      title: "Alias IP Ranges",
      items: {
        type: "object",
        properties: {
          ip_cidr_range: {
            title: "IP CIDR Range",
            type: "string",
          },
          subnetwork_range_name: {
            title: "Subnetwork Range Name",
            type: "string",
          },
        },
      },
      default: [],
    },
    resource_policies: {
      type: "array",
      title: "Resource Policies",
      items: {
        type: "string",
      },
      default: [],
    },
  },
  required: ["hostname"],
};

export const instanceSchemaUI = {
  hostname: {
    "ui:description": "Hostname of instances",
  },
  add_hostname_suffix: {
    "ui:description": "Add a suffix to the hostname",
  },
  hostname_suffix_separator: {
    "ui:description":
      "Separator character to compose hostname when add_hostname_suffix is set to true.",
    "ui:emptyValue": "-",
  },
  zone: {
    "ui:description": "Zone where instances should be deployed",
    "ui:emptyValue": "",
  },
  region: {
    "ui:description": "Region where instances should be deployed.",
    "ui:emptyValue": "",
  },
  num_instances: {
    "ui:description": "Number of instances to deploy.",
  },
  subnetwork: {
    "ui:description":
      "The self_link of the subnetwork to attach this interface to. The subnetwork must exist in the same region this instance will be created in. If not specified, the value from template is used.",
    "ui:emptyValue": "",
  },
  subnetwork_project: {
    "ui:description": "The project that subnetwork belongs to",
    "ui:emptyValue": "",
  },
  network: {
    "ui:description":
      "Network to deploy to. Only one of network or subnetwork should be specified. If not specified, the value from template is used.",
    "ui:emptyValue": "",
  },
  static_ips: {
    "ui:description": "List of static IPs to assign to the instances",
  },
  access_config: {
    "ui:description":
      "Access configurations, i.e. IPs via which the VM instance can be accessed via the Internet. If not specified, the value from template is used.",
    items: {
      "ui:description": "Access configuration",
      "ui:displayfield": "nat_ip",
      nat_ip: {
        "ui:description":
          "The IP address that will be 1:1 mapped to the instance's network ip.",
        "ui:emptyValue": "",
      },
      network_tier: {
        "ui:description":
          "The networking tier used for configuring this access configuration. This field can take the following values: PREMIUM or STANDARD. If this field is not specified, it is assumed to be PREMIUM.",
        "ui:emptyValue": "",
      },
    },
  },
  ipv6_access_config: {
    "ui:description":
      "IPv6 access configurations. Currently a max of 1 IPv6 access configuration is supported. If not specified, the instance will have no external IPv6 Internet access.",
    items: {
      "ui:description": "IPv6 access configuration",
      "ui:displayfield": "network_tier",
      network_tier: {
        "ui:description":
          "The networking tier used for configuring this access configuration. This field can take the following values: PREMIUM or STANDARD.",
      },
    },
  },
  deletion_protection: {
    "ui:description":
      "Whether deletion protection is enabled on this instance.",
  },
  alias_ip_ranges: {
    "ui:description":
      "List of alias IP ranges to be used by this instance. Can only be specified for network interfaces on subnet-mode networks.",
    items: {
      "ui:description": "Alias IP range",
      "ui:displayfield": "ip_cidr_range",
      ip_cidr_range: {
        "ui:description":
          "The IP CIDR range represented by this alias IP range. This IP CIDR range must belong to the specified subnetwork and cannot contain IP addresses reserved by system or used by other network interfaces.",
        "ui:emptyValue": "",
      },
      subnetwork_range_name: {
        "ui:description":
          "The subnetwork secondary range name specifying the secondary range from which to allocate the IP CIDR range for this alias IP range.",
        "ui:emptyValue": "",
      },
    },
  },
  resource_policies: {
    "ui:description": "List of resource policies applied to an instance.",
    items: {
      "ui:description": "Resource policy",
    },
  },
};
