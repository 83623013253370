export const awsIAMassumablePredefinedRolesWithSamlSchema = {
  type: "object",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    provider_id: {
      type: "string",
      title: "provider id",
      default: "",
    },
    provider_ids: {
      type: "array",
      items: {
        type: "string",
      },
      title: "provider ids",
      default: [],
    },
    aws_saml_endpoint: {
      type: "string",
      title: "aws saml endpoint",
      default: "https://signin.aws.amazon.com/saml",
    },
    allow_self_assume_role: {
      type: "boolean",
      title: "allow self assume role",
      default: false,
    },
    trusted_role_actions: {
      type: "array",
      items: {
        type: "string",
      },
      title: "trusted role actions",
      default: ["sts:AssumeRoleWithSAML", "sts:TagSession"],
    },
    create_admin_role: {
      type: "boolean",
      title: "create admin role",
      default: false,
    },
    admin_role_name: {
      type: "string",
      title: "admin role name",
      default: "admin",
    },
    admin_role_path: {
      type: "string",
      title: "admin role path",
      default: "/",
    },
    admin_role_policy_arns: {
      type: "array",
      items: {
        type: "string",
      },
      title: "admin role policy arns",
      default: ["arn:aws:iam::aws:policy/AdministratorAccess"],
    },
    admin_role_permissions_boundary_arn: {
      type: "string",
      title: "admin role permissions boundary arn",
      default: "",
    },
    admin_role_tags: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      title: "admin role tags",
      default: {},
    },
    create_poweruser_role: {
      type: "boolean",
      title: "create poweruser role",
      default: false,
    },
    poweruser_role_name: {
      type: "string",
      title: "poweruser role name",
      default: "poweruser",
    },
    poweruser_role_path: {
      type: "string",
      title: "poweruser role path",
      default: "/",
    },
    poweruser_role_policy_arns: {
      type: "array",
      items: {
        type: "string",
      },
      title: "poweruser role policy arns",
      default: ["arn:aws:iam::aws:policy/PowerUserAccess"],
    },
    poweruser_role_permissions_boundary_arn: {
      type: "string",
      title: "poweruser role permissions boundary arn",
      default: "",
    },
    poweruser_role_tags: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      title: "poweruser role tags",
      default: {},
    },
    create_readonly_role: {
      type: "boolean",
      title: "create readonly role",
      default: false,
    },
    readonly_role_name: {
      type: "string",
      title: "readonly role name",
      default: "readonly",
    },
    readonly_role_path: {
      type: "string",
      title: "readonly role path",
      default: "/",
    },
    readonly_role_policy_arns: {
      type: "array",
      items: {
        type: "string",
      },
      title: "readonly role policy arns",
      default: ["arn:aws:iam::aws:policy/ReadOnlyAccess"],
    },
    readonly_role_permissions_boundary_arn: {
      type: "string",
      title: "readonly role permissions boundary arn",
      default: "",
    },
    readonly_role_tags: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      title: "readonly role tags",
      default: {},
    },
    max_session_duration: {
      type: "number",
      title: "max session duration",
      default: 3600,
    },
    force_detach_policies: {
      type: "boolean",
      title: "force detach policies",
      default: false,
    },
  },
  required: ["name"],
};

export const awsIAMassumablePredefinedRolesWithSamlUISchema = {
  "ui:description": "Settings for AWS IAM Assumable Predefined Roles with SAML",
  name: {
    "ui:description": "Module name",
  },
  provider_id: {
    "ui:description":
      "ID of the SAML Provider. Use provider_ids to specify several IDs.",
  },
  provider_ids: {
    "ui:description": "List of SAML Provider IDs",
    items: {
      "ui:description": "ID of the SAML Provider.",
    },
  },
  aws_saml_endpoint: {
    "ui:description": "AWS SAML Endpoint",
  },
  allow_self_assume_role: {
    "ui:description":
      "Determines whether to allow the role to be [assume itself](https://aws.amazon.com/blogs/security/announcing-an-update-to-iam-role-trust-policy-behavior/)",
  },
  trusted_role_actions: {
    "ui:description": "Additional role actions",
    items: {
      "ui:description": "Role action",
    },
  },
  create_admin_role: {
    "ui:description": "Whether to create admin role",
  },
  admin_role_name: {
    "ui:description": "IAM role with admin access",
  },
  admin_role_path: {
    "ui:description": "Path of admin IAM role",
  },
  admin_role_policy_arns: {
    "ui:description": "List of policy ARNs to use for admin role",
    items: {
      "ui:description": "Policy ARN",
    },
  },
  admin_role_permissions_boundary_arn: {
    "ui:description": "Permissions boundary ARN to use for admin role",
  },
  admin_role_tags: {
    "ui:description": "A map of tags to add to admin role resource.",
  },
  create_poweruser_role: {
    "ui:description": "Whether to create poweruser role",
  },
  poweruser_role_name: {
    "ui:description": "IAM role with poweruser access",
  },
  poweruser_role_path: {
    "ui:description": "Path of poweruser IAM role",
  },
  poweruser_role_policy_arns: {
    "ui:description": "List of policy ARNs to use for poweruser role",
    items: {
      "ui:description": "Policy ARN",
    },
  },
  poweruser_role_permissions_boundary_arn: {
    "ui:description": "Permissions boundary ARN to use for poweruser role",
  },
  poweruser_role_tags: {
    "ui:description": "A map of tags to add to poweruser role resource.",
  },
  create_readonly_role: {
    "ui:description": "Whether to create readonly role",
  },
  readonly_role_name: {
    "ui:description": "IAM role with readonly access",
  },
  readonly_role_path: {
    "ui:description": "Path of readonly IAM role",
  },
  readonly_role_policy_arns: {
    "ui:description": "List of policy ARNs to use for readonly role",
    items: {
      "ui:description": "Policy ARN",
    },
  },
  readonly_role_permissions_boundary_arn: {
    "ui:description": "Permissions boundary ARN to use for readonly role",
  },
  readonly_role_tags: {
    "ui:description": "A map of tags to add to readonly role resource.",
  },
  max_session_duration: {
    "ui:description":
      "Maximum CLI/API session duration in seconds between 3600 and 43200",
  },
  force_detach_policies: {
    "ui:description":
      "Whether policies should be detached from this role when destroying",
  },
};
