import { gcpResourceConfigs } from "../components/gcpResourceDefintions";

/* Utility function to make an API call to the backend. user parameter is necessary for authentication with the backend
 *  @param {string} url - The url to make the request to
 *  @param {string} method - The HTTP method to use {GET, POST, PUT, DELETE}
 *  @param {object} body - The body of the request. Only used for POST and PUT
 *  @param {object} user - The user object to use for the request. Almost always currentUser from the AuthContext
 *  @returns {object} - The response object from the fetch call
 */
export const apiCall = async (url, method, body = {}, user) => {
  let userInfo = await user.getIdTokenResult(true);
  return await fetch(url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + userInfo.token,
    },
    method: method,
    body: Object.keys(body).length !== 0 ? JSON.stringify(body) : null,
  });
};

/*
 * This function will execute a terraform command on a node
 *
 * @param {string} tf_command - The terraform command to execute (plan, apply, destroy)
 * @param {string} access_token - The access token to use for the terraform command execution
 * @param {string} organizationId - The organization id to use for the request
 * @param {string} environmentId - The environment id to use for the request
 * @param {object} node - The node to execute the command on
 * @param {object} user - The user object to use for the request. Almost always currentUser from the AuthContext
 * @param {function} enqueueSnackbar - The enqueueSnackbar function from notistack
 * @param {function} setOpenBackdrop - The setOpenBackdrop function from the backdrop context
 */
export const executeTFcommand = async (
  tf_command,
  //access_token,
  organizationId,
  environmentId,
  node,
  user,
  enqueueSnackbar = null,
  setOpenBackdrop = null
) => {
  let name = getModuleName(node);
  if (name === undefined || name === null) {
    alert("Please enter a name for this module");
    if (setOpenBackdrop !== null) {
      setOpenBackdrop(false);
    }
    return;
  }
  let body = {
    id: node.id,
    type: node.type,
    displayField: gcpResourceConfigs[node.type].displayField,
    organizationId,
    environmentId,
    tf_command: tf_command,
    name,
  };
  if (setOpenBackdrop !== null) {
    setOpenBackdrop(true);
  }
  let res = await apiCall(
    `${process.env.REACT_APP_BACKEND_URL}/api/execute`,
    "POST",
    body,
    user
  );
  if (res.status !== 200) {
    // Handle error use case
    let error = await res.text();
    if (enqueueSnackbar !== null) {
      enqueueSnackbar(`Error: ${error}`, { variant: "error" });
    }
    if (setOpenBackdrop !== null) {
      setOpenBackdrop(false);
    }
    return false;
  }
  let task = await res.json();
  if (setOpenBackdrop !== null) {
    setOpenBackdrop(false);
  }
  if (enqueueSnackbar !== null) {
    enqueueSnackbar(
      `Queued terraform ${tf_command} task ${task.task_id} for ${name} of type ${node.type}`,
      {
        variant: "success",
      }
    );
  }
  return true;
};

/*
 * This function will verify that the node/module has valid name. It will return true if the name is valid and null if it is not
 * @param {object} node - The node to verify
 *
 */
export const getModuleName = (node) => {
  let display_field = gcpResourceConfigs[node.type].displayField;
  //This can happen when we add a new module to the tree but we don't have anything set yet
  if (node.properties === undefined) {
    return null;
  }
  if (display_field) {
    return node.properties[display_field];
  } else {
    return null;
  }
};
