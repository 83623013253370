export const organizationSchema = {
  type: "object",
  properties: {
    globals: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
    },
  },
};

export const organizationUISchema = {
  "ui:description": "Global values that can be replaced in the architecture",
  globals: {
    "ui:description": "Key/Value pairs",
  },
};
