export const hostProjectSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
    },
    billing_account: {
      type: "string",
    },
    org_id: {
      type: "string",
    },
    random_project_id: {
      type: "boolean",
      default: true,
    },
    folder_id: {
      type: "string",
    },
    domain: {
      title: "The domain name (optional).",
      type: "string",
      default: "",
    },
    auto_create_network: {
      title: "Create the default network",
      type: "boolean",
      default: false,
    },
    activate_apis: {
      title: "The list of apis to activate within the project",
      type: "array",
      items: {
        type: "string",
      },
      default: [
        "compute.googleapis.com",
        "container.googleapis.com",
        "dataflow.googleapis.com",
        "bigquery.googleapis.com",
      ],
    },
    labels: {
      type: "array",
      title: "Label",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
    lien: {
      type: "boolean",
      title: "Lien",
      default: false,
    },
    default_service_account: {
      title: "Compute Default Service Account",
      type: "string",
      default: "deprivilege",
    },
    vpc_service_control_attach_enabled: {
      title: "VPC Service Control Attach Enabled",
      type: "boolean",
      default: false,
    },
    vpc_service_control_perimeter_name: {
      title: "VPC Service Control Perimeter Name",
      type: "string",
      default: "",
    },
  },
  required: ["name", "billing_account", "org_id"],
};

export const hostProjectSchemaUI = {
  "ui:description": "Host Project Settings",
  name: {
    "ui:description": "The name for the project",
  },
  billing_account: {
    "ui:description": "Billing Account ID",
  },
  org_id: {
    "ui:description": "Organization ID",
  },
  random_project_id: {
    "ui:description": "Adds a suffix of 4 random characters to the",
  },
  folder_id: {
    "ui:description":
      "The ID of a folder to host this project. If placed under Folder resource or Folder Source this is not required.",
  },
  domain: {
    "ui:description": "The domain name (optional).",
  },
  auto_create_network: {
    "ui:description": "Create the default VPC network",
  },
  activate_apis: {
    "ui:description": "The list of apis to activate within the project",
    items: {
      "ui:description": "API name",
    },
  },
  labels: {
    "ui:description": "Map of labels for the project",
    items: {
      "ui:displayfield": "key",
      "ui:description": "Label properties",
      key: {
        "ui:description": "Label key",
      },
      value: {
        "ui:description": "Label value",
      },
    },
  },
  lien: {
    "ui:description":
      "Add a lien on the project to prevent accidental deletion",
  },
  default_service_account: {
    "ui:description":
      "Project default service account setting: can be one of `delete`, `deprivilege`, `disable`, or `keep`.",
  },
  vpc_service_control_attach_enabled: {
    "ui:description":
      "Whether the project will be attached to a VPC Service Control Perimeter",
  },
  vpc_service_control_perimeter_name: {
    "ui:description":
      "The name of the VPC Service Control Perimeter to add this project to",
  },
};
