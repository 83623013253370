import { React, useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useAuth } from "../contexts/AuthContext";
import { OrganizationsList } from "./Environments";
import { useSnackbar } from "notistack";
import { apiCall } from "../utils/api";

const tiers = {
  Free: {
    title: "Free",
    price: "0",
    subheader: "Your current plan",
    description: [
      "2 users included",
      "5 modules",
      "Help center access",
      "Email support",
    ],
    buttonText: "Included",
    buttonVariant: "outlined",
  },
  "Pro Plan": {
    title: "Pro",
    subheader: "Most popular",
    price: "100",
    description: [
      "5 users included",
      "50 modules",
      "Help center access",
      "Priority email support",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
  },
  "Premium Plan": {
    title: "Premium",
    subheader: "For larger organizations",
    price: "500",
    description: [
      "50 users included",
      "300 modules",
      "Help center access",
      "Priority Phone & email support",
    ],
    buttonText: "Get Started",
    buttonVariant: "outlined",
  },
};

const useOrganizations = (organizations, currentUser) => {
  const [adminOrgs, setAdminOrgs] = useState([]);
  const [currentAdminOrg, setCurrentAdminOrg] = useState({});

  useEffect(() => {
    let admin_orgs = organizations.filter(
      (org) => org.admin.id === currentUser.uid
    );
    if (Array.isArray(admin_orgs)) {
      // console.log(admin_orgs);
      setAdminOrgs(admin_orgs);
      setCurrentAdminOrg(admin_orgs[0]);
    }
  }, [organizations, currentUser]);

  const handleOrganizationChange = (event) => {
    event.preventDefault();
    setCurrentAdminOrg(
      adminOrgs.filter((org) => org.id === event.target.value)[0]
    );
  };

  return {
    adminOrgs,
    currentAdminOrg,
    handleOrganizationChange,
  };
};

export default function Billing() {
  const { organizations, currentUser } = useAuth();
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const { adminOrgs, currentAdminOrg, handleOrganizationChange } =
    useOrganizations(organizations, currentUser);

  const isFree = () => {
    if (currentAdminOrg === undefined) {
      // If currentAdminOrg is undefined, then we are still loading the organizations. Returnt true to display prices
      return true;
    } else if (Object.keys(currentAdminOrg).length === 0) {
      // If currentAdminOrg is an empty object, then the user has no organizations. Return true to display prices
      return true;
    } else if (currentAdminOrg.billing_tier.toLowerCase().includes("free")) {
      // if currentAdminOrg is free, then return true to display prices
      return true;
    } else {
      // In all other cases return false to hide prices. this means that currentAdminOrg is paying for a plan
      return false;
    }
  };
  return (
    <>
      <OrganizationsList
        organizations={adminOrgs}
        organizationChange={(event) => handleOrganizationChange(event)}
      />
      {isFree() && (
        <Pricing
          organization={currentAdminOrg}
          currentUser={currentUser}
          setOpenBackdrop={setOpenBackdrop}
        />
      )}
      {!isFree() && (
        <BillingDetails
          organization={currentAdminOrg}
          currentUser={currentUser}
          setOpenBackdrop={setOpenBackdrop}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}

function BillingDetails({ organization, currentUser, setOpenBackdrop }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleManageSubscription = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/create-customer-portal`,
      "POST",
      {
        organization_id: organization.id,
        return_url: window.location.href,
      },
      currentUser
    );
    if (res.status !== 200) {
      setOpenBackdrop(false);
      const data = await res.text();
      enqueueSnackbar(`Error creating customer portal: ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      setOpenBackdrop(false);
      const data = await res.json();
      window.location.href = data.url;
    }
    setOpenBackdrop(false);
  };

  const displayCancelationDate = () => {
    if (
      organization.plan_cancellation_date !== null &&
      organization.plan_cancellation_date !== undefined
    ) {
      const date = new Date(organization.plan_cancellation_date * 1000);
      return (
        <>
          <Grid item xs={3}>
            <Typography m={1} variant="subtitle1">
              Your plan will be canceled on:
            </Typography>
          </Grid>
          <Grid item xs={9}>
            <Typography variant="subtitle1" m={1}>
              {date.toLocaleString()}
            </Typography>
          </Grid>
        </>
      );
    }
  };
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={3}>
        <Typography m={1} variant="subtitle1">
          Billing Plan:
        </Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography variant="subtitle1" m={1}>
          {organization.billing_tier}
        </Typography>
      </Grid>
      {displayCancelationDate()}
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleManageSubscription}
        >
          Manage Subscription
        </Button>
      </Grid>
    </Grid>
  );
}

const useProducts = (user) => {
  const [products, setProducts] = useState([]);
  useEffect(() => {
    const fetchProducts = async () => {
      let res = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/api/products`,
        "GET",
        {},
        user
      );
      if (res.status === 200) {
        let prods = await res.json();
        setProducts(prods);
      }
    };
    if (products.length === 0) {
      fetchProducts();
    } else {
      console.log("Products already fetched");
    }
  }, [products, user]);

  return { products };
};
export function Pricing({ organization, currentUser, setOpenBackdrop }) {
  const { enqueueSnackbar } = useSnackbar();
  const { products } = useProducts(currentUser);

  const handleSubscribe = async (priceId) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/create-checkout-session`,
      "POST",
      {
        price_id: priceId,
        organization_id: organization.id,
        return_url: window.location.href,
        email: currentUser.email,
      },
      currentUser
    );
    if (res.status !== 200) {
      setOpenBackdrop(false);
      const data = await res.text();
      enqueueSnackbar(`Error creating checkout session ${data}`, {
        variant: "error",
      });
    }
    if (res.status === 200) {
      const data = await res.json();
      setOpenBackdrop(false);
      window.location.href = data.url;
    }
  };

  return (
    <Box m={1} p={1}>
      <CssBaseline />
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {products.map((product) => (
            <Grid
              item
              key={product.id}
              xs={12}
              //sm={tier.title === "Enterprise" ? 12 : 6}
              md={4}
            >
              <Card>
                <CardHeader
                  title={product.name}
                  subheader={tiers[product.name].subheader}
                  titleTypographyProps={{ align: "center" }}
                  subheaderTypographyProps={{ align: "center" }}
                  action={product.name === "Pro Plan" ? <StarIcon /> : null}
                  sx={{
                    backgroundColor: (theme) =>
                      theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[700],
                  }}
                />
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      marginBottom: (theme) => theme.spacing(2),
                    }}
                  >
                    <Typography component="h2" variant="h3" color="textPrimary">
                      ${product.prices[0].unit_amount / 100}
                    </Typography>
                    <Typography variant="h6" color="textSecondary">
                      /mo
                    </Typography>
                  </Box>
                  <ul>
                    {tiers[product.name].description.map((line) => (
                      <Typography
                        component="li"
                        variant="subtitle1"
                        align="center"
                        key={line}
                      >
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant="outlined"
                    color="primary"
                    disabled={product.name === "Free"}
                    onClick={() => handleSubscribe(product.default_price)}
                  >
                    Subscribe
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
