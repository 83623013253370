export const orgFolderSchema = {
  type: "object",
  properties: {
    name: {
      title: "Folder Name",
      type: "string",
      description: "Folder name",
    },
    parent: {
      type: "string",
      title: "Parent Organization id",
    },
  },
  required: ["name", "parent"],
};

export const orgFolderSchemaUI = {
  "ui:description": "Create a new folder",
  name: {
    "ui:description": "Folder name",
  },
  parent: {
    "ui:description":
      "Parent organization id. For example, organizations/111223333",
  },
};
