export const awsAFTaccountCustomizationsSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Module Name",
    },
    aft_home_region: {
      type: "string",
      title: "AFT Home Region",
    },
    aft_management_account_id: {
      type: ["string", "null"],
      title: "AFT Management Account ID",
    },
    aws_partition: {
      type: "string",
      title: "AWS Partition",
      default: "aws",
    },
    account_ids: {
      type: "array",
      title: "Account IDs",
      items: {
        type: "string",
      },
      default: [],
    },
    module_content: {
      type: "string",
      title: "Terraform customizations to be applied",
      format: "textarea",
      default: 'output test {\n  value = "test"\n}\n',
    },
  },
  required: ["name", "aft_home_region", "aws_partition", "account_ids"],
};

export const awsAFTaccountCustomizationsUISchema = {
  "ui:description": "AWS AFT Account Customizations",
  name: {
    "ui:description": "Name of the module",
  },
  aft_home_region: {
    "ui:description": "AFT Home Region",
  },
  aft_management_account_id: {
    "ui:description":
      "AWS Account ID of the AFT Account. This is the account where AFT module is deployed. If not specified we will use the AWS account ID from the parent source.",
    "ui:emptyValue": null,
  },
  aws_partition: {
    "ui:description": "AWS Partition",
  },
  account_ids: {
    "ui:description": "Account IDs",
    items: {
      "ui:description": "Account ID",
    },
  },
  module_content: {
    "ui:description": "Terraform customizations to be applied.",
    "ui:widget": "textarea",
    "ui:options": {
      rows: 15,
    },
  },
};
