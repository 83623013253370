import { React, useEffect, useState } from "react";
import {
  Drawer,
  Box,
  Button,
  Toolbar,
  Backdrop,
  Typography,
} from "@mui/material";
import QueueResource from "./deployQueue/QueueResource";
import { QueueResourceModuleSettingsDialog } from "./deployQueue/QueueResourceModuleSettings";

import { apiCall } from "../utils/api";
import { getPrioroties } from "./gcpResourceDefintions";
import { useAuth } from "../contexts/AuthContext";
import { useSnackbar } from "notistack";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../firebase";

export default function DeployQueue({
  organization_id,
  environment_id,
  openDeployQueue,
  setOpenDeployQueue,
}) {
  const [deployQueue, setDeployQueue] = useState([]);
  const [globalVariables, setGlobalVariables] = useState({});
  const [openConfigDialog, setOpenConfigDialog] = useState(false);
  const [node, setNode] = useState({});
  const [globalVariablesNode, setGlobalVariablesNode] = useState(null);
  const [queueStatus, setQueueStatus] = useState("");
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [backdropMessage, setBackdropMessage] = useState("");

  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let unsubscribe_queue = null;
    if (openDeployQueue) {
      const queueDocRef = doc(
        db,
        `organizations/${organization_id}/environments/${environment_id}/queue/${environment_id}`
      );
      unsubscribe_queue = onSnapshot(queueDocRef, (doc) => {
        const queue_data = doc.data();
        if (queue_data === undefined) {
          // Node data is undefined probably the node is deleted
          // setUnsubscribeQueueExist(false);
          setDeployQueue([]);
          setGlobalVariables({});
          setNode({});
          setGlobalVariablesNode(null);
          // setUnsubscribeQueueExist(false);
          return;
        }
        setDeployQueue(queue_data["queue"]);
        setQueueStatus(queue_data["status"]);
        if (queue_data["global_node"]) {
          setGlobalVariables(
            queue_data["global_node"]["properties"]["globals"]
          );
          setGlobalVariablesNode(queue_data["global_node"]);
        } else {
          setGlobalVariables({});
          setGlobalVariablesNode(null);
        }
        // setUnsubscribeQueueExist(true);
      });
    }
    return () => {
      if (unsubscribe_queue) {
        console.log("unsubscribing");
        unsubscribe_queue();
      }
    };

    // eslint-disable-next-line
  }, [openDeployQueue]);

  const sendNodeUpstream = (node) => {
    const { properties } = node;
    if (!properties) {
      alert(
        "The module is not set properly. Please check the module settings in your architecture and regenerate the queue."
      );
    }
    if (node?.type === "organization" && node?.properties?.globals) {
      let sortedKeys = Object.keys(node.properties.globals)
        .sort()
        .reduce((r, k) => {
          r[k] = node.properties.globals[k];
          return r;
        }, {});
      node.properties.globals = sortedKeys;
    }
    setNode(node);
    setOpenConfigDialog(true);
  };

  const generateDeployQueue = async () => {
    setBackdropMessage("Generating Deployment Queue");
    setOpenBackdrop(true);
    const priorities = getPrioroties();
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/generatequeue`,
      "POST",
      {
        organization_id: organization_id,
        environment_id: environment_id,
        priorities: priorities,
      },
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.json();
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
    if (res.status === 200) {
      enqueueSnackbar(`Deployment Queue Generated`, { variant: "success" });
    }
    setOpenBackdrop(false);
  };

  const cancelDeployQueue = async () => {
    setBackdropMessage("Canceling Deployment Queue");
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/cancelqueue/${organization_id}/${environment_id}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.json();
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
    if (res.status === 200) {
      enqueueSnackbar(
        `Deployment Queue Canceled. Currently running job will finish.`,
        { variant: "success" }
      );
    }
    setOpenBackdrop(false);
  };

  const clearDeployQueue = async () => {
    setBackdropMessage("Clearing Deployment Queue");
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/clearqueue/${organization_id}/${environment_id}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.json();
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
    if (res.status === 200) {
      let res_obj = await res.json();
      setQueueStatus(res_obj["status"]);
      enqueueSnackbar(`Deployment Queue Cleared`, { variant: "success" });
    }
    setOpenBackdrop(false);
  };

  const startQueue = async () => {
    setBackdropMessage("Starting the queue");
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/startqueue/${organization_id}/${environment_id}/${currentUser.uid}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.json();
      enqueueSnackbar(`${error.message}`, { variant: "error" });
    }
    if (res.status === 200) {
      enqueueSnackbar(`Deployment Queue Started`, { variant: "success" });
    }
    setOpenBackdrop(false);
  };

  const isQueueWorking = () => {
    return queueStatus === "IN-PROGRESS";
  };

  const startButtonMessage = () => {
    if (queueStatus === "IN-PROGRESS") {
      return "Queue is already running";
    } else if (queueStatus === "NO-QUEUE") {
      return "Start the Queue";
    } else if (queueStatus === "DEPLOY-FAILED" || queueStatus === "CANCELLED") {
      return "Resume the Queue";
    } else {
      return "Start the Queue";
    }
  };

  const handleClose = () => {
    setOpenDeployQueue(false);
  };

  return (
    <>
      <Drawer anchor="right" open={openDeployQueue} sx={{ overflow: "auto" }}>
        <Toolbar />
        <Box m={1}>
          <Button onClick={() => generateDeployQueue()}>Generate</Button>
          <Button
            onClick={() => startQueue()}
            disabled={
              queueStatus === "IN-PROGRESS" ||
              queueStatus === "NO-QUEUE" ||
              queueStatus === "COMPLETED"
            }
          >
            {startButtonMessage()}
          </Button>
          <Button onClick={() => cancelDeployQueue()}>Cancel Execution</Button>
          <Button
            onClick={() => clearDeployQueue()}
            disabled={isQueueWorking()}
          >
            Clear Queue
          </Button>
          <Button onClick={() => handleClose(false)}>Close</Button>
          <Box flex={1} flexDirection="column">
            <Box m={1}>
              <Typography variant="h6">Queue Status: {queueStatus}</Typography>
            </Box>
            {globalVariablesNode && (
              <QueueResource
                key={globalVariablesNode.id}
                node={globalVariablesNode}
                sendNodeUpstream={sendNodeUpstream}
                environment_id={environment_id}
                organization_id={organization_id}
                globalVariables={globalVariables}
              />
            )}
            {deployQueue.map((item) => (
              <QueueResource
                key={item.id}
                node={item}
                sendNodeUpstream={sendNodeUpstream}
                environment_id={environment_id}
                organization_id={organization_id}
                globalVariables={globalVariables}
              />
            ))}
          </Box>
        </Box>
      </Drawer>
      <QueueResourceModuleSettingsDialog
        openConfigDialog={openConfigDialog}
        setOpenConfigDialog={setOpenConfigDialog}
        node={node}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <Typography variant="subtitle2">{backdropMessage}</Typography>
      </Backdrop>
    </>
  );
}
