export const instanceTemplateSchema = {
  title: "Instance Template",
  type: "object",
  properties: {
    name_prefix: {
      title: "Name prefix for the instance template",
      type: "string",
      default: "default-instance-template",
    },
    region: {
      title: "Region",
      type: ["string", "null"],
      default: null,
    },
    service_account: {
      title: "Service Account",
      type: "object",
      properties: {
        email: {
          type: "string",
        },
        scopes: {
          type: "array",
          items: {
            type: "string",
          },
          default: ["cloud-platform"],
        },
      },
    },
    machine_type: {
      title: "Machine Type",
      type: "string",
      default: "e2-standard-2",
    },
    min_cpu_platform: {
      title: "Minimum CPU Platform",
      type: ["string", "null"],
      default: null,
    },
    disk_size_gb: {
      title: "Boot Disk Size in GB",
      type: "string",
      default: "100",
    },
    disk_type: {
      title: "Disk Type",
      type: "string",
      default: "pd-standard",
    },
    disk_encryption_key: {
      title: "Disk Encryption Key",
      type: ["string", "null"],
      default: null,
    },
    source_image: {
      title: "Source Image",
      default: "",
      type: "string",
    },
    source_image_family: {
      type: "string",
      title: "Source Image Family",
      default: "centos-7",
    },
    source_image_project: {
      type: "string",
      title: "Source Image Project",
      default: "centos-cloud",
    },
    disk_labels: {
      type: "array",
      title: "Disk Labels",
      items: {
        type: "object",
        properties: {
          key: {
            title: "Key",
            type: "string",
          },
          value: {
            title: "Value",
            type: "string",
          },
        },
      },
    },
    auto_delete: {
      type: "boolean",
      title: "Auto Delete",
      default: true,
    },
    can_ip_forward: {
      type: "boolean",
      default: false,
      title: "Enable IP forwarding",
    },
    network: {
      type: "string",
      title: "Network self_link",
      default: "",
    },
    subnetwork: {
      type: "string",
      title: "Subnetwork self_link",
      default: "",
    },
    subnetwork_project: {
      type: "string",
      title: "Subnetwork Project",
      default: "",
    },
    network_ip: {
      type: "string",
      title: "Network IP",
      default: "",
    },
    stack_type: {
      type: ["string", "null"],
      title: "Stack Type",
      default: null,
    },
    tags: {
      type: "array",
      title: "Network Tags",
      items: {
        type: "string",
      },
      default: [],
    },
    access_config: {
      type: "array",
      title: "Access Config",
      items: {
        type: "object",
        properties: {
          nat_ip: {
            title: "NAT IP",
            type: "string",
          },
          network_tier: {
            title: "Network Tier",
            type: "string",
            default: "STANDARD",
          },
        },
      },
      default: [],
    },
    ipv6_access_config: {
      type: "array",
      title: "IPv6 Access Config",
      items: {
        type: "object",
        properties: {
          network_tier: {
            title: "Network Tier",
            type: "string",
            default: "STANDARD",
          },
        },
      },
      default: [],
    },
    additional_networks: {
      type: "array",
      title: "Additional Networks",
      items: {
        type: "object",
        properties: {
          network: {
            type: "string",
            title: "Network self_link",
            default: "",
          },
          subnetwork: {
            type: "string",
            title: "Subnetwork self_link",
            default: "",
          },
          subnetwork_project: {
            type: "string",
            title: "Subnetwork Project",
            default: "",
          },
          network_ip: {
            type: ["string", "null"],
            title: "Network IP",
            default: null,
          },
          access_config: {
            type: "array",
            title: "Access Config",
            items: {
              properties: {
                nat_ip: {
                  title: "NAT IP",
                  type: "string",
                },
                network_tier: {
                  title: "Network Tier",
                  type: "string",
                  default: "STANDARD",
                },
              },
            },
            default: [],
          },
          ipv6_access_config: {
            type: "array",
            title: "IPv6 Access Config",
            items: {
              properties: {
                network_tier: {
                  title: "Network Tier",
                  type: "string",
                  default: "STANDARD",
                },
              },
            },
            default: [],
          },
        },
      },
    },

    startup_script: {
      type: "string",
      title: "Startup Script",
      default: "",
    },
    metadata: {
      type: "array",
      title: "Metadata",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
            title: "Key",
          },
          value: {
            type: "string",
            title: "Value",
          },
        },
      },
      default: [],
    },
    additional_disks: {
      title: "Additional Disks",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          disk_name: {
            type: "string",
            title: "Disk Name",
          },
          disk_size: {
            type: "number",
            title: "Disk Size",
            default: 50,
          },
          device_name: {
            type: "string",
            title: "Device Name",
          },
          disk_type: {
            title: "Disk Type",
            type: "string",
            default: "pd-standard",
          },
          auto_delete: {
            title: "Auto Delete",
            type: "boolean",
            default: true,
          },
          boot: {
            title: "Boot",
            type: "boolean",
            default: false,
          },
        },
      },
    },
    labels: {
      type: "array",
      title: "Labels",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
    preemptible: {
      type: "boolean",
      title: "Preemptible",
      default: false,
    },
    automatic_restart: {
      type: "boolean",
      title: "Automatic Restart",
      default: true,
    },
    enable_shielded_vm: {
      type: "boolean",
      title: "Enable Shielded VM",
      default: false,
    },
    shielded_instance_config: {
      type: "object",
      title: "Shielded Instance Config",
      properties: {
        enable_secure_boot: {
          type: "boolean",
          title: "Enable Secure Boot",
          default: true,
        },
        enable_vtpm: {
          type: "boolean",
          title: "Enable vTPM",
          default: true,
        },
        enable_integrity_monitoring: {
          type: "boolean",
          title: "Enable Integrity Monitoring",
          default: true,
        },
      },
    },
    on_host_maintenance: {
      type: "string",
      title: "On Host Maintenance",
      default: "MIGRATE",
    },
    enable_nested_virtualization: {
      type: "boolean",
      title: "Enable Nested Virtualization",
      default: false,
    },
    enable_confidential_vm: {
      type: "boolean",
      title: "Enable Confidential VM",
      default: false,
    },
    threads_per_core: {
      type: ["number", "null"],
      title: "Threads Per Core",
      default: null,
    },
    gpu: {
      type: "object",
      title: "GPU",
      properties: {
        type: {
          type: ["string", "null"],
          title: "Type",
          default: null,
        },
        count: {
          type: ["number", "null"],
          title: "Count",
          default: null,
        },
      },
    },
    // There is a bug in the terraform provider that prevents this from working
    // alias_ip_range: {
    //   type: "object",
    //   title: "Alias IP Range",
    //   properties: {
    //     ip_cidr_range: {
    //       type: "string",
    //       title: "IP CIDR Range",
    //       default: "",
    //     },
    //     subnetwork_range_name: {
    //       type: "string",
    //       title: "Subnetwork Range Name",
    //       default: "",
    //     },
    //   },
    // },
  },
  required: ["name_prefix"],
};

export const instanceTemplateSchemaUi = {
  name_prefix: {
    "ui:description": "Name prefix for the instance template",
  },
  region: {
    "ui:description": "Region where the instance template should be created.",
  },
  service_account: {
    "ui:description":
      "Service account to attach to the instance. See https://www.terraform.io/docs/providers/google/r/compute_instance_template#service_account.",
    email: {
      "ui:description": "Email of the service account.",
    },
    scopes: {
      "ui:description": "Scopes of the service account.",
      items: {
        "ui:description": "Scope of the service account.",
      },
    },
  },
  machine_type: {
    "ui:description": "Machine type of the instance.",
  },
  min_cpu_platform: {
    "ui:description":
      "Specifies a minimum CPU platform. Applicable values are the friendly names of CPU platforms, such as Intel Haswell or Intel Skylake. See the complete list: https://cloud.google.com/compute/docs/instances/specify-min-cpu-platform",
    "ui:emptyValue": null,
  },
  disk_size_gb: {
    "ui:description": "Boot disk size in GB.",
  },
  disk_type: {
    "ui:description":
      "Boot disk type, can be either pd-ssd, local-ssd, or pd-standard",
  },
  disk_encryption_key: {
    "ui:description":
      "The id of the encryption key that is stored in Google Cloud KMS to use to encrypt all the disks on this instance",
  },
  source_image: {
    "ui:description":
      "Source disk image. If neither source_image nor source_image_family is specified, defaults to the latest public CentOS image.",
  },
  source_image_family: {
    "ui:description":
      "Source image family. If neither source_image nor source_image_family is specified, defaults to the latest public CentOS image.",
  },
  source_image_project: {
    "ui:description":
      "Project where the source image comes from. The default project contains CentOS images.",
  },
  disk_labels: {
    "ui:description": "Labels to be assigned to boot disk, provided as a map",
    items: {
      "ui:description": "Label to be assigned to boot disk",
      "ui:displayfield": "key",
      key: {
        "ui:description": "Label key",
      },
      value: {
        "ui:description": "Label value",
      },
    },
  },
  auto_delete: {
    "ui:description": "Whether or not the boot disk should be auto-deleted.",
  },
  can_ip_forward: {
    "ui:description": "Enable IP forwarding, for NAT instances for example",
  },
  network: {
    "ui:description":
      "The name or self_link of the network to attach this interface to. Use network attribute for Legacy or Auto subnetted networks and subnetwork for custom subnetted networks.",
  },
  subnetwork: {
    "ui:description":
      "The self_link of the subnetwork to attach this interface to. The subnetwork must exist in the same region this instance will be created in. Either network or subnetwork must be provided.",
  },
  subnetwork_project: {
    "ui:description":
      "The ID of the project in which the subnetwork belongs. If it is not provided, the provider project is used.",
  },
  network_ip: {
    "ui:description":
      "Private IP address to assign to the instance if desired.",
  },
  stack_type: {
    "ui:description":
      "The stack type for this network interface to identify whether the IPv6 feature is enabled or not. Values are `IPV4_IPV6` or `IPV4_ONLY`. Default behavior is equivalent to IPV4_ONLY.",
  },
  tags: {
    "ui:description": "Network tags, provided as a list",
    items: {
      "ui:description": "Network tag",
    },
  },
  access_config: {
    "ui:description":
      "Access configurations, i.e. IPs via which the VM instance can be accessed via the Internet.",
    items: {
      "ui:description": "Access configuration",
      "ui:displayfield": "nat_ip",
      nat_ip: {
        "ui:description":
          "The IP address that will be 1:1 mapped to the instance's network ip.",
      },
      network_tier: {
        "ui:description":
          "The networking tier used for configuring this access configuration. This field can take the following values: PREMIUM or STANDARD. If this field is not specified, it is assumed to be PREMIUM.",
      },
    },
  },
  ipv6_access_config: {
    "ui:description":
      "IPv6 access configurations. Currently a max of 1 IPv6 access configuration is supported. If not specified, the instance will have no external IPv6 Internet access.",
    items: {
      "ui:description": "IPv6 access configuration",
      "ui:displayfield": "network_tier",
      network_tier: {
        "ui:description":
          "The networking tier used for configuring this access configuration. This field can take the following values: PREMIUM or STANDARD. If this field is not specified, it is assumed to be PREMIUM.",
      },
    },
  },
  additional_networks: {
    "ui:description": "Additional network interface details for GCE, if any.",
    items: {
      "ui:description": "Additional network interface",
      "ui:displayfield": "network",
      network: {
        "ui:description":
          "The name or self_link of the network to attach this interface to. Use network attribute for Legacy or Auto subnetted networks and subnetwork for custom subnetted networks.",
      },
      subnetwork: {
        "ui:description":
          "The name of the subnetwork to attach this interface to. The subnetwork must exist in the same region this instance will be created in. Either network or subnetwork must be provided.",
      },
      subnetwork_project: {
        "ui:description":
          "The ID of the project in which the subnetwork belongs. If it is not provided, the provider project is used.",
      },
      network_ip: {
        "ui:description":
          "Private IP address to assign to the instance if desired.",
      },
      access_config: {
        "ui:description":
          "Access configurations, i.e. IPs via which the VM instance can be accessed via the Internet.",
        items: {
          "ui:description": "Access configuration",
          "ui:displayfield": "nat_ip",
          nat_ip: {
            "ui:description":
              "The IP address that will be 1:1 mapped to the instance's network ip.",
          },
          network_tier: {
            "ui:description":
              "The networking tier used for configuring this access configuration. This field can take the following values: PREMIUM or STANDARD. If this field is not specified, it is assumed to be PREMIUM.",
          },
        },
      },
      ipv6_access_config: {
        "ui:description":
          "IPv6 access configurations. Currently a max of 1 IPv6 access configuration is supported. If not specified, the instance will have no external IPv6 Internet access.",
        items: {
          "ui:description": "IPv6 access configuration",
          "ui:displayfield": "network_tier",
          network_tier: {
            "ui:description":
              "The networking tier used for configuring this access configuration. This field can take the following values: PREMIUM or STANDARD. If this field is not specified, it is assumed to be PREMIUM.",
          },
        },
      },
    },
  },
  startup_script: {
    "ui:description": "User startup script to run when instances spin up",
    "ui:widget": "textarea",
  },
  metadata: {
    "ui:description":
      "Metadata key/value pairs to make available from within instances",
    items: {
      "ui:description": "Metadata key/value pair",
      "ui:displayfield": "key",
      key: {
        "ui:description": "Metadata key",
      },
      value: {
        "ui:description": "Metadata value",
      },
    },
  },
  additional_disks: {
    "ui:description":
      "List of maps of additional disks. See https://www.terraform.io/docs/providers/google/r/compute_instance_template#disk_name",
    items: {
      "ui:description": "Additional disk settings",
      "ui:displayfield": "disk_name",
      disk_name: {
        "ui:description": "Name of the disk.",
      },
      disk_size: {
        "ui:description": "Size of the disk in GB.",
      },
      device_name: {
        "ui:description": "Name of the device to mount the disk as.",
      },
      disk_type: {
        "ui:description":
          "Type of the disk, can be either pd-ssd, local-ssd, or pd-standard",
      },
      auto_delete: {
        "ui:description": "Whether or not the disk should be auto-deleted.",
      },
      boot: {
        "ui:description": "Whether or not this is a boot disk.",
      },
    },
  },
  labels: {
    "ui:description":
      "Labels to be assigned to the instance, provided as a map",
    items: {
      "ui:description": "Label to be assigned to the instance",
      "ui:displayfield": "key",
      key: {
        "ui:description": "Label key",
      },
      value: {
        "ui:description": "Label value",
      },
    },
  },
  preemptible: {
    "ui:description": "Allow the instance to be preempted",
  },
  automatic_restart: {
    "ui:description":
      "(Optional) Specifies whether the instance should be automatically restarted if it is terminated by Compute Engine (not terminated by a user).",
  },
  enable_shielded_vm: {
    "ui:description":
      "Whether to enable the Shielded VM configuration on the instance. Note that the instance image must support Shielded VMs. See https://cloud.google.com/compute/docs/images",
  },
  shielded_instance_config: {
    "ui:description":
      "Not used unless enable_shielded_vm is true. Shielded VM configuration for the instance.",
  },
  on_host_maintenance: {
    "ui:description": "Instance availability Policy",
  },
  enable_nested_virtualization: {
    "ui:description":
      "Defines whether the instance should have nested virtualization enabled.",
  },
  enable_confidential_vm: {
    "ui:description":
      "Whether to enable the Confidential VM configuration on the instance. Note that the instance image must support Confidential VMs. See https://cloud.google.com/compute/docs/images",
  },
  threads_per_core: {
    "ui:description":
      "The number of threads per physical core. To disable simultaneous multithreading (SMT) set this to 1.",
  },
  gpu: {
    "ui:description":
      "GPU information. Type and count of GPU to attach to the instance template. See https://cloud.google.com/compute/docs/gpus more details",
    type: {
      "ui:description":
        "Type of the GPU to attach to the instance template. See https://cloud.google.com/compute/docs/gpus more details",
    },
    count: {
      "ui:description":
        "Count of the GPU to attach to the instance template. See https://cloud.google.com/compute/docs/gpus more details",
    },
  },
  alias_ip_range: {
    "ui:description":
      "An array of alias IP ranges for this network interface. Can only be specified for network interfaces on subnet-mode networks. ip_cidr_range: The IP CIDR range represented by this alias IP range. This IP CIDR range must belong to the specified subnetwork and cannot contain IP addresses reserved by system or used by other network interfaces. At the time of writing only a netmask (e.g. /24) may be supplied, with a CIDR format resulting in an API error. subnetwork_range_name: The subnetwork secondary range name specifying the secondary range from which to allocate the IP CIDR range for this alias IP range. If left unspecified, the primary range of the subnetwork will be used.",
    ip_cidr_range: {
      "ui:description": "IP CIDR range represented by this alias IP range.",
    },
    subnetwork_range_name: {
      "ui:description": "Subnetwork secondary range name.",
    },
  },
};
