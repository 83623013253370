export const databricksAccountAdminGroupSchema = {
  type: "object",
  properties: {
    admin_group_name: {
      type: "string",
      title: "Admin Group Name",
      description: "Admin Group Name",
      default: "admin-group",
    },
    user_ids: {
      type: "array",
      title: "User IDs",
      description: "User IDs",
      items: {
        type: "string",
      },
    },
  },
  required: ["admin_group_name"],
};

export const databricksAccountAdminGroupUISchema = {
  "ui:description": "Databricks Admin Group",
  user_ids: {
    items: {
      "ui:description": "User ID",
    },
  },
};
