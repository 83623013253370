export const routerSchema = {
  title: "NAT Router",
  type: "object",
  properties: {
    name: {
      title: "Router name",
      type: "string",
    },
    region: {
      title: "Region",
      type: "string",
    },
    description: {
      title: "Description",
      type: "string",
    },
    bgp: {
      title: "BGP Configuration",
      type: ["object", "null"],
      properties: {
        asn: {
          title: "ASN",
          type: ["string", "null"],
          default: null,
        },
        advertised_groups: {
          title: "Advertised Groups",
          type: "array",
          items: {
            title: "Advertised Group",
            type: "string",
          },
          default: [],
        },
        advertised_ip_ranges: {
          title: "Advertised IP Ranges",
          type: "array",
          items: {
            title: "Advertised IP Range",
            type: "object",
            properties: {
              range: {
                title: "Range",
                type: "string",
              },
              description: {
                title: "Description",
                type: "string",
              },
            },
          },
          default: [],
        },
        keepalive_interval: {
          title: "Keepalive Interval",
          type: ["number", "null"],
          default: null,
        },
      },
      default: null,
    },
    nats: {
      title: "NATs to deploy on this router",
      type: "array",
      items: {
        properties: {
          name: {
            title: "Name of the NAT",
            type: "string",
          },
          source_subnetwork_ip_ranges_to_nat: {
            title: "How NAT should be configured per Subnetwork",
            type: "string",
            enum: [
              "ALL_SUBNETWORKS_ALL_IP_RANGES",
              "ALL_SUBNETWORKS_ALL_PRIMARY_IP_RANGES",
              "LIST_OF_SUBNETWORKS",
            ],
            default: "ALL_SUBNETWORKS_ALL_IP_RANGES",
          },
          nat_ip_allocate_option: {
            title: "IP Allocation Option",
            description: "How external IPs should be allocated for this NAT",
            type: "string",
            enum: ["MANUAL_ONLY", "AUTO_ONLY"],
            default: "AUTO_ONLY",
          },
          nat_ips: {
            title: "Self-links of NAT IPs",
            description: "List of reserved NAT IPs self_links",
            type: "array",
            items: {
              title: "NAT IP self_link",
              type: "string",
            },
            default: [],
          },
          min_ports_per_vm: {
            title: "Minimum ports per VM",
            type: ["number", "null"],
            default: null,
          },
          max_ports_per_vm: {
            title: "Maximum ports per VM",
            type: ["number", "null"],
            default: null,
          },
          udp_idle_timeout_sec: {
            title: "UDP idle timeout (seconds)",
            type: ["number", "null"],
            default: null,
          },
          icmp_idle_timeout_sec: {
            title: "ICMP idle timeout (seconds)",
            type: ["number", "null"],
            default: null,
          },
          tcp_established_idle_timeout_sec: {
            title: "TCP established idle timeout (seconds)",
            type: ["number", "null"],
            default: null,
          },
          tcp_transitory_idle_timeout_sec: {
            title: "TCP transitory idle timeout (seconds)",
            type: ["number", "null"],
            default: null,
          },
          tcp_time_wait_timeout_sec: {
            title: "TCP time wait timeout (seconds)",
            type: ["number", "null"],
            default: null,
          },
          log_config: {
            title: "Log Config",
            type: "object",
            properties: {
              enable: {
                title: "Enable",
                type: "boolean",
                default: false,
              },
              filter: {
                title: "Filter",
                type: "string",
                enum: ["ALL", "TRANSLATIONS_ONLY", "ERRORS_ONLY"],
                default: "ERRORS_ONLY",
              },
            },
          },
          subnetworks: {
            title: "List of subnetworks to be NATed",
            type: "array",
            items: {
              properties: {
                name: {
                  type: "string",
                  title: "Subnet self_link",
                },
                source_ip_ranges_to_nat: {
                  type: "string",
                  title: "Source IP ranges to NAT",
                  default: "",
                },
                secondary_ip_range_names: {
                  type: ["string", "null"],
                  title: "Secondary IP range names",
                  default: null,
                },
              },
            },
            default: [],
          },
        },
      },
      default: [],
    },
  },
  required: ["name", "region"],
};

export const routerSchemaUI = {
  "ui:description": "Router Settings",
  name: {
    "ui:description": "Name of the NAT Router",
  },
  region: {
    "ui:description":
      "Region where the NAT Router will be deployed. It must be the same region as the subnetworks that will be NATed.",
  },
  description: {
    "ui:description": "An optional description of this resource",
  },
  bgp: {
    "ui:description": "BGP information specific to this router.",
    asn: {
      "ui:description": "Local BGP Autonomous System Number (ASN)",
    },
    advertised_groups: {
      "ui:description": "User-specified list of prefix groups to advertise.",
      items: {
        "ui:description": "Group name",
      },
    },
    advertised_ip_ranges: {
      "ui:description":
        "User-specified list of individual IP ranges to advertise.",
      items: {
        "ui:displayfield": "range",
        "ui:description": "Advertised IP range settings.",
        range: {
          "ui:description": "",
        },
        description: {
          "ui:description": "User-specified description for the IP range.",
        },
      },
    },
    keepalive_interval: {
      "ui:description":
        "Period of time in seconds to wait before sending a new BGP keepalive message to the peer.",
    },
  },
  nats: {
    "ui:description": "NATs to deploy on this router",
    items: {
      "ui:description": "NAT configuration",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Name of the NAT",
      },
      nat_ip_allocate_option: {
        "ui:description":
          "IPs should be allocated for this NAT. Defaults to MANUAL_ONLY if nat_ips are set, else AUTO_ONLY.",
      },
      source_subnetwork_ip_ranges_to_nat: {
        "ui:description":
          "How NAT should be configured per Subnetwork. Defaults to ALL_SUBNETWORKS_ALL_IP_RANGES",
      },
      nat_ips: {
        "ui:description": "List of reserved NAT IPs self_links",
        items: {
          "ui:description": "NAT IP self_link",
        },
      },
      min_ports_per_vm: {
        "ui:description":
          "Minimum number of ports allocated to a VM from this NAT",
      },
      max_ports_per_vm: {
        "ui:description":
          "Maximum number of ports allocated to a VM from this NAT. This field can only be set when enableDynamicPortAllocation is enabled.",
      },
      udp_idle_timeout_sec: {
        "ui:description":
          "Timeout (in seconds) for UDP connections. Defaults to 30s if not set.",
      },
      icmp_idle_timeout_sec: {
        "ui:description":
          "Timeout (in seconds) for ICMP connections. Defaults to 30s if not set.",
      },
      tcp_established_idle_timeout_sec: {
        "ui:description":
          "Timeout (in seconds) for TCP established connections. Defaults to 1200s if not set.",
      },
      tcp_transitory_idle_timeout_sec: {
        "ui:description":
          "Timeout (in seconds) for TCP transitory connections. Defaults to 30s if not set.",
      },
      tcp_time_wait_timeout_sec: {
        "ui:description":
          "Timeout (in seconds) for TCP connections in TIME_WAIT. Defaults to 120s if not set.",
      },
      log_config: {
        "ui:description": "Log Config",
        filter: {
          "ui:description":
            "Specifies the desired filtering of logs on this NAT. Defaults to ALL",
        },
        enable: {
          "ui:description":
            "Indicates whether or not to export logs. Defaults to false",
        },
      },
      subnetworks: {
        "ui:description": "List of subnetworks to be NATed",
        items: {
          "ui:description": "Settings of Subnetwork to be NATed",
          "ui:displayfield": "name",
          name: {
            "ui:description": "Subnet self_link",
          },
          source_ip_ranges_to_nat: {
            "ui:description":
              "List of options for which source IPs in the subnetwork should have NAT enabled.",
          },
          secondary_ip_range_names: {
            "ui:description":
              "List of the secondary ranges of the subnetwork that are allowed to use NAT.",
          },
        },
      },
    },
  },
};
