export const migSchema = {
  title: "Regional Managed Instance Group",
  type: "object",
  properties: {
    mig_name: {
      title: "Managed Instance Group Name",
      type: "string",
    },
    hostname: {
      title: "Hostname prefix for instances",
      type: "string",
      default: "default",
    },
    region: {
      title: "Region",
      type: "string",
    },
    target_size: {
      title: "Target Size",
      type: "string",
      default: "1",
    },
    target_pools: {
      type: "array",
      title: "Target Pools",
      items: {
        type: "string",
      },
    },
    distribution_policy_target_shape: {
      title: "Distribution Policy Target Shape",
      type: "string",
      enum: ["EVEN", "BALANCED", "ANY", "ANY_SINGLE_ZONE", ""],
      default: "",
    },
    distribution_policy_zones: {
      title: "Distribution Policy Zones",
      type: "array",
      items: {
        type: "string",
      },
    },
    stateful_disks: {
      title: "Stateful Disks",
      type: "array",
      items: {
        type: "object",
        properties: {
          device_name: {
            title: "Device name",
            type: "string",
          },
          delete_rule: {
            title: "Delete Rule",
            type: "string",
          },
        },
      },
      default: [],
    },
    stateful_ips: {
      title: "Stateful IPs",
      type: "array",
      items: {
        type: "object",
        properties: {
          interface_name: {
            title: "Interface Name",
            type: "string",
          },
          delete_rule: {
            title: "Delete Rule",
            type: "string",
          },
          is_external: {
            title: "Is External",
            type: "boolean",
          },
        },
      },
      default: [],
    },
    update_policy: {
      type: "array",
      title: "Update Policy",
      items: {
        type: "object",
        properties: {
          max_surge_fixed: {
            title: "Max Surge Fixed",
            type: "string",
          },
          instance_redistribution_type: {
            title: "Instance Redistribution Type",
            enum: ["PROACTIVE", "NONE"],
            default: "NONE",
            type: "string",
          },
          max_surge_percent: {
            title: "Max Surge Percent",
            type: "number",
            default: 20,
          },
          max_unavailable_fixed: {
            title: "Max Unavailable Fixed",
            type: "number",
            default: 2,
          },
          max_unavailable_percent: {
            title: "Max Unavailable Fixed",
            type: "number",
            default: 2,
          },
          min_ready_sec: {
            title: "Min Ready Seconds",
            type: "number",
            default: 50,
          },
          replacement_method: {
            title: "Replacement Method",
            type: "string",
            enum: ["RECREATE", "RESTART"],
            default: "RECREATE",
          },
          minimal_action: {
            type: "string",
            title: "Minimal Action",
            enum: ["RESTART", "REPLACE", "REFRESH"],
            default: "REPLACE",
          },
          type: {
            type: "string",
            title: "The type of update process",
            enum: ["PROACTIVE", "OPPORTUNISTIC"],
            default: "PROACTIVE",
          },
        },
      },
    },
    health_check_name: {
      type: "string",
      default: "",
    },
    health_check: {
      title: "Health Check",
      type: "object",
      properties: {
        type: {
          title: "Type",
          enum: ["tcp", "http", "https"],
          type: "string",
          default: "http",
        },
        initial_delay_sec: {
          title: "Initial Delay in Seconds",
          type: "number",
          default: 30,
        },
        check_interval_sec: {
          title: "Check Interval in Seconds",
          type: "number",
          default: 30,
        },
        healthy_threshold: {
          title: "Healthy Threshold",
          type: "number",
          default: 1,
        },
        timeout_sec: {
          title: "Timoue Seconds",
          type: "number",
          default: 10,
        },
        unhealthy_threshold: {
          title: "Unhealthy threshold",
          type: "number",
          default: 5,
        },
        response: {
          title: "Health Check Response",
          type: "string",
          default: "",
        },
        proxy_header: {
          title: "Proxy Header",
          type: "string",
          default: "NONE",
        },
        port: {
          title: "Port",
          type: "number",
          default: 80,
        },
        request: {
          title: "Request",
          type: "string",
          default: "",
        },
        request_path: {
          title: "Request path",
          type: "string",
          default: "/",
        },
        host: {
          title: "Host header",
          type: "string",
          default: "",
        },
        enable_logging: {
          title: "Enable Logging",
          type: "boolean",
          default: false,
        },
      },
    },
    wait_for_instances: {
      type: "boolean",
      default: false,
      title: "Wait For Instances",
    },
    named_ports: {
      description:
        "Named name and named port. https://cloud.google.com/load-balancing/docs/backend-service#named_ports",
      type: "array",
      default: [],
      title: "Named Ports",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Name",
            type: "string",
          },
          port: {
            title: "Port",
            type: "number",
          },
        },
        required: ["name", "port"],
      },
    },
    autoscaler_name: {
      title: "Autoscaler name",
      type: "string",
      default: "",
    },
    autoscaling_enabled: {
      title: "Autoscalling enabled",
      type: "boolean",
      default: false,
    },
    max_replicas: {
      type: "number",
      default: 10,
      title: "Maximum Replicas",
    },
    min_replicas: {
      type: "number",
      default: 2,
      title: "Minimum Number of replicas",
    },
    cooldown_period: {
      type: "number",
      default: 60,
      title: "Cooldown Period",
    },
    autoscaling_mode: {
      type: "string",
      enum: ["ON", "OFF", "ONLY_UP"],
      default: "ON",
      title: "Autoscaling Mode",
    },
    autoscaling_cpu: {
      title: "Autoscalling CPU",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          target: {
            title: "Target",
            type: "number",
          },
          predictive_method: {
            title: "Predictive Method",
            type: "string",
            enum: ["NONE", "OPTIMIZE_AVAILABILITY"],
            default: "NONE",
          },
        },
      },
    },
    autoscaling_metric: {
      title: "Autoscaling Metric",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          target: {
            type: "number",
          },
          type: {
            type: "string",
            enum: ["GAUGE", "DELTA_PER_SECOND", "DELTA_PER_MINUTE"],
          },
        },
        required: ["name"],
      },
    },
    autoscaling_lb: {
      title: "Autoscaling Load Balancer",
      type: "array",
      default: [],
      items: {
        type: "object",
        properties: {
          target: {
            type: "number",
          },
        },
        required: ["target"],
      },
    },
    scaling_schedules: {
      title: "Scaling Schedules",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
          },
          min_required_replicas: {
            type: "number",
          },
          schedule: {
            type: "string",
          },
          duration_sec: {
            type: "number",
          },
          time_zone: {
            type: "string",
          },
          disabled: {
            type: "boolean",
            default: false,
          },
        },
        required: ["name", "min_required_replicas", "schedule"],
      },
      default: [],
    },
    autoscaling_scale_in_control: {
      type: "object",
      title: "Autoscaling scale in control",
      properties: {
        fixed_replicas: {
          type: ["number", "null"],
          title: "Fixed Replicas",
          default: null,
        },
        percent_replicas: {
          type: ["number", "null"],
          title: "Percent Replicas",
          default: null,
        },
        time_window_sec: {
          type: ["number", "null"],
          title: "Time Window in Seconds",
          default: null,
        },
      },
    },
    mig_timeouts: {
      type: "object",
      title: "MIG Timeouts",
      properties: {
        create: {
          type: "string",
          default: "5m",
        },
        update: {
          type: "string",
          default: "5m",
        },
        delete: {
          type: "string",
          default: "15m",
        },
      },
    },
  },
  required: ["mig_name", "region"],
};

export const migSchemaUI = {
  "ui:description": "Regional Managed Instance Group",
  hostname: {
    "ui:description": "Hostname prefix for instances",
  },
  mig_name: {
    "ui:description": "Managed Instance Group Name",
  },
  region: {
    "ui:description": "Region. For example: us-central1.",
  },
  target_size: {
    "ui:description":
      "The target number of running instances for this managed instance group. This value should always be explicitly set unless this resource is attached to an autoscaler, in which case it should never be set.",
  },
  target_pools: {
    "ui:description":
      "The target load balancing pools to assign this group to.",
    items: {
      "ui:description": "List of pools",
    },
  },
  distribution_policy_target_shape: {
    "ui:description":
      "MIG target distribution shape (EVEN, BALANCED, ANY, ANY_SINGLE_ZONE)",
    "ui:emptyValue": null,
  },
  distribution_policy_zones: {
    "ui:description":
      "The distribution policy, i.e. which zone(s) should instances be create in. Default is all zones in given region.",
    items: {
      "ui:description": "Zone. For example: us-central1-a.",
    },
  },
  stateful_disks: {
    "ui:description":
      "Disks created on the instances that will be preserved on instance delete. https://cloud.google.com/compute/docs/instance-groups/configuring-stateful-disks-in-migs",
    items: {
      "ui:description": "Disk settings",
      "ui:displayfield": "device_name",
      device_name: {
        "ui:description": "Device name of the disk",
      },
      delete_rule: {
        "ui:description": "Delete rule for the disk",
      },
    },
  },
  stateful_ips: {
    "ui:description":
      "Statful IPs created on the instances that will be preserved on instance delete. https://cloud.google.com/compute/docs/instance-groups/configuring-stateful-ip-addresses-in-migs",
    items: {
      "ui:description": "Stateful IP settings",
      "ui:displayfield": "interface_name",
      interface_name: {
        "ui:description": "Interface name of the IP",
      },
      delete_rule: {
        "ui:description": "Delete rule for the IP",
      },
      is_external: {
        "ui:description": "Is the IP external",
      },
    },
  },
  update_policy: {
    "ui:description":
      "The rolling update policy. https://www.terraform.io/docs/providers/google/r/compute_region_instance_group_manager#rolling_update_policy",
    items: {
      "ui:description": "Policy settings",
      "ui:displayfield": "type",
      max_surge_fixed: {
        "ui:description":
          "The maximum number of instances that can be created above the specified targetSize during the update process.",
        "ui:emptyValue": null,
      },
      instance_redistribution_type: {
        "ui:description":
          "The instance redistribution policy for regional managed instance groups. Valid values are: PROACTIVE, NONE. Defaults to NONE.",
        "ui:emptyValue": null,
      },
      max_surge_percent: {
        "ui:description":
          "The maximum number of instances(calculated as percentage) that can be created above the specified targetSize during the update process.",
        "ui:emptyValue": null,
      },
      max_unavailable_fixed: {
        "ui:description":
          "The maximum number of instances that can be unavailable during the update process.",
        "ui:emptyValue": null,
      },
      max_unavailable_percent: {
        "ui:description":
          "The maximum number of instances(calculated as percentage) that can be unavailable during the update process.",
        "ui:emptyValue": null,
      },
      min_ready_sec: {
        "ui:description":
          "Minimum number of seconds to wait for after a newly created instance becomes available. This value must be from range [0, 3600]",
        "ui:emptyValue": null,
      },
      replacement_method: {
        "ui:description":
          'The instance replacement method for managed instance groups. Valid values are: "RECREATE", "SUBSTITUTE". If SUBSTITUTE (default), the group replaces VM instances with new instances that have randomly generated names. If RECREATE, instance names are preserved. You must also set max_unavailable_fixed or max_unavailable_percent to be greater than 0',
      },
      minimal_action: {
        "ui:descrption":
          "Minimal action to be taken on an instance. You must specify this field when creating a managed instance group. The default value is REPLACE. This field can take the following values: REPLACE, RESTART, REFRESH",
      },
      type: {
        "ui:description":
          "The type of update process. You must specify this field when creating a managed instance group. The default value is PROACTIVE. This field can take the following values: PROACTIVE, OPPORTUNISTIC",
      },
    },
  },
  health_check_name: {
    "ui:description":
      "Health check name. When variable is empty, name will be derived from var.hostname.",
    "ui:emptyValue": "",
  },
  health_check: {
    "ui:description":
      "Health check to determine whether instances are responsive and able to do work",
    type: {
      "ui:description": "Health check type",
      "ui:emptyValue": "",
    },
    initial_delay_sec: {
      "ui:description":
        "Number of seconds after instance comes up before health check is run first time",
    },
    check_interval_sec: {
      "ui:description": "Number of seconds between health checks",
    },
    healthy_threshold: {
      "ui:description":
        "Number of consecutive successful health checks before instance is declared healthy",
    },
    timeout_sec: {
      "ui:description":
        "Number of seconds to wait for instance to respond before health check fails",
    },
    unhealthy_threshold: {
      "ui:description":
        "Number of consecutive failed health checks before instance is declared unhealthy",
    },
    response: {
      "ui:description": "Response string from the instance considered healthy",
      "ui:emptyValue": "",
    },
    proxy_header: {
      "ui:description": "Header to send with health check request",
    },
    port: {
      "ui:description": "Port to use for health check",
    },
    request: {
      "ui:description": "Request string to send with health check request",
      "ui:emptyValue": "",
    },
    request_path: {
      "ui:description": "Path to use for health check",
      "ui:emptyValue": "",
    },
    host: {
      "ui:description": "Host header to send with health check request",
      "ui:emptyValue": "",
    },
    enable_logging: {
      "ui:description": "Enable logging for health check",
    },
  },
  wait_for_instances: {
    "ui:description":
      "Whether to wait for all instances to be created/updated before returning. Note that if this is set to true and the operation does not succeed, Terraform will continue trying until it times out.",
  },
  named_ports: {
    "ui:description":
      "Named name and named port. https://cloud.google.com/load-balancing/docs/backend-service#named_ports",
    items: {
      "ui:description": "Named port settings",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Name of the port",
      },
      port: {
        "ui:description": "Port number",
      },
    },
  },
  autoscaler_name: {
    "ui:description":
      "Autoscaler name. When variable is empty, name will be derived from var.hostname.",
    "ui:emptyValue": "",
  },
  autoscaling_enabled: {
    "ui:description": "Creates an autoscaler for the managed instance group",
  },
  max_replicas: {
    "ui:description":
      "The maximum number of instances that the autoscaler can scale up to. This is required when creating or updating an autoscaler. The maximum number of replicas should not be lower than minimal number of replicas.",
    "ui:emptyValue": 0,
  },
  min_replicas: {
    "ui:description":
      "The minimum number of replicas that the autoscaler can scale down to. This cannot be less than 0.",
    "ui:emptyValue": 0,
  },
  cooldown_period: {
    "ui:description":
      "The number of seconds that the autoscaler should wait before it starts collecting information from a new instance.",
  },
  autoscaling_mode: {
    "ui:description":
      "Operating mode of the autoscaling policy. If omitted, the default value is ON. https://registry.terraform.io/providers/hashicorp/google/latest/docs/resources/compute_autoscaler#mode",
    "ui:emptyValue": "ON",
  },
  autoscaling_cpu: {
    "ui:description":
      "Autoscaling, cpu utilization policy block as single element array. https://www.terraform.io/docs/providers/google/r/compute_autoscaler#cpu_utilization",
    items: {
      "ui:description": "Autoscaling, cpu utilization policy block",
      "ui:displayfield": "target",
      target: {
        "ui:description": "Target CPU utilization.",
      },
      predictive_method: {
        "ui:description": "The predictive method for future CPU utilization.",
      },
    },
  },
  autoscaling_metric: {
    "ui:description":
      "Autoscaling, metric policy block as single element array. https://www.terraform.io/docs/providers/google/r/compute_autoscaler#metric",
    items: {
      "ui:description": "Autoscaling, metric policy block",
      "ui:displayfield": "name",
      name: {
        "ui:description": "The name of the metric.",
      },
      target: {
        "ui:description":
          "The target value of the metric that autoscaler should maintain.",
      },
      type: {
        "ui:description": "The type of metric.",
      },
    },
  },
  autoscaling_lb: {
    "ui:description":
      "Autoscaling, load balancing utilization policy block as single element array. https://www.terraform.io/docs/providers/google/r/compute_autoscaler#load_balancing_utilization",
    items: {
      "ui:description": "Autoscaling load balancing utilization policy block",
      "ui:displayfield": "target",
      target: {
        "ui:description": "Target load balancing utilization.",
      },
    },
  },
  scaling_schedules: {
    "ui:description":
      "Autoscaling, scaling schedule block. https://registry.terraform.io/providers/hashicorp/google/latest/docs/resources/compute_autoscaler#scaling_schedules",
    items: {
      "ui:description": "Scaling schedule block",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Name of the scaling schedule",
      },
      min_required_replicas: {
        "ui:description":
          "Minimum number of replicas to be set at this schedule",
      },
      schedule: {
        "ui:description": "The start time of the window.",
      },
      duration_sec: {
        "ui:description": "Duration of the time window, in seconds.",
      },
      time_zone: {
        "ui:description": "The timezone to use when specifying a time.",
      },
      disabled: {
        "ui:description": "Whether the scaling schedule is disabled.",
      },
    },
  },
  autoscaling_scale_in_control: {
    "ui:description":
      "Autoscaling, scale-in control block. https://www.terraform.io/docs/providers/google/r/compute_autoscaler#scale_in_control",
    fixed_replicas: {
      "ui:description":
        "Specifies a fixed number of VM instances. This must be a positive integer.",
      "ui:emptyValue": null,
    },
    percent_replicas: {
      "ui:description":
        "Specifies a percentage of instances between 0 to 100%, inclusive. For example, specify 80 for 80%.",
      "ui:emptyValue": null,
    },
    time_window_sec: {
      "ui:description":
        "How long back autoscaling should look when computing recommendations to include directives regarding slower scale down, as described above.",
      "ui:emptyValue": null,
    },
  },
  mig_timeouts: {
    "ui:description":
      "Times for creation, deleting and updating the MIG resources. Can be helpful when using wait_for_instances to allow a longer VM startup time. ",
    create: {
      "ui:description": "Timeout for creating the MIG",
      "ui:emptyValue": "5m",
    },
    update: {
      "ui:description": "Timeout for updating the MIG",
      "ui:emptyValue": "5m",
    },
    delete: {
      "ui:description": "Timeout for deleting the MIG",
      "ui:emptyValue": "15m",
    },
  },
};
