export const awsIAMassumablePredefinedRolesSchema = {
  title: "Assumable IAM Roles",
  type: "object",
  properties: {
    name: {
      title: "Name",
      type: "string",
    },
    trusted_role_actions: {
      title: "Trusted Role Actions",
      type: "array",
      items: {
        type: "string",
        title: "Trusted Role Action",
      },
      default: ["sts:AssumeRole", "sts:TagSession"],
    },
    trusted_role_arns: {
      title: "Trusted Role ARNs",
      type: "array",
      items: {
        type: "string",
        title: "Trusted Role ARN",
      },
      default: [],
    },
    trusted_role_services: {
      title: "Trusted Role Services",
      type: "array",
      items: {
        title: "Trusted Role Service",
        type: "string",
      },
      default: [],
    },
    mfa_age: {
      title: "MFA Age",
      type: "number",
      default: 86400,
    },
    allow_self_assume_role: {
      title: "Allow Self Assume Role",
      type: "boolean",
      default: false,
    },
    create_admin_role: {
      title: "Create Admin Role",
      type: "boolean",
      default: false,
    },
    admin_role_name: {
      title: "Admin Role Name",
      type: "string",
      default: "admin",
    },
    admin_role_path: {
      title: "Admin Role Path",
      type: "string",
      default: "/",
    },
    admin_role_requires_mfa: {
      title: "Admin Role Requires MFA",
      type: "boolean",
      default: true,
    },
    admin_role_policy_arns: {
      title: "Admin Role Policy ARNs",
      type: "array",
      items: {
        type: "string",
        title: "Admin Role Policy ARN",
      },
      default: ["arn:aws:iam::aws:policy/AdministratorAccess"],
    },
    admin_role_permissions_boundary_arn: {
      title: "Admin Role Permissions Boundary ARN",
      type: "string",
      default: "",
    },
    admin_role_tags: {
      title: "Admin Role Tags",
      description: "A simple form with additional properties example.",
      type: "object",
      additionalProperties: {
        type: "string",
        title: "Admin Role Tag Value",
      },
      default: {},
    },
    create_poweruser_role: {
      title: "Create Poweruser Role",
      type: "boolean",
      default: false,
    },
    poweruser_role_name: {
      title: "Poweruser Role Name",
      type: "string",
      default: "poweruser",
    },
    poweruser_role_path: {
      title: "Poweruser Role Path",
      type: "string",
      default: "/",
    },
    poweruser_role_requires_mfa: {
      title: "Poweruser Role Requires MFA",
      type: "boolean",
      default: true,
    },
    poweruser_role_policy_arns: {
      title: "Poweruser Role Policy ARNs",
      type: "array",
      items: {
        type: "string",
        title: "Poweruser Role Policy ARN",
      },
      default: ["arn:aws:iam::aws:policy/PowerUserAccess"],
    },
    poweruser_role_permissions_boundary_arn: {
      title: "Poweruser Role Permissions Boundary ARN",
      type: "string",
      default: "",
    },
    poweruser_role_tags: {
      title: "Poweruser Role Tags",
      type: "object",
      additionalProperties: {
        type: "string",
        title: "Poweruser Role Tag Value",
      },
      default: {},
    },
    create_readonly_role: {
      title: "Create Readonly Role",
      type: "boolean",
      default: false,
    },
    readonly_role_name: {
      title: "Readonly Role Name",
      type: "string",
      default: "readonly",
    },
    readonly_role_path: {
      title: "Readonly Role Path",
      type: "string",
      default: "/",
    },
    readonly_role_requires_mfa: {
      title: "Readonly Role Requires MFA",
      type: "boolean",
      default: true,
    },
    readonly_role_policy_arns: {
      title: "Readonly Role Policy ARNs",
      type: "array",
      items: {
        type: "string",
        title: "Readonly Role Policy ARN",
      },
      default: ["arn:aws:iam::aws:policy/ReadOnlyAccess"],
    },
    readonly_role_permissions_boundary_arn: {
      title: "Readonly Role Permissions Boundary ARN",
      type: "string",
      default: "",
    },
    readonly_role_tags: {
      title: "Readonly Role Tags",
      type: "object",
      additionalProperties: {
        type: "string",
        title: "Readonly Role Tag Value",
      },
      default: {},
    },
    max_session_duration: {
      title: "Max Session Duration",
      type: "number",
      default: 3600,
    },
    force_detach_policies: {
      title: "Force Detach Policies",
      type: "boolean",
      default: false,
    },
  },
  required: ["name"],
};

export const awsIAMassumablePredefinedRolesUISchema = {
  "ui:description": "AWS IAM Assumable Roles Settings",
  name: {
    "ui:description": "Name of the module",
  },
  trusted_role_actions: {
    "ui:description": "Additional trusted role actions",
    items: {
      "ui:description": "Trusted role action",
    },
  },
  trusted_role_arns: {
    "ui:description": "ARNs of AWS entities who can assume these roles",
    items: {
      "ui:description": "Trusted role ARN",
    },
  },
  trusted_role_services: {
    "ui:description": "AWS Services that can assume these roles",
    items: {
      "ui:description": "Trusted role service",
    },
  },
  mfa_age: {
    "ui:description":
      "Max age of valid MFA (in seconds) for roles which require MFA",
  },
  allow_self_assume_role: {
    "ui:description":
      "Determines whether to allow the role to be [assume itself](https://aws.amazon.com/blogs/security/announcing-an-update-to-iam-role-trust-policy-behavior/)",
  },
  create_admin_role: {
    "ui:description": "Whether to create admin role",
  },
  admin_role_name: {
    "ui:description": "IAM role with admin access",
  },
  admin_role_path: {
    "ui:description": "Path of admin IAM role",
  },
  admin_role_requires_mfa: {
    "ui:description": "Whether admin role requires MFA",
  },
  admin_role_policy_arns: {
    "ui:description": "List of policy ARNs to use for admin role",
    items: {
      "ui:description": "Admin role policy ARN",
    },
  },
  admin_role_permissions_boundary_arn: {
    "ui:description": "Permissions boundary ARN to use for admin role",
  },
  admin_role_tags: {
    "ui:description": "A map of tags to add to admin role resource.",
  },
  create_poweruser_role: {
    "ui:description": "Whether to create poweruser role",
  },
  poweruser_role_name: {
    "ui:description": "IAM role with poweruser access",
  },
  poweruser_role_path: {
    "ui:description": "Path of poweruser IAM role",
  },
  poweruser_role_requires_mfa: {
    "ui:description": "Whether poweruser role requires MFA",
  },
  poweruser_role_policy_arns: {
    "ui:description": "List of policy ARNs to use for poweruser role",
    items: {
      "ui:description": "Poweruser role policy ARN",
    },
  },
  poweruser_role_permissions_boundary_arn: {
    "ui:description": "Permissions boundary ARN to use for poweruser role",
  },
  poweruser_role_tags: {
    "ui:description": "A map of tags to add to poweruser role resource.",
  },
  create_readonly_role: {
    "ui:description": "Whether to create readonly role",
  },
  readonly_role_name: {
    "ui:description": "IAM role with readonly access",
  },
  readonly_role_path: {
    "ui:description": "Path of readonly IAM role",
  },
  readonly_role_requires_mfa: {
    "ui:description": "Whether readonly role requires MFA",
  },
  readonly_role_policy_arns: {
    "ui:description": "List of policy ARNs to use for readonly role",
    items: {
      "ui:description": "Readonly role policy ARN",
    },
  },
  readonly_role_permissions_boundary_arn: {
    "ui:description": "Permissions boundary ARN to use for readonly role",
  },
  readonly_role_tags: {
    "ui:description": "A map of tags to add to readonly role resource.",
  },
  max_session_duration: {
    "ui:description":
      "Maximum CLI/API session duration in seconds between 3600 and 43200",
  },
  force_detach_policies: {
    "ui:description":
      "Whether policies should be detached from this role when destroying",
  },
};
