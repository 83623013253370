import SignInSide from "./components/SigninSide";
import SignUp from "./components/SignUp";
import Dashboard from "./components/Dashboard";
import { Navigate } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import "./App.css";
import { useAuth } from "./contexts/AuthContext";
import { CssBaseline } from "@mui/material";

function App() {
  const { currentUser, theme } = useAuth();
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline>
          <Router>
            <Routes>
              <Route path="/" element={<SignInSide />} />
              <Route path="/login" element={<SignInSide />} />
              <Route path="/signup" element={<SignUp />} />
              {currentUser && (
                <Route path="/dashboard/*" element={<Dashboard />} />
              )}
              <Route path={"*"} element={<Navigate replace to={"/"} />} />
            </Routes>
          </Router>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
