export const projectIAMSchema = {
  title: "Define permissions for the Project",
  type: "object",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    mode: {
      title: "Mode for adding the IAM policies/bindings",
      type: "string",
      enum: ["additive", "authoritative"],
      default: "additive",
    },
    permissions: {
      type: "array",
      title: "List of Permissions",
      items: {
        properties: {
          role: {
            title: "Role",
            type: "string",
            examples: ["roles/editor", "roles/owner"],
          },
          personas: {
            type: "array",
            title: "List of personas to which the role will be granted",
            items: {
              type: "string",
              title: "Persona",
            },
          },
        },
        required: ["role"],
      },
    },
  },
  required: ["name"],
};

export const projectIAMSchemaUI = {
  "ui:description": "Project IAM Settings",
  permissions: {
    "ui:description":
      "Grant permissions to a role with the list of personas. Press the + button to add a new binding",
    items: {
      "ui:description": "Map of role to list of personas",
      "ui:displayfield": "role",
      role: {
        "ui:description": "For example roles/editor or roles/owner",
      },
      personas: {
        items: {
          "ui:description":
            "For example user:jack@example.com, serviceAccount@project-id.gserviceaccount.com, group:admins@example.com",
        },
      },
    },
  },
};
