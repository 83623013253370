import { React } from "react";
import { Box, IconButton, Tooltip } from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";

export function QueueResourceButtonGroup({ node, sendNodeUpstream }) {
  const sendCurrentNodeUpstream = (event) => {
    event.preventDefault();
    event.stopPropagation();
    sendNodeUpstream(node);
  };

  return (
    <Box
      sx={{
        display: "flex",
        margin: 0,
        alignItems: "center",
      }}
    >
      <Tooltip title="Module Config">
        <span>
          <IconButton
            variant="contained"
            color="primary"
            onClick={sendCurrentNodeUpstream}
            size="small"
          >
            <MoreHoriz />
          </IconButton>
        </span>
      </Tooltip>
    </Box>
  );
}
export default QueueResourceButtonGroup;
