export const awsSecurityGroupSchema = {
  type: "object",
  properties: {
    name: {
      title: "name",
      type: "string",
    },
    use_name_prefix: {
      title: "Use Name Prefix",
      type: "boolean",
      default: true,
    },
    description: {
      title: "description",
      type: "string",
      default: "Security Group managed by Terraform",
    },
    revoke_rules_on_delete: {
      title: "Revoke Rules on delete",
      type: "boolean",
      default: false,
    },
    tags: {
      title: "tags",
      type: "object",
      additionalProperties: {
        type: "string",
      },
      default: {
        tagkey: "tagvalue",
      },
    },
    ingress_prefix_list_ids: {
      title: "Ingress Prefix List IDs",
      type: "array",
      items: {
        type: "string",
        title: "Prefix ID",
      },
      default: [],
    },

    ingress_with_cidr_blocks: {
      title: "Ingress Firewall Rules",
      type: "array",
      items: {
        type: "object",
        title: "Firewall Ingress Rule",
        properties: {
          description: {
            title: "description",
            type: "string",
          },
          cidr_blocks: {
            title: "CIDR blocks",
            type: "string",
            default: "",
          },
          from_port: {
            title: "from port",
            type: "number",
            default: 0,
          },
          to_port: {
            title: "to port",
            type: "number",
            default: 0,
          },
          protocol: {
            title: "protocol",
            type: "string",
            enum: ["tcp", "udp", "icmp", "icmpv6", "all"],
            default: "all",
          },
        },
        required: ["description"],
      },
      default: [],
    },
    ingress_with_ipv6_cidr_blocks: {
      title: "Ingress Firewall Rules with IPv6 CIDR blocks",
      type: "array",
      items: {
        type: "object",
        title: "Firewall Ingress Rule",
        properties: {
          description: {
            title: "description",
            type: "string",
          },
          ipv6_cidr_blocks: {
            title: "CIDR blocks",
            type: "string",
            default: "",
          },
          from_port: {
            title: "from port",
            type: "number",
            default: 0,
          },
          to_port: {
            title: "to port",
            type: "number",
            default: 0,
          },
          protocol: {
            title: "protocol",
            type: "string",
            enum: ["tcp", "udp", "icmp", "icmpv6", "all"],
            default: "all",
          },
        },
        required: ["description"],
      },
      default: [],
    },

    egress_prefix_list_ids: {
      title: "Egress prefix list IDs",
      type: "array",
      items: {
        type: "string",
        title: "Prefix ID",
      },
      default: [],
    },
    egress_with_cidr_blocks: {
      title: "Egress Firewall Rules",
      type: "array",
      items: {
        type: "object",
        title: "Firewall Egress Rule",
        properties: {
          description: {
            title: "description",
            type: "string",
          },
          cidr_blocks: {
            title: "CIDR blocks",
            type: "string",
            default: "",
          },
          from_port: {
            title: "from port",
            type: "number",
            default: 0,
          },
          to_port: {
            title: "to port",
            type: "number",
            default: 0,
          },
          protocol: {
            title: "protocol",
            type: "string",
            enum: ["tcp", "udp", "icmp", "icmpv6", "all"],
            default: "all",
          },
        },
        required: ["description"],
      },
      default: [],
    },
    egress_with_ipv6_cidr_blocks: {
      title: "Egress Firewall Rules with IPv6 CIDR blocks",
      type: "array",
      items: {
        type: "object",
        title: "Firewall Egress Rule",
        properties: {
          description: {
            title: "description",
            type: "string",
          },
          ipv6_cidr_blocks: {
            title: "CIDR blocks",
            type: "string",
            default: "",
          },
          from_port: {
            title: "from port",
            type: "number",
            default: 0,
          },
          to_port: {
            title: "to port",
            type: "number",
            default: 0,
          },
          protocol: {
            title: "protocol",
            type: "string",
            enum: ["tcp", "udp", "icmp", "icmpv6", "all"],
            default: "all",
          },
        },
        required: ["description"],
      },
      default: [],
    },
    create_timeout: {
      title: "create timeout",
      type: "string",
      default: "10m",
    },
    delete_timeout: {
      title: "delete timeout",
      type: "string",
      default: "15m",
    },
  },
  required: ["name"],
};

export const awsSecurityGroupUISchema = {
  "ui:description": "AWS Security Group Settings",
  name: {
    "ui:description":
      "Name of security group - not required if create_sg is false",
  },
  use_name_prefix: {
    "ui:description":
      "Whether to use name_prefix or fixed name. Should be true to able to update security group name after initial creation",
  },
  description: {
    "ui:description": "Description of security group",
  },
  revoke_rules_on_delete: {
    "ui:description":
      "Instruct Terraform to revoke all of the Security Groups attached ingress and egress rules before deleting the rule itself. Enable for EMR.",
  },
  tags: {
    "ui:description": "A mapping of tags to assign to security group",
  },
  create_timeout: {
    "ui:description": "Time to wait for a security group to be created",
  },
  delete_timeout: {
    "ui:description": "Time to wait for a security group to be deleted",
  },
  ingress_with_cidr_blocks: {
    "ui:description": "List of ingress rules",
    items: {
      "ui:description": "Firewall Ingress Rule",
      "ui:displayfield": "description",
      cidr_blocks: {
        "ui:description":
          "Comma separated list of IPv4 CIDR ranges. Don't use spaces after commas.",
      },
      from_port: {
        "ui:description":
          "The start port (or ICMP type number if protocol is 'icmp')",
      },
      to_port: {
        "ui:description": "The end port (or ICMP code if protocol is 'icmp')",
      },
      protocol: {
        "ui:description":
          "The protocol. If not icmp, tcp, udp, or all use the protocol number",
      },
      description: {
        "ui:description": "Description of rule",
      },
    },
  },
  ingress_with_ipv6_cidr_blocks: {
    "ui:description":
      "List of ingress rules to create where IPv6 CIDR blocks are used",
    items: {
      "ui:description": "Firewall Ingress Rule",
      "ui:displayfield": "description",
      ipv6_cidr_blocks: {
        "ui:description":
          "Comma separate list of IPv6 CIDR ranges.  Don't use spaces after commas.",
      },
      from_port: {
        "ui:description":
          "The start port (or ICMP type number if protocol is 'icmp')",
      },
      to_port: {
        "ui:description": "The end port (or ICMP code if protocol is 'icmp')",
      },
      protocol: {
        "ui:description":
          "The protocol. If not icmp, tcp, udp, or all use the protocol number",
      },
      description: {
        "ui:description": "Description of rule",
      },
    },
  },
  ingress_prefix_list_ids: {
    "ui:description":
      "List of prefix list IDs (for allowing access to VPC endpoints) to use on all ingress rules",
    items: {
      "ui:description": "Prefix ID",
    },
  },
  egress_with_cidr_blocks: {
    "ui:description": "List of egress rules to create.",
    items: {
      "ui:description": "Firewall Egress Rule",
      "ui:displayfield": "description",
      cidr_blocks: {
        "ui:description":
          "Comma separated list of IPv4 CIDR ranges.  Don't use spaces after commas.",
      },
      from_port: {
        "ui:description":
          "The start port (or ICMP type number if protocol is 'icmp')",
      },
      to_port: {
        "ui:description": "The end port (or ICMP code if protocol is 'icmp')",
      },
      protocol: {
        "ui:description":
          "The protocol. If not icmp, tcp, udp, or all use the protocol number",
      },
      description: {
        "ui:description": "Description of rule",
      },
    },
  },
  egress_with_ipv6_cidr_blocks: {
    "ui:description": "List of egress rules to create  for IPV6 CIDR blocks",
    items: {
      "ui:description": "Firewall Egress Rule",
      "ui:displayfield": "description",
      ipv6_cidr_blocks: {
        "ui:description":
          "Comma separated list of IPv6 CIDR ranges.  Don't use spaces after commas.",
      },
      from_port: {
        "ui:description":
          "The start port (or ICMP type number if protocol is 'icmp')",
      },
      to_port: {
        "ui:description": "The end port (or ICMP code if protocol is 'icmp')",
      },
      protocol: {
        "ui:description":
          "The protocol. If not icmp, tcp, udp, or all use the protocol number",
      },
      description: {
        "ui:description": "Description of rule",
      },
    },
  },
  egress_prefix_list_ids: {
    "ui:description":
      "List of prefix list IDs (for allowing access to VPC endpoints) to use on all egress rules",
    items: {
      "ui:description": "Prefix ID",
    },
  },
};
