export const databricksMetaStoreGrantsSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
      description: "Name of the module",
    },
    grants: {
      type: "array",
      description: "List of grants",
      items: {
        type: "object",
        properties: {
          principal: {
            type: "string",
            description:
              "User name, group name or service principal application ID",
          },
          privileges: {
            type: "array",
            description:
              "One or more privileges that are specific to a securable type.",
            items: {
              type: "string",
              description: "Privilege to grant to the principal",
              enum: [
                "CREATE_CATALOG",
                "CREATE_CONNECTION",
                "CREATE_EXTERNAL_LOCATION",
                "CREATE_PROVIDER",
                "CREATE_RECIPIENT",
                "CREATE_SHARE",
                "SET_SHARE_PERMISSION",
                "USE_MARKETPLACE_ASSETS",
                "USE_CONNECTION",
                "USE_PROVIDER",
                "USE_RECIPIENT",
                "USE_SHARE",
              ],
            },
          },
        },
        required: ["principal"],
      },
    },
  },
  required: ["name"],
};

export const databricksMetaStoreGrantsUISchema = {
  "ui:description": "Databricks Metastore Grants",
  grants: {
    "ui:description": "List of grants",
    items: {
      "ui:displayfield": "principal",
      "ui:description": "Principal to grant privileges to.",
      privileges: {
        "ui:description":
          "One or more privileges that are specific to a securable type.",
        items: {
          "ui:description": "Privilege to grant to the principal",
        },
      },
    },
  },
};
