export const serviceAccountsSchema = {
  title: "Service Accounts",
  type: "object",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    names: {
      type: "array",
      title: "Names of the service accounts to be created in the project",
      items: {
        type: "string",
        title: "service-account-name",
      },
      default: ["gke-sa", "gce-sa", "gcs-sa", "pubsub-sa"],
    },
  },
  required: ["names", "name"],
};

export const serviceAccountsSchemaUI = {
  "ui:description": "Service Accounts to be created in the project",
  name: {
    "ui:description": "The name for the module",
  },
  names: {
    "ui:description":
      "Names of the service accounts to be created in the project",
    items: {
      "ui:description": "Name of the service account",
    },
  },
};
