import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ReactSpeedometer from "react-d3-speedometer";
import { useAuth } from "../contexts/AuthContext";
import { apiCall } from "../utils/api";
import {
  CardHeader,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Box,
  TableBody,
  Collapse,
  TableContainer,
  Paper,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useSnackbar } from "notistack";

export default function Overview() {
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [statistics, setStatistics] = useState([
    {
      name: "",
      total_architetures: 0,
      total_deployed_modules: 0,
      max_modules: 100,
      architectures: [
        {
          name: "",
          modules: [
            {
              name: "",
              type: "",
              resources: [],
            },
          ],
        },
      ],
    },
  ]);

  const getStatistics = async () => {
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/overview/stats`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error retrieving statistics", {
        variant: "error",
      });
    }
    if (res.status === 200) {
      const stats = await res.json();
      setStatistics(stats);
    }
  };

  useEffect(() => {
    getStatistics();
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container spacing={2}>
      {statistics.map((stat, index) => (
        <Gauges stat={stat} key={index} />
      ))}

      {statistics.map((stat, index1) =>
        stat.architectures.map((arch, index2) => (
          <ResourceUsage
            org_name={stat.name}
            architecture_name={arch.name}
            modules={arch.modules}
            key={index1 + index2}
          />
        ))
      )}
    </Grid>
  );
}

export function Gauges({ stat }) {
  return (
    <>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent align="center">
            <ReactSpeedometer
              startColor="#33CC33"
              endColor="#FF0000"
              value={stat.total_deployed_modules}
              maxValue={stat.max_modules}
              forceRender={true}
              height={200}
            />
            <Typography variant="h6" gutterBottom>
              Total Deployed Modules in
            </Typography>
            <Typography variant="h6" gutterBottom>
              {stat.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Card>
          <CardContent align="center">
            <ReactSpeedometer
              value={stat.total_architectures}
              startColor="#33CC33"
              endColor="#FF0000"
              maxValue={50}
              height={200}
            />
            <Typography variant="h6" gutterBottom>
              Environments in
            </Typography>
            <Typography variant="h6" gutterBottom>
              {stat.name}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export function ResourceUsage({ org_name, architecture_name, modules }) {
  const totalResources = () => {
    let total = 0;
    modules.forEach((module) => {
      total += module.resources.length;
    });
    return total;
  };
  return (
    <Grid item xs={12} sm={6}>
      <Card>
        <CardHeader
          title={`Architecture: ${architecture_name}`}
          subheader={`Organization: ${org_name}`}
        />
        <CardContent>
          <CollapsibleTable modules={modules} />
        </CardContent>
        <Typography variant="subtitle1" sx={{ m: 1 }}>
          Total Terraform Resources: {totalResources()}
        </Typography>
      </Card>
    </Grid>
  );
}

export function CollapsibleTable({ modules }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell align="left">Module Name</TableCell>
            <TableCell align="right">ModuleType</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {modules.map((module, index) => (
            <Row
              key={index}
              module_name={module.name}
              module_type={module.type}
              resources={module.resources}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function Row({ module_name, module_type, resources }) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell align="left">{module_name}</TableCell>
        <TableCell align="right">{module_type}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Terraform Resource</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resources.map((resource) => (
                    <TableRow key={resource}>
                      <TableCell>{resource}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
