export const publicIPSchema = {
  title: "Public IP Reservation",
  type: "object",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    names: {
      title: "IP Names",
      type: "array",
      items: {
        type: "string",
      },
    },
    ip_version: {
      type: "string",
      title: "IP Version",
      enum: ["IPv4", "IPv6"],
      default: "IPv4",
    },
  },
  required: ["name"],
};

export const publicIPSchemaUI = {
  "ui:description": "Reserve a public IP address",
  name: {
    "ui:description": "Module name",
  },
  names: {
    "ui:description": "List of IP names",
    items: {
      "ui:description": "IP name",
    },
  },
  ip_version: {
    "ui:description": "IP version",
  },
};
