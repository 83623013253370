import { React, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  Backdrop,
  CircularProgress,
  IconButton,
  Tooltip,
} from "@mui/material";
import {
  PlayArrow,
  MoreHoriz,
  Remove as RemoveIcon,
  Add as AddIcon,
  Clear as ClearIcon,
  Description,
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import TFplanViewer from "./TFplanViewer";
import { gcpResourceConfigs, getType } from "./gcpResourceDefintions";
import { useAuth } from "../contexts/AuthContext";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { apiCall, executeTFcommand } from "../utils/api";
import { removeModuleStatus } from "../utils/db";

function GCPResourceButtonGroup({
  node,
  refreshNodes,
  sendNodeUpstream,
  removeNode,
  organizationId,
  environmentId,
  status,
  parentStatus,
}) {
  const { currentUser } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTFplanViewer, setOpenTFplanViewer] = useState(false);
  const [openTFdestroyViewer, setOpenTFdestroyViewer] = useState(false);
  const [openTFstateViewer, setOpenTFstateViewer] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [tfPlan, setTfPlan] = useState("");
  const [tfDestroy, setTfdestroy] = useState("");
  const [tfstate, setTfstate] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const openMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePlan = async (event) => {
    // Must clone node because the architecture tree can get messed up
    let clone_node = { ...node };
    setOpenBackdrop(true);

    await executeTFcommand(
      "plan",
      //access_token,
      organizationId,
      environmentId,
      clone_node,
      currentUser,
      enqueueSnackbar,
      setOpenBackdrop
    );
  };

  const handleApply = async (event) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/plan/${organizationId}/${environmentId}/${node.id}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error fetching terraform plan", { variant: "error" });
    }
    let a = await res.text();
    setTfPlan(a);
    setOpenBackdrop(false);
    setOpenTFplanViewer(true);
  };

  const handleApplyConfirm = async (event) => {
    //let access_token = await getAccessToken();
    // Must clone node because the architecture tree can get messed up
    let clone_node = { ...node };
    // if (access_token === null) {
    //   setLastCommand({
    //     command: "apply",
    //     clone_node,
    //     organizationId,
    //     environmentId,
    //     currentUser,
    //   });
    //   signInWithGoogle();
    //   return;
    // }
    setOpenBackdrop(true);
    await executeTFcommand(
      "apply",
      //access_token,
      organizationId,
      environmentId,
      clone_node,
      currentUser,
      enqueueSnackbar,
      setOpenBackdrop
    );
    setOpenTFplanViewer(false);
  };

  const handleDestroy = async (event) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/destroy/${organizationId}/${environmentId}/${node.id}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error fetching current state", { variant: "error" });
    }
    let a = await res.text();
    setTfdestroy(a);
    setOpenBackdrop(false);
    setOpenTFdestroyViewer(true);
  };

  const handleDestroyConfirm = async (event) => {
    // Must clone node because the architecture tree can get messed up
    let clone_node = { ...node };
    // //let access_token = await getAccessToken();
    // if (access_token === null) {
    //   signInWithGoogle();
    //   setLastCommand({
    //     command: "destroy",
    //     clone_node,
    //     organizationId,
    //     environmentId,
    //     currentUser,
    //   });
    //   return;
    // }

    await executeTFcommand(
      "destroy",
      //access_token,
      organizationId,
      environmentId,
      clone_node,
      currentUser,
      enqueueSnackbar,
      setOpenBackdrop
    );
    setOpenTFdestroyViewer(false);
  };

  const handleCloseTFplanViewer = () => {
    setOpenTFplanViewer(false);
  };

  const handleCloseTFdestroyViewer = () => {
    setOpenTFdestroyViewer(false);
  };

  const handleRemove = (event) => {
    removeModuleStatus(organizationId, environmentId, node.id);
    removeNode(node);
  };

  const allowedToAdd = (item) => {
    // This function checks if there is already one of the onlyOneAllowedType in node.children
    const onlyOneAllowedType = [
      "orgIam",
      "folderIam",
      "projectIam",
      "serviceAccounts",
      "firewalls",
      "publicIPs",
    ];
    let itemType = getType(item);
    if (!onlyOneAllowedType.includes(itemType)) {
      return true;
    }
    for (let child of node.children) {
      if (child.type === itemType) {
        return false;
      }
    }
    return true;
  };

  const insertNode = async (node, itemValue, id) => {
    setOpenBackdrop(true);
    const parent_new_children = node.children.map((child) => ({
      id: child.id,
      key: child.id,
      type: child.type,
    }));
    parent_new_children.push({
      id: id,
      key: id,
      type: getType(itemValue),
    });
    parent_new_children.sort((a, b) => {
      return (
        gcpResourceConfigs[a.type].priority -
        gcpResourceConfigs[b.type].priority
      );
    });
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/environment/insert/node/${organizationId}/${environmentId}/${id}`,
      "POST",
      {
        type: getType(itemValue),
        parent: {
          type: node.type,
          id: node.id,
          key: node.id,
        },
        id: id,
        key: id,
        children: [],
        priority: gcpResourceConfigs[getType(itemValue)].priority,
        properties: {},
        parent_new_children: parent_new_children,
      },
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error inserting the node", { variant: "error" });
    }
    setOpenBackdrop(false);
  };
  const handleAddResource = async (e) => {
    e.preventDefault();
    const { itemValue } = e.currentTarget.dataset;
    if (!itemValue) {
      setAnchorEl(null);
      return;
    }
    if (allowedToAdd(itemValue)) {
      const id = uuidv4();
      await insertNode(node, itemValue, id);
      refreshNodes();
    } else {
      alert(`You can specify only one ${itemValue} in the parent`);
    }
    setAnchorEl(null);
  };

  const sendCurrentNodeUpstream = (event) => {
    event.preventDefault();
    event.stopPropagation();
    sendNodeUpstream(node);
  };

  const handleGetTFState = async (event) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/destroy/${organizationId}/${environmentId}/${node.id}`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error fetching current state", { variant: "error" });
    }
    let a = await res.text();
    setTfstate(a);
    setOpenBackdrop(false);
    setOpenTFstateViewer(true);
  };

  const isParentDeployedOrParentIsSource = () => {
    if (node.type === "organization") {
      return true;
    }
    if (isNodeDataSource(node.parent.type)) {
      return true;
    }
    if (parentStatus) {
      return parentStatus.deployed;
    }
    return false;
  };

  const isNodeDataSource = (node_type) => {
    const { isDataSource } = gcpResourceConfigs[node_type];
    if (isDataSource) {
      return isDataSource;
    }
    return false;
  };

  return (
    <Box
      sx={{
        display: "flex",
        margin: 0,
        alignItems: "center",
      }}
    >
      <Tooltip title="Module Config">
        <span>
          <IconButton
            variant="contained"
            color="primary"
            onClick={sendCurrentNodeUpstream}
            size="small"
          >
            <MoreHoriz />
          </IconButton>
        </span>
      </Tooltip>
      {!isNodeDataSource(node.type) && (
        <Tooltip title="Plan">
          <span>
            <IconButton
              variant="contained"
              color="primary"
              size="small"
              onClick={handlePlan}
              disabled={!isParentDeployedOrParentIsSource()}
            >
              <PlayArrow />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!isNodeDataSource(node.type) && (
        <Tooltip title="Apply">
          <span>
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleApply}
              size="small"
              disabled={
                !(
                  status.status === "PLAN-SUCCESS" ||
                  status.status === "APPLY-SUCCESS" ||
                  status.status === "APPLY-AUTO-SUCCESS" ||
                  status.status === "APPLY-FAILURE" ||
                  status.status === "DESTROY-FAILURE"
                )
              }
            >
              <CheckIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {!isNodeDataSource(node.type) && (
        <Tooltip title="Destroy">
          <span>
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleDestroy}
              size="small"
              disabled={
                !(
                  status.status === "APPLY-SUCCESS" ||
                  status.status === "APPLY-AUTO-SUCCESS" ||
                  status.status === "DESTROY-FAILURE"
                ) || node.children.length !== 0
              }
            >
              <ClearIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {gcpResourceConfigs[node.type].allowedChildren.length !== 0 && (
        <Tooltip title="Add Resource">
          <IconButton
            variant="contained"
            color="primary"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={openMenu}
            size="small"
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      )}
      {node.children.length === 0 && node.type !== "organization" && (
        <Tooltip title="Remove Resource">
          <span>
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleRemove}
              size="small"
              disabled={status.deployed}
            >
              <RemoveIcon />
            </IconButton>
          </span>
        </Tooltip>
      )}
      {node.type !== "organization" &&
        node.type !== "project_source" &&
        status.deployed && (
          <Tooltip title="Terrform State">
            <IconButton
              variant="contained"
              color="primary"
              onClick={handleGetTFState}
              size="small"
            >
              <Description />
            </IconButton>
          </Tooltip>
        )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {gcpResourceConfigs[node.type].allowedChildren.map((option) => (
          <MenuItem
            key={option}
            onClick={handleAddResource}
            data-item-value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 5000,
        }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
      <TFplanViewer
        open={openTFplanViewer}
        handleClose={handleCloseTFplanViewer}
        node={node}
        plan={tfPlan}
        action="apply"
        handleConfirm={handleApplyConfirm}
      />
      <TFplanViewer
        open={openTFdestroyViewer}
        handleClose={handleCloseTFdestroyViewer}
        node={node}
        plan={tfDestroy}
        action="destroy"
        handleConfirm={handleDestroyConfirm}
      />
      <TFplanViewer
        open={openTFdestroyViewer}
        handleClose={handleCloseTFdestroyViewer}
        node={node}
        plan={tfDestroy}
        action="destroy"
        handleConfirm={handleDestroyConfirm}
      />
      <TFplanViewer
        open={openTFstateViewer}
        handleClose={() => setOpenTFstateViewer(false)}
        node={node}
        plan={tfstate}
        action={null}
        handleConfirm={() => setOpenTFstateViewer(false)}
      />
    </Box>
  );
}
export default GCPResourceButtonGroup;
