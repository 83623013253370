export const httpsLBSchema = {
  title: "External HTTP(S) Load Balancer",
  type: "object",
  properties: {
    name: {
      title: "Name",
      type: "string",
    },
    create_address: {
      type: "boolean",
      title: "Create IPv4 Address",
      default: true,
    },
    address: {
      type: ["string", "null"],
      title: "Address",
      default: null,
    },
    enable_ipv6: {
      type: "boolean",
      title: "Enable IPv6 Address",
      default: false,
    },
    create_ipv6_address: {
      type: "boolean",
      title: "Create IPv6 Address",
      default: false,
    },
    ipv6_address: {
      type: ["null", "string"],
      default: null,
      title: "IPv6 Address",
    },
    firewall_networks: {
      type: "array",
      title: "Firewall Networks",
      items: {
        type: "string",
      },
      default: [],
    },
    firewall_projects: {
      type: "array",
      title: "Firewall Projects",
      items: {
        type: "string",
      },
      default: [],
    },
    target_tags: {
      type: "array",
      title: "Target Tags",
      items: {
        type: "string",
      },
      default: [],
    },
    target_service_accounts: {
      type: "array",
      title: "Target Service Accounts",
      items: {
        type: "string",
      },
      default: [],
    },
    backends: {
      title: "Backends",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Backend Name",
          },
          protocol: {
            type: "string",
            enum: ["HTTPS", "HTTP", "HTTP2"],
            default: "HTTP",
          },
          port: {
            type: "number",
          },
          port_name: {
            type: "string",
            default: "http",
          },
          description: {
            type: "string",
            default: "",
          },
          enable_cdn: {
            type: "boolean",
            default: false,
            title: "Enable CDN",
          },
          cdn_policy: {
            type: "object",
            title: "CDN Policy",
            properties: {
              cache_mode: {
                type: ["string", "null"],
                title: "Cache Mode",
                default: "",
              },
              signed_url_cache_max_age_sec: {
                type: ["number", "null"],
                title: "Signed URL Cache Max Age Seconds",
                default: "3600",
              },
              default_ttl: {
                type: ["number", "null"],
                title: "Default TTL",
                default: null,
              },
              max_ttl: {
                type: ["number", "null"],
                title: "Max TTL",
                default: null,
              },
              client_ttl: {
                type: ["number", "null"],
                title: "Client TTL",
                default: null,
              },
              negative_caching: {
                type: "boolean",
                title: "Negative Caching",
                default: false,
              },
              negative_caching_policy: {
                type: "object",
                title: "Negative Caching Policy",
                properties: {
                  code: {
                    type: ["number", "null"],
                    title: "Code",
                    default: null,
                  },
                  ttl: {
                    type: ["number", "null"],
                    title: "TTL",
                    default: null,
                  },
                },
              },
              serve_while_stale: {
                type: ["number", "null"],
                title: "Serve While Stale",
                default: null,
              },
              cache_key_policy: {
                type: "object",
                title: "Cache Key Policy",
                properties: {
                  include_host: {
                    type: "boolean",
                    title: "Include Host",
                    default: false,
                  },
                  include_protocol: {
                    type: "boolean",
                    title: "Include Protocol",
                    default: false,
                  },
                  include_query_string: {
                    type: "boolean",
                    title: "Include Query String",
                    default: false,
                  },
                  query_string_blacklist: {
                    type: "array",
                    title: "Query String Blacklist",
                    items: {
                      type: "string",
                    },
                    default: [],
                  },
                  query_string_whitelist: {
                    type: "array",
                    title: "Query String Whitelist",
                    items: {
                      type: "string",
                    },
                    default: [],
                  },
                  include_http_headers: {
                    type: "array",
                    title: "Include HTTP Headers",
                    items: {
                      type: "string",
                    },
                    default: [],
                  },
                  include_named_cookies: {
                    type: "array",
                    title: "Include Named Cookies",
                    items: {
                      type: "string",
                    },
                    default: [],
                  },
                },
              },
            },
          },
          compression_mode: {
            type: "string",
            title: "Compression Mode",
            default: "DISABLED",
          },
          security_policy: {
            type: ["string", "null"],
            default: null,
            title: "Security Policy",
          },
          edge_security_policy: {
            type: ["string", "null"],
            default: null,
            title: "Edge Security Policy",
          },
          custom_request_headers: {
            type: "array",
            title: "Custom Request Headers",
            items: {
              type: "string",
            },
            default: [],
          },
          custom_response_headers: {
            type: "array",
            title: "Custom Response Headers",
            items: {
              type: "string",
            },
            default: [],
          },
          timeout_sec: {
            title: "Timout Seconds",
            type: ["number", "null"],
            default: null,
          },
          connection_draining_timeout_sec: {
            title: "Connection Draining timout seconds",
            type: ["number", "null"],
            default: null,
          },
          session_affinity: {
            type: ["string", "null"],
            default: null,
            title: "Session Affinity",
          },
          affinity_cookie_ttl_sec: {
            type: ["number", "null"],
            default: null,
            title: "Affinity Cookie TTL Seconds",
          },
          health_check: {
            title: "Backend Health Check",
            type: "object",
            properties: {
              check_interval_sec: {
                type: "number",
                default: 5,
                title: "Check interval in seconds",
              },
              timeout_sec: {
                type: "number",
                default: 5,
                title: "Timoue in seconds",
              },
              healthy_threshold: {
                type: "number",
                default: 2,
                title: "Healthy threshold",
              },
              unhealthy_threshold: {
                type: "number",
                default: 2,
                title: "Unhealthy threshold",
              },
              request_path: {
                type: "string",
                default: "",
                title: "Request Path",
              },
              port: {
                type: "string",
                title: "Health Check Port",
                default: "",
              },
              host: {
                type: "string",
                title: "Host Header value",
                default: "",
              },
              logging: {
                type: "boolean",
                default: false,
                title: "Log healthcheck",
              },
            },
            required: ["port"],
          },
          log_config: {
            type: "object",
            title: "Log Config",
            properties: {
              enable: {
                type: "boolean",
                title: "Enable",
                default: false,
              },
              sample_rate: {
                type: "string",
                title: "Log Sample Rate",
                default: "1.0",
              },
            },
          },
          groups: {
            title: "Groups",
            type: "array",
            items: {
              type: "object",
              properties: {
                group: {
                  title: "Managed Instance Group",
                  description: "self_link for a managed instance group",
                  type: "string",
                },
                balancing_mode: {
                  title: "Balancing mode",
                  type: "string",
                  default: "",
                },
                capacity_scaler: {
                  title: "Capacity scaler",
                  type: ["number", "null"],
                  default: null,
                },
                description: {
                  title: "Description",
                  type: ["string", "null"],
                  default: null,
                },
                max_connections: {
                  title: "Max Connections",
                  type: ["number", "null"],
                  default: null,
                },
                max_connections_per_instance: {
                  title: "Max Connections Per Instance",
                  type: ["number", "null"],
                  default: null,
                },
                max_connections_per_endpoint: {
                  title: "Max Connections Per Endpoint",
                  type: ["number", "null"],
                  default: null,
                },
                max_rate: {
                  title: "Max Rate",
                  type: ["number", "null"],
                  default: null,
                },
                max_rate_per_instance: {
                  title: "Max Rate Per Intance",
                  type: ["number", "null"],
                  default: null,
                },
                max_rate_per_endpoint: {
                  title: "Max Rage Per Endpoint",
                  type: ["number", "null"],
                  default: null,
                },
                max_utilization: {
                  title: "Max Utilization",
                  type: ["number", "null"],
                  default: null,
                },
              },
              default: [],
              required: ["group"],
            },
          },
          iap_config: {
            type: "object",
            title: "IAP Config",
            properties: {
              enable: {
                type: "boolean",
                default: false,
              },
              oauth2_client_id: {
                title: "Oauth2 Client ID",
                type: "string",
                default: "",
              },
              oauth2_client_secret: {
                title: "Oauth2 Client Secret",
                type: "string",
                default: "",
              },
            },
          },
        },
        required: ["name", "protocol", "port_name", "port", "health_check"],
      },
    },
    create_url_map: {
      type: "boolean",
      default: true,
      title: "Create URL Map",
    },
    url_map: {
      description:
        "The url_map resource to use. Default is to send all traffic to first backend.",
      type: ["string", "null"],
      title: "URL Map",
      default: null,
    },
    http_forward: {
      type: "boolean",
      default: true,
      title: "HTTP Forward",
    },
    ssl: {
      type: "boolean",
      default: false,
    },
    ssl_policy: {
      type: ["string", "null"],
      default: null,
      title: "SSL Policy",
    },
    quic: {
      type: ["boolean", "null"],
      default: null,
      title: "QUIC",
    },
    private_key: {
      type: ["string", "null"],
      title: "Private Key",
      default: null,
    },
    certificate: {
      type: ["string", "null"],
      title: "Certificate",
      default: null,
    },
    managed_ssl_certificate_domains: {
      type: "array",
      title: "Managed SSL Certificate Domains",
      items: {
        type: "string",
      },
      default: [],
    },
    use_ssl_certificates: {
      type: "boolean",
      default: false,
      title: "Use SSL Certificates",
    },
    ssl_certificates: {
      title: "SSL Certificates",
      type: "array",
      items: {
        type: "string",
      },
      default: [],
    },
    edge_security_policy: {
      title: "Edge Security Policy",
      type: ["string", "null"],
      default: null,
    },
    security_policy: {
      title: "Security Policy",
      type: ["string", "null"],
      default: null,
    },
    https_redirect: {
      type: "boolean",
      title: "HTTP Redirect",
      default: false,
    },
    random_certificate_suffix: {
      type: "boolean",
      title: "Random Certificate Suffix",
      default: false,
    },
    load_balancing_scheme: {
      type: "string",
      title: "Load Balancing Scheme",
      enum: ["EXTERNAL", "INTERNAL_SELF_MANAGED", "EXTERNAL_MANAGED"],
      default: "EXTERNAL",
    },
    certificate_map: {
      type: ["string", "null"],
      title: "Certificate Map",
      default: null,
    },
    network: {
      type: "string",
      title: "Network",
      default: "default",
    },
    labels: {
      type: "array",
      title: "Map of labels for project",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
  },
  required: ["name"],
};

export const httpsLBSchemaUI = {
  "ui:description": "External HTTP(S) Load Balancer settings",
  name: {
    "ui:description":
      "Name for the forwarding rule and prefix for supporting resources",
  },
  create_address: {
    "ui:description": "Create a new global IPv4 address",
  },
  address: {
    "ui:description":
      "Existing IPv4 address to use (the actual IP address value)",
    "ui:emptyValue": null,
  },
  enable_ipv6: {
    "ui:description": "Enable IPv6 address on the CDN load-balancer",
  },
  create_ipv6_address: {
    "ui:description":
      'Allocate a new IPv6 address. Conflicts with "ipv6_address" - if both specified, "create_ipv6_address" takes precedence.',
  },
  ipv6_address: {
    "ui:description":
      "An existing IPv6 address to use (the actual IP address value)",
    "ui:emptyValue": null,
  },
  firewall_networks: {
    "ui:description": "Names of the networks to create firewall rules in",
    items: {
      "ui:description": "Network Name",
    },
  },
  firewall_projects: {
    "ui:description": "IDs of the projects to create firewall rules in",
    items: {
      "ui:description": "Project ID",
    },
  },
  target_tags: {
    "ui:description":
      "List of target tags for health check firewall rule. Exactly one of target_tags or target_service_accounts should be specified.",
    items: {
      "ui:description": "List of target tags",
    },
  },
  target_service_accounts: {
    "ui:description":
      "List of target service accounts for health check firewall rule. Exactly one of target_tags or target_service_accounts should be specified.",
    items: {
      "ui:description": "Service Account",
    },
  },
  backends: {
    "ui:description": "Map backend indices to list of backend maps.",
    items: {
      "ui:description":
        "Backend settings for the load balancer. Each backend must have a unique name.",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Name of the backend. Required",
      },
      protocol: {
        "ui:description": "Protocol for the backend",
        "ui:emptyValue": null,
      },
      port: {
        "ui:description": "Port for the backend",
        "ui:emptyValue": null,
      },
      port_name: {
        "ui:description":
          'Port name for the backend. If empty, defaults to "http"',
        "ui:emptyValue": "http",
      },
      description: {
        "ui:description": "Description for the backend",
        "ui:emptyValue": "",
      },
      enable_cdn: {
        "ui:description": "Enable CDN for the backend",
      },
      compression_mode: {
        "ui:description":
          'Compression mode for the backend. If empty, defaults to "DISABLED"',
        "ui:emptyValue": "DISABLED",
      },
      security_policy: {
        "ui:description": "Security policy for the backend",
        "ui:emptyValue": null,
      },
      edge_security_policy: {
        "ui:description": "Edge security policy for the backend",
        "ui:emptyValue": null,
      },
      custom_request_headers: {
        "ui:description": "Custom request headers for the backend",
        items: {
          "ui:description": "List of custom request headers",
        },
      },
      custom_response_headers: {
        "ui:description": "Custom response headers for the backend",
        items: {
          "ui:description": "List of custom response headers",
        },
      },
      timeout_sec: {
        "ui:description":
          "Timeout in seconds for the backend. If empty defaults to null",
        "ui:emptyValue": null,
      },
      connection_draining_timeout_sec: {
        "ui:description":
          "Connection draining timeout in seconds for the backend. If empty, defaults to null",
        "ui:emptyValue": null,
      },
      session_affinity: {
        "ui:description":
          "Session affinity for the backend. If empty, defaults to null",
        "ui:emptyValue": null,
      },
      affinity_cookie_ttl_sec: {
        "ui:description":
          "Affinity cookie TTL in seconds for the backend. If empty, defaults to null",
        "ui:emptyValue": null,
      },
      health_check: {
        "ui:description": "Health check for the backend",
        check_interval_sec: {
          "ui:description":
            "Check interval in seconds for the health check. If empty, defaults to 5",
          "ui:emptyValue": 5,
        },
        timeout_sec: {
          "ui:description":
            "Timeout in seconds for the health check. If empty, defaults to 5",
          "ui:emptyValue": 5,
        },
        healthy_threshold: {
          "ui:description":
            "Healthy threshold for the health check. If empty, defaults to 2",
          "ui:emptyValue": 2,
        },
        unhealthy_threshold: {
          "ui:description":
            "Unhealthy threshold for the health check. If empty, defaults to 2",
          "ui:emptyValue": 2,
        },
        request_path: {
          "ui:description":
            "Request path for the health check. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        port: {
          "ui:description": "Port for the health check",
          "ui:emptyValue": null,
        },
        host: {
          "ui:description":
            "Host header value for the health check. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        logging: {
          "ui:description": "Log health check for the health check",
        },
      },
      log_config: {
        "ui:description": "Log config for the backend",
        enable: {
          "ui:description": "Enable log config for the backend",
        },
        sample_rate: {
          "ui:description":
            "Log sample rate for the backend. If empty, defaults to 1.0",
          "ui:emptyValue": "1.0",
        },
      },
      groups: {
        "ui:description":
          "Definitions for managed instance groups. Instance groups should already be deployed",
        items: {
          "ui:description": "Managed instance group settings",
          "ui:displayfield": "group",
          group: {
            "ui:description":
              "Self link for the managed instance group. Required",
          },
          balancing_mode: {
            "ui:description":
              "Balancing mode for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          capacity_scaler: {
            "ui:description":
              "Capacity scaler for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          description: {
            "ui:description":
              "Description for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          max_connections: {
            "ui:description":
              "Max connections for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          max_connections_per_instance: {
            "ui:description":
              "Max connections per instance for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          max_connections_per_endpoint: {
            "ui:description":
              "Max connections per endpoint for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          max_rate: {
            "ui:description":
              "Max rate for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          max_rate_per_instance: {
            "ui:description":
              "Max rate per instance for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          max_rate_per_endpoint: {
            "ui:description":
              "Max rate per endpoint for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          max_utilization: {
            "ui:description":
              "Max utilization for the managed instance group. If empty, defaults to null",
            "ui:emptyValue": null,
          },
        },
      },
      iap_config: {
        "ui:description": "IAP config for the backend",
        enable: {
          "ui:description": "Enable IAP config for the backend",
        },
        oauth2_client_id: {
          "ui:description":
            "Oauth2 client ID for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        oauth2_client_secret: {
          "ui:description":
            "Oauth2 client secret for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
      },
      cdn_policy: {
        "ui:description":
          "CDN policy for the backend. This is required if enable_cdn is true",
        cache_mode: {
          "ui:description":
            "Cache mode for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        signed_url_cache_max_age_sec: {
          "ui:description":
            "Signed URL cache max age seconds for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        default_ttl: {
          "ui:description":
            "Default TTL for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        max_ttl: {
          "ui:description":
            "Max TTL for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        client_ttl: {
          "ui:description":
            "Client TTL for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        negative_caching: {
          "ui:description": "Negative caching for the backend.",
        },
        negative_caching_policy: {
          "ui:description":
            "Negative caching policy for the backend. Required if negative_caching is set to true",
          code: {
            "ui:description":
              "Code for the backend. If empty, defaults to null",
            "ui:emptyValue": null,
          },
          ttl: {
            "ui:description": "TTL for the backend. If empty, defaults to null",
            "ui:emptyValue": null,
          },
        },
        serve_while_stale: {
          "ui:description":
            "Serve while stale for the backend. If empty, defaults to null",
          "ui:emptyValue": null,
        },
        cache_key_policy: {
          "ui:description": "Cache key policy for the backend",
          include_host: {
            "ui:description": "Include host for the backend",
          },
          include_protocol: {
            "ui:description": "Include protocol for the backend",
          },
          include_query_string: {
            "ui:description": "Include query string for the backend",
          },
          query_string_blacklist: {
            "ui:description": "Query string blacklist for the backend",
            items: {
              "ui:description": "List of query string blacklist",
            },
          },
          query_string_whitelist: {
            "ui:description": "Query string whitelist for the backend",
            items: {
              "ui:description": "List of query string whitelist",
            },
          },
          include_http_headers: {
            "ui:description": "Include HTTP headers for the backend",
            items: {
              "ui:description": "List of include HTTP headers",
            },
          },
          include_named_cookies: {
            "ui:description": "Include named cookies for the backend",
            items: {
              "ui:description": "List of include named cookies",
            },
          },
        },
      },
    },
  },
  create_url_map: {
    "ui:description": "Set to `false` if url_map variable is provided.",
  },
  url_map: {
    "ui:description":
      "The url_map resource to use. Default is to send all traffic to first backend.",
    "ui:emptyValue": null,
  },
  http_forward: {
    "ui:description": "Set to `false` to disable HTTP port 80 forward",
  },
  ssl: {
    "ui:description":
      "Set to `true` to enable SSL support, requires variable `ssl_certificates` - a list of self_link certs",
  },
  ssl_policy: {
    "ui:description": "Selfink to SSL Policy",
    "ui:emptyValue": null,
  },
  quic: {
    "ui:description":
      "Specifies the QUIC override policy for this resource. Set true to enable HTTP/3 and Google QUIC support, false to disable both. Defaults to null which enables support for HTTP/3 only.",
    "ui:emptyValue": null,
  },
  private_key: {
    "ui:description":
      "Content of the private SSL key. Required if `ssl` is `true` and `ssl_certificates` is empty.",
    "ui:emptyValue": null,
  },
  certificate: {
    "ui:description":
      "Content of the SSL certificate. Required if `ssl` is `true` and `ssl_certificates` is empty.",
    "ui:emptyValue": null,
  },
  managed_ssl_certificate_domains: {
    "ui:description":
      "Create Google-managed SSL certificates for specified domains. Requires `ssl` to be set to `true` and `use_ssl_certificates` set to `false`.",
    items: {
      "ui:description":
        "List of domains to create Google-managed SSL certificates for.",
    },
  },
  use_ssl_certificates: {
    "ui:description":
      "If true, use the certificates provided by `ssl_certificates`, otherwise, create cert from `private_key` and `certificate`",
  },
  ssl_certificates: {
    "ui:description":
      "SSL cert self_link list. Required if `ssl` is `true` and no `private_key` and `certificate` is provided.",
    items: {
      "ui:description": "List of SSL cert self_links",
    },
  },
  edge_security_policy: {
    "ui:description":
      "The resource URL for the edge security policy to associate with the backend service",
    "ui:emptyValue": null,
  },
  security_policy: {
    "ui:description":
      "The resource URL for the security policy to associate with the backend service",
    "ui:emptyValue": null,
  },
  https_redirect: {
    "ui:description": "Set to `true` to enable https redirect on the lb.",
  },
  random_certificate_suffix: {
    "ui:description":
      "Bool to enable/disable random certificate name generation. Set and keep this to true if you need to change the SSL cert.",
  },
  load_balancing_scheme: {
    "ui:description":
      "Load balancing scheme type (EXTERNAL for classic external load balancer, EXTERNAL_MANAGED for Envoy-based load balancer, and INTERNAL_SELF_MANAGED for traffic director)",
  },
  certificate_map: {
    "ui:description":
      "Certificate Map ID in format projects/{project}/locations/global/certificateMaps/{name}. Identifies a certificate map associated with the given target proxy",
    "ui:emptyValue": null,
  },
  network: {
    "ui:description": "Network for INTERNAL_SELF_MANAGED load balancing scheme",
  },
  labels: {
    "ui:description": "Map of labels for project",
    "ui:displayfield": "key",
    items: {
      "ui:description": "Label key/value pair",
      key: {
        "ui:description": "Label key",
      },
      value: {
        "ui:description": "Label value",
      },
    },
  },
};
