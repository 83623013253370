export const awsIAMaccountSchema = {
  title: "AWS IAM Settings",
  type: "object",
  properties: {
    get_caller_identity: {
      title: "Get Caller Identity",
      type: "boolean",
      default: true,
    },
    account_alias: {
      title: "Account Alias",
      type: "string",
    },
    create_account_password_policy: {
      title: "Create Account Password Policy",
      type: "boolean",
      default: true,
    },
    max_password_age: {
      title: "Max Password Age",
      type: "number",
      default: 0,
    },
    minimum_password_length: {
      title: "Minimum Password Length",
      type: "number",
      default: 8,
    },
    allow_users_to_change_password: {
      title: "Allow Users To Change Password",
      type: "boolean",
      default: true,
    },
    hard_expiry: {
      title: "Hard Expiry",
      type: "boolean",
      default: false,
    },
    password_reuse_prevention: {
      title: "Password Reuse Prevention",
      type: ["number", "null"],
    },
    require_lowercase_characters: {
      title: "Require Lowercase Characters",
      type: "boolean",
      default: true,
    },
    require_uppercase_characters: {
      title: "Require Uppercase Characters",
      type: "boolean",
      default: true,
    },
    require_numbers: {
      title: "Require Numbers",
      type: "boolean",
      default: true,
    },
    require_symbols: {
      title: "Require Symbols",
      type: "boolean",
      default: true,
    },
  },
  required: ["account_alias"],
};

export const awsIAMaccountUISchema = {
  "ui:description": "AWS Account IAM Settings",
  get_caller_identity: {
    "ui:description":
      "Whether to get AWS account ID, User ID, and ARN in which Terraform is authorized",
  },
  account_alias: { "ui:description": "AWS IAM account alias for this account" },
  create_account_password_policy: {
    "ui:description": "Whether to create AWS IAM account password policy",
  },
  max_password_age: {
    "ui:description": "The number of days that an user password is valid.",
  },
  minimum_password_length: {
    "ui:description": "Minimum length to require for user passwords",
  },
  allow_users_to_change_password: {
    "ui:description": "Whether to allow users to change their own password",
  },
  hard_expiry: {
    "ui:description":
      "Whether users are prevented from setting a new password after their password has expired (i.e. require administrator reset)",
  },
  password_reuse_prevention: {
    "ui:description":
      "The number of previous passwords that users are prevented from reusing",
    "ui:emptyValue": null,
  },
  require_uppercase_characters: {
    "ui:description":
      "Whether to require uppercase characters for user passwords",
  },
  require_lowercase_characters: {
    "ui:description":
      "Whether to require lowercase characters for user passwords",
  },
  require_numbers: {
    "ui:description": "Whether to require numbers for user passwords",
  },
  require_symbols: {
    "ui:description": "Whether to require symbols for user passwords",
  },
};
