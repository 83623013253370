export const databricksWorkspaceAdminSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      description: "Module Name",
    },
    enableResultsDownloading: {
      type: "boolean",
      description: "Enable Results Downloading",
      default: false,
    },
    enableNotebookTableClipboard: {
      type: "boolean",
      description: "Enable Notebook Table Clipboard",
      default: false,
    },
    enableVerboseAuditLogs: {
      type: "boolean",
      description: "Enable Verbose Audit Logs",
      default: true,
    },
    enable_X_Frame_Options: {
      type: "boolean",
      description: "Enable X-Frame-Options",
      default: true,
    },
    enable_X_Content_Type_Options: {
      type: "boolean",
      description: "Enable X-Content-Type-Options",
      default: true,
    },
    enable_X_XSS_Protection: {
      type: "boolean",
      description: "Enable X-XSS-Protection",
      default: true,
    },
    enableWebTerminal: {
      type: "boolean",
      description: "Enable Web Terminal",
      default: true,
    },
    enableDbfsFileBrowser: {
      type: "boolean",
      description: "Enable DBFS File Browser",
      default: false,
    },
    enforceUserIsolation: {
      type: "boolean",
      description: "Enforce User Isolation",
      default: true,
    },
    enableNotebookGitVersioning: {
      type: "boolean",
      description: "Enable Notebook Git Versioning",
      default: false,
    },
    ip_addresses: {
      type: "array",
      description: "IP Addresses",
      items: {
        type: "string",
      },
    },
  },
  required: ["name"],
};
export const databricksWorkspaceAdminUISchema = {
  "ui:description": "Databricks Workspace Admin Settings",
  ip_addresses: {
    "ui:description": "List of allowed IP Addresses for the Workspace",
    items: {
      "ui:description": "IP Address",
    },
  },
};
