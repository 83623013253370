export const awsS3Schema = {
  title: "AWS S3 Bucket",
  type: "object",
  properties: {
    bucket: {
      title: "Bucket",
      type: "string",
    },
    attach_elb_log_delivery_policy: {
      title: "Attach Elb Log Delivery Policy",
      type: "boolean",
      default: false,
    },
    attach_lb_log_delivery_policy: {
      title: "Attach Lb Log Delivery Policy",
      type: "boolean",
      default: false,
    },
    attach_access_log_delivery_policy: {
      title: "Attach Access Log Delivery Policy",
      type: "boolean",
      default: false,
    },
    attach_deny_insecure_transport_policy: {
      title: "Attach Deny Insecure Transport Policy",
      type: "boolean",
      default: false,
    },
    attach_require_latest_tls_policy: {
      title: "Attach Require Latest Tls Policy",
      type: "boolean",
      default: false,
    },
    attach_policy: {
      title: "Attach Policy",
      type: "boolean",
      default: false,
    },
    attach_public_policy: {
      title: "Attach Public Policy",
      type: "boolean",
      default: false,
    },
    attach_inventory_destination_policy: {
      title: "Attach Inventory Destination Policy",
      type: "boolean",
      default: false,
    },
    attach_analytics_destination_policy: {
      title: "Attach Analytics Destination Policy",
      type: "boolean",
      default: false,
    },
    attach_deny_incorrect_encryption_headers: {
      title: "Attach Deny Incorrect Encryption Headers",
      type: "boolean",
      default: false,
    },
    attach_deny_incorrect_kms_key_sse: {
      title: "Attach Deny Incorrect Kms Key Sse",
      type: "boolean",
      default: false,
    },
    allowed_kms_key_arn: {
      title: "Allowed Kms Key Arn",
      type: ["string", "null"],
      default: null,
    },
    attach_deny_unencrypted_object_uploads: {
      title: "Attach Deny Unencrypted Object Uploads",
      type: "boolean",
      default: false,
    },
    acl: {
      title: "Acl",
      type: ["string", "null"],
      default: null,
    },
    policy: {
      title: "Policy",
      type: ["string", "null"],
      default: null,
    },
    tags: {
      title: "Tags",
      type: "object",
      additionalProperties: {
        title: "Tag",
        type: "string",
      },
      default: {
        tagkey: "tagvalue",
      },
    },
    force_destroy: {
      title: "Force Destroy",
      type: "boolean",
      default: true,
    },
    acceleration_status: {
      title: "Acceleration Status",
      type: ["string", "null"],
      default: null,
    },
    request_payer: {
      title: "Request Payer",
      type: ["string", "null"],
      default: null,
    },
    website: {
      title: "Website",
      type: "object",
      properties: {
        index_document: {
          title: "Index Document",
          type: ["string", "null"],
          default: null,
        },
        error_document: {
          title: "Error Document",
          type: ["string", "null"],
          default: null,
        },
        redirect_all_requests_to: {
          title: "Redirect All Requests To",
          type: "object",
          properties: {
            host_name: {
              title: "Host Name",
              type: ["string", "null"],
              default: null,
            },
            protocol: {
              title: "Protocol",
              type: ["string", "null"],
              enum: ["http", "https", null],
              default: null,
            },
          },
          default: {},
        },
        routing_rules: {
          title: "Routing Rules",
          type: "array",
          items: {
            type: "object",
            title: "Routing Rule",
            properties: {
              condition: {
                title: "Condition",
                type: "object",
                properties: {
                  http_error_code_returned_equals: {
                    title: "Http Error Code Returned Equals",
                    type: ["string", "null"],
                    default: null,
                  },
                  key_prefix_equals: {
                    title: "Key Prefix Equals",
                    type: ["string", "null"],
                    default: null,
                  },
                },
                default: {},
              },
              redirect: {
                title: "Redirect",
                type: "object",
                properties: {
                  host_name: {
                    title: "Host Name",
                    type: ["string", "null"],
                    default: null,
                  },
                  http_redirect_code: {
                    title: "Http Redirect Code",
                    type: ["string", "null"],
                    default: null,
                  },
                  protocol: {
                    title: "Protocol",
                    type: ["string", "null"],
                    default: "https",
                    enum: ["http", "https", null],
                  },
                  replace_key_prefix_with: {
                    title: "Replace Key Prefix With",
                    type: ["string", "null"],
                    default: null,
                  },
                  replace_key_with: {
                    title: "Replace Key With",
                    type: ["string", "null"],
                    default: null,
                  },
                },
              },
            },
          },
          default: [],
        },
      },
      default: {},
    },
    cors_rule: {
      title: "Cors Rules",
      type: "array",
      items: {
        type: "object",
        title: "Cors Rule",
        properties: {
          id: {
            title: "ID",
            type: "string",
          },
          allowed_headers: {
            title: "Allowed Headers",
            type: "array",
            items: {
              type: "string",
              title: "Allowed Header",
            },
            default: [],
          },
          allowed_methods: {
            title: "Allowed Methods",
            type: "array",
            items: {
              type: "string",
              title: "Allowed Method",
              enum: ["GET", "PUT", "POST", "DELETE", "HEAD"],
            },
            default: [],
          },
          allowed_origins: {
            title: "Allowed Origins",
            type: "array",
            items: {
              type: "string",
              title: "Allowed Origin",
            },
            default: [],
          },
          expose_headers: {
            title: "Expose Headers",
            type: "array",
            items: {
              type: "string",
              title: "Expose Header",
            },
            default: [],
          },
          max_age_seconds: {
            title: "Max Age Seconds",
            type: ["number", "null"],
            default: null,
          },
        },
        required: ["id"],
      },
      default: [],
    },
    versioning: {
      title: "Versioning",
      type: "object",
      properties: {
        enabled: {
          title: "Enabled",
          type: "boolean",
          default: false,
        },
        mfa: {
          title: "MFA",
          type: "boolean",
          default: false,
        },
        mfa_delete: {
          title: "MFA Delete",
          type: "boolean",
          default: false,
        },
      },
      default: {},
    },
    logging: {
      title: "Logging",
      type: "object",
      properties: {
        target_bucket: {
          title: "Target Bucket",
          type: ["string", "null"],
          default: null,
        },
        target_prefix: {
          title: "Target Prefix",
          type: ["string", "null"],
          default: null,
        },
      },
      default: {},
    },
    access_log_delivery_policy_source_buckets: {
      title: "Access Log Delivery Policy Source Buckets",
      type: "array",
      items: {
        type: "string",
        title: "Source Bucket",
      },
      default: [],
    },
    access_log_delivery_policy_source_accounts: {
      title: "Access Log Delivery Policy Source Accounts",
      type: "array",
      items: {
        type: "string",
        title: "Source Account",
      },
      default: [],
    },
    grant: {
      title: "Grants",
      type: "array",
      items: {
        type: "object",
        title: "Grant",
        properties: {
          permission: {
            title: "Permission",
            type: "string",
          },
          id: {
            title: "ID",
            type: ["string", "null"],
            default: null,
          },
          type: {
            title: "Type",
            type: "string",
            enum: ["CanonicalUser", "AmazonCustomerByEmail", "Group"],
          },
          email: {
            title: "Email",
            type: ["string", "null"],
            default: null,
          },
          uri: {
            title: "URI",
            type: ["string", "null"],
            default: null,
          },
        },
      },
      default: [],
    },
    owner: {
      title: "Owner",
      type: "object",
      properties: {
        display_name: {
          title: "Display Name",
          type: ["string", "null"],
          default: null,
        },
        id: {
          title: "ID",
          type: ["string", "null"],
          default: null,
        },
      },
      default: {},
    },
    expected_bucket_owner: {
      title: "Expected Bucket Owner",
      type: ["string", "null"],
      default: null,
    },
    lifecycle_rule: {
      title: "Lifecycle Rule",
      type: "array",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
            title: "ID",
          },
          enabled: {
            type: "boolean",
            title: "Enabled",
            default: true,
          },
          abort_incomplete_multipart_upload_days: {
            type: ["number", "null"],
            title: "Abort Incomplete Multipart Upload Days",
            default: null,
          },
          expiration: {
            type: "object",
            title: "Expiration",
            properties: {
              date: {
                type: ["string", "null"],
                title: "Date",
                default: null,
              },
              days: {
                type: ["number", "null"],
                title: "Days",
                default: null,
              },
              expired_object_delete_marker: {
                type: "boolean",
                title: "Expired Object Delete Marker",
                default: false,
              },
            },
            default: {},
          },
          transition: {
            type: "array",
            title: "Transition",
            items: {
              type: "object",
              properties: {
                date: {
                  type: ["string", "null"],
                  title: "Date",
                  default: null,
                },
                days: {
                  type: ["number", "null"],
                  title: "Days",
                  default: null,
                },
                storage_class: {
                  type: "string",
                  title: "Storage Class",
                  enum: [
                    "GLACIER",
                    "STANDARD_IA",
                    "ONEZONE_IA",
                    "INTELLIGENT_TIERING",
                    "DEEP_ARCHIVE",
                  ],
                  default: "GLACIER",
                },
              },
              default: [],
            },
          },
          noncurrent_version_expiration: {
            type: "object",
            title: "Noncurrent Version Expiration",
            properties: {
              newer_noncurrent_versions: {
                type: ["number", "null"],
                title: "Newer Noncurrent Versions",
              },
              days: {
                type: ["number", "null"],
                title: "Days",
                default: null,
              },
            },
            default: {},
          },
          noncurrent_version_transition: {
            type: "array",
            title: "Noncurrent Version Transitions",
            items: {
              type: "object",
              title: "Noncurrent Version Transition",
              properties: {
                newer_noncurrent_versions: {
                  type: ["number", "null"],
                  title: "Newer Noncurrent Versions",
                },
                noncurrent_days: {
                  type: ["number", "null"],
                  title: "Days",
                  default: null,
                },
                storage_class: {
                  type: "string",
                  title: "Storage Class",
                  enum: [
                    "GLACIER",
                    "STANDARD_IA",
                    "ONEZONE_IA",
                    "INTELLIGENT_TIERING",
                    "DEEP_ARCHIVE",
                  ],
                  default: "GLACIER",
                },
              },
              default: {},
            },
            default: [],
          },
          filter: {
            type: "array",
            title: "Filter",
            items: {
              type: "object",
              title: "Filter",
              properties: {
                prefix: {
                  type: ["string", "null"],
                  title: "Prefix",
                  default: null,
                },
                object_size_greater_than: {
                  type: ["number", "null"],
                  title: "Object Size Greater Than",
                  default: null,
                },
                object_size_less_than: {
                  type: ["number", "null"],
                  title: "Object Size Less Than",
                  default: null,
                },
                tags: {
                  type: "object",
                  title: "Tags",
                  additionalProperties: {
                    type: "string",
                  },
                  default: {},
                },
              },
              default: {},
            },
            default: [],
          },
        },
        required: ["id"],
      },
      default: [],
    },
    replication_configuration: {
      title: "Replication Configuration",
      type: "object",
      properties: {
        role: {
          title: "Role",
          type: "string",
        },
        rules: {
          title: "Rules",
          type: "array",
          items: {
            type: "object",
            title: "Rule",
            properties: {
              id: {
                title: "ID",
                type: "string",
              },
              priority: {
                title: "Priority",
                type: ["number", "null"],
                default: null,
              },
              status: {
                title: "Enabled",
                type: "boolean",
                default: false,
              },
              delete_marker_replication: {
                title: "Delete Marker Replication",
                type: "object",
                properties: {
                  status: {
                    title: "Enabled",
                    type: "boolean",
                    default: false,
                  },
                },
                default: {},
              },
              destination: {
                title: "Destination",
                type: "object",
                properties: {
                  bucket: {
                    title: "Bucket",
                    type: "string",
                  },
                  account: {
                    title: "Account",
                    type: ["string", "null"],
                    default: null,
                  },
                  storage_class: {
                    title: "Storage Class",
                    type: ["string", "null"],
                    enum: [
                      "STANDARD",
                      "REDUCED_REDUNDANCY",
                      "STANDARD_IA",
                      "ONEZONE_IA",
                      "INTELLIGENT_TIERING",
                      "GLACIER",
                      "DEEP_ARCHIVE",
                      null,
                    ],
                    default: null,
                  },
                  access_control_translation: {
                    title: "Access Control Translation",
                    type: "object",
                    properties: {
                      owner: {
                        title: "Owner",
                        type: "string",
                        enum: ["Destination", "Source"],
                        default: "Destination",
                      },
                    },
                    default: {},
                  },
                  encryption_configuration: {
                    title: "Encryption Configuration",
                    type: "object",
                    properties: {
                      replica_kms_key_id: {
                        title: "Replica KMS Key ID",
                        type: ["string", "null"],
                        default: null,
                      },
                    },
                    default: {},
                  },
                  replication_time: {
                    title: "Replication Time",
                    type: "object",
                    properties: {
                      status: {
                        title: "Enabled",
                        type: "boolean",
                        default: false,
                      },
                      time: {
                        title: "Time",
                        type: "object",
                        properties: {
                          minutes: {
                            title: "Minutes",
                            type: "number",
                            default: 15,
                          },
                        },
                      },
                      default: {},
                    },
                    default: {},
                  },
                  metrics: {
                    title: "Metrics",
                    type: "object",
                    properties: {
                      status: {
                        title: "Enabled",
                        type: "boolean",
                        default: false,
                      },
                      event_threshold: {
                        type: "object",
                        title: "Event Threshold",
                        properties: {
                          minutes: {
                            title: "Minutes",
                            type: "number",
                            default: 15,
                          },
                        },
                      },
                    },
                    default: {},
                  },
                },
                default: {},
              },
              source_selection_criteria: {
                title: "Source Selection Criteria",
                type: "object",
                properties: {
                  sse_kms_encrypted_objects: {
                    type: "object",
                    title: "SSE KMS Encrypted Objects",
                    properties: {
                      status: {
                        type: "boolean",
                        title: "Enabled",
                        default: false,
                      },
                    },
                    default: {},
                  },
                  replica_modifications: {
                    type: "object",
                    title: "Replica Modifications",
                    properties: {
                      status: {
                        type: "boolean",
                        title: "Enabled",
                        default: false,
                      },
                    },
                    default: {},
                  },
                },
                default: {},
              },
              filter: {
                title: "Filter",
                type: "object",
                properties: {
                  prefix: {
                    title: "Prefix",
                    type: ["string", "null"],
                    default: null,
                  },
                  tags: {
                    title: "Tags",
                    type: "object",
                    additionalProperties: {
                      type: "string",
                      title: "Tag",
                    },
                    default: {},
                  },
                },
                default: {},
              },
            },
            default: {},
          },
          default: [],
        },
      },
      default: {},
    },
    server_side_encryption_configuration: {
      title: "Server Side Encryption Configuration",
      type: "object",
      properties: {
        rule: {
          title: "Rule",
          type: "object",
          properties: {
            bucket_key_enabled: {
              title: "Bucket Key Enabled",
              type: "boolean",
              default: false,
            },
            apply_server_side_encryption_by_default: {
              title: "Apply Server Side Encryption By Default",
              type: "object",
              properties: {
                kms_master_key_id: {
                  title: "KMS Master Key ID",
                  type: ["string", "null"],
                  default: null,
                },
                sse_algorithm: {
                  title: "SSE Algorithm",
                  type: ["string", "null"],
                  enum: ["AES256", "aws:kms", null],
                  default: null,
                },
              },
            },
          },
        },
      },
      default: {},
    },
    intelligent_tiering: {
      title: "Intelligent Tiering",
      type: "object",
      additionalProperties: {
        type: "object",
        title: "Intelligent Tiering Settings",
        properties: {
          status: {
            type: "boolean",
            title: "Enabled",
            default: false,
          },
          filter: {
            type: "object",
            title: "Filter",
            properties: {
              prefix: {
                type: ["string", "null"],
                title: "Prefix",
                default: null,
              },
              tags: {
                type: "object",
                title: "Tags",
                additionalProperties: {
                  type: "string",
                  title: "Tag",
                },
                default: {},
              },
            },
            default: {},
          },
          tiering: {
            type: "object",
            title: "Tiering",
            additionalProperties: {
              type: "object",
              title: "Tiering Days",
              properties: {
                days: {
                  type: "number",
                  title: "Days",
                  default: 30,
                },
              },
            },
            default: {},
          },
        },
      },

      default: {},
    },
    object_lock_configuration: {
      title: "Object Lock Configuration",
      type: "object",
      properties: {
        rule: {
          title: "Rule",
          type: "object",
          properties: {
            default_retention: {
              title: "Default Retention",
              type: "object",
              properties: {
                mode: {
                  title: "Mode",
                  type: "string",
                  enum: ["GOVERNANCE", "COMPLIANCE"],
                  default: "GOVERNANCE",
                },
                days: {
                  title: "Days",
                  type: ["number", "null"],
                  default: null,
                },
                years: {
                  title: "Years",
                  type: ["number", "null"],
                  default: null,
                },
              },
              default: {},
            },
          },
          default: {},
        },
        token: {
          title: "Token",
          type: ["string", "null"],
          default: null,
        },
      },
      default: {},
    },
    metric_configuration: {
      title: "Metric Configurations",
      type: "array",
      items: {
        type: "object",
        title: "Metric Configuration",
        properties: {
          name: {
            title: "Name",
            type: "string",
          },
          filter: {
            title: "Filter",
            type: "object",
            properties: {
              prefix: {
                title: "Prefix",
                type: ["string", "null"],
                default: null,
              },
              tags: {
                title: "Tags",
                type: "object",
                additionalProperties: {
                  type: "string",
                  title: "Tag",
                },
                default: {},
              },
            },
            default: {},
          },
        },
      },
      default: [],
    },
    inventory_configuration: {
      title: "Inventory Configurations",
      type: "array",
      items: {
        type: "object",
        title: "Inventory Configuration",
        properties: {
          name: {
            title: "Name",
            type: "string",
          },
          enabled: {
            title: "Enabled",
            type: "boolean",
            default: false,
          },
          optional_fields: {
            title: "Optional Fields",
            type: "array",
            items: {
              type: "string",
              title: "Optional Field",
            },
            default: [],
          },
          frequency: {
            title: "Frequency",
            type: "string",
            enum: ["Daily", "Weekly"],
            default: "Daily",
          },
          included_object_versions: {
            title: "Include Object Versions",
            type: "string",
            enum: ["All", "Current"],
            default: "All",
          },
          destination: {
            title: "Destination",
            type: "object",
            properties: {
              format: {
                title: "Format",
                type: ["string", "null"],
                enum: ["CSV", "ORC", "Parquet"],
                default: null,
              },
              account_id: {
                title: "Account ID",
                type: ["string", "null"],
                default: null,
              },
              prefix: {
                title: "Prefix",
                type: ["string", "null"],
                default: null,
              },
              encryption: {
                title: "Encryption",
                type: "object",
                properties: {
                  encryption_type: {
                    title: "Encryption Type",
                    type: ["string", "null"],
                    enum: ["sse_S3", "sse_kms", null],
                    default: null,
                  },
                  kms_key_id: {
                    title: "KMS Key ID",
                    type: ["string", "null"],
                    default: null,
                  },
                },
                default: {},
              },
            },
          },
          filter: {
            title: "Filter",
            type: "object",
            properties: {
              prefix: {
                title: "Prefix",
                type: ["string", "null"],
                default: null,
              },
            },
            default: {},
          },
        },
        default: {},
      },
      default: [],
    },
    inventory_source_account_id: {
      title: "Inventory Source Account ID",
      type: ["string", "null"],
      default: null,
    },
    inventory_source_bucket_arn: {
      title: "Inventory Source Bucket ARN",
      type: ["string", "null"],
      default: null,
    },
    inventory_self_source_destination: {
      title: "Inventory Self Source Destination",
      type: "boolean",
      default: false,
    },
    analytics_configuration: {
      title: "Analytics Configurations",
      type: "array",
      items: {
        type: "object",
        title: "Analytics Configuration",
        properties: {
          name: {
            title: "Name",
            type: "string",
          },
          filter: {
            title: "Filter",
            type: "object",
            properties: {
              prefix: {
                title: "Prefix",
                type: ["string", "null"],
                default: null,
              },
              tags: {
                title: "Tags",
                type: "object",
                additionalProperties: {
                  type: "string",
                  title: "Tag",
                },
                default: {},
              },
            },
            default: {},
          },
          storage_class_analysis: {
            title: "Storage Class Analysis",
            type: "object",
            properties: {
              output_schema_version: {
                title: "Output Schema Version",
                type: ["string", "null"],
                default: null,
              },

              export_format: {
                title: "Export Format",
                type: "string",
                default: "CSV",
              },
              export_prefix: {
                title: "Export Prefix",
                type: ["string", "null"],
                default: null,
              },
            },
            default: {},
          },
        },
        default: {},
      },
      default: [],
    },
    analytics_source_account_id: {
      title: "Analytics Source Account ID",
      type: ["string", "null"],
      default: null,
    },
    analytics_source_bucket_arn: {
      title: "Analytics Source Bucket ARN",
      type: ["string", "null"],
      default: null,
    },
    analytics_self_source_destination: {
      title: "Analytics Self Source Destination",
      type: "boolean",
      default: false,
    },
    object_lock_enabled: {
      title: "Object Lock Enabled",
      type: "boolean",
      default: false,
    },
    block_public_acls: {
      title: "Block Public ACLs",
      type: "boolean",
      default: true,
    },
    block_public_policy: {
      title: "Block Public Policy",
      type: "boolean",
      default: true,
    },
    ignore_public_acls: {
      title: "Ignore Public ACLs",
      type: "boolean",
      default: true,
    },
    restrict_public_buckets: {
      title: "Restrict Public Buckets",
      type: "boolean",
      default: true,
    },
    control_object_ownership: {
      title: "Control Object Ownership",
      type: "boolean",
      default: false,
    },
    object_ownership: {
      title: "Object Ownership",
      type: "string",
      default: "BucketOwnerEnforced",
    },
  },
  required: ["bucket"],
};

export const awsS3SUIchema = {
  "ui:description": "Creates a new S3 bucket.",
  attach_elb_log_delivery_policy: {
    "ui:description":
      "Controls if S3 bucket should have ELB log delivery policy attached",
  },
  attach_lb_log_delivery_policy: {
    "ui:description":
      "Controls if S3 bucket should have ALB/NLB log delivery policy attached",
  },
  attach_access_log_delivery_policy: {
    "ui:description":
      "Controls if S3 bucket should have S3 access log delivery policy attached",
  },
  attach_deny_insecure_transport_policy: {
    "ui:description":
      "Controls if S3 bucket should have deny non-SSL transport policy attached",
  },
  attach_require_latest_tls_policy: {
    "ui:description":
      "Controls if S3 bucket should require the latest version of TLS",
  },
  attach_policy: {
    "ui:description":
      "Controls if S3 bucket should have bucket policy attached (set to `true` to use value of `policy` as bucket policy)",
  },
  attach_public_policy: {
    "ui:description":
      "Controls if a user defined public bucket policy will be attached (set to `false` to allow upstream to apply defaults to the bucket)",
  },
  attach_inventory_destination_policy: {
    "ui:description":
      "Controls if S3 bucket should have bucket inventory destination policy attached.",
  },
  attach_analytics_destination_policy: {
    "ui:description":
      "Controls if S3 bucket should have bucket analytics destination policy attached.",
  },
  attach_deny_incorrect_encryption_headers: {
    "ui:description":
      "Controls if S3 bucket should deny incorrect encryption headers policy attached.",
  },
  attach_deny_incorrect_kms_key_sse: {
    "ui:description":
      "Controls if S3 bucket policy should deny usage of incorrect KMS key SSE.",
  },
  allowed_kms_key_arn: {
    "ui:description": "The ARN of KMS key which should be allowed in PutObject",
    "ui:emptyValue": null,
  },
  attach_deny_unencrypted_object_uploads: {
    "ui:description":
      "Controls if S3 bucket should deny unencrypted object uploads policy attached.",
  },
  bucket: {
    "ui:description": "Bucket Name",
  },
  acl: {
    "ui:description":
      "(Optional) The canned ACL to apply. Conflicts with `grant`",
    "ui:emptyValue": null,
  },
  policy: {
    "ui:description":
      "(Optional) A valid bucket policy JSON document. Note that if the policy document is not specific enough (but still valid), Terraform may view the policy as constantly changing in a terraform plan. In this case, please make sure you use the verbose/specific version of the policy. For more information about building AWS IAM policy documents with Terraform, see the AWS IAM Policy Document Guide.",
    "ui:emptyValue": null,
    "ui:widget": "textarea",
    "ui:options": {
      rows: 15,
    },
  },
  tags: {
    "ui:description": "(Optional) A mapping of tags to assign to the bucket.",
    additionalProperties: {
      "ui:description": "Tag Value",
    },
  },
  force_destroy: {
    "ui:description":
      "(Optional, Default:true ) A boolean that indicates all objects should be deleted from the bucket so that the bucket can be destroyed without error. These objects are not recoverable.",
  },
  acceleration_status: {
    "ui:description":
      "(Optional) Sets the accelerate configuration of an existing bucket. Can be Enabled or Suspended.",
    "ui:emptyValue": null,
  },
  request_payer: {
    "ui:description":
      "(Optional) Specifies who should bear the cost of Amazon S3 data transfer. Can be either BucketOwner or Requester. By default, the owner of the S3 bucket would incur the costs of any data transfer. See Requester Pays Buckets developer guide for more information.",
    "ui:emptyValue": null,
  },
  website: {
    "ui:description":
      "Map containing static web-site hosting or redirect configuration.",
    index_document: {
      "ui:description":
        "The object key name to use when a 4XX class error occurs.",
      "ui:emptyValue": null,
    },
    error_document: {
      "ui:description":
        "The object key name to use when a 4XX class error occurs.",
      "ui:emptyValue": null,
    },
    redirect_all_requests_to: {
      "ui:description":
        "(Optional, Required if index_document is not specified) Redirect behavior for every request to this bucket's website endpoint",
      host_name: {
        "ui:description": "Name of the host where requests are redirected.",
        "ui:emptyValue": null,
      },
      protocol: {
        "ui:description":
          "Protocol to use when redirecting requests. The default is the protocol that is used in the original request. Valid values: http, https",
        "ui:emptyValue": null,
      },
    },
    routing_rules: {
      "ui:description":
        "(Optional, Conflicts with routing_rule and redirect_all_requests_to) JSON array containing routing rules describing redirect behavior and when redirects are applied. Use this parameter when your routing rules contain empty String values.",
      items: {
        "ui:description": "Routing Rule",
        condition: {
          "ui:description": "Condition",
          http_error_code_returned_equals: {
            "ui:description":
              "(Optional, Required if key_prefix_equals is not specified) HTTP error code when the redirect is applied. If specified with key_prefix_equals, then both must be true for the redirect to be applied.",
            "ui:emptyValue": null,
          },
          key_prefix_equals: {
            "ui:description":
              "(Optional, Required if http_error_code_returned_equals is not specified) Object key name prefix when the redirect is applied. If specified with http_error_code_returned_equals, then both must be true for the redirect to be applied.",
            "ui:emptyValue": null,
          },
        },
        redirect: {
          "ui:description": "Redirect",
          host_name: {
            "ui:description":
              "(Optional) Host name to use in the redirect request.",
            "ui:emptyValue": null,
          },
          http_redirect_code: {
            "ui:description":
              "(Optional) HTTP redirect code to use on the response.",
            "ui:emptyValue": null,
          },
          protocol: {
            "ui:description":
              "(Optional) Protocol to use when redirecting requests. The default is the protocol that is used in the original request. Valid values: http, https",
            "ui:emptyValue": null,
          },
          replace_key_prefix_with: {
            "ui:description":
              "(Optional, Conflicts with replace_key_with) Object key prefix to use in the redirect request. For example, to redirect requests for all pages with prefix docs/ (objects in the docs/ folder) to documents/, you can set a condition block with key_prefix_equals set to docs/ and in the redirect set replace_key_prefix_with to /documents",
            "ui:emptyValue": null,
          },
          replace_key_with: {
            "ui:description":
              "(Optional, Conflicts with replace_key_prefix_with) Specific object key to use in the redirect request. For example, redirect request to error.html.",
            "ui:emptyValue": null,
          },
        },
      },
    },
  },
  cors_rule: {
    "ui:description":
      "List of maps containing rules for Cross-Origin Resource Sharing.",
    items: {
      "ui:description": "CORS Rule",
      "ui:displayfield": "id",
      id: {
        "ui:description":
          "Unique identifier for the rule. The value cannot be longer than 255 characters.",
      },
      allowed_headers: {
        "ui:description":
          "(Optional) Set of Headers that are specified in the Access-Control-Request-Headers header.",
        items: {
          "ui:description": "Allowed Header",
        },
      },
      allowed_methods: {
        "ui:description":
          "(Required) Set of HTTP methods that you allow the origin to execute. Valid values are GET, PUT, HEAD, POST, and DELETE.",
        items: {
          "ui:description": "Allowed Method",
        },
      },
      allowed_origins: {
        "ui:description":
          "(Required) Set of origins you want customers to be able to access the bucket from.",
        items: {
          "ui:description": "Allowed Origin",
        },
      },
      expose_headers: {
        "ui:description":
          "(Optional) Set of headers in the response that you want customers to be able to access from their applications (for example, from a JavaScript XMLHttpRequest object).",
        items: {
          "ui:description": "Expose Header",
        },
      },
      max_age_seconds: {
        "ui:description":
          "(Optional) The time in seconds that your browser is to cache the preflight response for the specified resource.",
        "ui:emptyValue": null,
      },
    },
  },
  versioning: {
    "ui:description": "Map containing versioning configuration.",
    enabled: {
      "ui:description": "Enable versioning.",
    },
    mfa: {
      "ui:description":
        "Required if mfa_delete is enabled. Concatenation of the authentication device's serial number, a space, and the value that is displayed on your authentication device.",
    },
    mfa_delete: {
      "ui:description":
        "(Optional) Specifies whether MFA delete is enabled in the bucket versioning configuration.",
    },
  },
  logging: {
    "ui:description": "Map containing access bucket logging configuration.",
    target_bucket: {
      "ui:description":
        "The name of the bucket that will receive the log objects.",
      "ui:emptyValue": null,
    },
    target_prefix: {
      "ui:description":
        "The prefix that should be used when storing log objects.",
      "ui:emptyValue": null,
    },
  },
  access_log_delivery_policy_source_buckets: {
    "ui:description":
      "(Optional) List of S3 bucket ARNs wich should be allowed to deliver access logs to this bucket.",
    items: {
      "ui:description": "Source Bucket",
    },
  },
  access_log_delivery_policy_source_accounts: {
    "ui:description":
      "(Optional) List of AWS Account IDs should be allowed to deliver access logs to this bucket.",
    items: {
      "ui:description": "Source Account",
    },
  },
  grant: {
    "ui:description": "An ACL policy grant. Conflicts with acl",
    items: {
      "ui:description": "Grant",
      "ui:displayfield": "permission",
      permission: {
        "ui:description":
          "Logging permissions assigned to the grantee for the bucket.",
      },
      id: {
        "ui:description": "Canonical user ID of the grantee",
        "ui:emptyValue": null,
      },
      type: {
        "ui:description":
          "Type of grantee. Valid values: CanonicalUser, AmazonCustomerByEmail, Group",
      },
      email: {
        "ui:description":
          "Email address of the grantee. See Regions and Endpoints for supported AWS regions where this argument can be specified.",
        "ui:emptyValue": null,
      },
      uri: {
        "ui:description": "URI of the grantee group.",
        "ui:emptyValue": null,
      },
    },
  },
  owner: {
    "ui:description": "Bucket owner's display name and ID. Conflicts with acl",
    display_name: {
      "ui:description": "Bucket owner's display name",
      "ui:emptyValue": null,
    },
    id: {
      "ui:description": "Bucket owner's ID",
      "ui:emptyValue": null,
    },
  },
  expected_bucket_owner: {
    "ui:description": "The account ID of the expected bucket owner",
    "ui:emptyValue": null,
  },
  lifecycle_rule: {
    "ui:description":
      "List of maps containing configuration of object lifecycle management.",
    items: {
      "ui:description": "Lifecycle Rule",
      "ui:displayfield": "id",
      id: {
        "ui:description": "ID",
      },
      enabled: {
        "ui:description": "Enabled",
      },
      abort_incomplete_multipart_upload_days: {
        "ui:description": "Abort Incomplete Multipart Upload Days",
        "ui:emptyValue": null,
      },
      expiration: {
        "ui:description": "Expiration",
        date: {
          "ui:description": "Date",
          "ui:emptyValue": null,
        },
        days: {
          "ui:description": "Days",
          "ui:emptyValue": null,
        },
        expired_object_delete_marker: {
          "ui:description": "Expired Object Delete Marker",
        },
      },
      transition: {
        "ui:description": "Transition",
        items: {
          "ui:description": "Transition",
          "ui:displayfield": "date",

          date: {
            "ui:description": "Date",
            "ui:emptyValue": null,
          },
          days: {
            "ui:description": "Days",
            "ui:emptyValue": null,
          },
          storage_class: {
            "ui:description": "Storage Class",
          },
        },
      },
      noncurrent_version_expiration: {
        "ui:description": "Noncurrent Version Expiration",
        newer_noncurrent_versions: {
          "ui:description": "Newer Noncurrent Versions",
          "ui:emptyValue": null,
        },
        days: {
          "ui:description": "Days",
          "ui:emptyValue": null,
        },
      },
      noncurrent_version_transition: {
        "ui:description": "Noncurrent Version Transitions",
        items: {
          "ui:description": "Noncurrent Version Transition",
          "ui:displayfield": "storage_class",
          newer_noncurrent_versions: {
            "ui:description": "Newer Noncurrent Versions",
            "ui:emptyValue": null,
          },
          noncurrent_days: {
            "ui:description": "Days",
            "ui:emptyValue": null,
          },
          storage_class: {
            "ui:description": "Storage Class",
          },
        },
      },
      filter: {
        "ui:description": "Filter",
        items: {
          "ui:description": "Filter",
          "ui:displayfield": "prefix",
          prefix: {
            "ui:description": "Prefix",
            "ui:emptyValue": null,
          },
          object_size_greater_than: {
            "ui:description": "Object Size Greater Than",
            "ui:emptyValue": null,
          },
          object_size_less_than: {
            "ui:description": "Object Size Less Than",
            "ui:emptyValue": null,
          },
          tags: {
            "ui:description": "Tags",
            additionalProperties: {
              "ui:description": "Tag Value",
            },
          },
        },
      },
    },
  },
  replication_configuration: {
    "ui:description": "Map containing cross-region replication configuration.",
    role: {
      "ui:description":
        "ARN of the IAM role for Amazon S3 to assume when replicating the objects.",
    },
    rules: {
      "ui:description": "List of replication rules",
      items: {
        "ui:description": "Replication Rule",
        "ui:displayfield": "id",
        id: {
          "ui:description":
            "Unique identifier for the rule. Must be less than or equal to 255 characters in length",
        },
        priority: {
          "ui:description":
            "Priority associated with the rule. Priority should only be set if filter is configured. If not provided, defaults to 0. Priority must be unique between multiple rules.",
          "ui:emptyValue": null,
        },
        status: {
          "ui:description": "Whether the rule is enabled.",
        },
        delete_marker_replication: {
          "ui:description":
            "Whether delete markers are replicated. This argument is only valid with V2 replication configurations (i.e., when filter is used)",
          status: {
            "ui:description": "Whether delete markers are replicated.",
          },
        },
        destination: {
          "ui:description": "Specifies the destination for the rule",
          bucket: {
            "ui:description":
              "ARN of the bucket where you want Amazon S3 to store the results.",
          },
          account: {
            "ui:description":
              "Account ID to specify the replica ownership. Must be used in conjunction with access_control_translation override configuration",
            "ui:emptyValue": null,
          },
          storage_class: {
            "ui:description":
              "The storage class used to store the object. By default, Amazon S3 uses the storage class of the source object to create the object replica.",
          },
          access_control_translation: {
            "ui:description":
              "Configuration block that specifies the overrides to use for object owners on replication. See below. Specify this only in a cross-account scenario (where source and destination bucket owners are not the same), and you want to change replica ownership to the AWS account that owns the destination bucket. If this is not specified in the replication configuration, the replicas are owned by same AWS account that owns the source object. Must be used in conjunction with account owner override configuration.",
            owner: {
              "ui:description":
                "Specifies the replica ownership. For default and valid values, see PUT bucket replication in the Amazon S3 API Reference. Valid values: Destination",
            },
          },
          encryption_configuration: {
            "ui:description":
              "Configuration block that provides information about encryption. If source_selection_criteria is specified, you must specify this element.",
            replica_kms_key_id: {
              "ui:description":
                "The Amazon Resource Name (ARN) of the AWS KMS key for Amazon S3 to use to encrypt the replica object.",
              "ui:emptyValue": null,
            },
          },
          replication_time: {
            "ui:description":
              "Configuration block that specifies S3 Replication Time Control (S3 RTC), including whether S3 RTC is enabled and the time when all objects and operations on objects must be replicated. Replication Time Control must be used in conjunction with metrics.",
            status: {
              "ui:description":
                "Specifies whether S3 Replication Time Control is enabled.",
            },
            time: {
              "ui:description":
                "Specifies the time by which replication should complete for all objects and operations on objects.",
              minutes: {
                "ui:description": "Time in minutes. Valid values: 15",
              },
            },
          },
          metrics: {
            "ui:description":
              "Configuration block that specifies metrics-related settings enabling replication metrics and events.",
            status: {
              "ui:description":
                "Specifies whether the replication metrics and events are enabled.",
            },
            event_threshold: {
              "ui:description":
                "A container specifying the time threshold for emitting the s3:Replication:OperationMissedThreshold event.",
              minutes: {
                "ui:description": "Time in minutes. Valid values: 15",
              },
            },
          },
        },
        source_selection_criteria: {
          "ui:description": " Specifies special object selection criteria",
          sse_kms_encrypted_objects: {
            "ui:description":
              "Configuration block for filter information for the selection of Amazon S3 objects encrypted with AWS KMS. If specified, replica_kms_key_id in destination encryption_configuration must be specified as well.",
            status: {
              "ui:description":
                "Whether the existing objects should be replicated. Either 'Enabled' or 'Disabled'.",
            },
          },
          replica_modifications: {
            "ui:description":
              "Configuration block that you can specify for selections for modifications on replicas. Amazon S3 doesn't replicate replica modifications by default. In the latest version of replication configuration (when filter is specified), you can specify this element and set the status to Enabled to replicate modifications on replicas.",
            status: {
              "ui:description":
                "Whether replica modifications are replicated. Either 'Enabled' or 'Disabled'.",
            },
          },
        },
        filter: {
          "ui:description":
            "Filter that identifies subset of objects to which the replication rule applies",
          prefix: {
            "ui:description":
              "Object key name prefix that identifies subset of objects to which the rule applies. Must be less than or equal to 1024 characters in length.",
            "ui:emptyValue": null,
          },
          tags: {
            "ui:description":
              "Tags that identifies subset of objects to which the rule applies",
            additionalProperties: {
              "ui:description": "Tag Value",
            },
          },
        },
      },
    },
  },
  server_side_encryption_configuration: {
    "ui:description": "Map containing server-side encryption configuration.",
    rule: {
      "ui:description":
        "Set of server-side encryption configuration rules. Currently, only a single rule is supported",
      bucket_key_enabled: {
        "ui:description":
          "Whether or not to use Amazon S3 Bucket Keys for SSE-KMS.",
      },
      apply_server_side_encryption_by_default: {
        "ui:description":
          "apply_server_side_encryption_by_default. Single object for setting server-side encryption by default.",
        kms_master_key_id: {
          "ui:description":
            "The AWS KMS master key ID used for the SSE-KMS encryption. This can only be used when you set the value of sse_algorithm as aws:kms. The default aws/s3 AWS KMS master key is used if this element is absent while the sse_algorithm is aws:kms.",
          "ui:emptyValue": null,
        },
        sse_algorithm: {
          "ui:description":
            "Server-side encryption algorithm to use for the default encryption.",
          //"ui:emptyValue": null,
        },
      },
    },
  },
  intelligent_tiering: {
    "ui:description":
      "Map containing intelligent tiering configurations. Key corresponds to the name in s3_bucket_intelligent_tiering_configuration",
    additionalProperties: {
      "ui:description": "Intelligent Tiering Settings",
      filter: {
        "ui:description": "Filter Settings",
        prefix: {
          "ui:description":
            "Object key name prefix that identifies the subset of objects to which the configuration applies.",
          "ui:emptyValue": null,
        },
        tags: {
          "ui:description":
            "Tag key and value pair that identifies the subset of objects to which the configuration applies. All of these tags must exist in the object's tag set in order for the configuration to apply.",
          additionalProperties: {
            "ui:description": "Tag Value",
          },
        },
      },
      status: {
        "ui:description": "Specifies the status of the configuration",
      },
      tiering: {
        "ui:description":
          "S3 Intelligent-Tiering storage class tiers of the configuration. Valid key values are: ARCHIVE_ACCESS, DEEP_ARCHIVE_ACCESS",
        additionalProperties: {
          "ui:description": "Tiering Days",
          days: {
            "ui:description":
              "Number of days after object creation when access tier changes take effect.",
          },
        },
      },
    },
  },
  object_lock_configuration: {
    "ui:description": "Map containing S3 object locking configuration.",
    token: {
      "ui:description":
        "Token to allow Object Lock to be enabled for an existing bucket. You must contact AWS support for the bucket's 'Object Lock token'. The token is generated in the back-end when versioning is enabled on a bucket. For more details on versioning, see the aws_s3_bucket_versioning resource.",
      "ui:emptyValue": null,
    },
    rule: {
      "ui:description":
        "Configuration block for specifying the Object Lock rule for the specified object.",
      default_retention: {
        "ui:description":
          "Configuration block for specifying the default Object Lock retention settings for new objects placed in the specified bucket",
        mode: {
          "ui:description":
            "Default Object Lock retention mode you want to apply to new objects placed in the specified bucket. Valid values: COMPLIANCE, GOVERNANCE",
        },
        days: {
          "ui:description":
            "Required if years is not specified. Number of days that you want to specify for the default retention period.",
          "ui:emptyValue": null,
        },
        years: {
          "ui:description":
            "Required if days is not specified. Number of years that you want to specify for the default retention period.",
        },
      },
    },
  },
  metric_configuration: {
    "ui:description": "Map containing bucket metric configuration.",
    items: {
      "ui:description": "Metric Configuration",
      "ui:displayfield": "name",
      name: {
        "ui:description":
          "Unique identifier of the metrics configuration for the bucket. Must be less than or equal to 64 characters in length.",
      },
      filter: {
        "ui:description":
          "Object filtering that accepts a prefix, tags, or a logical AND of prefix and tags",
        prefix: {
          "ui:description": "Object prefix for filtering (singular)",
          "ui:emptyValue": null,
        },
        tags: {
          "ui:description": "Object tags for filtering (up to 10))",
          additionalProperties: {
            "ui:description": "Tag Value",
          },
        },
      },
    },
  },
  inventory_configuration: {
    "ui:description": "Map containing S3 inventory configuration.",
    items: {
      "ui:description": "Inventory Configuration",
      "ui:displayfield": "name",
      name: {
        "ui:description":
          "Unique identifier of the inventory configuration for the bucket",
      },
      enabled: {
        "ui:description":
          "Specifies whether the inventory is enabled or disabled",
      },
      optional_fields: {
        "ui:description":
          "List of optional fields that are included in the inventory results. Please refer to the S3 documentation for more details.",
        items: {
          "ui:description": "Optional Field",
        },
      },
      frequency: {
        "ui:description":
          "Specifies how frequently inventory results are produced. Valid values: Daily, Weekly",
      },
      included_object_versions: {
        "ui:description":
          "Object versions to include in the inventory list. Valid values: All, Current",
      },
      destination: {
        "ui:description":
          "Information about where to publish the inventory results",
        format: {
          "ui:description":
            "Specifies the output format of the inventory results. Valid values: CSV, ORC, Parquet",
        },
        account_id: {
          "ui:description":
            "ID of the account that owns the destination bucket. Recommended to be set to prevent problems if the destination bucket ownership changes.",
          "ui:emptyValue": null,
        },
        prefix: {
          "ui:description":
            "Specifies the prefix that is prepended to all inventory results",
          "ui:emptyValue": null,
        },
        encryption: {
          "ui:description":
            "Contains the type of server-side encryption used to encrypt the inventory results",
          encryption_type: {
            "ui:description":
              "Specifies the server-side encryption algorithm to use when Amazon S3 creates or copies an object in S3 Glacier or S3 Glacier Deep Archive. Valid values: sse_S3, sse_kms",
            "ui:emptyValue": null,
          },
          kms_key_id: {
            "ui:description":
              "Specifies the ID (Key ARN or Alias ARN) of the AWS Key Management Service (KMS) master encryption key used to encrypt the inventory results.",
            "ui:emptyValue": null,
          },
        },
      },
      filter: {
        "ui:description":
          "Specifies an inventory filter. The inventory only includes objects that meet the filter's criteria.",
        prefix: {
          "ui:description":
            "The prefix that an object must have to be included in the inventory results.",
          "ui:emptyValue": null,
        },
      },
    },
  },
  inventory_source_account_id: {
    "ui:description": "The inventory source account id.",
  },
  inventory_source_bucket_arn: {
    "ui:description": "The inventory source bucket ARN.",
  },
  inventory_self_source_destination: {
    "ui:description":
      "Whether or not the inventory source bucket is also the destination bucket.",
  },
  analytics_configuration: {
    "ui:description": "Map containing S3 analytics configuration.",
    items: {
      "ui:description": "Analytics Configuration",
      "ui:displayfield": "name",
      name: {
        "ui:description":
          "Unique identifier of the analytics configuration for the bucket",
      },
      storage_class_analysis: {
        "ui:description": "Configuration for the analytics data export",

        output_schema_version: {
          "ui:description":
            "Schema version of exported analytics data. Allowed values: V_1",
        },

        export_format: {
          "ui:description":
            "Output format of exported analytics data. Allowed values: CSV",
        },
        export_prefix: {
          "ui:description": " Prefix to append to exported analytics data",
        },
      },
      filter: {
        "ui:description":
          "Object filtering that accepts a prefix, tags, or a logical AND of prefix and tags",
        prefix: {
          "ui:description": "Object prefix for filtering (singular)",
          "ui:emptyValue": null,
        },
        tags: {
          "ui:description": "Object tags for filtering (up to 10))",
          additionalProperties: {
            "ui:description": "Tag Value",
          },
        },
      },
    },
  },
  analytics_source_account_id: {
    "ui:description": "The analytics source account id.",
    "ui:emptyValue": null,
  },
  analytics_source_bucket_arn: {
    "ui:description": "The analytics source bucket ARN.",
    "ui:emptyValue": null,
  },
  analytics_self_source_destination: {
    "ui:description":
      "Whether or not the analytics source bucket is also the destination bucket.",
  },
  object_lock_enabled: {
    "ui:description":
      "Whether S3 bucket should have an Object Lock configuration enabled.",
  },
  block_public_acls: {
    "ui:description":
      "Whether Amazon S3 should block public ACLs for this bucket.",
  },
  block_public_policy: {
    "ui:description":
      "Whether Amazon S3 should block public bucket policies for this bucket.",
  },
  ignore_public_acls: {
    "ui:description":
      "Whether Amazon S3 should ignore public ACLs for this bucket.",
  },
  restrict_public_buckets: {
    "ui:description":
      "Whether Amazon S3 should restrict public bucket policies for this bucket.",
  },
  control_object_ownership: {
    "ui:description":
      "Whether to manage S3 Bucket Ownership Controls on this bucket.",
  },
  object_ownership: {
    "ui:description":
      "Object ownership. Valid values: BucketOwnerEnforced, BucketOwnerPreferred or ObjectWriter. 'BucketOwnerEnforced': ACLs are disabled, and the bucket owner automatically owns and has full control over every object in the bucket. 'BucketOwnerPreferred': Objects uploaded to the bucket change ownership to the bucket owner if the objects are uploaded with the bucket-owner-full-control canned ACL. 'ObjectWriter': The uploading account will own the object if the object is uploaded with the bucket-owner-full-control canned ACL.",
  },
};
