import { React, useEffect, useState } from "react";
import {
  Box,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  MoreHoriz,
  Remove as RemoveIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { gcpResourceConfigs, getType } from "./gcpResourceDefintions";
import { v4 as uuidv4 } from "uuid";
import { useSnackbar } from "notistack";
import { apiCall } from "../utils/api";
import { useAuth } from "../contexts/AuthContext";

function ArchitecureTemplateResourceButtonGroup({
  node,
  refreshNodes,
  sendNodeUpstream,
  removeNode,
  isAdmin,
  organization,
  templateId,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  // https://stackoverflow.com/questions/60618844/react-hooks-useeffect-is-called-twice-even-if-an-empty-array-is-used-as-an-ar
  useEffect(() => {
    // eslint-disable-next-line
  }, []);

  const openMenu = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRemove = (event) => {
    removeNode(node);
  };

  const allowedToAdd = (item) => {
    // This function checks if there is already one of the onlyOneAllowedType in node.children
    const onlyOneAllowedType = [
      "orgIam",
      "folderIam",
      "projectIam",
      "serviceAccounts",
      "firewalls",
      "publicIPs",
    ];
    let itemType = getType(item);
    if (!onlyOneAllowedType.includes(itemType)) {
      return true;
    }
    for (let child of node.children) {
      if (child.type === itemType) {
        return false;
      }
    }
    return true;
  };

  const insertNode = async (node, itemValue, id) => {
    setOpenBackdrop(true);
    const parent_new_children = node.children.map((child) => ({
      id: child.id,
      key: child.id,
      type: child.type,
    }));
    parent_new_children.push({
      id: id,
      key: id,
      type: getType(itemValue),
    });
    parent_new_children.sort((a, b) => {
      return (
        gcpResourceConfigs[a.type].priority -
        gcpResourceConfigs[b.type].priority
      );
    });
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/template/insert/node/${organization.id}/${templateId}/${id}`,
      "POST",
      {
        type: getType(itemValue),
        parent: {
          type: node.type,
          id: node.id,
          key: node.id,
        },
        id: id,
        key: id,
        children: [],
        priority: gcpResourceConfigs[getType(itemValue)].priority,
        properties: {},
        parent_new_children: parent_new_children,
      },
      currentUser
    );
    if (res.status !== 200) {
      enqueueSnackbar("Error inserting the node", { variant: "error" });
    }
    setOpenBackdrop(false);
  };
  const handleAddResource = async (e) => {
    e.preventDefault();
    const { itemValue } = e.currentTarget.dataset;
    if (!itemValue) {
      setAnchorEl(null);
      return;
    }
    if (allowedToAdd(itemValue)) {
      const id = uuidv4();
      await insertNode(node, itemValue, id);
      refreshNodes();
    } else {
      alert(`You can specify only one ${itemValue} in the parent`);
    }
    setAnchorEl(null);
  };

  const sendCurrentNodeUpstream = (event) => {
    event.preventDefault();
    event.stopPropagation();
    sendNodeUpstream(node);
  };

  return (
    <Box
      sx={{
        display: "flex",
        margin: 0,
        alignItems: "center",
      }}
    >
      <Tooltip title="Module Config">
        <span>
          <IconButton
            variant="contained"
            color="primary"
            onClick={sendCurrentNodeUpstream}
            size="small"
          >
            <MoreHoriz />
          </IconButton>
        </span>
      </Tooltip>
      {gcpResourceConfigs[node.type].allowedChildren.length !== 0 &&
        isAdmin() && (
          <Tooltip title="Add Resource">
            <IconButton
              variant="contained"
              color="primary"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={openMenu}
              size="small"
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      {node.children.length === 0 &&
        node.type !== "organization" &&
        isAdmin() && (
          <Tooltip title="Remove Module">
            <span>
              <IconButton
                variant="contained"
                color="primary"
                onClick={handleRemove}
                size="small"
              >
                <RemoveIcon />
              </IconButton>
            </span>
          </Tooltip>
        )}
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {gcpResourceConfigs[node.type].allowedChildren.map((option) => (
          <MenuItem
            key={option}
            onClick={handleAddResource}
            data-item-value={option}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.modal + 50,
        }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress></CircularProgress>
      </Backdrop>
    </Box>
  );
}
export default ArchitecureTemplateResourceButtonGroup;
