export const databricksUCexternalStorageSchema = {
  type: "object",
  properties: {
    bucket: {
      type: "string",
      description: "S3 bucket name",
    },
    prefix: {
      type: "string",
      description: "S3 bucket prefix",
      default: "",
    },
    owner: {
      type: ["string", "null"],
      description:
        "Username/groupname/sp application_id of the external location owner.",
    },
    skip_validation: {
      type: "boolean",
      description: "Skip Validation",
      default: true,
    },
    read_only: {
      type: "boolean",
      description: "Indicates whether the external location is read-only.",
      default: false,
    },
    force_destroy: {
      type: "boolean",
      description: "Destroy external location regardless of its dependents",
      default: false,
    },
    create_bucket: {
      type: "boolean",
      description: "Wheather to create a new bucket or use an existing one",
      default: true,
    },
    external_location_grants: {
      type: "array",
      description: "List of grants",
      items: {
        type: "object",
        properties: {
          principal: {
            type: "string",
            description:
              "User name, group name or service principal application ID",
          },
          privileges: {
            type: "array",
            description:
              "One or more privileges that are specific to a securable type.",
            items: {
              type: "string",
              description: "Privilege to grant to the principal",
              enum: [
                "ALL_PRIVILEGES",
                "CREATE_EXTERNAL_TABLE",
                "CREATE_MANAGED_STORAGE",
                "CREATE EXTERNAL VOLUME",
                "READ_FILES",
                "WRITE_FILES",
              ],
            },
          },
        },
        required: ["principal"],
      },
    },
    external_creds_grants: {
      type: "array",
      description: "List of grants",
      items: {
        type: "object",
        properties: {
          principal: {
            type: "string",
            description:
              "User name, group name or service principal application ID",
          },
          privileges: {
            type: "array",
            description:
              "One or more privileges that are specific to a securable type.",
            items: {
              type: "string",
              description: "Privilege to grant to the principal",
              enum: [
                "ALL_PRIVILEGES",
                "CREATE_EXTERNAL_TABLE",
                "READ_FILES",
                "WRITE_FILES",
              ],
            },
          },
        },
        required: ["principal"],
      },
    },
  },
};
export const databricksUCexternalStorageUISchema = {
  bucket: {
    "ui:emptyValue": "",
  },
  prefix: {
    "ui:emptyValue": "",
  },
  owner: {
    "ui:emptyValue": null,
  },
  skip_validation: {},
  read_only: {},
  force_destroy: {},
  create_bucket: {},
  external_location_grants: {
    "ui:description": "List of grants",
    items: {
      "ui:displayfield": "principal",
      "ui:description": "Principal to grant privileges to.",
      privileges: {
        "ui:description":
          "One or more privileges that are specific to a securable type.",
        items: {
          "ui:description": "Privilege to grant to the principal",
        },
      },
    },
  },
  external_creds_grants: {
    "ui:description": "List of grants",
    items: {
      "ui:displayfield": "principal",
      "ui:description": "Principal to grant privileges to.",
      privileges: {
        "ui:description":
          "One or more privileges that are specific to a securable type.",
        items: {
          "ui:description": "Privilege to grant to the principal",
        },
      },
    },
  },
};
