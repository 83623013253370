export const gcpCloudSQLprivateAccessSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
      default: "",
    },
    address: {
      type: "string",
      title: "Address",
      default: "",
    },
    description: {
      type: "string",
      title: "Description",
      default: "",
    },
    prefix_length: {
      type: "number",
      title: "Prefix Length",
      default: 16,
    },
    ip_version: {
      type: "string",
      title: "IP Version",
      enum: ["IPV4", "IPV6"],
      default: "IPV4",
    },
    labels: {
      type: "object",
      title: "Labels",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },
  },
  required: ["name"],
};

export const gcpCloudSQLprivateAccessUISchema = {
  "ui:description":
    "MySQL Private IP configurations require a special peering between your VPC network and a VPC managed by Google. The module supports creating such a peering. It is sufficient to instantiate this module once for all MySQL instances that are connected to the same VPC",
  name: {
    "ui:description": "Name of the module",
  },
  address: {
    "ui:description":
      "First IP address of the IP range to allocate to CLoud SQL instances and other Private Service Access services. If not set, GCP will pick a valid one for you.",
  },
  description: {
    "ui:description": "An optional description of the Global Address resource.",
  },
  prefix_length: {
    "ui:description":
      "Prefix length of the IP range reserved for Cloud SQL instances and other Private Service Access services. Defaults to /16.",
  },
  ip_version: {
    "ui:description": "IP Version for the allocation. Can be IPV4 or IPV6.",
  },
  labels: {
    "ui:description":
      "A set of key/value label pairs to assign to the resource.",
  },
};
