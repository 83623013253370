export const databricksCatalogSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    storage_root: {
      type: ["string", "null"],
      default: null,
      title: "Storage Root",
    },
    provider_name: {
      type: ["string", "null"],
      default: null,
      title: "Provider Name",
    },
    share_name: {
      type: ["string", "null"],
      default: null,
      title: "Share Name",
    },
    owner: {
      type: ["string", "null"],
      default: null,
      title: "Owner",
    },
    isolation_mode: {
      type: "string",
      default: "ISOLATED",
      enum: ["ISOLATED", "OPEN"],
      title: "Isolation Mode",
    },
    properties: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      default: {},
      title: "Properties",
    },
    options: {
      type: ["string", "null"],
      default: null,
      title: "Options",
    },
    force_destroy: {
      type: "boolean",
      default: false,
      title: "Force Destroy",
    },
    comment: {
      type: "string",
      default: "This catalog is managed by terraform",
      title: "Comment",
    },
    grants: {
      type: "array",
      description: "List of grants",
      items: {
        type: "object",
        properties: {
          principal: {
            type: "string",
            description:
              "User name, group name or service principal application ID",
          },
          privileges: {
            type: "array",
            description:
              "One or more privileges that are specific to a securable type.",
            items: {
              type: "string",
              description: "Privilege to grant to the principal",
              enum: [
                "ALL_PRIVILEGES",
                "CREATE_SCHEMA",
                "USE_CATALOG",
                "CREATE_FUNCTION",
                "CREATE_TABLE",
                "CREATE_VOLUME",
                "EXECUTE",
                "MODIFY",
                "REFRESH",
                "SELECT",
                "READ_VOLUME",
                "WRITE_VOLUME",
                "USE_SCHEMA",
              ],
            },
          },
        },
        required: ["principal"],
      },
    },
    attach_workspace_ids: {
      type: "array",
      description:
        "List of workspace IDs to attach the catalog to. Use when isolation_mode is ISOLATED.",
      items: {
        type: "string",
        description: "Workspace ID to attach the catalog to.",
      },
    },
  },
  required: ["name"],
};

export const databricksCatalogUISchema = {
  name: {
    "ui:description": "Name of the catalog",
  },
  storage_root: {
    "ui:emptyValue": null,
    "ui:description": "Managed location of the catalog.",
  },
  provider_name: {
    "ui:emptyValue": null,
    "ui:description":
      "For Delta Sharing Catalogs: the name of the delta sharing provider. Change forces creation of a new resource.",
  },
  share_name: {
    "ui:emptyValue": null,
    "ui:description":
      "For Delta Sharing Catalogs: the name of the share under the share provider. Change forces creation of a new resource",
  },
  owner: {
    "ui:emptyValue": null,
    "ui:description":
      "Username/groupname/sp application_id of the catalog owner.",
  },
  isolation_mode: {
    "ui:description":
      "Whether the catalog is accessible from all workspaces or a specific set of workspaces. Can be ISOLATED or OPEN. Setting the catalog to ISOLATED will automatically allow access from the current workspace.",
  },
  properties: {
    "ui:description": "Extensible Catalog properties",
  },
  options: {
    "ui:emptyValue": null,
    "ui:description":
      "For Foreign Catalogs: the name of the entity from an external data source that maps to a catalog. For example, the database name in a PostgreSQL server.",
  },
  force_destroy: {
    "ui:description": "Delete catalog regardless of its contents",
  },
  comment: {
    "ui:description": "Comment",
  },
  grants: {
    "ui:description": "List of grants",
    items: {
      "ui:displayfield": "principal",
      "ui:description": "Principal to grant privileges to.",
      privileges: {
        "ui:description":
          "One or more privileges that are specific to a securable type.",
        items: {
          "ui:description": "Privilege to grant to the principal",
        },
      },
    },
  },
  attach_workspace_ids: {
    "ui:description":
      "List of workspace IDs to attach the catalog to. Use when isolation_mode is ISOLATED.",
    items: {
      "ui:description": "Workspace ID to attach the catalog to.",
    },
  },
};
