export const workspaceSchema = {
  type: "object",
  properties: {
    workspace_name: {
      type: "string",
      description: "Databricks Workspace Name",
    },
    bucket: {
      type: "string",
      description: "Root Bucket Name",
    },
    availability_zones: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Availability Zones",
    },
    // metastore_id: {
    //   type: "string",
    //   description: "Databricks Metastore ID",
    // },
    harden_firewall: {
      type: "boolean",
      description: "Enable Firewall and Harden Network Security",
      default: false,
    },
    default_catalog_name: {
      type: "string",
      description: "Default Unity Catalog Name for Databricks Metastore",
      default: "hive_metastore",
    },
    vpc_cidr_range: {
      type: "string",
      description: "VPC CIDR Range",
      default: "10.0.0.0/18",
    },
    private_subnets_cidr: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Private Subnets CIDR Ranges",
      default: ["10.0.16.0/22", "10.0.24.0/22"],
    },
    privatelink_subnets_cidr: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Private Link Subnets CIDR Ranges",
      default: ["10.0.32.0/26", "10.0.32.64/26"],
    },
    public_subnets_cidr: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Public Subnets CIDR Ranges",
      default: ["10.0.32.128/26", "10.0.32.192/26"],
    },
    firewall_subnets_cidr: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Firewall Subnets CIDR Ranges",
      default: ["10.0.33.0/26", "10.0.33.64/26"],
    },
    firewall_allow_list: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Firewall Allow List",
      default: [
        ".pypi.org",
        ".pythonhosted.org",
        ".cran.r-project.org",
        "mdb7sywh50xhpr.chkweekm4xjq.us-east-1.rds.amazonaws.com",
      ],
    },
    firewall_protocol_deny_list: {
      type: "string",
      description: "Firewall Protocol Deny List",
      default: "ICMP,FTP,SSH",
    },
    sg_egress_ports: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Security Group Egress Ports",
      default: ["443", "3306", "6666"],
    },
    sg_ingress_protocol: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Security Group Ingress Protocol",
      default: ["tcp", "udp"],
    },
    sg_egress_protocol: {
      type: "array",
      items: {
        type: "string",
      },
      description: "Security Group Egress Protocol",
      default: ["tcp", "udp"],
    },
    workspace_vpce_service: {
      type: "string",
      description: "Workspace VPCE Service",
      default: "com.amazonaws.vpce.us-east-1.vpce-svc-09143d1e626de2f04",
    },
    relay_vpce_service: {
      type: "string",
      description: "Relay VPCE Service",
      default: "com.amazonaws.vpce.us-east-1.vpce-svc-00018a8c3ff62ffdf",
    },
    tags: {
      type: "object",
      description: "Tags to apply to the created resources",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },
  },
  required: ["workspace_name", "bucket"],
};

export const workspaceUISchema = {
  "ui:description": "Create Databricks Workspace",
  workspace_name: {
    "ui:emptyValue": "",
  },
  bucket: {
    "ui:emptyValue": "",
  },
  availability_zones: {
    "ui:description": "AWS Availability Zones",
    items: {
      "ui:description": "Availability Zone",
    },
  },
  //   metastore_id: {
  //     "ui:emptyValue": "",
  //   },
  harden_firewall: {},
  default_catalog_name: {
    "ui:emptyValue": "",
  },
  vpc_cidr_range: {
    "ui:emptyValue": "",
  },
  private_subnets_cidr: {
    "ui:description": "Private Subnet CIDR Ranges",
    items: {
      "ui:description": "Private Subnet CIDR Range",
    },
  },
  privatelink_subnets_cidr: {
    "ui:description": "Private Link Subnet CIDR Ranges",
    items: {
      "ui:description": "Private Link Subnet CIDR Range",
    },
  },
  public_subnets_cidr: {
    "ui:description": "Public Subnet CIDR Ranges",
    items: {
      "ui:description": "Public Subnet CIDR Range",
    },
  },
  firewall_subnets_cidr: {
    "ui:description": "Firewall Subnet CIDR Ranges",
    items: {
      "ui:description": "Firewall Subnet CIDR Range",
    },
  },
  firewall_allow_list: {
    "ui:description": "Firewall Allow List",
    items: {
      "ui:description": "Firewall Allow List",
    },
  },
  firewall_protocol_deny_list: {
    "ui:emptyValue": "",
  },
  sg_egress_ports: {
    "ui:description": "Security Group Egress Ports",
    items: {
      "ui:description": "Security Group Egress Port",
    },
  },
  sg_ingress_protocol: {
    "ui:description": "Security Group Ingress Protocols",
    items: {
      "ui:description": "Security Group Ingress Protocol",
    },
  },
  sg_egress_protocol: {
    "ui:description": "Security Group Egress Protocols",
    items: {
      "ui:description": "Security Group Egress Protocol",
    },
  },
  workspace_vpce_service: {
    "ui:emptyValue": "",
  },
  relay_vpce_service: {
    "ui:emptyValue": "",
  },
  tags: {
    "ui:emptyValue": {},
  },
};
