import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { auth, googleProvider } from "../../src/firebase";
import { fetchOrganizations } from "../utils/db";
import {
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useGoogleLogin, hasGrantedAnyScopeGoogle } from "@react-oauth/google";
import { apiCall } from "../utils/api";
import { createTheme } from "@mui/material/styles";

const AuthContext = createContext({ toggleColorMode: () => {} });

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [expiryTokenDate, setExpiryTokenDate] = useState(0);
  const [organizations, setOrganizations] = useState([]);
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [lastCommand, setLastCommand] = useState({});
  const [mode, setMode] = useState("light");

  const signOutFirebase = async () => {
    let a = await signOut(auth);
    console.log("signOut", a);
    //googleLogout();
  };

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      // https://firebase.google.com/docs/auth/web/manage-users
      if (user) {
        const { emailVerified } = user;
        if (emailVerified) {
          setCurrentUser(user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } else {
        setCurrentUser(null);
        setLoading(false);
      }
    });
  }, []);

  const signIn = async () => {
    try {
      signInWithPopup(auth, googleProvider).then((result) => {
        setIsLoadingLogin(true);
        apiCall(
          `${process.env.REACT_APP_BACKEND_URL}/api/newuser`,
          "GET",
          {},
          result.user
        ).then((res) => {
          if (res.status === 200 || res.status === 204) {
            fetchOrganizations(result.user.uid).then((orgs) => {
              setOrganizations(orgs);
            });
          }
        });
      });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const handleSignInUsernamePassword = async (e, username, password) => {
    e.preventDefault();
    setIsLoadingLogin(true);
    try {
      signInWithEmailAndPassword(auth, username, password)
        .then((userCredential) => {
          if (!userCredential.user.emailVerified) {
            alert("Please check your email and verify your account");
            setIsLoadingLogin(false);
            return;
          }
          apiCall(
            `${process.env.REACT_APP_BACKEND_URL}/api/newuser`,
            "GET",
            {},
            userCredential.user
          ).then((res) => {
            if (res.status === 200 || res.status === 204) {
              fetchOrganizations(userCredential.user.uid).then((orgs) => {
                console.log("orgs", orgs);
                setOrganizations(orgs);
                // In case currentUser is null we need to set it
                if (currentUser === null) {
                  setCurrentUser(userCredential.user);
                }
              });
            }
          });
        })
        .catch((error) => {
          const errorMessage = error.message;
          setLoading(false);
          setIsLoadingLogin(false);
          alert(errorMessage);
        });
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  const signInWithGoogle = useGoogleLogin({
    onSuccess: async ({ code }) => {
      const res = await apiCall(
        `${process.env.REACT_APP_BACKEND_URL}/auth/google`,
        "POST",
        { code },
        currentUser
      );

      if (res.status !== 200) {
        let error = await res.text();
        console.log("error", error);
        return;
      }
      let token = await res.json();

      //console.log(token);
      setAccessToken(token.access_token);
      setRefreshToken(token.refresh_token);
      setExpiryTokenDate(parseInt(token.expires_in));
      let granted = hasGrantedAnyScopeGoogle(
        token,
        "https://www.googleapis.com/auth/cloud-platform"
      );
      console.log("granted", granted);
      const event = new CustomEvent("executeCommand", {
        detail: {
          gauthenticated: true,
          access_token: token.access_token,
          last_command: lastCommand,
        },
      });
      document.dispatchEvent(event);
    },
    flow: "auth-code",
    scope: "https://www.googleapis.com/auth/cloud-platform",
    include_granted_scopes: false,
  });

  const getOrganizations = async () => {
    const orgs = await fetchOrganizations(currentUser.uid);
    // console.log("refresh orgs", orgs);
    setOrganizations(orgs);
  };

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  const value = {
    currentUser,
    accessToken,
    setAccessToken,
    signOutFirebase,
    signIn,
    handleSignInUsernamePassword,
    loading,
    organizations,
    expiryTokenDate,
    refreshToken,
    setOrganizations,
    setExpiryTokenDate,
    getOrganizations,
    signInWithGoogle,
    isLoadingLogin,
    setIsLoadingLogin,
    setLastCommand,
    colorMode,
    theme,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
