export const databricksAccountGroupSchema = {
  type: "object",
  properties: {
    display_name: {
      type: "string",
      description: "Group Name",
    },
    external_id: {
      type: ["string", "null"],
      description: "ID of the group in an external identity provider.",
      default: null,
    },
    workspace_access: {
      type: "boolean",
      default: false,
      description:
        "This is a field to allow the group to have access to Databricks Workspace.",
    },
    allow_cluster_create: {
      type: "boolean",
      default: false,
      description:
        "This is a field to allow the group to have cluster create privileges. More fine grained permissions could be assigned with databricks_permissions and cluster_id argument. Everyone without allow_cluster_create argument set, but with permission to use Cluster Policy would be able to create clusters, but within boundaries of that specific policy.",
    },
    allow_instance_pool_create: {
      type: "boolean",
      default: false,
      description:
        "This is a field to allow the group to have instance pool create privileges. More fine grained permissions could be assigned with databricks_permissions and instance_pool_id argument.",
    },
    databricks_sql_access: {
      type: "boolean",
      default: false,
      description:
        "This is a field to allow the group to have access to Databricks SQL feature in User Interface and through databricks_sql_endpoint.",
    },
    force: {
      type: "boolean",
      description:
        "Ignore cannot create group: Group with name X already exists. errors and implicitly import the specific group into Terraform state, enforcing entitlements defined in the instance of resource. This functionality is experimental and is designed to simplify corner cases, like Azure Active Directory synchronisation.",
      default: false,
    },
    members: {
      type: "array",
      desription: "List of members of the group.",
      items: {
        type: "string",
      },
    },
  },
  required: ["display_name"],
};

export const databricksAccountGroupUISchema = {
  "ui:description": "Databricks Account Group",
  display_name: {
    "ui:placeholder": "Group Name",
  },
  external_id: {
    "ui:emptyValue": null,
  },
  members: {
    items: {
      "ui:description": "Member ID",
    },
  },
};
