export const awsIAMusersSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      description: "Name of the module",
    },
    users: {
      type: "array",
      items: {
        type: "object",
        properties: {
          create_user: {
            type: "boolean",
            description: "Whether to create the IAM user",
            default: true,
          },
          create_iam_user_login_profile: {
            type: "boolean",
            description: "Whether to create IAM user login profile",
            default: true,
          },
          create_iam_access_key: {
            type: "boolean",
            description: "Whether to create IAM access key",
            default: true,
          },
          name: {
            type: "string",
            description: "Desired name for the IAM user",
          },
          path: {
            type: "string",
            description: "Desired path for the IAM user",
            default: "/",
          },
          force_destroy: {
            type: "boolean",
            description:
              "When destroying this user, destroy even if it has non-Terraform-managed IAM access keys, login profile or MFA devices. Without force_destroy a user with non-Terraform-managed access keys and login profile will fail to be destroyed.",
            default: false,
          },
          pgp_key: {
            type: "string",
            description:
              "Either a base-64 encoded PGP public key, or a keybase username in the form keybase:username. Used to encrypt password and access key.",
            default: "",
          },
          iam_access_key_status: {
            type: ["string", "null"],
            description: "Access key status to apply.",
          },
          password_reset_required: {
            type: "boolean",
            description:
              "Whether the user should be forced to reset the generated password on first login.",
            default: true,
          },
          password_length: {
            type: "number",
            description: "The length of the generated password",
            default: 20,
          },
          upload_iam_user_ssh_key: {
            type: "boolean",
            description: "Whether to upload a public ssh key to the IAM user",
            default: false,
          },
          ssh_key_encoding: {
            type: "string",
            description:
              "Specifies the public key encoding format to use in the response. To retrieve the public key in ssh-rsa format, use SSH. To retrieve the public key in PEM format, use PEM",
            default: "SSH",
          },
          ssh_public_key: {
            type: "string",
            description:
              "The SSH public key. The public key must be encoded in ssh-rsa format or PEM format",
            default: "",
          },
          permissions_boundary: {
            type: "string",
            description:
              "The ARN of the policy that is used to set the permissions boundary for the user.",
            default: "",
          },
          policy_arns: {
            type: "array",
            items: {
              type: "string",
              title: "policy_arn",
            },
            description:
              "The list of ARNs of policies directly assigned to the IAM user",
            default: [],
          },
          tags: {
            type: "object",
            additionalProperties: {
              type: "string",
            },
            description: "A map of tags to add to all resources.",
            default: {},
          },
        },
        required: ["name"],
      },
    },
  },
  required: ["name"],
};

export const awsIAMusersUISchema = {
  "ui:description":
    "Creates and manages an IAM user. Users can be created with or without IAM login profiles or access keys. IAM users can be optionally created with a permissions boundary and with tags that are applied to all resources created by the module.",
  name: {
    "ui:description": "Name of the module",
  },
  users: {
    "ui:description": "IAM users to create",
    //"ui:displayfield": "name",
    items: {
      "ui:description": "User settings",
      "ui:displayfield": "name",
      create_user: {
        "ui:description": "Whether to create the IAM user",
      },
      create_iam_user_login_profile: {
        "ui:description": "Whether to create IAM user login profile",
      },
      create_iam_access_key: {
        "ui:description": "Whether to create IAM access key",
      },
      name: {
        "ui:description": "Desired name for the IAM user",
        "ui:emptyValue": "",
      },
      path: { "ui:description": "Desired path for the IAM user" },
      force_destroy: {
        "ui:description":
          "When destroying this user, destroy even if it has non-Terraform-managed IAM access keys, login profile or MFA devices. Without force_destroy a user with non-Terraform-managed access keys and login profile will fail to be destroyed.",
      },
      pgp_key: {
        "ui:description":
          "Either a base-64 encoded PGP public key, or a keybase username in the form keybase:username. Used to encrypt password and access key.",
        "ui:emptyValue": "",
      },
      iam_access_key_status: {
        "ui:description": "Access key status to apply.",
        "ui:emptyValue": null,
      },
      password_reset_required: {
        "ui:description":
          "Whether the user should be forced to reset the generated password on first login.",
      },
      password_length: {
        "ui:description": "The length of the generated password",
      },
      upload_iam_user_ssh_key: {
        "ui:description": "Whether to upload a public ssh key to the IAM user",
      },
      ssh_key_encoding: {
        "ui:description":
          "Specifies the public key encoding format to use in the response. To retrieve the public key in ssh-rsa format, use SSH. To retrieve the public key in PEM format, use PEM",
      },
      ssh_public_key: {
        "ui:description":
          "The SSH public key. The public key must be encoded in ssh-rsa format or PEM format",
        "ui:emptyValue": "",
      },
      permissions_boundary: {
        "ui:description":
          "The ARN of the policy that is used to set the permissions boundary for the user.",
        "ui:emptyValue": "",
      },
      policy_arns: {
        "ui:description":
          "The list of ARNs of policies directly assigned to the IAM user",
        items: {
          "ui:description": "Policy ARN",
        },
      },
      tags: {
        "ui:description": "A map of tags to add to all resources.",
      },
    },
  },
};
