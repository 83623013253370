export const awsVpcSchema = {
  title: "Define network settings",
  type: "object",
  properties: {
    name: {
      title: "Name",
      type: "string",
    },
    cidr: {
      title: "CIDR",
      type: "string",
      default: "10.0.0.0/16",
    },
    secondary_cidr_blocks: {
      title: "Secondary CIDR Blocks",
      type: "array",
      items: {
        title: "Secondary CIDR Block",
        type: "string",
      },
      default: [],
    },
    azs: {
      title: "Availability Zones",
      type: "array",
      items: {
        title: "Availability Zone",
        type: "string",
      },
      default: [],
    },
    private_subnets: {
      type: "array",
      title: "Private Subnets",
      items: {
        type: "string",
        title: "Private Subnet CIDR",
      },
      default: ["10.0.1.0/24", "10.0.2.0/24", "10.0.3.0/24"],
    },
    public_subnets: {
      type: "array",
      title: "Public Subnets",
      items: {
        type: "string",
        title: "Public Subnet CIDR",
      },
      default: ["10.0.101.0/24", "10.0.102.0/24", "10.0.103.0/24"],
    },
    enable_nat_gateway: {
      title: "Enable NAT Gateway",
      type: "boolean",
      default: true,
    },
    enable_vpn_gateway: {
      title: "Enable VPN Gateway",
      type: "boolean",
      default: false,
    },
    tags: {
      title: "Tags",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Tag Name",
            type: "string",
          },
          value: {
            title: "Tag Value",
            type: "string",
          },
        },
      },
      default: [],
    },
  },
  required: ["name"],
};

export const awsVpcSchemaUI = {
  "ui:description": "Define network settings",
  name: {
    "ui:description": "Name to be used on all the resources as identifier",
  },
  cidr: {
    "ui:description":
      "(Optional) The IPv4 CIDR block for the VPC. CIDR can be explicitly set or it can be derived from IPAM using `ipv4_netmask_length` & `ipv4_ipam_pool_id`",
  },
  secondary_cidr_blocks: {
    "ui:description":
      "List of secondary CIDR blocks to associate with the VPC to extend the IP Address pool",
    items: {
      "ui:description":
        "Secondary CIDR block to associate with the VPC to extend the IP Address pool",
    },
  },
  azs: {
    "ui:description": "A list of availability zones names or ids in the region",
    items: {
      "ui:description": "Availability zone name in the region",
    },
  },
  private_subnets: {
    "ui:description": "A list of private subnets inside the VPC",
    items: {
      "ui:description": "Private subnet CIDR",
    },
  },
  public_subnets: {
    "ui:description": "A list of public subnets inside the VPC",
    items: {
      "ui:description": "Public subnet CIDR",
    },
  },
  enable_nat_gateway: {
    "ui:description":
      "Should be true if you want to provision NAT Gateways for each of your private networks",
  },
  enable_vpn_gateway: {
    "ui:description":
      "Should be true if you want to create a new VPN Gateway resource and attach it to the VPC",
  },
  tags: {
    "ui:description": "A list of tags to attach to the VPC",
    items: {
      "ui:description": "Tag key/value pair",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Tag Name",
      },
      value: {
        "ui:description": "Tag Value",
      },
    },
  },
};
