export const gcpMemcacheSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      description:
        "The ID of the instance or a fully qualified identifier for the instance.",
    },
    region: {
      type: "string",
      description: "The GCP region to use.",
    },
    authorized_network: {
      type: "string",
      description:
        "The full name of the Google Compute Engine network to which the instance is connected. For example: projects/<PROJECT_ID>/global/networks/<NETWORK_NAME>",
    },
    display_name: {
      type: ["string", "null"],
      description:
        "An arbitrary and optional user-provided name for the instance.",
      default: null,
    },
    node_count: {
      description: "Number of nodes in the memcache instance.",
      type: "number",
      default: 1,
    },
    cpu_count: {
      description: "Number of CPUs per node",
      type: "number",
      default: 1,
    },
    memory_size_mb: {
      description: "Memory size in MB per node",
      type: "number",
      default: 1024,
    },
    zones: {
      description:
        "Zones where memcache nodes should be provisioned. If not provided, all zones will be used.",
      type: "array",
      items: {
        type: "string",
      },
      default: [],
    },
    labels: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      description: "The resource labels to represent user provided metadata.",
      default: {},
    },
    params: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      description: "Parameters for the memcache process",
      default: {},
    },
    maintenance_policy: {
      type: "object",
      description: "The maintenance policy for an instance.",
      properties: {
        day: {
          type: ["string", "null"],
        },
        start_time: {
          type: "object",
          properties: {
            hours: {
              type: ["number", "null"],
            },
            minutes: {
              type: ["number", "null"],
            },
            seconds: {
              type: ["number", "null"],
            },
            nanos: {
              type: ["number", "null"],
            },
          },
        },
      },
      default: {
        day: null,
        start_time: {
          hours: null,
          minutes: null,
          seconds: null,
          nanos: null,
        },
      },
    },
  },
  required: ["name", "region", "authorized_network"],
};

export const gcpMemcacheUISchema = {
  region: {
    "ui:widget": "text",
  },
  enable_apis: {
    "ui:widget": "checkbox",
  },
  name: {
    "ui:widget": "text",
  },
  authorized_network: {
    "ui:widget": "text",
  },
  node_count: {
    "ui:widget": "text",
  },
  cpu_count: {
    "ui:widget": "text",
  },
  memory_size_mb: {
    "ui:widget": "text",
  },
  zones: {
    "ui:description":
      "Zones where memcache nodes should be provisioned. If not provided, all zones will be used.",
    items: {
      "ui:description": "zone",
    },
  },
  display_name: {
    "ui:widget": "text",
    "ui:emptyValue": null,
  },
  maintenance_policy: {
    "ui:description": "The maintenance policy for an instance.",
    "ui:widget": "object",
    day: {
      "ui:widget": "text",
      "ui:emptyValue": null,
    },
    start_time: {
      "ui:widget": "object",
      "ui:description": "Start Time Settings",
      hours: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
      minutes: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
      seconds: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
      nanos: {
        "ui:widget": "text",
        "ui:emptyValue": null,
      },
    },
  },
};
