export const firewallsSchema = {
  type: "object",
  title: "Firewall Rules",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    ingress_rules: {
      type: "array",
      title: "Ingress Firewall Rule",
      items: {
        title: "Rule",
        type: "object",
        properties: {
          name: {
            title: "Firewall Name",
            type: "string",
          },
          description: {
            title: "Description",
            type: "string",
            default: "",
          },
          priority: {
            title: "Priority",
            type: "number",
            default: 1000,
          },
          destination_ranges: {
            title: "Destination IP Range",
            type: "array",
            items: {
              title: "Destination IP Range (CIDR)",
              type: "string",
            },
            default: [],
          },
          source_ranges: {
            title: "Source IP Range",
            type: "array",
            items: {
              title: "Source IP Range (CIDR)",
              type: "string",
            },
            default: [],
          },
          allow: {
            title: "Allow ports",
            type: "array",
            items: {
              type: "object",
              properties: {
                protocol: {
                  title: "Protocol",
                  type: "string",
                },
                ports: {
                  title: "Port or port ranges",
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
              },
              default: [],
            },
          },
          deny: {
            title: "Deny ports",
            type: "array",
            items: {
              type: "object",
              properties: {
                protocol: {
                  title: "Protocol",
                  type: "string",
                },
                ports: {
                  title: "Port or port ranges",
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
              },
              default: [],
            },
          },
          source_tags: {
            title: "Source Tag",
            type: "array",
            items: {
              title: "Source Tag",
              type: "string",
            },
            default: [],
          },
          target_tags: {
            title: "Target Tag",
            type: "array",
            items: {
              title: "Target Tag",
              type: "string",
            },
            default: [],
          },
          source_service_accounts: {
            title: "Source Service Account",
            type: "array",
            items: {
              title: "Source Service Account",
              type: "string",
            },
            default: [],
          },
          target_service_accounts: {
            title: "Target Service Account",
            type: "array",
            items: {
              title: "Target Service Account",
              type: "string",
            },
            default: [],
          },
          log_config: {
            title: "Log Config",
            type: "object",
            properties: {
              metadata: {
                title: "Metadata",
                type: ["string", "null"],
                default: null,
              },
            },
          },
        },
      },
      default: [],
    },
    egress_rules: {
      type: "array",
      title: "Egress Firewall Rule",
      items: {
        title: "Rule",
        type: "object",
        properties: {
          name: {
            title: "Firewall Name",
            type: "string",
          },
          description: {
            title: "Description",
            type: "string",
            default: "",
          },
          priority: {
            title: "Priority",
            type: "number",
            default: 1000,
          },
          destination_ranges: {
            title: "Destination IP Range",
            type: "array",
            items: {
              title: "Destination IP Range (CIDR)",
              type: "string",
            },
            default: [],
          },
          source_ranges: {
            title: "Source IP Range",
            type: "array",
            items: {
              title: "Source IP Range (CIDR)",
              type: "string",
            },
            default: [],
          },
          allow: {
            title: "Allow ports",
            type: "array",
            items: {
              type: "object",
              properties: {
                protocol: {
                  title: "Protocol",
                  type: "string",
                },
                ports: {
                  title: "Port or port ranges",
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
              },
            },
            default: [],
          },
          deny: {
            title: "Deny ports",
            type: "array",
            items: {
              type: "object",
              properties: {
                protocol: {
                  title: "Protocol",
                  type: "string",
                },
                ports: {
                  title: "Port or port ranges",
                  type: "array",
                  items: {
                    type: "string",
                  },
                },
              },
            },
            default: [],
          },
          source_tags: {
            title: "Source Tag",
            type: "array",
            items: {
              title: "Source Tag",
              type: "string",
            },
            default: [],
          },
          target_tags: {
            title: "Target Tag",
            type: "array",
            items: {
              title: "Target Tag",
              type: "string",
            },
            default: [],
          },
          source_service_accounts: {
            title: "Source Service Account",
            type: "array",
            items: {
              title: "Source Service Account",
              type: "string",
            },
            default: [],
          },
          target_service_accounts: {
            title: "Target Service Account",
            type: "array",
            items: {
              title: "Target Service Account",
              type: "string",
            },
            default: [],
          },
          log_config: {
            title: "Log Config",
            type: "object",
            properties: {
              metadata: {
                title: "Metadata",
                type: ["string", "null"],
                default: null,
              },
            },
          },
        },
        required: ["name"],
      },
      default: [],
    },
  },
  required: ["name"],
};

export const firewallsSchemaUI = {
  "ui:description": "Firewall Rules",
  name: {
    "ui:description": "Name of the module",
  },
  ingress_rules: {
    "ui:description": "List of ingress rules.",
    items: {
      "ui:description": "Ingress rule",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Name of the firewall rule",
      },
      description: {
        "ui:description": "Description of the firewall rule",
      },
      priority: {
        "ui:description": "Priority of the firewall rule",
      },
      destination_ranges: {
        "ui:description": "Destination IP range",
        items: {
          "ui:description": "Destination IP range (CIDR)",
        },
      },
      source_ranges: {
        "ui:description": "Source IP range",
        items: {
          "ui:description": "Source IP range (CIDR)",
        },
      },
      source_tags: {
        "ui:description": "Source tag",
        items: {
          "ui:description": "Source tag",
        },
      },
      target_tags: {
        "ui:description": "Target tag settings",
        items: {
          "ui:description": "Target tag",
        },
      },
      allow: {
        "ui:description": "Allow ports",
        items: {
          "ui:description": "Allow port settings",
          "ui:displayfield": "protocol",
          protocol: {
            "ui:description": "Protocol. Example: tcp, udp, icmp",
          },
          ports: {
            "ui:description": "Ports or port ranges.",
            items: {
              "ui:description":
                "Port or Ports range. Example: 1000 or 1000-2000",
              items: {
                "ui:description": "Port number",
              },
            },
          },
        },
      },
      deny: {
        "ui:description": "Deny port",
        items: {
          "ui:description": "Deny port settings",
          "ui:displayfield": "protocol",
          protocol: {
            "ui:description": "Protocol. Example: tcp, udp, icmp",
          },
          ports: {
            "ui:description": "Port or Ports range. Example: 1000 or 1000-2000",
            items: {
              "ui:description": "Port number",
            },
          },
        },
      },
      source_service_accounts: {
        "ui:description": "Source service account",
        items: {
          "ui:description": "Source service account",
        },
      },
      target_service_accounts: {
        "ui:description": "Target service account",
        items: {
          "ui:description": "Target service account",
        },
      },
      log_config: {
        "ui:description": "Log config",
        metadata: {
          "ui:description": "Metadata",
        },
      },
    },
  },
  egress_rules: {
    "ui:description": "List of egress rules.",
    items: {
      "ui:description": "Egress rule settings",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Name of the firewall rule",
      },
      description: {
        "ui:description": "Description of the firewall rule",
      },
      priority: {
        "ui:description": "Priority of the firewall rule",
      },
      destination_ranges: {
        "ui:description": "Destination IP range",
        items: {
          "ui:description": "Destination IP range (CIDR)",
        },
      },
      source_ranges: {
        "ui:description": "Source IP range",
        items: {
          "ui:description": "Source IP range (CIDR)",
        },
      },
      source_tags: {
        "ui:description": "Source tag",
        items: {
          "ui:description": "Source tag",
        },
      },
      target_tags: {
        "ui:description": "Target tag",
        items: {
          "ui:description": "Target tag",
        },
      },
      allow: {
        "ui:description": "Allow ports",
        items: {
          "ui:description": "Allow port settings",
          "ui:displayfield": "protocol",
          protocol: {
            "ui:description": "Protocol. Example: tcp, udp, icmp",
          },
          ports: {
            "ui:description": "Ports or port ranges.",
            items: {
              "ui:description":
                "Port or Ports range. Example: 1000 or 1000-2000",
              items: {
                "ui:description": "Port number",
              },
            },
          },
        },
      },
      deny: {
        "ui:description": "Deny ports",
        items: {
          "ui:description": "Deny port settings",
          "ui:displayfield": "protocol",
          protocol: {
            "ui:description": "Protocol. Example: tcp, udp, icmp",
          },
          ports: {
            "ui:description": "Port or Ports range. Example: 1000 or 1000-2000",
            items: {
              "ui:description": "Port number",
            },
          },
        },
      },
      source_service_accounts: {
        "ui:description": "Source service account",
        items: {
          "ui:description": "Source service account",
        },
      },
      target_service_accounts: {
        "ui:description": "Target service account",
        items: {
          "ui:description": "Target service account",
        },
      },
      log_config: {
        "ui:description": "Log config",
        metadata: {
          "ui:description": "Metadata",
        },
      },
    },
  },
};
