import { db } from "../firebase";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  query,
  orderBy,
} from "firebase/firestore";

export const fetchOrganization = async (uid) => {
  let orgRefDoc = doc(db, "organizations", uid);
  let orgSnap = await getDoc(orgRefDoc);
  let org = { ...orgSnap.data(), id: orgSnap.id };
  return org;
};

export const fetchOrganizations = async (uid) => {
  const userDocRef = doc(db, "users", uid);
  const userDoc = await getDoc(userDocRef);
  let orgs = [];
  for (let oid of userDoc.data().organizations) {
    let orgRef = doc(db, "organizations", oid);
    let orgSnap = await getDoc(orgRef);
    let org = orgSnap.data();
    org.id = orgSnap.id;
    orgs.push(org);
  }
  return orgs;
};

export const fetchEnvironment = async (orgId, uid) => {
  let envDocRef = doc(db, `organizations/${orgId}/environments`, uid);
  let envDoc = await getDoc(envDocRef);
  if (!envDoc.exists()) {
    return null;
  }
  //console.log(envDocRef);
  let envData = envDoc.data();
  return { ...envData, id: envDocRef.id };
};

export const fetchEnvironments = async (orgId) => {
  let environmentsDocsRef = collection(
    db,
    `organizations/${orgId}/environments`
  );
  let environmentsDocs = await getDocs(environmentsDocsRef);
  let environments = [];
  environmentsDocs.forEach((doc) => {
    let env = doc.data();
    environments.push({ ...env, id: doc.id });
  });

  return environments;
};

export const addEnvironment = async (orgId, env) => {
  const envDoc = await addDoc(
    collection(db, `organizations/${orgId}/environments`),
    env
  );

  return envDoc;
};

export const updateEnvironment = async (orgID, envId, name) => {
  const envDocRef = doc(db, `organizations/${orgID}/environments`, envId);
  const res = await setDoc(envDocRef, { name: name }, { merge: true });
  return res;
};

export const removeEnvironment = async (orgID, envId) => {
  let envDocRef = doc(db, `organizations/${orgID}/environments`, envId);
  const res = await deleteDoc(envDocRef);
  return res;
};

export const updateOrganizationName = async (orgId, name) => {
  let orgRefDoc = doc(db, "organizations", orgId);
  let res = await setDoc(orgRefDoc, { name: name }, { merge: true });
  return res;
};

export const fetchJobs = async (orgId) => {
  let jobsDocsRef = collection(db, `organizations/${orgId}/jobs`);
  let q = query(jobsDocsRef, orderBy("created", "desc"));
  let jobsDocs = await getDocs(q);
  let jobs = [];
  jobsDocs.forEach((doc) => {
    let job = doc.data();
    jobs.push({ ...job, id: doc.id });
  });
  return jobs;
};

export const fetchModuleStatus = async (orgId, archId, nodeId) => {
  let statusRef = doc(
    db,
    `organizations/${orgId}/environments/${archId}/modulestatus/${nodeId}`
  );
  let statusDoc = await getDoc(statusRef);
  if (!statusDoc.exists()) {
    return null;
  }
  //console.log(envDocRef);
  let status = statusDoc.data();
  return { ...status, id: statusRef.id };
};

export const fetchAllModuleStatus = async (orgId, archId) => {
  let statusRef = collection(
    db,
    `organizations/${orgId}/environments/${archId}/modulestatus`
  );
  let statusDocs = await getDocs(statusRef);
  let moduleStatuses = {};
  statusDocs.forEach((doc) => {
    let stat = doc.data();
    // Some terraform outputs can not be ingested into firestore raw
    // so we base64 encode them before storing them in firestore
    // and decode them here
    if ("outputs" in stat) {
      if (typeof stat.outputs === "string") {
        let decoded_outputs = JSON.parse(atob(stat.outputs));
        stat.outputs = decoded_outputs;
      }
    }
    moduleStatuses[doc.id] = stat;
  });
  return moduleStatuses;
};

export const removeModuleStatus = async (orgId, archId, node_id) => {
  let statusRef = doc(
    db,
    `organizations/${orgId}/environments/${archId}/modulestatus/${node_id}`
  );
  let docSnap = await getDoc(statusRef);
  if (docSnap.exists()) {
    await deleteDoc(statusRef);
  }
};

export const fetchTemplates = async (orgId) => {
  let templatesDocsRef = collection(db, `organizations/${orgId}/templates`);
  let templateDocs = await getDocs(templatesDocsRef);
  let templates = [];
  templateDocs.forEach((doc) => {
    let template = doc.data();
    templates.push({ ...template, id: doc.id });
  });
  return templates;
};

export const fetchTemplate = async (orgId, uid) => {
  let templateDocRef = doc(db, `organizations/${orgId}/templates`, uid);
  let templateDoc = await getDoc(templateDocRef);
  if (!templateDoc.exists()) {
    return null;
  }
  //console.log(envDocRef);
  let templateData = templateDoc.data();
  return { ...templateData, id: templateDocRef.id };
};

export const addTemplate = async (orgId, template) => {
  const templateDoc = await addDoc(
    collection(db, `organizations/${orgId}/templates`),
    template
  );

  return templateDoc;
};

export const updateTemplateName = async (orgID, templateId, newName) => {
  const templDocRef = doc(db, `organizations/${orgID}/templates`, templateId);
  const res = await setDoc(templDocRef, { name: newName }, { merge: true });
  return res;
};

export const updateTemplateKeyValue = async (
  organization_id,
  template_id,
  label,
  newTextValue
) => {
  const templDocRef = doc(
    db,
    `organizations/${organization_id}/templates`,
    template_id
  );
  const modified_at = new Date().getTime();
  const res = await setDoc(
    templDocRef,
    { [label]: newTextValue, modified_at: modified_at },
    { merge: true }
  );
  return res;
};

export const deleteTemplate = async (orgId, template) => {
  const templateDocRef = doc(
    collection(db, `organizations/${orgId}/templates`),
    template
  );
  const res = await deleteDoc(templateDocRef);
  return res;
};

export const fetchBlueprints = async () => {
  let blueprints = [];
  let blueprintsDocsRef = collection(db, `blueprints`);
  let blueprintsDocs = await getDocs(blueprintsDocsRef);
  blueprintsDocs.forEach((doc) => {
    let blueprint = doc.data();
    blueprints.push({
      ...blueprint,
      id: doc.id,
      source: "public",
      source_id: null,
    });
  });
  return blueprints;
};
