export const awsIAMassumableRoleWithOidcSchema = {
  type: "object",
  properties: {
    create_role: {
      type: "boolean",
      title: "create role",
      default: true,
    },
    provider_url: {
      type: "string",
      title: "provider url",
      default: `https://securetoken.google.com/${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
    },
    provider_urls: {
      type: "array",
      items: {
        type: "string",
        title: "provider url",
      },
      title: "provider urls",
      default: [],
    },
    aws_account_id: {
      type: "string",
      title: "aws account id",
      default: "",
    },
    tags: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      title: "tags",
      default: {},
    },
    role_name: {
      type: "string",
      title: "role name",
    },
    role_name_prefix: {
      type: ["string", "null"],
      title: "role name prefix",
      default: null,
    },
    role_description: {
      type: "string",
      title: "role description",
      default: "",
    },
    role_path: {
      type: "string",
      title: "role path",
      default: "/",
    },
    role_permissions_boundary_arn: {
      type: "string",
      title: "role permissions boundary arn",
      default: "",
    },
    max_session_duration: {
      type: "number",
      title: "max session duration",
      default: 3600,
    },
    role_policy_arns: {
      type: "array",
      items: {
        type: "string",
        title: "role policy arn",
      },
      title: "role policy arns",
      default: [],
    },
    oidc_fully_qualified_subjects: {
      type: "array",
      items: {
        type: "string",
        title: "oidc fully qualified subject",
      },
      title: "oidc fully qualified subjects",
      default: [],
    },
    oidc_subjects_with_wildcards: {
      type: "array",
      items: {
        type: "string",
        title: "oidc subject with wildcards",
      },
      title: "oidc subjects with wildcards",
      default: [],
    },
    oidc_fully_qualified_audiences: {
      type: "array",
      items: {
        type: "string",
        title: "oidc fully qualified audience",
      },
      title: "oidc fully qualified audiences",
      default: [`${process.env.REACT_APP_FIREBASE_PROJECT_ID}`],
    },
    force_detach_policies: {
      type: "boolean",
      title: "force detach policies",
      default: false,
    },
    allow_self_assume_role: {
      type: "boolean",
      title: "allow self assume role",
      default: false,
    },
  },
  required: ["role_name"],
};

export const awsIAMassumableRoleWithOidcUISchema = {
  "ui:description": "IAM assumable role with OIDC Settings",
  create_role: {
    "ui:description": "Whether to create a role",
  },
  provider_url: {
    "ui:description":
      "URL of the OIDC Provider. Use provider_urls to specify several URLs.",
    "ui:emptyValue": "",
  },
  provider_urls: {
    "ui:description": "List of URLs of the OIDC Providers",
    items: {
      "ui:description": "URL of the OIDC Provider",
    },
  },
  aws_account_id: {
    "ui:description":
      "The AWS account ID where the OIDC provider lives, leave empty to use the account for the AWS provider",
    "ui:emptyValue": "",
  },
  tags: {
    "ui:description": "A map of tags to add to IAM role resources",
  },
  role_name: {
    "ui:description": "IAM role name",
  },
  role_name_prefix: {
    "ui:description": "IAM role name prefix",
    "ui:emptyValue": null,
  },
  role_description: {
    "ui:description": "IAM Role description",
    "ui:emptyValue": "",
  },
  role_path: {
    "ui:description": "Path of IAM role",
  },
  role_permissions_boundary_arn: {
    "ui:description": "Permissions boundary ARN to use for IAM role",
    "ui:emptyValue": "",
  },
  max_session_duration: {
    "ui:description":
      "Maximum CLI/API session duration in seconds between 3600 and 43200",
  },
  role_policy_arns: {
    "ui:description": "List of ARNs of IAM policies to attach to IAM role",
    items: {
      "ui:description": "ARN of IAM policy",
    },
  },
  oidc_fully_qualified_subjects: {
    "ui:description":
      "The fully qualified OIDC subjects to be added to the role policy",
    items: {
      "ui:description": "Fully qualified OIDC subject",
    },
  },
  oidc_subjects_with_wildcards: {
    "ui:description":
      "The OIDC subject using wildcards to be added to the role policy",
    items: {
      "ui:description": "OIDC subject using wildcards",
    },
  },
  oidc_fully_qualified_audiences: {
    "ui:description":
      "The audience to be added to the role policy. Set to sts.amazonaws.com for cross-account assumable role. Leave empty otherwise.",
    items: {
      "ui:description": "OIDC audience",
    },
  },
  force_detach_policies: {
    "ui:description":
      "Whether policies should be detached from this role when destroying",
  },
  allow_self_assume_role: {
    "ui:description":
      "Determines whether to allow the role to be [assume itself](https://aws.amazon.com/blogs/security/announcing-an-update-to-iam-role-trust-policy-behavior/)",
  },
};
