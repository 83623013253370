export const folderSourceSchema = {
  type: "object",
  properties: {
    id: {
      title: "Folder ID",
      type: "string",
    },
  },
  required: ["id"],
};

export const folderSourceSchemaUI = {
  "ui:description": "The source folder settings",
  id: {
    "ui:description":
      "The folder ID of the folder to be used as the source for the child modules. For example, 1234567890",
  },
};
