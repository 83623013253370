export const serviceProjectSchema = {
  type: "object",
  properties: {
    name: {
      title: "The name for the project",
      type: "string",
    },
    billing_account: {
      title: "Billing Account ID",
      type: "string",
    },
    org_id: {
      title: "Organization ID",
      type: "string",
    },
    random_project_id: {
      title: "Adds a suffix of 4 random characters to the project ID",
      type: "boolean",
      default: true,
    },
    folder_id: {
      title:
        "The ID of a folder to host this project. If placed under Folder resource or Folder Source this is not required.",
      type: "string",
    },
    domain: {
      title: "The domain name (optional).",
      type: "string",
      default: "",
    },
    auto_create_network: {
      title: "Create the default network",
      type: "boolean",
      default: false,
    },
    activate_apis: {
      title: "The list of apis to activate within the project",
      type: "array",
      items: {
        type: "string",
      },
      default: [
        "compute.googleapis.com",
        "container.googleapis.com",
        "dataflow.googleapis.com",
        "bigquery.googleapis.com",
      ],
    },
    shared_vpc_subnets: {
      type: "array",
      title: "List of host VPCs, subnet names and regions",
      items: {
        type: "object",
        properties: {
          subnet_name: {
            title: "Subnet Name",
            type: "string",
          },
          region: {
            title: "Subnet Region",
            type: "string",
          },
        },
      },
    },
    labels: {
      type: "array",
      title: "Map of labels for project",
      items: {
        type: "object",
        properties: {
          key: {
            type: "string",
          },
          value: {
            type: "string",
          },
        },
      },
    },
    lien: {
      type: "boolean",
      title: "Add a lien on the project to prevent accidental deletion",
      default: false,
    },
    default_service_account: {
      title:
        "Project default service account setting: can be one of `delete`, `deprivilege`, `disable`, or `keep`.",
      type: "string",
      default: "deprivilege",
    },
    vpc_service_control_attach_enabled: {
      title:
        "Whether the project will be attached to a VPC Service Control Perimeter",
      type: "boolean",
      default: false,
    },
    vpc_service_control_perimeter_name: {
      title:
        "The name of the VPC Service Control Perimeter to add the created project to",
      type: "string",
      default: "",
    },
  },
  required: ["name", "billing_account", "org_id"],
};

export const serviceProjectSchemaUI = {
  "ui:description": "Service Project Settings",
  name: {
    "ui:description": "The name for the project",
  },
  billing_account: {
    "ui:description": "Billing Account ID",
  },
  org_id: {
    "ui:description": "Organization ID",
  },
  random_project_id: {
    "ui:description": "Adds a suffix of 4 random characters to the",
  },
  folder_id: {
    "ui:description":
      "The ID of a folder to host this project. If omitted it will use the folder of the Host Project.",
  },
  domain: {
    "ui:description": "The domain name (optional).",
  },
  auto_create_network: {
    "ui:description": "Create the default VPC network",
  },
  activate_apis: {
    "ui:description": "The list of apis to activate within the project",
    items: {
      "ui:description": "API name",
    },
  },
  shared_vpc_subnets: {
    "ui:description":
      "Ensure that the VPCs and subnets exist under the host project and that they are deployed",
    items: {
      "ui:description": "Subnet settings",
      "ui:displayfield": "subnet_name",
      subnet_name: {
        "ui:description": "The name of the subnet in the host project",
      },
      region: {
        "ui:description": "The region of the subnet in the host project",
      },
    },
  },
  labels: {
    "ui:description": "Map of labels for the project",
    items: {
      "ui:description": "Label key/value pair",
      "ui:displayfield": "key",
      key: {
        "ui:description": "Label key",
      },
      value: {
        "ui:description": "Label value",
      },
    },
  },
  lien: {
    "ui:description":
      "Add a lien on the project to prevent accidental deletion",
  },
  default_service_account: {
    "ui:description":
      "Project default service account setting: can be one of `delete`, `deprivilege`, `disable`, or `keep`.",
  },
  vpc_service_control_attach_enabled: {
    "ui:description":
      "Whether the project will be attached to a VPC Service Control Perimeter",
  },
  vpc_service_control_perimeter_name: {
    "ui:description":
      "The name of the VPC Service Control Perimeter to add this project to",
  },
};
