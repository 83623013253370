export const gcpSaferMySQLSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
    },
    random_instance_name: {
      type: "boolean",
      default: false,
      title: "Random Instance Name",
    },
    database_version: {
      type: "string",
      title: "Database Version",
      enum: ["MYSQL_5_6", "MYSQL_5_7", "MYSQL_8_0"],
      default: "MYSQL_5_7",
    },
    region: {
      type: "string",
      title: "Region",
    },
    vpc_network: {
      type: "string",
      title: "Vpc Network",
    },
    allocated_ip_range: {
      type: ["string", "null"],
      default: null,
      title: "Allocated Ip Range",
    },
    tier: {
      type: "string",
      default: "db-n1-standard-1",
      title: "Tier",
    },
    edition: {
      type: ["string", "null"],
      default: null,
      title: "Edition",
    },
    zone: {
      type: "string",
      title: "Zone",
    },
    secondary_zone: {
      type: ["string", "null"],
      default: null,
      title: "Secondary Zone",
    },
    follow_gae_application: {
      type: ["string", "null"],
      default: null,
      title: "Follow GAE Application",
    },
    activation_policy: {
      type: "string",
      default: "ALWAYS",
      title: "Activation Policy",
    },
    availability_type: {
      type: "string",
      default: "REGIONAL",
      title: "Availability Type",
    },
    deletion_protection: {
      type: "boolean",
      default: false,
      title: "Deletion Protection Enabled",
    },
    read_replica_deletion_protection_enabled: {
      type: "boolean",
      default: false,
      title: "Read Replica Deletion Protection Enabled",
    },
    disk_autoresize: {
      type: "boolean",
      default: true,
      title: "Disk Autoresize",
    },
    disk_autoresize_limit: {
      type: "number",
      default: 0,
      title: "Disk Autoresize Limit",
    },
    disk_size: {
      type: "number",
      default: 20,
      title: "Disk Size",
    },
    disk_type: {
      type: "string",
      default: "PD_SSD",
      title: "Disk Type",
    },
    pricing_plan: {
      type: "string",
      default: "PER_USE",
      title: "Pricing Plan",
    },
    maintenance_window_day: {
      type: "number",
      default: 1,
      title: "Maintenance Window Day",
    },
    maintenance_window_hour: {
      type: "number",
      default: 23,
      title: "Maintenance Window Hour",
    },
    maintenance_window_update_track: {
      type: "string",
      default: "stable",
      title: "Maintenance Window Update Track",
    },
    data_cache_enabled: {
      type: "boolean",
      default: false,
      title: "Data Cache Enabled",
    },
    deny_maintenance_period: {
      type: "array",
      items: {
        type: "object",
        properties: {
          end_date: {
            type: "string",
            title: "End Date",
          },
          start_date: {
            type: "string",
            title: "Start Date",
          },
          time: {
            type: "string",
            title: "Time",
          },
        },
      },
      default: [],
      title: "Deny Maintenance Period",
    },
    database_flags: {
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          value: {
            type: "string",
            title: "Value",
          },
        },
      },
      default: [],
      title: "Database Flags",
    },
    user_labels: {
      type: "object",
      additionalProperties: {
        type: "string",
      },
      default: {},
      title: "User Labels",
    },
    backup_configuration: {
      type: "object",
      title: "Backup Configuration",
      properties: {
        binary_log_enabled: {
          type: "boolean",
          default: false,
          title: "Binary Log Enabled",
        },
        enabled: {
          type: "boolean",
          default: false,
          title: "Enabled",
        },
        start_time: {
          type: ["string", "null"],
          default: null,
          title: "Start Time",
        },
        location: {
          type: ["string", "null"],
          default: null,
          title: "Location",
        },
        transaction_log_retention_days: {
          type: ["string", "null"],
          default: null,
          title: "Transaction Log Retention Days",
        },
        retained_backups: {
          type: ["number", "null"],
          default: null,
          title: "Retained Backups",
        },
        retention_unit: {
          type: ["string", "null"],
          default: null,
          title: "Retention Unit",
        },
      },
      default: {
        binary_log_enabled: false,
        enabled: false,
        start_time: null,
        location: null,
        transaction_log_retention_days: null,
        retained_backups: null,
        retention_unit: null,
      },
    },
    assign_public_ip: {
      type: "boolean",
      default: false,
      title: "Assign Public IP",
    },
    read_replica_name_suffix: {
      type: "string",
      default: "",
      title: "Read Replica Name Suffix",
    },
    read_replicas: {
      type: "array",
      title: "Read Replicas",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          tier: {
            type: "string",
            title: "Tier",
            default: "db-n1-standard-1",
          },
          edition: {
            type: ["string", "null"],
            enum: ["ENTERPRISE", "ENTERPRISE_PLUS", null],
            title: "Edition",
            default: null,
          },
          availability_type: {
            type: "string",
            default: "REGIONAL",
            title: "Availability Type",
          },
          zone: {
            type: "string",
            title: "Zone",
          },
          disk_type: {
            type: "string",
            default: "PD_SSD",
            title: "Disk Type",
          },
          disk_autoresize: {
            type: "boolean",
            default: true,
            title: "Disk Autoresize",
          },
          disk_autoresize_limit: {
            type: "number",
            default: 0,
            title: "Disk Autoresize Limit",
          },
          disk_size: {
            type: "number",
            default: 20,
            title: "Disk Size",
          },
          user_labels: {
            type: "object",
            additionalProperties: {
              type: "string",
            },
            title: "User Labels",
            default: {},
          },
          database_flags: {
            type: "array",
            items: {
              type: "object",
              properties: {
                name: {
                  type: "string",
                  title: "Name",
                },
                value: {
                  type: "string",
                  title: "Value",
                },
              },
            },
            default: [],
            title: "Database Flags",
          },
          insights_config: {
            type: "object",
            properties: {
              query_plans_per_minute: {
                type: ["number", "null"],
                default: null,
                title: "Query Plans Per Minute",
              },
              query_string_length: {
                type: ["number", "null"],
                default: null,
                title: "Query String Length",
              },
              record_application_tags: {
                type: "boolean",
                title: "Record Application Tags",
                default: false,
              },
              record_client_address: {
                type: "boolean",
                title: "Record Client Address",
                default: false,
              },
            },
            default: {},
            title: "Insights Config",
          },
          ip_configuration: {
            type: "object",
            properties: {
              authorized_networks: {
                type: "array",
                items: {
                  type: "object",
                  title: "Authorized Network",
                  additionalProperties: {
                    type: "string",
                  },
                },
                title: "Authorized Networks",
              },
              ipv4_enabled: {
                type: "boolean",
                title: "Ipv4 Enabled",
                default: true,
              },
              private_network: {
                type: ["string", "null"],
                title: "Private Network",
                default: null,
              },
              require_ssl: {
                type: "boolean",
                title: "Require SSL",
                default: false,
              },
              allocated_ip_range: {
                type: ["string", "null"],
                title: "Allocated IP Range",
                default: null,
              },
            },
            title: "IP Configuration",
          },
          encryption_key_name: {
            type: ["string", "null"],
            title: "Encryption Key Name",
            default: null,
          },
        },
        required: ["name", "zone"],
      },
      default: [],
    },
    db_name: {
      type: "string",
      default: "default",
      title: "DB Name",
    },
    db_charset: {
      type: "string",
      default: "",
      title: "DB Charset",
    },
    db_collation: {
      type: "string",
      default: "",
      title: "DB Collation",
    },
    additional_databases: {
      type: "array",
      title: "Additional Databases",
      items: {
        type: "object",
        title: "Additional Database",
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          charset: {
            type: ["string", "null"],
            title: "Charset",
            default: null,
          },
          collation: {
            type: ["string", "null"],
            title: "Collation",
            default: null,
          },
        },
        required: ["name"],
      },
      default: [],
    },
    user_name: {
      type: "string",
      default: "default",
      title: "User Name",
    },
    user_password: {
      type: "string",
      default: "",
      title: "User Password",
    },
    additional_users: {
      type: "array",
      items: {
        type: "object",
        title: "Additional Users",
        properties: {
          name: {
            type: "string",
            title: "Name",
          },
          password: {
            type: ["string", "null"],
            title: "Password",
            default: null,
          },
          host: {
            type: "string",
            title: "Host",
          },
          type: {
            type: "string",
            title: "Type",
            enum: ["BUILT_IN", "CLOUD_IAM_USER", "CLOUD_IAM_SERVICE_ACCOUNT"],
            default: "BUILT_IN",
          },
          random_password: {
            type: "boolean",
            title: "Random Password",
            default: true,
          },
        },
      },
      default: [],
      title: "Additional Users",
    },
    iam_users: {
      type: "array",
      title: "IAM Users",
      items: {
        type: "object",
        properties: {
          id: {
            type: "string",
            title: "ID",
          },
          email: {
            type: "string",
            title: "Email",
          },
        },
        required: ["id", "email"],
      },
      default: [],
    },
    create_timeout: {
      type: "string",
      default: "30m",
      title: "Create Timeout",
    },
    update_timeout: {
      type: "string",
      default: "30m",
      title: "Update Timeout",
    },
    delete_timeout: {
      type: "string",
      default: "30m",
      title: "Delete Timeout",
    },
    read_replica_deletion_protection: {
      type: "boolean",
      default: false,
      title: "Read Replica Deletion Protection",
    },
    encryption_key_name: {
      type: ["string", "null"],
      default: null,
      title: "Encryption Key Name",
    },
    insights_config: {
      type: "object",
      properties: {
        query_plans_per_minute: {
          type: ["number", "null"],
          default: null,
          title: "Query Plans Per Minute",
        },
        query_string_length: {
          type: ["number", "null"],
          default: null,
          title: "Query String Length",
        },
        record_application_tags: {
          type: "boolean",
          title: "Record Application Tags",
          default: false,
        },
        record_client_address: {
          type: "boolean",
          title: "Record Client Address",
          default: false,
        },
      },
      default: {},
      title: "Insights Config",
    },
  },
  required: ["name", "database_version", "region", "vpc_network"],
};

export const gcpSaferMySQLUISchema = {
  "ui:description": "Cloud SQL settings",
  name: {
    "ui:description": "The name of the Cloud SQL resources",
  },
  random_instance_name: {
    "ui:description":
      "Sets random suffix at the end of the Cloud SQL resource name",
  },
  database_version: {
    "ui:description": "The database version to use",
  },
  region: {
    "ui:description": "The region of the Cloud SQL resources",
  },
  vpc_network: {
    "ui:description":
      "Existing VPC network to which instances are connected. The networks needs to be configured with https://cloud.google.com/vpc/docs/configure-private-services-access. If the Cloud SQL instance is hosted in a Shared VPC network, the VPC_NETWORK_NAME variables used in the following instructions must be the host project's VPC network names. To specify a network with a Shared VPC network, use the full URL of the network—for example, projects/HOST_PROJECT/global/networks/NETWORK_NAME.",
  },
  allocated_ip_range: {
    "ui:description":
      "Existing allocated IP range name for the Private IP CloudSQL instance. The networks needs to be configured with https://cloud.google.com/vpc/docs/configure-private-services-access.",
    "ui:emptyValue": null,
  },
  tier: { "ui:description": "The tier for the master instance." },
  edition: {
    "ui:description":
      "The edition of the instance, can be ENTERPRISE or ENTERPRISE_PLUS.",
    "ui:emptyValue": null,
  },
  zone: {
    "ui:description":
      "The zone for the master instance. For example, if region is us-central1 then the zone should be one of us-central1-a us-central1-b, us-central1-c.",
  },
  secondary_zone: {
    "ui:description":
      "The preferred zone for the secondary/failover instance, it should be something like: us-central1-a, us-east1-c.",
    "ui:emptyValue": null,
  },
  follow_gae_application: {
    "ui:description":
      "A Google App Engine application whose zone to remain in. Must be in the same region as this instance.",
    "ui:emptyValue": null,
  },
  activation_policy: {
    "ui:description":
      "The activation policy for the master instance. Can be either ALWAYS, NEVER or ON_DEMAND.",
  },
  availability_type: {
    "ui:description":
      "The availability type for the master instance. Can be either REGIONAL or null.",
  },
  deletion_protection: {
    "ui:description":
      "Enables protection of an instance from accidental deletion across all surfaces (API, gcloud, Cloud Console and Terraform).",
  },
  read_replica_deletion_protection_enabled: {
    "ui:description":
      "Enables protection of a read replica from accidental deletion across all surfaces (API, gcloud, Cloud Console and Terraform).",
  },
  disk_autoresize: {
    "ui:description": "Configuration to increase storage size",
  },
  disk_autoresize_limit: {
    "ui:description":
      "The maximum size to which storage can be auto increased.",
  },
  disk_size: {
    "ui:description": "The disk size for the master instance",
  },
  disk_type: {
    "ui:description": "The disk type for the master instance.",
  },
  pricing_plan: {
    "ui:description": "The pricing plan for the master instance.",
  },
  maintenance_window_day: {
    "ui:description":
      "The day of week (1-7) for the master instance maintenance.",
  },
  maintenance_window_hour: {
    "ui:description":
      "The hour of day (0-23) maintenance window for the master instance maintenance.",
  },
  maintenance_window_update_track: {
    "ui:description":
      "The update track of maintenance window for the master instance maintenance. Can be either canary or stable.",
  },
  data_cache_enabled: {
    "ui:description":
      "Whether data cache is enabled for the instance. Defaults to false. Feature is only available for ENTERPRISE_PLUS tier and supported database_versions",
  },
  deny_maintenance_period: {
    "ui:description":
      "The Deny Maintenance Period fields to prevent automatic maintenance from occurring during a 90-day time period. See [more details](https://cloud.google.com/sql/docs/mysql/maintenance)",
    items: {
      "ui:description": "Maintenance Period Settings",
      "ui:displayfield": "end_date",
      end_date: {},
      start_date: {},
      time: {},
    },
  },
  database_flags: {
    "ui:description":
      "The database flags for the master instance. See [more details](https://cloud.google.com/sql/docs/mysql/flags)",
    items: {
      "ui:description": "Database Flag",
      "ui:displayfield": "name",
      name: {
        "ui:description": "Flag Name",
      },
      value: {
        "ui:description": "Flag Value",
      },
    },
  },
  user_labels: {
    "ui:description": "The key/value labels for the master instances.",
  },
  backup_configuration: {
    "ui:description":
      "The backup_configuration settings subblock for the database setings",
    binary_log_enabled: {
      "ui:description": "Binary Log Enabled",
    },
    enabled: {
      "ui:description": "True if backup configuration is enabled.",
    },
    start_time: {
      "ui:description":
        "HH:MM format time indicating when backup configuration starts.",
      "ui:emptyValue": null,
    },
    location: {
      "ui:emptyValue": null,
      "ui:description": "The region where the backup will be stored",
    },
    transaction_log_retention_days: {
      "ui:emptyValue": null,
      "ui:description":
        "The number of days of transaction logs we retain for point in time restore, from 1-7. For PostgreSQL Enterprise Plus instances, the number of days of retained transaction logs can be set from 1 to 35.",
    },
    retained_backups: {
      "ui:emptyValue": null,
      "ui:description":
        "Depending on the value of retention_unit, this is used to determine if a backup needs to be deleted. If retention_unit is 'COUNT', we will retain this many backups.",
    },
    retention_unit: {
      "ui:emptyValue": null,
      "ui:description":
        "The unit that 'retained_backups' represents. Defaults to COUNT",
    },
  },
  assign_public_ip: {
    "ui:description":
      "Set to true if the master instance should also have a public IP (less secure).",
  },
  read_replica_name_suffix: {
    "ui:description": "The optional suffix to add to the read instance name",
  },
  read_replicas: {
    "ui:description":
      "List of read replicas to create. Encryption key is required for replica in different region. For replica in same region as master set encryption_key_name = null",
    items: {
      "ui:description": "Read Replica Settings",
      "ui:displayfield": "name",
      name: {
        "ui:description": "name",
      },
      tier: {
        "ui:decription": "The tier for the replica instance.",
      },
      edition: {
        "ui:description":
          "The edition of the instance, can be ENTERPRISE or ENTERPRISE_PLUS.",
      },
      availability_type: {
        "ui:description":
          "The availability type for the master instance. Can be either REGIONAL or null.",
      },
      zone: {
        "ui:description":
          "The zone for the master instance, it should be something like: `us-central1-a`, `us-east1-c`.",
      },
      disk_type: {
        "ui:description": "The disk type for the replica instance.",
      },
      disk_autoresize: {
        "ui:description": "Configuration to increase storage size",
      },
      disk_autoresize_limit: {
        "ui:description":
          "The maximum size to which storage can be auto increased.",
      },
      disk_size: {
        "ui:description": "The disk size for the replica instance",
      },
      user_labels: {
        "ui:description": "The key/value labels for the replica instances.",
        "ui:emptyValue": {},
      },
      database_flags: {
        "ui:description":
          "The database flags for the replica instance. See [more details](https://cloud.google.com/sql/docs/mysql/flags)",
        items: {
          "ui:description": "Database Flag",
          "ui:displayfield": "name",
          name: {
            "ui:description": "Flag Name",
          },
          value: {
            "ui:description": "Flag Value",
          },
        },
      },
      insights_config: {
        "ui:description": "The insights_config settings for the database.",
        query_plans_per_minute: {
          "ui:description":
            "The maximum query plans per minute (QPM) for the instance.",
          "ui:emptyValue": null,
        },
        query_string_length: {
          "ui:description": "The maximum query string length for the instance.",
          "ui:emptyValue": null,
        },
        record_application_tags: {
          "ui:description": "Whether to record application tags.",
        },
        record_client_address: {
          "ui:description": "Whether to record client address.",
        },
      },
      ip_configuration: {
        "ui:description": "The ip_configuration settings subblock",
        authorized_networks: {
          "ui:description":
            "To add an authorized network set key to 'name' or 'value'. For example add the following as key/value pairs: name='my-cidr' value='MySQL instance IP range'",
          items: {
            "ui:description": "Authorized Network settings.",
          },
        },
        ipv4_enabled: {
          "ui:description": "Enable IPv4",
        },
        private_network: {
          "ui:description": "Private Network",
          "ui:emptyValue": null,
        },
        require_ssl: {
          "ui:description": "Require SSL",
        },
        allocated_ip_range: {
          "ui:description": "Allocate IP Range",
          "ui:emptyValue": null,
        },
      },
      encryption_key_name: {
        "ui:description":
          "The full path to the encryption key used for the CMEK disk encryption",
        "ui:displayfield": null,
      },
    },
  },
  db_name: {
    "ui:description": "The name of the default database to create",
  },
  db_charset: {
    "ui:description": "The charset for the default database",
  },
  db_collation: {
    "ui:description":
      "The collation for the default database. Example: 'utf8_general_ci'",
  },
  additional_databases: {
    "ui:description": "A list of databases to be created in your cluster",
    items: {
      "ui:description": "A database to be created in your cluster",
      "ui:displayfield": "name",
      name: {
        "ui:description": "The name of the database",
      },
      charset: {
        "ui:description": "The charset for the database",
        "ui:emptyValue": null,
      },
      collation: {
        "ui:description":
          "The collation for the database. Example: 'utf8_general_ci'",
        "ui:emptyValue": null,
      },
    },
  },
  user_name: {
    "ui:description": "The name of the default user",
  },
  user_password: {
    "ui:description":
      "The password for the default user. If not set, a random one will be generated and available in the generated_user_password output variable.",
  },
  additional_users: {
    "ui:description":
      "A list of users to be created in your cluster. A random password would be set for the user if the `random_password` variable is set.",
    items: {
      "ui:description":
        "A User to be created in your cluster. A random password would be set for the user if the `random_password` variable is set.",
      "ui:displayfield": "name",
      name: {
        "ui:description": "The name of the user",
      },
      password: {
        "ui:description":
          "The password for the user. Can be updated. For Postgres instances this is a Required field, unless type is set to either CLOUD_IAM_USER or CLOUD_IAM_SERVICE_ACCOUNT. Don't set this field for CLOUD_IAM_USER and CLOUD_IAM_SERVICE_ACCOUNT user types for any Cloud SQL instance.",
        "ui:emptyValue": null,
      },
      host: {
        "ui:description": "The host for the user. Example: `%`.",
      },
      type: {
        "ui:description":
          "The user type. It determines the method to authenticate the user during login. The default is the database's built-in user type. Flags include 'BUILT_IN', 'CLOUD_IAM_USER', or 'CLOUD_IAM_SERVICE_ACCOUNT'.",
      },
      random_password: {
        "ui:description": "Whether to generate a random password for the user.",
      },
    },
  },
  iam_users: {
    "ui:description":
      "A list of IAM users to be created in your CloudSQL instance",
  },
  create_timeout: {
    "ui:description":
      "The optional timout that is applied to limit long database creates.",
  },
  update_timeout: {
    "ui:description":
      "The optional timout that is applied to limit long database updates.",
  },
  delete_timeout: {
    "ui:description":
      "The optional timout that is applied to limit long database deletes.",
  },
  read_replica_deletion_protection: {
    "ui:description":
      "Used to block Terraform from deleting replica SQL Instances.",
  },
  encryption_key_name: {
    "ui:emptyValue": null,
    "ui:description":
      "The full path to the encryption key used for the CMEK disk encryption",
  },
  insights_config: {
    "ui:description": "The insights_config settings for the database.",
    query_plans_per_minute: {
      "ui:description":
        "The maximum query plans per minute (QPM) for the instance.",
      "ui:emptyValue": null,
    },
    query_string_length: {
      "ui:description": "The maximum query string length for the instance.",
      "ui:emptyValue": null,
    },
    record_application_tags: {
      "ui:description": "Whether to record application tags.",
    },
    record_client_address: {
      "ui:description": "Whether to record client address.",
    },
  },
};
