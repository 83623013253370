import { React, useState } from "react";
import { styled } from "@mui/material/styles";
import { generateTemplates } from "@rjsf/mui";
import { getUiOptions, canExpand, titleId } from "@rjsf/utils";
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Paper,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  //border: "1px solid red",
  minHeight: 32,
  "&.Mui-expanded": {
    minHeight: 32,
    margin: 0,
    //border: "1px solid yellow",
  },
  "& .MuiAccordionSummary-content": {
    margin: 0,
    padding: 0,
  },
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingLeft: "8px", // You can modify as per your needs
  paddingTop: "0px", // You can modify as per your needs
  paddingRight: "8px", // You can modify as per your needs
  paddingBottom: "8px", // You can modify as per your needs
  marginTop: "8px",
  //border: "1px solid green",
}));
const templates = generateTemplates();
/**
 * ObjectFieldTemplateX component is used for displaying and manipulating
 * an object in a form. We extend this to add Accordian functionality so that the forms
 * can be folded and unfolded.
 * @component ObjectFieldTemplateX
 * @param {Object} props - Properties passed to the component
 * @param {string} props.description - Description of the object
 * @param {string} props.title - Title of the object
 * @param {Object[]} props.properties - List of properties of the object
 * @param {boolean} props.required - Indicates if the object is required
 * @param {boolean} props.disabled - Indicates if the object is disabled
 * @param {boolean} props.readonly - Indicates if the object is read-only
 * @param {Object} props.uiSchema - UI specific configurations for the object
 * @param {Object} props.idSchema - Schema information related to the object
 * @param {Object} props.schema - The JSON schema definition for the object
 * @param {Object} props.formData - Stores the form's existing data
 * @param {Function} props.onAddClick - Callback function for when Add button is clicked
 * @param {Object} props.registry - Holds form registry fields
 * @returns {JSX.Element} An element for displaying and manipulating object
 **/
export const ObjectFieldTemplateX = (props) => {
  const {
    description,
    properties,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
    registry,
  } = props;
  const [expanded, setExpanded] = useState(true);
  const AddButton = templates.ButtonTemplates.AddButton;
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <Accordion
        key={`accordion-${titleId(idSchema)}`}
        onChange={() => handleExpandClick()}
        expanded={expanded}
        disableGutters // This is to remove the extra magin when accordion is expanded. It is needed for CustomAcoordionSummary
      >
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
          {description && <Typography m={1}>{description}</Typography>}
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Grid container={true} spacing={2}>
            {properties.map((element, index) =>
              // Remove the <Grid> if the inner element is hidden as the <Grid>
              // itself would otherwise still take up space.
              element.hidden ? (
                element.content
              ) : (
                <Grid item={true} xs={12} key={index}>
                  {element.content}
                </Grid>
              )
            )}
            {canExpand(schema, uiSchema, formData) && (
              <Grid container justifyContent="flex-end">
                <Grid item={true}>
                  <AddButton
                    className="object-property-expand"
                    onClick={onAddClick(schema)}
                    disabled={disabled || readonly}
                    uiSchema={uiSchema}
                    registry={registry}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </CustomAccordionDetails>
      </Accordion>
    </>
  );
};

/**
 * ArrayFieldTemplateX component is used for displaying and manipulating
 * an array of items in a form. It allows users to add and remove items from the list.
 *
 * @component
 * @param {Object} props - Properties passed to the component
 * @param {string} props.id - Identifies the array field
 * @param {boolean} props.canAdd - Flag that determines if a new item can be added to the array
 * @param {boolean} props.disabled - Flag that controls if the array field is disabled
 * @param {Object} props.idSchema - Schema information related to the array
 * @param {Object} props.uiSchema - UI specific configurations for the array field
 * @param {Object[]} props.items - List of item objects
 * @param {Function} props.onAddClick - Callback function for when Add button is clicked
 * @param {boolean} props.readonly - Indicates if array field is read-only
 * @param {Object} props.registry - Holds form registry fields
 * @param {boolean} props.required - Indicates if the array field is required
 * @param {Object} props.schema - The JSON schema definition for the array
 * @param {string} props.title - The title of the array field
 * @param {Object} props.formData - Stores the form's existing data
 *
 * @returns {JSX.Element} An element for displaying and manipulating array field
 */
export const ArrayFieldTemplateX = (props) => {
  const {
    id,
    canAdd,
    disabled,
    idSchema,
    uiSchema,
    items,
    onAddClick,
    readonly,
    registry,
    required,
    schema,
    title,
    formData,
  } = props;
  const uiOptions = getUiOptions(uiSchema);
  const TitleField = templates.TitleFieldTemplate;
  const DescriptionField = templates.DescriptionFieldTemplate;
  const AddButton = templates.ButtonTemplates.AddButton;
  const RemoveButton = templates.ButtonTemplates.RemoveButton;
  const ArrayFieldItemTemplate = templates.ArrayFieldItemTemplate;

  const getTitleForAccordian = (index) => {
    // Same item title if available
    let item_title = null;
    const { items } = schema;
    if (!items) {
      return `${index} | ${title}`;
    } else {
      if ("title" in items) {
        // Save the value of items.title if it exists
        item_title = items.title;
      }
      if ("additionalProperties" in items) {
        return `${index} | ${item_title}`;
      }
    }

    if ("ui:displayfield" in uiSchema["items"]) {
      const displayfield = uiSchema["items"]["ui:displayfield"];
      if (displayfield in formData[index]) {
        if (formData[index][displayfield]) {
          return `${index} | ${formData[index][displayfield]}`;
        }
      }
    }
    if (
      typeof formData[index] === "string" ||
      typeof formData[index] === "number" ||
      formData[index] === null ||
      formData[index] === undefined
    ) {
      const value = formData[index];
      if (value) {
        return `${index} | ${value}`;
      }
    }
    if (item_title) {
      return `${index} | ${item_title} not set`;
    } else {
      return `${index} | ${title} not set`;
    }
  };

  return (
    <Paper elevation={1}>
      <Box p={2}>
        <TitleField
          id={id}
          title={uiOptions.title || title}
          schema={schema}
          uiSchema={uiSchema}
          required={required}
          registry={registry}
        />
        <DescriptionField
          id={id}
          description={uiOptions.description}
          schema={schema}
          uiSchema={uiSchema}
          required={required}
          registry={registry}
        />

        {items.map(({ key, ...itemProps }, index) => (
          <Accordion key={key} disableGutters>
            <CustomAccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Box
                alignContent="center"
                display="flex"
                justifyContent="space-between"
                alignItems="stretch"
                flexGrow={1}
                flexDirection="row"
              >
                <Typography m={1}>{getTitleForAccordian(index)}</Typography>
                <Box display="flex">
                  <RemoveButton
                    disabled={disabled || readonly}
                    uiSchema={uiSchema}
                    registry={registry}
                    onClick={itemProps.onDropIndexClick(index)}
                  />
                </Box>
              </Box>
            </CustomAccordionSummary>
            <CustomAccordionDetails>
              <Grid container={true} key={`array-item-list-${idSchema.$id}`}>
                <ArrayFieldItemTemplate key={key} {...itemProps} />
              </Grid>
            </CustomAccordionDetails>
          </Accordion>
        ))}
        {canAdd && (
          <Grid container justifyContent="flex-end">
            <Grid item={true}>
              <AddButton
                className="array-item-add"
                onClick={onAddClick}
                disabled={disabled || readonly}
                uiSchema={uiSchema}
                registry={registry}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Paper>
  );
};
