export const databricksWorkspacePermissionsSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      description: "The name of the module",
    },
    users: {
      description:
        "List of usernames that will be granted USER permission on the workspace",
      type: "array",
      items: {
        title: "Username",
        type: "string",
      },
      default: [],
    },
    admins: {
      description:
        "List of usernames that will be granted ADMIN permission on the workspace",
      type: "array",
      items: {
        title: "Username",
        type: "string",
      },
      default: [],
    },
    user_groups: {
      description:
        "List of groupnames that will be granted USER permission on the workspace",
      type: "array",
      items: {
        title: "Groupname",
        type: "string",
      },
      default: [],
    },
    admin_groups: {
      description:
        "List of groupname that will be granted ADMIN permission on the workspace",
      type: "array",
      items: {
        title: "Groupname",
        type: "string",
      },
      default: [],
    },
    user_service_principles: {
      description:
        "List of service_principle names that will be granted USER permission on the workspace",
      type: "array",
      items: {
        title: "service_principle",
        type: "string",
      },
      default: [],
    },
    admin_service_principles: {
      description:
        "List of service_principle names that will be granted ADMIN permission on the workspace",
      type: "array",
      items: {
        title: "service_principle",
        type: "string",
      },
      default: [],
    },
  },
  required: ["name"],
};

export const databricksWorkspacePermissionsUISchema = {
  "ui:description": "Workspace permissions",
  users: {
    "ui:description":
      "List of usernames that will be granted USER permission on the workspace",
    items: {
      "ui:description": "Username",
    },
  },
  admins: {
    "ui:description":
      "List of usernames that will be granted ADMIN permission on the workspace",
    items: {
      "ui:description": "Username",
    },
  },
  user_groups: {
    "ui:description":
      "List of groupnames that will be granted USER permission on the workspace",
    items: {
      "ui:description": "Groupname",
    },
  },
  admin_groups: {
    "ui:description":
      "List of groupnames that will be granted ADMIN permission on the workspace",
    items: {
      "ui:description": "Groupname",
    },
  },
  user_service_principles: {
    "ui:description":
      "List of service_principle names that will be granted USER permission on the workspace",
    items: {
      "ui:description": "service_principle",
    },
  },
  admin_service_principles: {
    "ui:description":
      "List of service_principle names that will be granted ADMIN permission on the workspace",
    items: {
      "ui:description": "service_principle",
    },
  },
};
