export const tcpudpLBSchema = {
  type: "object",
  title: "External TCP/UDP Load Balancer",
  properties: {
    name: {
      title: "Name",
      description:
        "Name for the forwarding rule and prefix for supporting resources.",
      type: "string",
    },
    region: {
      title: "Region",
      description: "Region used for GCP resources.",
      type: "string",
    },
    network: {
      title: "Network",
      type: "string",
      description: "Name of the network to create resources in.",
    },
    service_port: {
      title: "Service Port",
      description: "TCP port your service is listening on.",
      type: "number",
    },
    ip_protocol: {
      title: "IP Protocol",
      type: "string",
      enum: ["TCP", "UDP", "ESP", "AH", "SCTP", "ICMP"],
      default: "TCP",
    },
    session_affinity: {
      title: "Session Affinity",
      description:
        "How to distribute load. Options are `NONE`, `CLIENT_IP` and `CLIENT_IP_PROTO`",
      enum: ["NONE", "CLIENT_IP", "CLIENT_IP_PROTO"],
      default: "NONE",
    },
    disable_health_check: {
      title: "Disable Health Check",
      description: "Disables the health check on the target pool.",
      type: "boolean",
      default: false,
    },
    health_check: {
      description:
        "Health check to determine whether instances are responsive and able to do work",
      title: "Health Check",
      type: "object",
      properties: {
        check_interval_sec: {
          type: ["number", "null"],
          default: null,
          title: "Check Interval Seconds",
        },
        healthy_threshold: {
          type: ["number", "null"],
          default: null,
          title: "Healthy Threshold",
        },
        timout_sec: {
          type: ["number", "null"],
          default: null,
          title: "Timout Seconds",
        },
        unhealthy_threshold: {
          type: ["number", "null"],
          default: null,
          title: "Unhealthy threshold",
        },
        port: {
          type: ["number", "null"],
          default: null,
          title: "Port",
        },
        request_path: {
          type: ["string", "null"],
          default: null,
          title: "Request Path",
        },
        host: {
          type: ["string", "null"],
          default: null,
          title: "Host",
        },
      },
    },
    ip_address: {
      type: ["string", "null"],
      title: "IP Address",
      description:
        "IP address of the external load balancer, if empty one will be assigned.",
      default: null,
    },
  },
  required: ["name", "region", "network", "service_port"],
};
