export const gcpPubSubSchema = {
  type: "object",
  properties: {
    topic: {
      type: "string",
      description:
        "The Pub/Sub topic name. If you set create_topic to true, this will be the name of the topic that is created. If create_topic is false then the subscriptions will be created on this existing topic.",
    },
    create_topic: {
      type: "boolean",
      description: "Specify true if you want to create a topic.",
      default: true,
    },
    create_subscriptions: {
      type: "boolean",
      description: "Specify true if you want to create subscriptions.",
      default: true,
    },
    topic_labels: {
      type: "object",
      description: "A map of labels to assign to the Pub/Sub topic.",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },
    push_subscriptions: {
      type: "array",
      description: "The list of the push subscriptions.",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            description: "The name of the subscription.",
          },
          push_endpoint: {
            type: "string",
            description:
              "A URL locating the endpoint to which messages should be pushed.",
          },
          ack_deadline_seconds: {
            type: "number",
            description:
              "The maximum time after a subscriber receives a message before the subscriber should acknowledge the message.",
            default: 10,
          },
          message_retention_duration: {
            type: ["string", "null"],
            description:
              "How long to retain unacknowledged messages in the subscription's backlog, from the moment a message is published to the subscription. If null, it will default to 7 days.",
            default: null,
          },
          retain_acked_messages: {
            type: "boolean",
            description:
              "Whether to retain acknowledged messages. If true, then messages are not expunged from the subscription's backlog, even if they are acknowledged, until they fall out of the message_retention_duration window.",
            default: false,
          },
          filter: {
            type: ["string", "null"],
            description:
              "A filter applied to incoming messages. If the filter returns true, the message will be published to this subscription. If false, the message will be discarded.",
            default: null,
          },
          expiration_policy: {
            type: ["string", "null"],
            description: "Expiration policy ttl",
            default: null,
          },
          dead_letter_topic: {
            type: "string",
            description:
              "The name of the topic to which dead letter messages should be published.",
            default: "",
          },
          max_delivery_attempts: {
            type: "number",
            description:
              "The maximum number of delivery attempts for any message. The value must be between 5 and 100.",
            default: 5,
          },
          maximum_backoff: {
            type: "string",
            description:
              "The maximum delay between consecutive deliveries of a given message. Value should be between 0 and 600 seconds. Defaults to 600 seconds.",
            default: "",
          },
          minimum_backoff: {
            type: "string",
            description:
              "The minimum delay between consecutive deliveries of a given message. Value should be between 0 and 600 seconds. Defaults to 10 seconds.",
            default: "",
          },
          "x-goog-version": {
            type: "string",
            description:
              "The version of the API used in this request, for example v1.",
            default: "v1",
          },
          oidc_service_account_email: {
            type: "string",
            description:
              "Service account email to be used for generating the OIDC token. The caller (for CreateSubscription, UpdateSubscription, and ModifyPushConfig RPCs) must have the iam.serviceAccounts.actAs permission for the service account.",
            default: "",
          },
          audience: {
            type: "string",
            description:
              "Audience to be used when generating OIDC token. The audience claim identifies the recipients that the JWT is intended for. The audience value is a single case-sensitive string. Having multiple values (array) for the audience field is not supported. More info about the OIDC JWT token audience here: https://tools.ietf.org/html/rfc7519#section-4.1.3 Note: if not specified, the Push endpoint URL will be used.",
            default: "",
          },
        },
        required: ["name", "push_endpoint"],
      },
      default: [],
    },
    pull_subscriptions: {
      type: "array",
      description: "The list of the pull subscriptions.",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            description: "The name of the subscription.",
          },
          service_account: {
            type: ["string", "null"],
            description: "The service account email to be used for pull.",
            default: null,
          },
          enable_exactly_once_delivery: {
            type: "boolean",
            description: "Enable exactly once delivery",
            default: false,
          },
          ack_deadline_seconds: {
            type: "number",
            description:
              "The maximum time after a subscriber receives a message before the subscriber should acknowledge the message.",
            default: 10,
          },
          message_retention_duration: {
            type: ["string", "null"],
            description:
              "How long to retain unacknowledged messages in the subscription's backlog, from the moment a message is published to the subscription. If null, it will default to 7 days.",
            default: null,
          },
          retain_acked_messages: {
            type: "boolean",
            description:
              "Whether to retain acknowledged messages. If true, then messages are not expunged from the subscription's backlog, even if they are acknowledged, until they fall out of the message_retention_duration window.",
            default: false,
          },
          filter: {
            type: ["string", "null"],
            description:
              "A filter applied to incoming messages. If the filter returns true, the message will be published to this subscription. If false, the message will be discarded.",
            default: null,
          },
          enable_message_ordering: {
            type: "boolean",
            description:
              "If true, messages published with the same ordering_key in PubsubMessage will be delivered to the subscribers in the order in which they are received by the Pub/Sub system. Otherwise, they may be delivered in any order.",
            default: true,
          },
          expiration_policy: {
            type: ["string", "null"],
            description: "Expiration policy ttl",
            default: null,
          },
          dead_letter_topic: {
            type: "string",
            description:
              "The name of the topic to which dead letter messages should be published.",
            default: "",
          },
          max_delivery_attempts: {
            type: "number",
            description:
              "The maximum number of delivery attempts for any message. The value must be between 5 and 100.",
            default: 5,
          },
          maximum_backoff: {
            type: "string",
            description:
              "The maximum delay between consecutive deliveries of a given message. Value should be between 0 and 600 seconds. Defaults to 600 seconds.",
            default: "",
          },
          minimum_backoff: {
            type: "string",
            description:
              "The minimum delay between consecutive deliveries of a given message. Value should be between 0 and 600 seconds. Defaults to 10 seconds.",
            default: "",
          },
        },
        required: ["name"],
      },
      default: [],
    },
    bigquery_subscriptions: {
      type: "array",
      description: "The list of the bigquery push subscriptions.",
      items: {
        type: "object",
        properties: {
          name: {
            type: "string",
            description: "The name of the subscription.",
          },
          table: {
            type: "string",
            description:
              "The name of the BigQuery table to which the messages are published.",
          },
          use_topic_schema: {
            type: "boolean",
            description: "Use topic schema",
            default: false,
          },
          write_metadata: {
            type: "boolean",
            description: "Write metadata",
            default: false,
          },
          drop_unknown_fields: {
            type: "boolean",
            description: "Drop unknown fields",
            default: false,
          },
          ack_deadline_seconds: {
            type: "number",
            description:
              "The maximum time after a subscriber receives a message before the subscriber should acknowledge the message.",
            default: 10,
          },
          message_retention_duration: {
            type: ["string", "null"],
            description:
              "How long to retain unacknowledged messages in the subscription's backlog, from the moment a message is published.",
            default: null,
          },
          retain_acked_messages: {
            type: "boolean",
            description:
              "Whether to retain acknowledged messages. If true, then messages are not expunged from the subscription's backlog, even if they are acknowledged, until they fall out of the message_retention_duration window.",
            default: false,
          },
          filter: {
            type: ["string", "null"],
            description:
              "A filter applied to incoming messages. If the filter returns true, the message will be published to this subscription. If false, the message will be discarded.",
            default: null,
          },
          enable_message_ordering: {
            type: "boolean",
            description:
              "If true, messages published with the same ordering_key in PubsubMessage will be delivered to the subscribers in the order in which they are received by the Pub/Sub system. Otherwise, they may be delivered in any order.",
            default: true,
          },
          expiration_policy: {
            type: ["string", "null"],
            description: "Expiration policy ttl",
            default: null,
          },
          dead_letter_topic: {
            type: "string",
            description:
              "The name of the topic to which dead letter messages should be published.",
            default: "",
          },
          max_delivery_attempts: {
            type: "number",
            description:
              "The maximum number of delivery attempts for any message. The value must be between 5 and 100.",
            default: 5,
          },
          maximum_backoff: {
            type: ["string", "null"],
            description:
              "The maximum delay between consecutive deliveries of a given message. Value should be between 0 and 600 seconds. Defaults to 600 seconds.",
          },
          minimum_backoff: {
            type: ["string", "null"],
            description:
              "The minimum delay between consecutive deliveries of a given message. Value should be between 0 and 600 seconds. Defaults to 10 seconds.",
          },
        },
        required: ["name", "table"],
      },
      default: [],
    },
    subscription_labels: {
      type: "object",
      description: "A map of labels to assign to every Pub/Sub subscription.",
      additionalProperties: {
        type: "string",
      },
      default: {},
    },
    topic_message_retention_duration: {
      type: ["string", "null"],
      description:
        "The minimum duration in seconds to retain a message after it is published to the topic.",
      default: null,
    },
    topic_kms_key_name: {
      type: ["string", "null"],
      description:
        "The resource name of the Cloud KMS CryptoKey to be used to protect access to messages published on this topic.",
      default: null,
    },
    grant_token_creator: {
      type: "boolean",
      description:
        "Specify true if you want to add token creator role to the default Pub/Sub SA.",
      default: true,
    },
    schema: {
      type: "object",
      description: "Schema for the topic.",
      properties: {
        name: {
          type: ["string", "null"],
          description: "The name of the schema.",
          default: null,
        },
        type: {
          type: ["string", "null"],
          description: "The type of the schema definition.",
          enum: ["AVRO", "PROTOCOL_BUFFER", "TYPE_UNSPECIFIED", null],
          default: null,
        },
        definition: {
          type: ["string", "null"],
          description:
            "The definition of the schema. This can be either a string of the form 'message_type={...}', or a JSON object conforming to the schema definition language for the chosen schema type.",
          default: null,
        },
        encoding: {
          type: ["string", "null"],
          enum: ["ENCODING_UNSPECIFIED", "JSON", "BINARY", null],
          description:
            "The encoding of messages validated against schema. Default value is ENCODING_UNSPECIFIED. Possible values are: ENCODING_UNSPECIFIED, JSON, BINARY",
          default: null,
        },
      },
      default: null,
    },
  },
  required: ["topic"],
};

export const gcpPubSubUISchema = {
  "ui:description": "PubSub module settings",
  push_subscriptions: {
    "ui:description": "Push subscriptions",
    items: {
      "ui:description": "Push subscription",
      "ui:displayfield": "name",
      message_retention_duration: {
        "ui:emptyValue": null,
      },
      filter: {
        "ui:emptyValue": null,
      },
      expiration_policy: {
        "ui:emptyValue": null,
      },
    },
  },
  pull_subscriptions: {
    "ui:description": "Pull subscriptions",
    items: {
      "ui:description": "Pull subscription",
      "ui:displayfield": "name",
      message_retention_duration: {
        "ui:emptyValue": null,
      },
      service_account: {
        "ui:emptyValue": null,
      },
      filter: {
        "ui:emptyValue": null,
      },
      expiration_policy: {
        "ui:emptyValue": null,
      },
    },
  },
  bigquery_subscriptions: {
    "ui:description": "BigQuery subscriptions",
    items: {
      "ui:description": "BigQuery subscription",
      "ui:displayfield": "name",
      message_retention_duration: {
        "ui:emptyValue": null,
      },
      filter: {
        "ui:emptyValue": null,
      },
      expiration_policy: {
        "ui:emptyValue": null,
      },
    },
  },
  topic_message_retention_duration: {
    "ui:emptyValue": null,
  },
  topic_kms_key_name: {
    "ui:emptyValue": null,
  },
  schema: {
    "ui:emptyValue": null,
    name: {
      "ui:emptyValue": null,
    },
    type: {
      "ui:emptyValue": null,
    },
    definition: {
      "ui:emptyValue": null,
      "ui:widget": "textarea",
      "ui:options": {
        rows: 15,
      },
    },
    encoding: {
      "ui:emptyValue": null,
    },
  },
};
