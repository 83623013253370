import { React, useState, useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { apiCall } from "../../utils/api";
import { useSnackbar } from "notistack";
import { db } from "../../firebase";
import { onSnapshot, doc } from "firebase/firestore";
import {
  Grid,
  Box,
  Typography,
  Button,
  IconButton,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export default function GithubSettings({ organization_id, isAdmin }) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [repo, setRepo] = useState("");
  const [rootDir, setRootDir] = useState(""); // root directory of the repo
  const [branch, setBranch] = useState("master"); // branch to push to
  const [installation_id, setInstallationId] = useState(null); // github app installation id
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let unsubscribeGithubSettings = null;
    if (organization_id) {
      unsubscribeGithubSettings = onSnapshot(
        doc(db, `organizations/${organization_id}`),
        (doc) => {
          const github_data = doc.data()?.github;
          if (github_data === undefined) {
            // Node data is undefined probably the node is deleted
            return;
          }
          setRepo(github_data?.repo ? github_data.repo : "");
          setBranch(github_data?.branch ? github_data.branch : "master");
          setRootDir(github_data?.rootDir ? github_data.rootDir : "/");
          setInstallationId(
            github_data?.installation_id ? github_data.installation_id : null
          );
        }
      );
    }
    return () => {
      if (unsubscribeGithubSettings) {
        unsubscribeGithubSettings();
      }
    };
    // eslint-disable-next-line
  }, [organization_id]);

  const connectGithubSettings = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/organization/github`,
      "POST",
      {
        organization_id,
        repo,
        rootDir,
        branch,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.json();
      enqueueSnackbar(data.message, {
        variant: "error",
      });
    } else {
      const data = await res.json();
      enqueueSnackbar(data.message, {
        variant: "success",
      });
    }
    setOpenBackdrop(false);
  };

  const disconnectGithubSettings = async () => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/organization/github`,
      "DELETE",
      {
        organization_id,
      },
      currentUser
    );
    if (res.status !== 200) {
      const data = await res.json();
      enqueueSnackbar(data.message, {
        variant: "error",
      });
    } else {
      const data = await res.json();
      enqueueSnackbar(data.message, {
        variant: "success",
      });
    }
    setOpenBackdrop(false);
  };

  return (
    <>
      <Grid item xs={12} borderColor={"black"}>
        <Typography variant="h6" m={1}>
          Github Settings:
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" m={1}>
          Repo:
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <GithubSettingField
          setFieldFunction={setRepo}
          currentValue={repo}
          isAdmin={isAdmin}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" m={1}>
          Branch:
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <GithubSettingField
          setFieldFunction={setBranch}
          currentValue={branch}
          isAdmin={isAdmin}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" m={1}>
          Root Folder:
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <GithubSettingField
          setFieldFunction={setRootDir}
          currentValue={rootDir}
          isAdmin={isAdmin}
        />
      </Grid>
      <Grid item xs={2}>
        <Typography variant="body1" m={1}>
          Status:
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Box display="flex" alignItems="center" flexDirection="row">
          {installation_id ? (
            <CheckCircleIcon style={{ color: "green" }} />
          ) : (
            <CancelIcon style={{ color: "red" }} />
          )}
          <Typography m={1}>
            {installation_id ? "Connected" : "Not Connected"}
          </Typography>
        </Box>
      </Grid>
      {isAdmin() && (
        <Grid item xs={12}>
          <Box display="flex" flexDirection="row" alignContent="center">
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => connectGithubSettings()}
              >
                Connect
              </Button>
            </Box>
            <Box m={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => disconnectGithubSettings()}
                m={1}
              >
                Disconnect
              </Button>
            </Box>
          </Box>
        </Grid>
      )}
      <Backdrop
        open={openBackdrop}
        sx={{ zIndex: (theme) => theme.zIndex.modal + 1 }}
        onClick={() => setOpenBackdrop(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export function GithubSettingField({
  setFieldFunction,
  currentValue,
  isAdmin,
}) {
  const [editField, setEditField] = useState(false);
  const [fieldSetting, setFieldSetting] = useState(
    currentValue ? currentValue : ""
  );

  const applyChangeField = () => {
    setFieldFunction(fieldSetting);
    setEditField(false);
  };

  useEffect(() => {
    setFieldSetting(currentValue);
  }, [currentValue]);

  const handleCancelChangeName = () => {
    setEditField(false);
    setFieldFunction(currentValue);
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="row">
      {!editField && isAdmin() && (
        <IconButton onClick={() => setEditField(true)}>
          <EditIcon />
        </IconButton>
      )}
      {!editField && (
        <Typography m={1} variant="subtitle1" fullWidth>
          {fieldSetting}
        </Typography>
      )}
      {editField && (
        <TextField
          defaultValue={fieldSetting}
          onChange={(e) => setFieldSetting(e.target.value)}
          fullWidth
        />
      )}
      {editField && (
        <IconButton onClick={() => applyChangeField(false)}>
          <CheckIcon />
        </IconButton>
      )}
      {editField && (
        <IconButton onClick={() => handleCancelChangeName(false)}>
          <ClearIcon />
        </IconButton>
      )}
    </Box>
  );
}
