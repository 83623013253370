export const internalTCPUDPLBSchema = {
  title: "Internal TCP/UDP LB",
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Name",
      description:
        "Name for the forwarding rule and prefix for supporting resources.",
    },
    network: {
      title: "VPC Name",
      description: "Name of the network to create resources in.",
      type: "string",
    },
    subnetwork: {
      title: "Subnetwork Name",
      description: "Name of the subnetwork to create resources in.",
      type: "string",
    },
    region: {
      title: "Region",
      type: "string",
      default: "us-central1",
    },
    global_access: {
      title: "Global Access",
      type: "boolean",
      description: "Allow all regions on the same VPC network access.",
      default: false,
    },
    network_project: {
      title: "Network Project",
      description:
        "Name of the project for the network. Useful for shared VPC. Default is var.project.",
      type: "string",
    },
    backends: {
      title: "Backends",
      description:
        "List of backends, should be a map of key-value pairs for each backend, must have the 'group' key.",
      type: "array",
      items: {
        type: "object",
        properties: {
          group: {
            type: "string",
            title: "Group",
          },
          description: {
            type: "string",
            title: "Description",
            default: "",
          },
        },
        required: ["group"],
      },
    },
    ip_protocol: {
      title: "IP Protocol",
      type: "string",
      enum: ["TCP", "UDP"],
      default: "TCP",
    },
    ip_address: {
      type: ["string", "null"],
      title: "IP Address",
      description:
        "IP address of the external load balancer, if empty one will be assigned.",
      default: null,
    },
    session_affinity: {
      title: "Session Affinity",
      description:
        "How to distribute load. Options are `NONE`, `CLIENT_IP` and `CLIENT_IP_PROTO`",
      enum: ["NONE", "CLIENT_IP", "CLIENT_IP_PROTO"],
      default: "NONE",
    },
    ports: {
      type: "array",
      title: "Ports",
      description:
        "List of ports range to forward to backend services. Max is 5.",
      items: {
        type: "string",
      },
    },
    all_ports: {
      type: ["boolean", "null"],
      default: null,
      description: "Boolean for all_ports setting on forwarding rule.",
      title: "All Ports",
    },
    health_check: {
      description:
        "Health check to determine whether instances are responsive and able to do work",
      title: "Health Check",
      type: "object",
      properties: {
        type: {
          type: "string",
          title: "Type",
          default: "tcp",
          enum: ["tcp", "http"],
        },
        check_interval_sec: {
          type: ["number", "null"],
          default: null,
          title: "Check Interval Seconds",
        },
        healthy_threshold: {
          type: ["number", "null"],
          default: null,
          title: "Healthy Threshold",
        },
        timout_sec: {
          type: ["number", "null"],
          default: null,
          title: "Timout Seconds",
        },
        unhealthy_threshold: {
          type: ["number", "null"],
          default: null,
          title: "Unhealthy threshold",
        },
        port: {
          type: ["number", "null"],
          default: null,
          title: "Port",
        },
        port_name: {
          type: ["string", "null"],
          default: null,
          title: "Port Name",
        },
        request: {
          type: ["string", "null"],
          default: null,
          title: "Request",
        },
        proxy_header: {
          type: ["string", "null"],
          default: null,
          title: "Proxy Header",
        },
        request_path: {
          type: ["string", "null"],
          default: null,
          title: "Request Path",
        },
        response: {
          type: ["string", "null"],
          default: null,
          title: "Response",
        },
        enable_log: {
          type: "boolean",
          default: false,
          title: "Enable Log",
        },
        host: {
          type: ["string", "null"],
          default: null,
          title: "Host",
        },
      },
    },
    connection_draining_timeout_sec: {
      title: "Connection Draining Timout in seconds",
      type: ["number", "null"],
      description: "Time for which instance will be drained",
      default: null,
    },
  },
  required: [
    "name",
    "network",
    "subnetwork",
    "backends",
    "ports",
    "health_check",
  ],
};
