import { React, useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import EmailIcon from "@mui/icons-material/Email";
import ThumbUpAltRoundedIcon from "@mui/icons-material/ThumbUpAltRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

import { useSnackbar } from "notistack";
import { useAuth } from "../contexts/AuthContext";
import { apiCall } from "../utils/api";
import {
  IconButton,
  ListSubheader,
  Backdrop,
  CircularProgress,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider,
  Tooltip,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Typography,
  MenuItem,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&:before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
        "#fff"
      )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

export default function Profile() {
  const { currentUser, colorMode } = useAuth();
  const theme = useTheme();

  return (
    <>
      <List
        sx={{
          width: "100%",
          maxWidth: 480,
          backgroundColor: (theme) => theme.palette.background.paper,
        }}
      >
        <ListItem>
          <ListItemAvatar>
            <Avatar src={currentUser.photoURL} />
          </ListItemAvatar>
          <ListItemText primary="Name:" secondary={currentUser.displayName} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <EmailIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Email:" secondary={currentUser.email} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <InfoIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary="Google ID:"
            secondary={currentUser.providerData[0].uid}
          />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <InfoIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="UID:" secondary={currentUser.uid} />
        </ListItem>
      </List>
      <FormGroup>
        <FormControlLabel
          control={
            <MaterialUISwitch
              sx={{ m: 1 }}
              defaultChecked={theme.palette.mode === "dark"}
              onClick={colorMode.toggleColorMode}
            />
          }
          label={theme.palette.mode === "dark" ? "Dark Mode" : "Light Mode"}
        />
      </FormGroup>
      <CloudTokens />
      <Invitations />
    </>
  );
}

export function Invitations() {
  const { currentUser, getOrganizations } = useAuth();
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const getInvitations = async () => {
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite`,
      "GET",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      setInvitations(inv_response);
    }
  };
  useEffect(() => {
    getInvitations();
    // eslint-disable-next-line
  }, []);

  const handleAccept = async (invitation) => {
    console.log(invitation);
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite/accept/`,
      "POST",
      {
        organization_id: invitation.organization_id,
        inviter: invitation.inviter,
      },
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      enqueueSnackbar(inv_response.message, { variant: "success" });
    }
    await getInvitations();
    await getOrganizations();
    setOpenBackdrop(false);
  };

  const handleCancel = async (invitation) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/team/invite/accept/${invitation.organization_id}/${currentUser.email}/${invitation.inviter}`,
      "DELETE",
      {},
      currentUser
    );
    if (res.status !== 200) {
      let error = await res.text();
      enqueueSnackbar(`Error ${error}`, { variant: "error" });
    }
    if (res.status === 200) {
      let inv_response = await res.json();
      enqueueSnackbar(inv_response.message, { variant: "success" });
    }
    await getInvitations();
    setOpenBackdrop(false);
  };

  return (
    invitations.length > 0 && (
      <>
        <List
          sx={{
            width: "100%",
            maxWidth: 480,
            backgroundColor: (theme) => theme.palette.background.paper,
          }}
        >
          <ListSubheader>Pending Invitations:</ListSubheader>
          <Divider variant="inset" component="li" />
          {invitations.map((invitation, index) => (
            <>
              <ListItem key={`invite-${index}`}>
                <ListItemText
                  secondary={`From: ${invitation.inviter}`}
                  primary={`Join ${invitation.organization_name} `}
                  key={`invite-text-${index}`}
                />
                <Tooltip title="Accept Invite" key={`accept-tooltip-${index}`}>
                  <span key={`accept-span-${index}`}>
                    <IconButton
                      key={`invite-accept-button-${index}`}
                      variant="contained"
                      color="primary"
                      onClick={() => handleAccept(invitation)}
                    >
                      <ThumbUpAltRoundedIcon key={`thumb-up-${index}`} />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip
                  title="Decline Invite"
                  key={`decline-tooltip-${index}`}
                >
                  <span key={`decline-span-${index}`}>
                    <IconButton
                      key={`invite-cancel-button-${index}`}
                      variant="contained"
                      color="primary"
                      onClick={() => handleCancel(invitation)}
                    >
                      <CancelRoundedIcon key={`cr-${index}`} />
                    </IconButton>
                  </span>
                </Tooltip>
              </ListItem>
              <Divider
                variant="inset"
                component="li"
                key={`divider-${index}`}
              />
            </>
          ))}
        </List>
        <Backdrop open={openBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    )
  );
}

const available_tokens = [
  "GOOGLE_OAUTH_ACCESS_TOKEN",
  "AWS_TOKEN_ID",
  "DATABRICKS_ADMIN_TOKEN",
];

function CloudTokens() {
  const [open, setOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState("");
  const [selectedTokenDisabled, setSelectedTokenDisabled] = useState(false);

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [tokenValue, setTokenValue] = useState("");
  const [isEditOperation, setIsEditOperation] = useState(false);
  const [cloudTokens, setCloudTokens] = useState([]);
  const { currentUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const getCloudTokens = async () => {
    const userDocRef = doc(db, "users", currentUser.uid);
    let user = await getDoc(userDocRef);
    const { cloud_tokens } = user.data();
    if (cloud_tokens) {
      setCloudTokens(cloud_tokens);
    }
  };

  const handleSelectedToken = (event) => {
    setSelectedToken(event.target.value);
  };

  const cloudTokenAlreadyExistAndNotEditOpertion = () => {
    if (cloudTokens.length === 0) {
      return false;
    }
    return cloudTokens.some(
      (token) => token.name === selectedToken && !isEditOperation
    );
  };
  const handleSaveToken = async () => {
    if (cloudTokenAlreadyExistAndNotEditOpertion()) {
      alert(
        `Token ${selectedToken} already exists. You can edit it instead of adding a new one.`
      );
      return;
    }
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/cloudtokens`,
      "POST",
      {
        name: selectedToken,
        value: tokenValue,
      },
      currentUser
    );
    if (res.status !== 200) {
      const error = await res.text();
      enqueueSnackbar(error, { variant: "error" });
    }
    if (res.status === 200) {
      const success = await res.json();
      enqueueSnackbar(success.message, { variant: "success" });
      setOpen(false);
    }
    await getCloudTokens();
    setOpenBackdrop(false);
    setIsEditOperation(false);
    setSelectedTokenDisabled(false);
  };

  const deleteToken = async (token) => {
    setOpenBackdrop(true);
    const res = await apiCall(
      `${process.env.REACT_APP_BACKEND_URL}/api/cloudtokens`,
      "DELETE",
      {
        name: token.name,
      },
      currentUser
    );
    if (res.status !== 200) {
      const error = await res.text();
      enqueueSnackbar(error, { variant: "error" });
    }
    if (res.status === 200) {
      const success = await res.json();
      enqueueSnackbar(success.message, { variant: "success" });
    }
    await getCloudTokens();
    setOpenBackdrop(false);
  };

  const editToken = (token) => {
    setSelectedToken(token.name);
    setSelectedTokenDisabled(true);
    setTokenValue("");
    setIsEditOperation(true);
    setOpen(true);
  };

  const handleCancel = () => {
    setSelectedTokenDisabled(false);
    setOpen(false);
  };

  useEffect(() => {
    getCloudTokens();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Box m={1} sx={{ maxWidth: 800 }}>
        <Box display="flex" flexDirection="column">
          <Typography ml={1} mt={1} variant="h6">
            Cloud Keys
          </Typography>
          <Typography ml={1} mb={1} variant="body2">
            Cloud tokens are used to access cloud resources. They can be used to
            deploy modules in your architectures. Tokens are usually short
            lived. You can obtain them from the cloud provider's console. For
            example, you can obtain a Google OAuth access token from the Google
            Cloud Console by running the following command:
            <pre>gcloud auth print-access-token</pre>
          </Typography>
        </Box>
        <Box m={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Typography component="div" m={1} width="50%">
              Cloud Token
            </Typography>
            <Typography
              component="div"
              display="flex"
              m={1}
              width="30%"
              justifyContent="right"
            >
              Last updated
            </Typography>
          </Box>
          {cloudTokens.map((token, index) => (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              key={index}
            >
              <Typography component="div" m={1} width="30%">
                {token.name}
              </Typography>
              <Typography
                component="div"
                m={1}
                width="50%"
                justifyContent="right"
                display="flex"
              >
                {new Date(token.update_time).toLocaleString()}
              </Typography>
              <Box display="flex" alignItems="center">
                <IconButton onClick={() => editToken(token)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => deleteToken(token)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Button
            m={1}
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Set Cloud Token for {currentUser.email}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Add Cloud Toknes to your user profile. For example add
                GOOGLE_ACCESS_TOKEN or AWS token. Tokens are used to
                authenticate against your cloud provider when deploying modules.
                Tokens take precedence over other authentication methods.
              </DialogContentText>
              <Box
                //variant="standard"
                // sx={{ margin: (theme) => theme.spacing(1), minWidth: 120 }}
                margin="normal"
                display="flex"
                flexDirection="column"
                m={1}
                noValidate
                autoComplete="off"
              >
                <TextField
                  select
                  value={selectedToken}
                  label="Cloud Key"
                  onChange={handleSelectedToken}
                  margin="normal"
                  disabled={selectedTokenDisabled}
                >
                  {available_tokens.map((token, index) => (
                    <MenuItem key={index} value={token}>
                      {token}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  id="outlined-multiline-static"
                  label="Value"
                  multiline
                  rows={10}
                  onChange={(e) => setTokenValue(e.target.value)}
                  margin="normal"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => handleCancel()}>Cancel</Button>
              <Button onClick={() => handleSaveToken()} autoFocus>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box>
      <Backdrop
        //sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={() => setOpenBackdrop(false)}
        sx={{
          color: "#fff",
          zIndex: (theme) =>
            Math.max.apply(Math, Object.values(theme.zIndex)) + 1,
        }}
      >
        <CircularProgress />
      </Backdrop>
    </>
  );
}
