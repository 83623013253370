export const awsIAMassumableRolesSchema = {
  type: "object",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    roles: {
      type: "array",
      title: "List of assumable roles",
      items: {
        type: "object",
        properties: {
          create_role: {
            type: "boolean",
            title: "create role",
            default: true,
          },
          role_name: {
            type: "string",
            title: "role name",
          },
          trusted_role_actions: {
            type: "array",
            items: {
              type: "string",
              title: "trusted role action",
            },
            title: "trusted role actions",
            default: ["sts:AssumeRole", "sts:TagSession"],
          },
          trusted_role_arns: {
            type: "array",
            items: {
              type: "string",
              title: "trusted role arn",
            },
            title: "trusted role arns",
            default: [],
          },
          trusted_role_services: {
            type: "array",
            items: {
              type: "string",
              title: "trusted role service",
            },
            title: "trusted role services",
            default: [],
          },
          mfa_age: {
            type: "number",
            title: "mfa age",
            default: 86400,
          },
          max_session_duration: {
            type: "number",
            title: "max session duration",
            default: 3600,
          },
          create_instance_profile: {
            type: "boolean",
            title: "create instance profile",
            default: false,
          },
          role_name_prefix: {
            type: ["string", "null"],
            title: "role name prefix",
            default: null,
          },
          role_path: {
            type: "string",
            title: "role path",
            default: "/",
          },
          role_requires_mfa: {
            type: "boolean",
            title: "role requires mfa",
            default: false,
          },
          role_permissions_boundary_arn: {
            type: "string",
            title: "role permissions boundary arn",
            default: "",
          },
          tags: {
            type: "object",
            additionalProperties: {
              type: "string",
            },
            title: "tags",
            default: {},
          },
          custom_role_policy_arns: {
            type: "array",
            items: {
              type: "string",
              title: "custom role policy arn",
            },
            title: "custom role policy arns",
            default: [],
          },
          custom_role_trust_policy: {
            type: "string",
            title: "custom role trust policy",
            default: "",
          },
          create_custom_role_trust_policy: {
            type: "boolean",
            title: "create custom role trust policy",
            default: false,
          },
          admin_role_policy_arn: {
            type: "string",
            title: "admin role policy arn",
            default: "arn:aws:iam::aws:policy/AdministratorAccess",
          },
          poweruser_role_policy_arn: {
            type: "string",
            title: "poweruser role policy arn",
            default: "arn:aws:iam::aws:policy/PowerUserAccess",
          },
          readonly_role_policy_arn: {
            type: "string",
            title: "readonly role policy arn",
            default: "arn:aws:iam::aws:policy/ReadOnlyAccess",
          },
          attach_admin_policy: {
            type: "boolean",
            title: "attach admin policy",
            default: false,
          },
          attach_poweruser_policy: {
            type: "boolean",
            title: "attach poweruser policy",
            default: false,
          },
          attach_readonly_policy: {
            type: "boolean",
            title: "attach readonly policy",
            default: false,
          },
          force_detach_policies: {
            type: "boolean",
            title: "force detach policies",
            default: false,
          },
          role_description: {
            type: "string",
            title: "role description",
            default: "",
          },
          role_sts_externalid: {
            type: "array",
            items: {
              type: "string",
              title: "role sts externalid",
            },
            title: "role sts externalid",
            default: [],
          },
          allow_self_assume_role: {
            type: "boolean",
            title: "allow self assume role",
            default: false,
          },
          role_requires_session_name: {
            type: "boolean",
            title: "role requires session name",
            default: false,
          },
          role_session_name: {
            type: "array",
            items: {
              type: "string",
              title: "role session name",
            },
            title: "role session name",
            default: ["{aws:username}"],
          },
        },
        required: ["role_name"],
      },
    },
  },
  required: ["name"],
};

export const awsIAMassumableRolesUISchema = {
  "ui:description": "AWS IAM Assumable Roles",
  name: {
    "ui:description": "Module Name",
  },
  roles: {
    "ui:description": "List of assumable roles",
    items: {
      "ui:description": "Assumable role",
      "ui:displayfield": "role_name",
      trusted_role_actions: {
        "ui:description": "Additional trusted role actions",
        items: {
          "ui:description": "Additional trusted role action",
        },
      },
      trusted_role_arns: {
        "ui:description": "ARNs of AWS entities who can assume these roles",
        items: {
          "ui:description": "ARN of AWS entity who can assume this role",
        },
      },
      trusted_role_services: {
        "ui:description": "AWS Services that can assume these roles",
        items: {
          "ui:description": "AWS Service that can assume this role",
        },
      },
      mfa_age: {
        "ui:description":
          "Max age of valid MFA (in seconds) for roles which require MFA",
      },
      max_session_duration: {
        "ui:description":
          "Maximum CLI/API session duration in seconds between 3600 and 43200",
      },
      create_role: {
        "ui:description": "Whether to create a role",
      },
      create_instance_profile: {
        "ui:description": "Whether to create an instance profile",
      },
      role_name: {
        "ui:description": "IAM role name",
        "ui:emptyValue": null,
      },
      role_name_prefix: {
        "ui:description": "IAM role name prefix",
        "ui:emptyValue": null,
      },
      role_path: {
        "ui:description": "Path of IAM role",
      },
      role_requires_mfa: {
        "ui:description": "Whether role requires MFA",
      },
      role_permissions_boundary_arn: {
        "ui:description": "Permissions boundary ARN to use for IAM role",
      },
      tags: {
        "ui:description": "A map of tags to add to IAM role resources",
        additionalProperties: {
          "ui:description": "Tag value",
        },
      },
      custom_role_policy_arns: {
        "ui:description": "List of ARNs of IAM policies to attach to IAM role",
        items: {
          "ui:description": "Policy ARN",
        },
      },
      custom_role_trust_policy: {
        "ui:description":
          "A custom role trust policy. (Only valid if create_custom_role_trust_policy = true)",
      },
      create_custom_role_trust_policy: {
        "ui:description":
          "Whether to create a custom_role_trust_policy. Prevent errors with count, when custom_role_trust_policy is computed",
      },
      admin_role_policy_arn: {
        "ui:description": "Policy ARN to use for admin role",
      },
      poweruser_role_policy_arn: {
        "ui:description": "Policy ARN to use for poweruser role",
      },
      readonly_role_policy_arn: {
        "ui:description": "Policy ARN to use for readonly role",
      },
      attach_admin_policy: {
        "ui:description": "Whether to attach an admin policy to a role",
      },
      attach_poweruser_policy: {
        "ui:description": "Whether to attach a poweruser policy to a role",
      },
      attach_readonly_policy: {
        "ui:description": "Whether to attach a readonly policy to a role",
      },
      force_detach_policies: {
        "ui:description":
          "Whether policies should be detached from this role when destroying",
      },
      role_description: {
        "ui:description": "IAM Role description",
      },
      role_sts_externalid: {
        "ui:description":
          "STS ExternalId condition values to use with a role (when MFA is not required)",
        items: {
          "ui:description": "STS ExternalId condition value",
        },
      },
      allow_self_assume_role: {
        "ui:description":
          "Determines whether to allow the role to be [assume itself](https://aws.amazon.com/blogs/security/announcing-an-update-to-iam-role-trust-policy-behavior/)",
      },
      role_requires_session_name: {
        "ui:description":
          "Determines if the role-session-name variable is needed when assuming a role(https://aws.amazon.com/blogs/security/easily-control-naming-individual-iam-role-sessions/)",
      },
      role_session_name: {
        "ui:description":
          "role_session_name for roles which require this parameter when being assumed. By default, you need to set your own username as role_session_name",
        items: {
          "ui:description": "role_session_name",
        },
      },
    },
  },
};
