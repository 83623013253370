export const vpcSchema = {
  title: "Define network settings",
  type: "object",
  properties: {
    network_name: {
      title: "Network Name",
      type: "string",
    },
    routing_mode: {
      title: "Routing Mode",
      type: "string",
      enum: ["GLOBAL", "REGIONAL"],
      default: "GLOBAL",
    },
    subnets: {
      type: "array",
      title: "Subnets",
      items: {
        properties: {
          subnet_name: {
            title: "Subnet Name",
            type: "string",
          },
          subnet_region: {
            title: "Subnet Region",
            type: "string",
          },
          subnet_ip: {
            title: "Subnet IP range",
            type: "string",
          },
          subnet_flow_logs: {
            title: "Subnet Flow Logs",
            type: "boolean",
            default: false,
          },
          secondary_ranges: {
            type: "array",
            title: "Secondary Ranges",
            items: {
              properties: {
                range_name: {
                  title: "Range Name",
                  type: "string",
                },
                ip_cidr_range: {
                  title: "IP Range",
                  type: "string",
                },
              },
            },
          },
        },
        //required: ["subnet_name", "subnet_region", "subnet_ip"],
      },
      default: [
        {
          subnet_name: "workload-subnet",
          subnet_region: "us-central1",
          subnet_ip: "10.0.0.0/24",
          subnet_flow_logs: true,
          secondary_ranges: [
            {
              range_name: "workload-secondary-range",
              ip_cidr_range: "192.168.0.0/16",
            },
          ],
        },
      ],
    },
    routes: {
      title: "Route Definitions",
      type: "array",
      items: {
        type: "object",
        properties: {
          name: {
            title: "Name",
            type: "string",
          },
          description: {
            title: "Description",
            type: ["string", "null"],
          },
          destination_range: {
            title: "Destination Range",
            type: ["string", "null"],
          },
          next_hop_ip: {
            title: "Next Hop IP",
            type: ["string", "null"],
          },
          next_hop_internet: {
            title: "Next Hop Internet",
            type: "string",
          },
          tags: {
            type: "string",
            title: "Tags",
          },
          next_hop_instance: {
            title: "Next Hop Instance",
            type: ["string", "null"],
          },
          next_hop_vpn_tunnel: {
            type: ["string", "null"],
            title: "Next Hop VPN Tunnel",
          },
          next_hop_instance_zone: {
            type: ["string", "null"],
            title: "Next Hop Instance Zone",
          },
          next_hop_ilb: {
            type: ["string", "null"],
            title: "Next Hop ILB",
          },
          priority: {
            type: ["string", "null"],
            title: "The priority of this route.",
            default: "1000",
          },
        },
      },
      default: [
        {
          name: "egress-internet",
          description: "route through IGW to access internet",
          destination_range: "0.0.0.0/0",
          tags: "egress-inet",
          next_hop_internet: "true",
        },
      ],
    },
    mtu: {
      title: "MTU",
      type: "number",
      default: 0,
    },
  },
  required: ["network_name"],
};

export const vpcSchemaUI = {
  "ui:description": "Define network settings",
  network_name: {
    "ui:description": "The name of the VPC network to create",
  },
  routing_mode: {
    "ui:description": "The network routing mode",
  },
  subnets: {
    "ui:description": "The subnets to create in the VPC network.",
    items: {
      "ui:description": "Subnet properties",
      "ui:displayfield": "subnet_name",
      subnet_name: {
        "ui:description": "The name of the subnet to create",
      },
      subnet_region: {
        "ui:description": "The region of the subnet to create",
      },
      subnet_ip: {
        "ui:description": "The IP range of the subnet to create",
      },
      subnet_flow_logs: {
        "ui:description": "Enalbe flow logs for the subnet",
      },
      secondary_ranges: {
        "ui:description":
          "The secondary ranges to create in the subnet. Mostly needed for GKE",
        items: {
          "ui:description": "Secondary range properties",
          "ui:displayfield": "range_name",
          range_name: {
            "ui:description": "The name of the secondary range to create",
          },
          ip_cidr_range: {
            "ui:description": "The IP range of the secondary range to create",
          },
        },
      },
    },
  },
  routes: {
    "ui:description": "The routes to create in the VPC network.",
    items: {
      "ui:description": "Route properties",
      "ui:displayfield": "name",
      name: {
        "ui:description": "The name of the route to create",
      },
      description: {
        "ui:description": "The description of the route to create",
      },
      destination_range: {
        "ui:description":
          "The destination range of outgoing packets that this route applies to. Only IPv4 is supported",
      },
      next_hop_ip: {
        "ui:description":
          "Network IP address of an instance that should handle matching packets",
      },
      next_hop_internet: {
        "ui:description":
          'Whether the next hop to this route will the default internet gateway. Use "true" to enable this as next hop',
      },
      tags: {
        "ui:description":
          "The network tags assigned to this route. This is a list in string format.",
      },
      next_hop_instance: {
        "ui:description":
          'URL or name of an instance that should handle matching packets. If just name is specified "next_hop_instance_zone" is required',
      },
      next_hop_vpn_tunnel: {
        "ui:description":
          "URL to a VpnTunnel that should handle matching packets",
      },
      next_hop_instance_zone: {
        "ui:description":
          "The zone of the instance specified in next_hop_instance. Only required if next_hop_instance is specified as a name",
      },
      next_hop_ilb: {
        "ui:description":
          "The URL to a forwarding rule of type loadBalancingScheme=INTERNAL that should handle matching packets.",
      },
      priority: {
        "ui:description": "The priority of this route.",
      },
    },
  },
  mtu: {
    "ui:description": "The MTU of the VPC network",
  },
};
