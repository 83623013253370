export const folderSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "Folder Name",
    },
  },
  required: ["name"],
};

export const folderSchemaUI = {
  "ui:description": "Create a new folder",
  name: {
    "ui:description": "Name of the folder",
  },
};
