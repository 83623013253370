export const folderIAMSchema = {
  title: "Define permissions for the folder",
  type: "object",
  properties: {
    name: {
      title: "Module Name",
      type: "string",
    },
    permissions: {
      type: "array",
      title: "IAM Permission",
      items: {
        properties: {
          role: {
            title: "Role",
            type: "string",
          },
          personas: {
            type: "array",
            title: "Persona",
            items: {
              type: "string",
              title: "Persona",
            },
          },
        },
      },
    },
  },
};

export const folderIAMSchemaUI = {
  "ui:description": "Define IAM permissions for the folder",
  permissions: {
    "ui:description":
      "Grant permissions to a role with the list of personas. Press the + button to add a new binding",
    items: {
      "ui:description": "Map of role to list of personas",
      "ui:displayfield": "role",
      role: {
        "ui:description": "For example roles/editor or roles/owner",
      },
      personas: {
        "ui:description": "Persona",
        items: {
          "ui:description":
            "For example user:jack@example.com, serviceAccount@project-id.gserviceaccount.com, group:admins@example.com",
        },
      },
    },
  },
};
